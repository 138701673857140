import moment from 'moment';

import parseTs from './parseTs';

const DISPLAY_DAY_FORMAT = 'DD-MM-YYYY';
const DISPLAY_LONG_DAY_FORMAT = 'MMMM D, YYYY';
const DISPLAY_WEEK_FORMAT = 'MMM D, YYYY';
const DISPLAY_WEEK_FORMAT_NO = 'D MMM,  YYYY';

const DEFAULT_OPTS = {
    format: DISPLAY_DAY_FORMAT,
};

// day = formattedDay(item.day)
// if (day) { print it out }
function formattedDay(ts, opts) {
    opts = { ...DEFAULT_OPTS, opts };

    const parsed = parseTs(ts);
    if (parsed === null || parsed === undefined) return parsed;

    return moment(parsed).format(opts.format);
}

const translate_month = (month) => {
    const allMonths = {
        Jan: 'jan',
        Feb: 'febr',
        Mar: 'mar',
        Apr: 'apr',
        May: 'mai',
        Jun: 'jun',
        Jul: 'jul',
        Aug: 'aug',
        Sep: 'sep',
        Oct: 'okt',
        Nov: 'nov',
        Dec: 'des',
    };
    return allMonths[month] ?? month;
};
const TRANSLATE_THIS_LABEL = (label) => {
    const month = label.match(/Jan|Feb|Mar|Apr|May|Jun|Jul|Aug|Sep|Oct|Nov|Dec/g);
    if (!month) return label;
    const translation = translate_month(month[0]);
    return label.replace(month, translation, 'g');
};
export default formattedDay;
export {
    DISPLAY_DAY_FORMAT,
    DISPLAY_LONG_DAY_FORMAT,
    DISPLAY_WEEK_FORMAT,
    DISPLAY_WEEK_FORMAT_NO,
    TRANSLATE_THIS_LABEL,
};
