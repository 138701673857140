import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownItem } from 'reactstrap';

import styles from '../ClientNav.module.scss';

interface LogoutProps {
    onLogout: () => Promise<unknown>;
}

export const Logout = ({ onLogout }: LogoutProps) => {
    const { t } = useTranslation();
    const onLogoutClick = async () => {
        try {
            await onLogout();
        } finally {
            window.location.reload();
        }
    };

    return (
        <DropdownItem onClick={onLogoutClick} className={styles.dropdownItem}>
            {t('Logout')}
        </DropdownItem>
    );
};
