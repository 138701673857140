import type React from 'react';
import { type MouseEvent, type ReactNode, useState } from 'react';
import type { Chart } from 'chart.js';
import classNames from 'classnames';

import { CopyIcon } from 'components/atoms/icons';

import styles from './CopyAsPng.module.scss';

export interface CopyAsPngProps {
    children?: ReactNode;
    chart?: Chart<'line' | 'bar' | 'doughnut' | 'pie'>;
    className?: string;
    onClick?: (ev: MouseEvent<HTMLButtonElement>) => void;
}

const CopyAsPng: React.FC<CopyAsPngProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
    chart,
    className = '',
    onClick = (e) => {},
}: CopyAsPngProps) => {
    const [isCopied, setIsCopied] = useState(false);

    return (
        <div className={classNames(styles.copyaspng, className)}>
            {/*  <span className={styles.copied}>{isCopied ? 'Copied!' : null}</span> */}
            <button onClick={onClick} className={classNames(styles.copyBtn)}>
                <CopyIcon size={24} />
            </button>
        </div>
    );
};

export default CopyAsPng;
export { CopyAsPng };
