import React from 'react';

import { Chart } from 'components/Chart';

import Legend from './components/Legend';
import options from './options';
import plugins from './plugins';

export default ({ data = [], colors = [], labels = [], relativeCounts = [] }) => {
    const chartData = {
        datasets: [
            {
                data,
                backgroundColor: colors,
            },
        ],
        labels,
    };

    return (
        <>
            <div style={{ maxWidth: '450px', width: '100%' }}>
                <Chart
                    type="doughnut"
                    data={chartData}
                    options={options()}
                    plugins={plugins}
                    header={false}
                    sidebar={false}
                />
            </div>
            <Legend
                colors={colors}
                labels={labels}
                relativeCounts={relativeCounts.map((item) => (item * 100).toFixed(1))}
            />
        </>
    );
};
