//@ts-nocheck
/**
 * AUTO_GENERATED Do not change this file directly, use config.ts file instead
 *
 * @version 6
 */

import type { AxiosRequestConfig } from 'axios';
import type { SwaggerResponse } from './config';
import { Http } from './httpRequest';
//@ts-ignore
import qs from 'qs';
import type {
    WelfareController_imagesByDayQueryParams,
    WelfareController_dayWithImagesQueryParams,
    WelfareController_allImagesQueryParams,
    WelfareController_FullWelfareQueryParams,
    WelfareController_FullWelfareColorQueryParams,
    LiceController_GetTemperatureQueryParams,
    LiceController_ImagesQueryParams,
    LiceController_DailyLiceQueryParams,
    LiceController_WeeklyLiceQueryParams,
    BiomassController_GetFeedingConversionRateQueryParams,
    UserController_LoginQueryParams,
    LocationController_MarkHistoricalQueryParams,
    CageController_OverviewQueryParams,
    CageController_DistributionsQueryParams,
    CombinedWelfareController_FullWelfareQueryParams,
    ExternalClientController_WelfareDailyDataQueryParams,
    ExternalClientController_LiceDataQueryParams,
    ExternalClientController_BiomassQueryParams,
    ExternalMeasurementsController_GetMeasurementsQueryParams,
    Alert,
    CreateNewContractInput,
    LicenseInfo,
    Contract,
    CreateNewContractLicenseInput,
    CreateNewLicenseForClientInput,
    CreateNewLicenseForUserInput,
    UserLicenseResponse,
    LicenseResponse,
    UpdateClientLicenseInput,
    FullSizeImage,
    DaysWithImages,
    FullWelfareResult,
    FullWelfareColorResult,
    TemperatureResult,
    LiceImageResult,
    Last3DaysResult,
    DailyLiceCount,
    WeeklyLiceCount,
    MoveBioscopeQueryParams,
    FeedingConversionRateResult,
    Me,
    ResetPasswordRequest,
    ResetPassword,
    InviteForm,
    RegisterUserForm,
    CageType,
    FishType,
    ClientWithLocationsInfo,
    ClientInfo,
    OverviewResult,
    CompareInputs,
    AllWeeklyReportResult,
    ApiUserResponse,
    CreateApiUserResponse,
    CreateNewClientInput,
    Cages,
    UpdateLocation,
    NewLocation,
    NewCage,
    UpdateName,
    UpdateType,
    CageStatus,
    CageContext,
    MeasurmentOverviewResult,
    DistributionResult,
    BioscopeVersion,
    ActiveBioscope,
    CreateBioscope,
    BioscopeConfigParams,
    AcknowledgeAlert,
    AlertAcknowledgement,
    UnacknowledgeAlert,
    ReportList,
    ReportDetail,
    NewReport,
    BioscopeDetail,
    Notice,
    CombinedWelfareResult,
    CreateComment,
    Comment,
    BioscopeRestart,
    BioscopeRouterRestart,
    CageImage,
    BioscopeImage,
    SmsInput,
    WelfareResult,
    LiceResult,
    BiomassResult,
    LocationDetail,
    CageDetail,
    BioscopeDetails,
} from './types';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const __DEV__ = process.env.NODE_ENV !== 'production';

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function overrideConfig(
    config?: AxiosRequestConfig,
    configOverride?: AxiosRequestConfig
): AxiosRequestConfig {
    return {
        ...config,
        ...configOverride,
        headers: {
            ...config?.headers,
            ...configOverride?.headers,
        },
    };
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export function template(path: string, obj: { [x: string]: any } = {}) {
    Object.keys(obj).forEach((key) => {
        const re = new RegExp(`{${key}}`, 'i');
        path = path.replace(re, obj[key]);
    });

    return path;
}

function isFormData(obj: any) {
    // This checks for the append method which should exist on FormData instances
    return (
        (typeof obj === 'object' &&
            typeof obj.append === 'function' &&
            obj[Symbol.toStringTag] === undefined) ||
        obj[Symbol.toStringTag] === 'FormData'
    );
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToForm(requestBody: object) {
    if (isFormData(requestBody)) {
        return requestBody;
    }
    const formData = new FormData();

    Object.entries(requestBody).forEach(([key, value]) => {
        value && formData.append(key, value);
    });

    return formData;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
function objToUrlencoded(requestBody: object) {
    return qs.stringify(requestBody);
}

/**
 *
 * Acknowledge an alert
 */
export const AcknowledgedAlertsController_acknowledgeAlert = (
    requestBody: AcknowledgeAlert,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<AlertAcknowledgement>> => {
    return Http.postRequest(
        AcknowledgedAlertsController_acknowledgeAlert.key,
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
AcknowledgedAlertsController_acknowledgeAlert.key = '/api/v2/alerts/ack';

/**
 *
 * Get all acknowledgements
 */
export const AcknowledgedAlertsController_getAcknowledgements = (
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<AlertAcknowledgement[]>> => {
    return Http.getRequest(
        AcknowledgedAlertsController_getAcknowledgements.key,
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
AcknowledgedAlertsController_getAcknowledgements.key = '/api/v2/alerts/acknowledgements';

/**
 *
 * Unacknowledge an alert
 */
export const AcknowledgedAlertsController_unacknowledgeAlert = (
    requestBody: UnacknowledgeAlert,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<AlertAcknowledgement>> => {
    return Http.postRequest(
        AcknowledgedAlertsController_unacknowledgeAlert.key,
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
AcknowledgedAlertsController_unacknowledgeAlert.key = '/api/v2/alerts/unack';

export const AlertsController_allAlerts = (
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Alert[]>> => {
    return Http.getRequest(
        AlertsController_allAlerts.key,
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
AlertsController_allAlerts.key = '/api/v2/alerts/unsolved';

export const BiomassController_GetFeedingConversionRate = (
    cageId: number,
    queryParams: BiomassController_GetFeedingConversionRateQueryParams,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<FeedingConversionRateResult>> => {
    return Http.getRequest(
        template(BiomassController_GetFeedingConversionRate.key, { cageId }),
        queryParams,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
BiomassController_GetFeedingConversionRate.key = '/api/v2/Biomass/{cageId}/feedingConversionRate';

export const BioscopeConfigController_getConfigBioscope = (
    bioscopeId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.getRequest(
        template(BioscopeConfigController_getConfigBioscope.key, { bioscopeId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
BioscopeConfigController_getConfigBioscope.key = '/api/v2/bioscope/{bioscopeId}/config';

export const BioscopeConfigController_setConfigBioscope = (
    bioscopeId: number,
    requestBody: BioscopeConfigParams,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.postRequest(
        template(BioscopeConfigController_setConfigBioscope.key, { bioscopeId }),
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
BioscopeConfigController_setConfigBioscope.key = '/api/v2/bioscope/{bioscopeId}/config';

export const BioscopeController_ActiveBioscopes = (
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ActiveBioscope[]>> => {
    return Http.getRequest(
        BioscopeController_ActiveBioscopes.key,
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
BioscopeController_ActiveBioscopes.key = '/api/v2/bioscope/active';

export const BioscopeController_ActiveBioscopesForClient = (
    clientId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ActiveBioscope[]>> => {
    return Http.getRequest(
        template(BioscopeController_ActiveBioscopesForClient.key, { clientId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
BioscopeController_ActiveBioscopesForClient.key = '/api/v2/bioscope/active/{clientId}';

export const BioscopeController_CreateApiKey = (
    bioscopeId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.postRequest(
        template(BioscopeController_CreateApiKey.key, { bioscopeId }),
        undefined,
        undefined,
        _CONSTANT1,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
BioscopeController_CreateApiKey.key = '/api/v2/bioscope/{bioscopeId}/apikey';

export const BioscopeController_CreateBioscope = (
    requestBody: CreateBioscope,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.postRequest(
        BioscopeController_CreateBioscope.key,
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
BioscopeController_CreateBioscope.key = '/api/v2/bioscope/create';

export const BioscopeController_DetailBioscope = (
    bioscopeId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<BioscopeVersion>> => {
    return Http.getRequest(
        template(BioscopeController_DetailBioscope.key, { bioscopeId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
BioscopeController_DetailBioscope.key = '/api/v2/bioscope/{bioscopeId}/version';

export const BioscopeController_Status = (
    bioscopeId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.getRequest(
        template(BioscopeController_Status.key, { bioscopeId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
BioscopeController_Status.key = '/api/v2/bioscope/{bioscopeId}/last-measurement';

export const BioscopeDetailController_BioscopeDetailNew = (
    bioscopeId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<BioscopeDetail>> => {
    return Http.getRequest(
        template(BioscopeDetailController_BioscopeDetailNew.key, { bioscopeId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
BioscopeDetailController_BioscopeDetailNew.key =
    '/api/v2/bioscope-api/bioscopeDetail/{bioscopeId}/BioscopeDetail';

export const BioscopeImageController_GetImageList = (
    bioscopeId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<BioscopeImage[]>> => {
    return Http.getRequest(
        template(BioscopeImageController_GetImageList.key, { bioscopeId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
BioscopeImageController_GetImageList.key = '/api/v2/bioscope/{bioscopeId}/images';

export const BioscopeImageController_Image = (
    bioscopeId: number,
    id: string,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.getRequest(
        template(BioscopeImageController_Image.key, { bioscopeId, id }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
BioscopeImageController_Image.key = '/api/v2/bioscope/{bioscopeId}/images/{id}';

export const BioscopeRestartController_BioscopeRestarts = (
    bioscopeId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<BioscopeRestart[]>> => {
    return Http.getRequest(
        template(BioscopeRestartController_BioscopeRestarts.key, { bioscopeId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
BioscopeRestartController_BioscopeRestarts.key = '/api/v2/bioscope/{bioscopeId}/restarts';

export const BioscopeRestartController_BioscopeRouterRestarts = (
    bioscopeId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<BioscopeRouterRestart[]>> => {
    return Http.getRequest(
        template(BioscopeRestartController_BioscopeRouterRestarts.key, { bioscopeId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
BioscopeRestartController_BioscopeRouterRestarts.key =
    '/api/v2/bioscope/{bioscopeId}/routerRestarts';

export const BioscopeRestartController_RestartBioscope = (
    bioscopeId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.postRequest(
        template(BioscopeRestartController_RestartBioscope.key, { bioscopeId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
BioscopeRestartController_RestartBioscope.key = '/api/v2/bioscope/{bioscopeId}/restartBioscope';

export const BioscopeRestartController_RestartRouter = (
    bioscopeId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.postRequest(
        template(BioscopeRestartController_RestartRouter.key, { bioscopeId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
BioscopeRestartController_RestartRouter.key = '/api/v2/bioscope/{bioscopeId}/restartRouter';

export const CageController_Context = (
    cageId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<CageContext>> => {
    return Http.getRequest(
        template(CageController_Context.key, { cageId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
CageController_Context.key = '/api/v2/cage/{cageId}/context';

export const CageController_DeleteCage = (
    cageId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.deleteRequest(
        template(CageController_DeleteCage.key, { cageId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
CageController_DeleteCage.key = '/api/v2/cage/{cageId}';

export const CageController_Distributions = (
    cageId: number,
    queryParams: CageController_DistributionsQueryParams,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DistributionResult[]>> => {
    return Http.getRequest(
        template(CageController_Distributions.key, { cageId }),
        queryParams,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
CageController_Distributions.key = '/api/v2/cage/{cageId}/distributions';

export const CageController_FishTypes = (
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<FishType[]>> => {
    return Http.getRequest(
        CageController_FishTypes.key,
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
CageController_FishTypes.key = '/api/v2/cage/fish-types';

export const CageController_Overview = (
    cageId: number,
    queryParams: CageController_OverviewQueryParams,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<MeasurmentOverviewResult>> => {
    return Http.getRequest(
        template(CageController_Overview.key, { cageId }),
        queryParams,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
CageController_Overview.key = '/api/v2/cage/{cageId}/overview';

export const CageController_Status = (
    cageId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<CageStatus>> => {
    return Http.getRequest(
        template(CageController_Status.key, { cageId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
CageController_Status.key = '/api/v2/cage/{cageId}/last-measurement';

export const CageController_UpdateName = (
    cageId: number,
    requestBody: UpdateName,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.patchRequest(
        template(CageController_UpdateName.key, { cageId }),
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
CageController_UpdateName.key = '/api/v2/cage/{cageId}/name';

export const CageController_UpdateType = (
    cageId: number,
    requestBody: UpdateType,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.patchRequest(
        template(CageController_UpdateType.key, { cageId }),
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
CageController_UpdateType.key = '/api/v2/cage/{cageId}/type';

export const CageImageController_GetImageList = (
    cageId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<CageImage[]>> => {
    return Http.getRequest(
        template(CageImageController_GetImageList.key, { cageId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
CageImageController_GetImageList.key = '/api/v2/cage/{cageId}/images/bioscope';

export const CageImageController_Image = (
    cageId: number,
    id: string,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.getRequest(
        template(CageImageController_Image.key, { cageId, id }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
CageImageController_Image.key = '/api/v2/cage/{cageId}/images/bioscope/{id}';

export const ClientController_AllLiceWeeklyReport = (
    clientId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<AllWeeklyReportResult[]>> => {
    return Http.getRequest(
        template(ClientController_AllLiceWeeklyReport.key, { clientId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
ClientController_AllLiceWeeklyReport.key =
    '/api/v2/client/internal/{clientId}/lice/allWeeklyReport';

export const ClientController_ApiUsers = (
    clientId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ApiUserResponse[]>> => {
    return Http.getRequest(
        template(ClientController_ApiUsers.key, { clientId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
ClientController_ApiUsers.key = '/api/v2/client/internal/{clientId}/apiUsers';

export const ClientController_Compare = (
    requestBody: CompareInputs,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.postRequest(
        ClientController_Compare.key,
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
ClientController_Compare.key = '/api/v2/client/internal/compare';

export const ClientController_CreateApiUser = (
    clientId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<CreateApiUserResponse>> => {
    return Http.putRequest(
        template(ClientController_CreateApiUser.key, { clientId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
ClientController_CreateApiUser.key = '/api/v2/client/internal/{clientId}/apiUsers/create';

export const ClientController_CreateNewClient = (
    requestBody: CreateNewClientInput,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.putRequest(
        ClientController_CreateNewClient.key,
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
ClientController_CreateNewClient.key = '/api/v2/client/internal/createNewClient';

export const ClientController_GetAllClientsWithLocations = (
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ClientWithLocationsInfo[]>> => {
    return Http.getRequest(
        ClientController_GetAllClientsWithLocations.key,
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
ClientController_GetAllClientsWithLocations.key = '/api/v2/client/internal/clientsWithLocations';

export const ClientController_GetClient = (
    clientId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ClientInfo>> => {
    return Http.getRequest(
        template(ClientController_GetClient.key, { clientId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
ClientController_GetClient.key = '/api/v2/client/internal/{clientId}';

export const ClientController_GetClientWithLocations = (
    clientId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ClientWithLocationsInfo>> => {
    return Http.getRequest(
        template(ClientController_GetClientWithLocations.key, { clientId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
ClientController_GetClientWithLocations.key =
    '/api/v2/client/internal/{clientId}/clientWithLocations';

export const ClientController_GetClients = (
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ClientInfo[]>> => {
    return Http.getRequest(
        ClientController_GetClients.key,
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
ClientController_GetClients.key = '/api/v2/client/internal';

export const ClientController_Overview = (
    clientId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<OverviewResult>> => {
    return Http.getRequest(
        template(ClientController_Overview.key, { clientId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
ClientController_Overview.key = '/api/v2/client/internal/{clientId}/overview';

export const CombinedWelfareController_FullWelfare = (
    cageId: number,
    queryParams: CombinedWelfareController_FullWelfareQueryParams,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<CombinedWelfareResult>> => {
    return Http.getRequest(
        template(CombinedWelfareController_FullWelfare.key, { cageId }),
        queryParams,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
CombinedWelfareController_FullWelfare.key = '/api/v2/welfare-combined/{cageId}/full-welfare';

export const CommentsController_CreateComment = (
    cageId: number,
    requestBody: CreateComment,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.putRequest(
        template(CommentsController_CreateComment.key, { cageId }),
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
CommentsController_CreateComment.key = '/api/v2/comments/cage/{cageId}';

export const CommentsController_GetCageComments = (
    cageId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Comment[]>> => {
    return Http.getRequest(
        template(CommentsController_GetCageComments.key, { cageId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
CommentsController_GetCageComments.key = '/api/v2/comments/cage/{cageId}';

export const ContentController_Notices = (
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Notice[]>> => {
    return Http.getRequest(
        ContentController_Notices.key,
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
ContentController_Notices.key = '/api/v2/content/notices';

export const ExternalClientController_Biomass = (
    queryParams?: ExternalClientController_BiomassQueryParams,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<BiomassResult[]>> => {
    return Http.getRequest(
        ExternalClientController_Biomass.key,
        queryParams,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
ExternalClientController_Biomass.key = '/api/v2/client/biomass/daily';

export const ExternalClientController_Bioscopes = (
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<BioscopeDetails[]>> => {
    return Http.getRequest(
        ExternalClientController_Bioscopes.key,
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
ExternalClientController_Bioscopes.key = '/api/v2/client/bioscopes';

export const ExternalClientController_Cages = (
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<CageDetail[]>> => {
    return Http.getRequest(
        ExternalClientController_Cages.key,
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
ExternalClientController_Cages.key = '/api/v2/client/cages';

export const ExternalClientController_LiceData = (
    queryParams?: ExternalClientController_LiceDataQueryParams,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<LiceResult[]>> => {
    return Http.getRequest(
        ExternalClientController_LiceData.key,
        queryParams,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
ExternalClientController_LiceData.key = '/api/v2/client/lice/daily';

export const ExternalClientController_Locations = (
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<LocationDetail[]>> => {
    return Http.getRequest(
        ExternalClientController_Locations.key,
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
ExternalClientController_Locations.key = '/api/v2/client/locations';

export const ExternalClientController_WelfareDailyData = (
    queryParams?: ExternalClientController_WelfareDailyDataQueryParams,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<WelfareResult[]>> => {
    return Http.getRequest(
        ExternalClientController_WelfareDailyData.key,
        queryParams,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
ExternalClientController_WelfareDailyData.key = '/api/v2/client/welfare/daily';

export const ExternalMeasurementsController_GetMeasurements = (
    cageId: number,
    queryParams: ExternalMeasurementsController_GetMeasurementsQueryParams,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<string>> => {
    return Http.getRequest(
        template(ExternalMeasurementsController_GetMeasurements.key, { cageId }),
        queryParams,
        undefined,
        undefined,
        overrideConfig(_CONSTANT2, configOverride)
    );
};

/** Key is end point string without base url */
ExternalMeasurementsController_GetMeasurements.key = '/api/v2/cages/{cageId}/measurements';

export const LiceController_DailyLice = (
    cageId: number,
    queryParams: LiceController_DailyLiceQueryParams,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DailyLiceCount[]>> => {
    return Http.getRequest(
        template(LiceController_DailyLice.key, { cageId }),
        queryParams,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LiceController_DailyLice.key = '/api/v2/lice/{cageId}/lice/daily';

export const LiceController_GetImage = (
    cageId: number,
    id: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.getRequest(
        template(LiceController_GetImage.key, { cageId, id }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LiceController_GetImage.key = '/api/v2/lice/{cageId}/images/{id}';

export const LiceController_GetTemperature = (
    cageId: number,
    queryParams: LiceController_GetTemperatureQueryParams,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<TemperatureResult[]>> => {
    return Http.getRequest(
        template(LiceController_GetTemperature.key, { cageId }),
        queryParams,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LiceController_GetTemperature.key = '/api/v2/lice/{cageId}/temperature';

export const LiceController_Images = (
    cageId: number,
    queryParams: LiceController_ImagesQueryParams,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<LiceImageResult[]>> => {
    return Http.getRequest(
        template(LiceController_Images.key, { cageId }),
        queryParams,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LiceController_Images.key = '/api/v2/lice/{cageId}/images';

export const LiceController_Last200Lice = (
    cageId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Last3DaysResult>> => {
    return Http.getRequest(
        template(LiceController_Last200Lice.key, { cageId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LiceController_Last200Lice.key = '/api/v2/lice/{cageId}/lice/last200';

export const LiceController_WeeklyLice = (
    cageId: number,
    queryParams: LiceController_WeeklyLiceQueryParams,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<WeeklyLiceCount[]>> => {
    return Http.getRequest(
        template(LiceController_WeeklyLice.key, { cageId }),
        queryParams,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LiceController_WeeklyLice.key = '/api/v2/lice/{cageId}/lice/weekly';

export const LicenseController_addContractLicense = (
    id: number,
    requestBody: CreateNewContractLicenseInput,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.putRequest(
        template(LicenseController_addContractLicense.key, { id }),
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LicenseController_addContractLicense.key = '/api/v2/licenses/contract/{id}/license';

export const LicenseController_availableLicenses = (
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<LicenseInfo[]>> => {
    return Http.getRequest(
        LicenseController_availableLicenses.key,
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LicenseController_availableLicenses.key = '/api/v2/licenses/contract/available';

export const LicenseController_createContract = (
    requestBody: CreateNewContractInput,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.putRequest(
        LicenseController_createContract.key,
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LicenseController_createContract.key = '/api/v2/licenses/contract';

/**
 * @deprecated This endpoint deprecated and will be remove. Please use an alternative
 */
export const LicenseController_createNewLicenseForClient = (
    requestBody: CreateNewLicenseForClientInput,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    if (__DEV__) {
        console.warn(
            'LicenseController_createNewLicenseForClient',
            'This endpoint deprecated and will be remove. Please use an alternative'
        );
    }
    return Http.postRequest(
        LicenseController_createNewLicenseForClient.key,
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LicenseController_createNewLicenseForClient.key = '/api/v2/licenses/client';

/**
 * @deprecated This endpoint deprecated and will be remove. Please use an alternative
 */
export const LicenseController_createNewLicenseForUser = (
    requestBody: CreateNewLicenseForUserInput,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    if (__DEV__) {
        console.warn(
            'LicenseController_createNewLicenseForUser',
            'This endpoint deprecated and will be remove. Please use an alternative'
        );
    }
    return Http.postRequest(
        LicenseController_createNewLicenseForUser.key,
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LicenseController_createNewLicenseForUser.key = '/api/v2/licenses/user';

/**
 * @deprecated This endpoint deprecated and will be remove. Please use an alternative
 */
export const LicenseController_deleteClientLicenses = (
    id: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    if (__DEV__) {
        console.warn(
            'LicenseController_deleteClientLicenses',
            'This endpoint deprecated and will be remove. Please use an alternative'
        );
    }
    return Http.deleteRequest(
        template(LicenseController_deleteClientLicenses.key, { id }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LicenseController_deleteClientLicenses.key = '/api/v2/licenses/client/{id}';

export const LicenseController_deleteContract = (
    id: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.deleteRequest(
        template(LicenseController_deleteContract.key, { id }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LicenseController_deleteContract.key = '/api/v2/licenses/contract/{id}';

export const LicenseController_deleteContractLicense = (
    licenseId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.deleteRequest(
        template(LicenseController_deleteContractLicense.key, { licenseId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LicenseController_deleteContractLicense.key =
    '/api/v2/licenses/contract/{contractId}/liense/{licenseId}';

/**
 * @deprecated This endpoint deprecated and will be remove. Please use an alternative
 */
export const LicenseController_deleteUserLicenses = (
    id: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    if (__DEV__) {
        console.warn(
            'LicenseController_deleteUserLicenses',
            'This endpoint deprecated and will be remove. Please use an alternative'
        );
    }
    return Http.deleteRequest(
        template(LicenseController_deleteUserLicenses.key, { id }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LicenseController_deleteUserLicenses.key = '/api/v2/licenses/user/{id}';

/**
 * @deprecated This endpoint deprecated and will be remove. Please use an alternative
 */
export const LicenseController_getAllClientUser = (
    clientId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    if (__DEV__) {
        console.warn(
            'LicenseController_getAllClientUser',
            'This endpoint deprecated and will be remove. Please use an alternative'
        );
    }
    return Http.getRequest(
        template(LicenseController_getAllClientUser.key, { clientId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LicenseController_getAllClientUser.key = '/api/v2/licenses/users/{clientId}';

/**
 * @deprecated This endpoint deprecated and will be remove. Please use an alternative
 */
export const LicenseController_getAllClientWithUser = (
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<{ [x in string | number]: any }[]>> => {
    if (__DEV__) {
        console.warn(
            'LicenseController_getAllClientWithUser',
            'This endpoint deprecated and will be remove. Please use an alternative'
        );
    }
    return Http.getRequest(
        LicenseController_getAllClientWithUser.key,
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LicenseController_getAllClientWithUser.key = '/api/v2/licenses/clients';

/**
 * @deprecated This endpoint deprecated and will be remove. Please use an alternative
 */
export const LicenseController_getAllUser = (
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<{ [x in string | number]: any }[]>> => {
    if (__DEV__) {
        console.warn(
            'LicenseController_getAllUser',
            'This endpoint deprecated and will be remove. Please use an alternative'
        );
    }
    return Http.getRequest(
        LicenseController_getAllUser.key,
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LicenseController_getAllUser.key = '/api/v2/licenses/users';

export const LicenseController_getClientContracts = (
    clientId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Contract[]>> => {
    return Http.getRequest(
        template(LicenseController_getClientContracts.key, { clientId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LicenseController_getClientContracts.key = '/api/v2/licenses/client/{clientId}/contracts';

/**
 * @deprecated This endpoint deprecated and will be remove. Please use an alternative
 */
export const LicenseController_getClientLicenses = (
    clientId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<LicenseResponse[]>> => {
    if (__DEV__) {
        console.warn(
            'LicenseController_getClientLicenses',
            'This endpoint deprecated and will be remove. Please use an alternative'
        );
    }
    return Http.getRequest(
        template(LicenseController_getClientLicenses.key, { clientId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LicenseController_getClientLicenses.key = '/api/v2/licenses/client/{clientId}';

export const LicenseController_getLicense = (
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<LicenseInfo[]>> => {
    return Http.getRequest(
        LicenseController_getLicense.key,
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LicenseController_getLicense.key = '/api/v2/licenses/license';

/**
 * @deprecated This endpoint deprecated and will be remove. Please use an alternative
 */
export const LicenseController_getUserLicenses = (
    userId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<UserLicenseResponse[]>> => {
    if (__DEV__) {
        console.warn(
            'LicenseController_getUserLicenses',
            'This endpoint deprecated and will be remove. Please use an alternative'
        );
    }
    return Http.getRequest(
        template(LicenseController_getUserLicenses.key, { userId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LicenseController_getUserLicenses.key = '/api/v2/licenses/user/{userId}';

/**
 * @deprecated This endpoint deprecated and will be remove. Please use an alternative
 */
export const LicenseController_updateClientLicense = (
    id: number,
    requestBody: UpdateClientLicenseInput,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    if (__DEV__) {
        console.warn(
            'LicenseController_updateClientLicense',
            'This endpoint deprecated and will be remove. Please use an alternative'
        );
    }
    return Http.patchRequest(
        template(LicenseController_updateClientLicense.key, { id }),
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LicenseController_updateClientLicense.key = '/api/v2/licenses/client/{id}';

export const LicenseController_updateContract = (
    id: number,
    requestBody: CreateNewContractInput,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.patchRequest(
        template(LicenseController_updateContract.key, { id }),
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LicenseController_updateContract.key = '/api/v2/licenses/contract/{id}';

export const LicenseController_updateContractLicense = (
    licenseId: number,
    requestBody: CreateNewContractLicenseInput,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.patchRequest(
        template(LicenseController_updateContractLicense.key, { licenseId }),
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LicenseController_updateContractLicense.key =
    '/api/v2/licenses/contract/{contractId}/license/{licenseId}';

export const LocationController_CageTypes = (
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<CageType[]>> => {
    return Http.getRequest(
        LocationController_CageTypes.key,
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LocationController_CageTypes.key = '/api/v2/location/cage-types';

export const LocationController_CagesWithHistoricalBioscope = (
    locationId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Cages[]>> => {
    return Http.getRequest(
        template(LocationController_CagesWithHistoricalBioscope.key, { locationId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LocationController_CagesWithHistoricalBioscope.key =
    '/api/v2/location/{locationId}/cagesWithHistoricalBioscope';

export const LocationController_CreateCage = (
    requestBody: NewCage,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.putRequest(
        LocationController_CreateCage.key,
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LocationController_CreateCage.key = '/api/v2/location/{locationId}/cages';

export const LocationController_CreateLocation = (
    requestBody: NewLocation,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.putRequest(
        LocationController_CreateLocation.key,
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LocationController_CreateLocation.key = '/api/v2/location';

export const LocationController_DeleteCage = (
    cageId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.deleteRequest(
        template(LocationController_DeleteCage.key, { cageId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LocationController_DeleteCage.key = '/api/v2/location/cages/{cageId}';

export const LocationController_DeleteLocation = (
    locationId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.deleteRequest(
        template(LocationController_DeleteLocation.key, { locationId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LocationController_DeleteLocation.key = '/api/v2/location/{locationId}';

export const LocationController_MarkHistorical = (
    cageId: number,
    queryParams: LocationController_MarkHistoricalQueryParams,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.putRequest(
        template(LocationController_MarkHistorical.key, { cageId }),
        queryParams,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LocationController_MarkHistorical.key = '/api/v2/location/cages/{cageId}/markHistorical';

export const LocationController_UpdateLocation = (
    locationId: number,
    requestBody: UpdateLocation,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.patchRequest(
        template(LocationController_UpdateLocation.key, { locationId }),
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
LocationController_UpdateLocation.key = '/api/v2/location/{locationId}';

export const MoveBioscopeController_DeleteLastMove = (
    bioscopeId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.deleteRequest(
        template(MoveBioscopeController_DeleteLastMove.key, { bioscopeId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
MoveBioscopeController_DeleteLastMove.key = '/api/v2/bioscopes/move/{bioscopeId}';

export const MoveBioscopeController_MoveBioscope = (
    bioscopeId: number,
    requestBody: MoveBioscopeQueryParams,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.postRequest(
        template(MoveBioscopeController_MoveBioscope.key, { bioscopeId }),
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
MoveBioscopeController_MoveBioscope.key = '/api/v2/bioscopes/move/{bioscopeId}';

export const ReportsController_CreateReport = (
    cageId: number,
    requestBody: NewReport,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.postRequest(
        template(ReportsController_CreateReport.key, { cageId }),
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
ReportsController_CreateReport.key = '/api/v2/reports/{cageId}';

export const ReportsController_DeleteReport = (
    cageId: number,
    reportId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.deleteRequest(
        template(ReportsController_DeleteReport.key, { cageId, reportId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
ReportsController_DeleteReport.key = '/api/v2/reports/{cageId}/{reportId}';

export const ReportsController_Report = (
    cageId: number,
    reportId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ReportDetail>> => {
    return Http.getRequest(
        template(ReportsController_Report.key, { cageId, reportId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
ReportsController_Report.key = '/api/v2/reports/{cageId}/{reportId}';

export const ReportsController_ReportList = (
    cageId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<ReportList[]>> => {
    return Http.getRequest(
        template(ReportsController_ReportList.key, { cageId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
ReportsController_ReportList.key = '/api/v2/reports/{cageId}';

export const SmsController_SmsWebhook = (
    requestBody: SmsInput,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.postRequest(
        SmsController_SmsWebhook.key,
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
SmsController_SmsWebhook.key = '/api/sms';

export const UserController_DeleteUser = (
    userId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.deleteRequest(
        template(UserController_DeleteUser.key, { userId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
UserController_DeleteUser.key = '/api/v2/user/{userId}';

export const UserController_EndImpersonate = (
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.postRequest(
        UserController_EndImpersonate.key,
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
UserController_EndImpersonate.key = '/api/v2/user/end-impersonate';

export const UserController_ImpersonateUser = (
    userId: number,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.postRequest(
        template(UserController_ImpersonateUser.key, { userId }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
UserController_ImpersonateUser.key = '/api/v2/user/impersonate/{userId}';

export const UserController_InviteUser = (
    requestBody: InviteForm,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.postRequest(
        UserController_InviteUser.key,
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
UserController_InviteUser.key = '/api/v2/user/invite';

export const UserController_Login = (
    queryParams: UserController_LoginQueryParams,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.postRequest(
        UserController_Login.key,
        queryParams,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
UserController_Login.key = '/api/v2/user/login';

export const UserController_Logout = (
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.postRequest(
        UserController_Logout.key,
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
UserController_Logout.key = '/api/v2/user/logout';

export const UserController_Me = (
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<Me>> => {
    return Http.getRequest(
        UserController_Me.key,
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
UserController_Me.key = '/api/v2/user/me';

export const UserController_RegisterUser = (
    requestBody: RegisterUserForm,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.postRequest(
        UserController_RegisterUser.key,
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
UserController_RegisterUser.key = '/api/v2/user/register';

export const UserController_ResetPassword = (
    requestBody: ResetPassword,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.postRequest(
        UserController_ResetPassword.key,
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
UserController_ResetPassword.key = '/api/v2/user/reset-password';

export const UserController_ResetPasswordRequest = (
    requestBody: ResetPasswordRequest,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.postRequest(
        UserController_ResetPasswordRequest.key,
        undefined,
        requestBody,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
UserController_ResetPasswordRequest.key = '/api/v2/user/reset-password-request';

export const WelfareController_FullWelfare = (
    cageId: number,
    queryParams: WelfareController_FullWelfareQueryParams,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<FullWelfareResult>> => {
    return Http.getRequest(
        template(WelfareController_FullWelfare.key, { cageId }),
        queryParams,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
WelfareController_FullWelfare.key = '/api/v2/welfare/{cageId}/fullWelfare';

export const WelfareController_FullWelfareColor = (
    cageId: number,
    queryParams: WelfareController_FullWelfareColorQueryParams,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<FullWelfareColorResult>> => {
    return Http.getRequest(
        template(WelfareController_FullWelfareColor.key, { cageId }),
        queryParams,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
WelfareController_FullWelfareColor.key = '/api/v2/welfare/{cageId}/fullWelfareColor';

export const WelfareController_GetFullSizeImage = (
    cageId: number,
    id: string,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<any>> => {
    return Http.getRequest(
        template(WelfareController_GetFullSizeImage.key, { cageId, id }),
        undefined,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
WelfareController_GetFullSizeImage.key = '/api/v2/welfare/{cageId}/images/{id}';

export const WelfareController_allImages = (
    cageId: number,
    queryParams?: WelfareController_allImagesQueryParams,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<FullSizeImage[]>> => {
    return Http.getRequest(
        template(WelfareController_allImages.key, { cageId }),
        queryParams,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
WelfareController_allImages.key = '/api/v2/welfare/{cageId}/allImages';

export const WelfareController_dayWithImages = (
    cageId: number,
    queryParams?: WelfareController_dayWithImagesQueryParams,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<DaysWithImages[]>> => {
    return Http.getRequest(
        template(WelfareController_dayWithImages.key, { cageId }),
        queryParams,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
WelfareController_dayWithImages.key = '/api/v2/welfare/{cageId}/dayWithImages';

export const WelfareController_imagesByDay = (
    cageId: number,
    queryParams: WelfareController_imagesByDayQueryParams,
    configOverride?: AxiosRequestConfig
): Promise<SwaggerResponse<FullSizeImage[]>> => {
    return Http.getRequest(
        template(WelfareController_imagesByDay.key, { cageId }),
        queryParams,
        undefined,
        undefined,
        overrideConfig(_CONSTANT0, configOverride)
    );
};

/** Key is end point string without base url */
WelfareController_imagesByDay.key = '/api/v2/welfare/{cageId}/images';
export const _CONSTANT0 = {
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
};
export const _CONSTANT1 = [{ 'production-api-key': [] }];
export const _CONSTANT2 = {
    headers: {
        'Content-Type': 'application/json',
        Accept: 'text/csv',
    },
};
