import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import cn from 'classnames';

import ChangeLanguage from 'components/molecules/ChangeLanguage/ChangeLanguage';

import '../public.scss';

interface ResetPasswordRequestProps {
    newPasswordRequest: (props: { email: string }) => Promise<any>;
}

export function ResetPasswordRequest({ newPasswordRequest }: ResetPasswordRequestProps) {
    const [email, setEmail] = useState('');
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(null);
    const { t } = useTranslation();

    const onSubmit = async () => {
        try {
            await newPasswordRequest({ email });
            setSuccess(true);
        } catch (e: any) {
            console.log(e);
            setError(e?.message?.message ?? 'Something went wrong');
        }
    };

    return (
        <div className={cn('pt-5', 'login')}>
            <Container className="mt-5">
                <Row className="justify-content-md-center  flex-column">
                    <div className="languageBox">
                        <ChangeLanguage />
                    </div>
                    <Col md={{ size: 6, offset: 3 }} className="loginBox">
                        <FormGroup>
                            <Label className="text-white">{t('Email')}</Label>
                            <Input
                                type="email"
                                data-testid="email"
                                name="email"
                                placeholder="email"
                                className="formControl"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                            />
                        </FormGroup>
                        {success ? (
                            <Alert color="success">
                                {t('Please check your email for the reset link')}.
                            </Alert>
                        ) : (
                            ''
                        )}
                        {error ? <Alert color="danger">{error}</Alert> : ''}
                        <div className="mt-2 d-flex">
                            <Button
                                data-testid="submitButton"
                                onClick={onSubmit}
                                className="btn-block">
                                {t('Reset password')}
                            </Button>
                        </div>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}
