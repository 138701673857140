import React from 'react';

import styles from './OverviewIcon.module.scss';

export interface OverviewIconProps {
    children?: React.ReactNode;
}

const OverviewIcon = ({ children }: OverviewIconProps) => {
    return (
        <div className={styles.overviewicon}>
            <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="20px"
                height="20px"
                viewBox="0 0 20 20"
                enableBackground="new 0 0 20 20"
                xmlSpace="preserve">
                {' '}
                <image
                    id="image0"
                    width="20"
                    height="20"
                    x="0"
                    y="0"
                    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAUCAQAAAAngNWGAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElN
RQfmAwgPGTemPEsqAAAAg0lEQVQoz62SMQrDMAxFn4zdDsFLl97/RNlaeoQuhiRTnIAyxIV2UUTJ
X6Th8fWRJKr8SlpV4IkSuJDoI7YWMnc6REzHTz9QAh4VFh84sR5l3HVFbPArv280IOoE3Y7ngxHr
Mv85mjtp9YX4HGdWH5iJPvBGsjM+qO1xx6PvSVTeJPoN3Mocb42mgxsAAAAldEVYdGRhdGU6Y3Jl
YXRlADIwMjItMDMtMDhUMTI6MjU6NTUrMDM6MDC58h4BAAAAJXRFWHRkYXRlOm1vZGlmeQAyMDIy
LTAzLTA4VDEyOjI1OjU1KzAzOjAwyK+mvQAAABl0RVh0U29mdHdhcmUAQWRvYmUgSW1hZ2VSZWFk
eXHJZTwAAAAASUVORK5CYII="
                />
            </svg>
        </div>
    );
};

export default OverviewIcon;
export { OverviewIcon };
