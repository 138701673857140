/* 
    Sets a zoom limit and cancels zoom if trying to zoom to far in    
    *This plugin is an extension to chartjs-plugin-zoom 
*/

export default {
    beforeUpdate: (chart, options) => {
        if (chart.options.plugins.zoom) {
            const maxZoomWidth = options.maxZoomWidth || 86400000 * 7; // 7 Days
            const zoomWidth =
                chart.scales['x-axis-0'].options.ticks.max -
                chart.scales['x-axis-0'].options.ticks.min;
            // - Calculate missing zoom width
            const missingZoomWidth = maxZoomWidth - zoomWidth;
            // - Adjust zoom width if necessary
            if (missingZoomWidth > 0) {
                chart.scales['x-axis-0'].options.ticks.min = chart.scales['x-axis-0'].min;
                chart.scales['x-axis-0'].options.ticks.max = chart.scales['x-axis-0'].max;
                if (chart.scales['x-axis-0'].type === 'time') {
                    chart.scales['x-axis-0'].options.time.min = chart.scales['x-axis-0'].min;
                    chart.scales['x-axis-0'].options.time.max = chart.scales['x-axis-0'].max;
                }
            }
        }
    },
};
