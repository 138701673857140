import React from 'react';

import styles from './MoveIcon.module.scss';

export interface MoveIconProps {
    children?: React.ReactNode;
}

const MoveIcon = ({ children }: MoveIconProps) => {
    return (
        <div className={styles.moveicon}>
            <svg
                width="20"
                height="20"
                viewBox="0 0 20 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.0834 5.56535H6.42895V11.8743C6.42895 12.0137 6.37048 12.1474 6.26642 12.246C6.16235 12.3446 6.02121 12.4 5.87404 12.4H4.76423C4.61706 12.4 4.47591 12.3446 4.37185 12.246C4.26778 12.1474 4.20932 12.0137 4.20932 11.8743V5.56535H0.554877C0.445136 5.56534 0.337862 5.53451 0.246616 5.47674C0.15537 5.41898 0.0842511 5.33688 0.0422503 5.24082C0.000249547 5.14477 -0.0107467 5.03907 0.0106518 4.93709C0.0320504 4.83511 0.0848827 4.74144 0.162469 4.66791L4.92676 0.153976C5.03084 0.0553862 5.17198 0 5.31915 0C5.46632 0 5.60746 0.0553862 5.71153 0.153976L10.4758 4.66791C10.5534 4.74144 10.6062 4.83511 10.6276 4.93709C10.649 5.03907 10.638 5.14477 10.596 5.24082C10.554 5.33688 10.4829 5.41898 10.3917 5.47674C10.3004 5.53451 10.1932 5.56534 10.0834 5.56535Z"
                    fill="white"
                />
                <path
                    d="M19.4451 14.4347H15.7906V8.12574C15.7906 7.98631 15.7322 7.85258 15.6281 7.75399C15.5241 7.65539 15.3829 7.6 15.2357 7.6H14.1259C13.9788 7.6 13.8376 7.65539 13.7336 7.75399C13.6295 7.85258 13.571 7.98631 13.571 8.12574V14.4347H9.91658C9.80684 14.4347 9.69956 14.4655 9.60832 14.5233C9.51707 14.581 9.44595 14.6631 9.40395 14.7592C9.36195 14.8552 9.35096 14.9609 9.37235 15.0629C9.39375 15.1649 9.44659 15.2586 9.52417 15.3321L14.2885 19.846C14.3925 19.9446 14.5337 20 14.6809 20C14.828 20 14.9692 19.9446 15.0732 19.846L19.8375 15.3321C19.9151 15.2586 19.9679 15.1649 19.9893 15.0629C20.0107 14.9609 19.9998 14.8552 19.9577 14.7592C19.9157 14.6631 19.8446 14.581 19.7534 14.5233C19.6621 14.4655 19.5549 14.4347 19.4451 14.4347Z"
                    fill="white"
                />
            </svg>
        </div>
    );
};

export default MoveIcon;
export { MoveIcon };
