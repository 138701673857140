import React from 'react';

import styles from './breadcrumb.module.scss';

export interface BreadcrumbProps {
    children?: any /* TODO : Get help to find a good way to juggle these kind of types */;
}

const Breadcrumb = ({ children }: BreadcrumbProps) => (
    <ul className={styles.breadcrumb}>{children}</ul>
);

export default Breadcrumb;
export { Breadcrumb };
