import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Button,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import { useQueryClient } from '@tanstack/react-query';
import { useLicenseController_createNewLicenseForClient } from 'services/hooks';

const DEFAULT_LICENSE_NUMBER = 50;

const AddLicense = ({
    onClose,
    clientId,
    missingLicenses,
}: {
    onClose: () => void;
    clientId: number;
    missingLicenses: {
        id: number;
        name: string;
    }[];
}) => {
    const { t } = useTranslation();
    const addClientLicense = useLicenseController_createNewLicenseForClient();
    const queryClient = useQueryClient();

    const [licenseNumber, setLicenseNumber] = useState(DEFAULT_LICENSE_NUMBER);
    const [licenseSelect, setLicenseSelect] = useState(missingLicenses[0]);

    const onSubmit = (event) => {
        const input = {
            clientId: clientId,
            licenseId: licenseSelect.id,
            numLicenses: licenseNumber,
        };

        addClientLicense
            .mutateAsync({
                requestBody: input,
            })
            .finally(() => {
                queryClient.invalidateQueries();
                onClose();
            });

        event.preventDefault();
    };

    return (
        <Modal isOpen={true} toggle={onClose}>
            <ModalHeader toggle={onClose}>{t('Add Client License')}</ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label>{t('License')}</Label>
                    <Input
                        type="select"
                        name="license"
                        placeholder="Select the license type"
                        value={JSON.stringify(licenseSelect)}
                        onChange={(e) => {
                            setLicenseSelect(JSON.parse(e.target.value));
                        }}>
                        {missingLicenses.map((license) => (
                            <option key={license.id} value={JSON.stringify(license)}>
                                {license.name}
                            </option>
                        ))}
                    </Input>
                    <Label>{t('Number of License')}</Label>
                    <Input
                        type="text"
                        name="licenseNumber"
                        placeholder=""
                        value={licenseNumber}
                        onChange={(e) => {
                            !Number.isNaN(Number(e.target.value)) &&
                                setLicenseNumber(Number(e.target.value));
                        }}
                    />
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onSubmit}>
                    {t('Save')}
                </Button>{' '}
                <Button color="secondary" onClick={onClose}>
                    {t('Cancel')}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default AddLicense;
