import { useCallback } from 'react';
import { useMe } from 'contexts/meContext';

function useCheckPageFeatureFlags() {
    const { hasUserFeature } = useMe();

    return useCallback(
        (page) => {
            if (!page) return false;
            return (page?.featureFlags ?? []).every((feat) => hasUserFeature(feat));
        },
        [hasUserFeature]
    );
}

export default useCheckPageFeatureFlags;
