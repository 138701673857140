import type { ChartOptions } from 'chart.js';

import { resizeGraphHandler } from 'components/organisms/OptoGraphs/commonOptoChartHelpers';

const defaultLineChartOptions: ChartOptions<'line'> = {
    layout: {
        padding: 16,
    },
    onResize: resizeGraphHandler,
    resizeDelay: 20,
    responsive: true,
    maintainAspectRatio: true,
    clip: false,
    elements: {
        line: {
            tension: 0,
            borderWidth: 3,
        },
        point: {
            radius: 2,
            pointStyle: 'circle',
            hoverRadius: 5,
        },
    },
    hover: {
        mode: 'index',
        axis: 'x',
        intersect: false,
    },

    scales: {
        x: {
            type: 'time',
            time: {
                unit: 'day',
            },
            ticks: {
                callback: (value) => {
                    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    return Intl.DateTimeFormat(navigator.language, {
                        month: 'short',
                        day: 'numeric',
                        timeZone: timeZone,
                    }).format(value as number);
                },
            },
        },
        y: {
            type: 'linear',
            grace: '5%',
        },
    },
    parsing: false, // Passing data in the prepared format will increase performance by skipping the parsing step.
    interaction: {
        intersect: false,
        mode: 'index',
    },
    plugins: {
        legend: {
            position: 'top',
            display: false,
        },
        filler: {
            propagate: false,
        },
        tooltip: {
            enabled: true,
            position: 'nearest',
            callbacks: {
                label: (context) => `${context.dataset.label} : ${context.formattedValue || ''}`,
                title: (context) => {
                    const timeObj = context[0].raw as { x: number; y: number };
                    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    return Intl.DateTimeFormat(navigator.language, {
                        month: 'short',
                        day: 'numeric',
                        timeZone: timeZone,
                    }).format(timeObj.x);
                },
            },
            // Using optoScales own tooltip handler
            // external: externalTooltipHandler,
        },
        title: {
            display: true,
            text: '',
            position: 'left',
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        htmlLegend: {
            // ID of the container to put the legend in
            containerClass: 'legend-container',
            disabled: false,
            displayLegendValue: true,
            position: 'top',
            showLegend: true,
        },
        zoom: {
            limits: {
                /*
                x: { min: 'original', max: 'original' },
                y: { min: 'original', max: 'original' },
                */
            },
            zoom: {
                wheel: {
                    enabled: false,
                    //modifierKey: 'ctrl',
                },
                pinch: {
                    enabled: true,
                },
                drag: {
                    threshold: 20,
                    enabled: true,
                },
                mode: 'x',
            },
        },
        decimation: {
            enabled: true,
            algorithm: 'lttb',
            samples: 2,
        },
    },
};

export default defaultLineChartOptions;
export { defaultLineChartOptions };
