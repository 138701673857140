import React from 'react';
import clsx from 'clsx';

import { Box } from '../../../../../components/Layout';
import useMyTranslation from '../../../../../hooks/useMyTranslation';

import AllGraph from './components/AllGraph';
import { OneGraph } from './components/OneGraph';
import Status from './components/Status';
import WelfareIndicators from './components/WelfareIndicators';
import { COLOR_NEUTRAL, formatNumber, getIcon, getTitle } from './utils';

import styles from './FishHealth.module.scss';

/* Private stuff is for testing */

const PRIVATE_WELFARE_INDICATOR_LABELS = [
    'wound',
    'skin speckles',
    'tail fin damage severe',
    'fat lip',
    'mature jaw',
    'tail fin split',
    'deformed lower jaw',
];

const STATUS_LABELS = ['tail fin split'];

function LabelValue(status) {
    const { t } = useMyTranslation();

    const percentage = formatNumber(status.tail.percentage, {
        suffix: '%',
        decimals: Math.abs(status.tail.percentage) < 10 ? 1 : 0,
    });
    const diff = formatNumber(status.diff, {
        suffix: '%',
        decimals: Math.abs(status.diff) < 10 ? 1 : 0,
    });

    const Icon = getIcon(status.op);
    const color = status.color || COLOR_NEUTRAL;

    return (
        <>
            {percentage}
            <span
                title={status.diff ? `${t('Week change')}: ${status.diff.toFixed(2)}` : ''}
                className="pl-2">
                (
                {(status.op > 0 || status.op < 0) && (
                    <span className={clsx('pr-2', styles.icon)} style={{ color }}>
                        <Icon />
                    </span>
                )}
                {diff})
            </span>
        </>
    );
}

function formatLabel(label, status, opts = {}) {
    return {
        text: getTitle(label),
        value: () => LabelValue(status),
        title: status.tail.percentage
            ? ['Weekly average', ': ', status.tail.percentage.toFixed(2)]
            : undefined,
    };
}

function graphData(title, labels, getStatus) {
    if (!labels || typeof getStatus !== 'function') {
        return {};
    }

    const formattedLabels = labels.map((label) => {
        return formatLabel(label, getStatus(label));
    });

    const extraFormattedLabels = [
        {
            text: getTitle('total'),
            value: formatNumber(getStatus('total').tail.totalCount),
        },
    ];

    //  reformat the n info's above
    // const info = [...Array(1).keys()].reduce((acc, index) => {
    //     formattedLabels.map(item => {
    //         return item[index];
    //     });
    //     acc.push(
    //         ...formattedLabels.map(item => {
    //             return item[index];
    //         })
    //     );

    //     return acc;
    // }, []);

    const info = [...formattedLabels, ...extraFormattedLabels]; // no need to reformat if only one value

    // extra is not shown on graph
    return {
        title,
        labels,
        info,
        trendlines: labels.reduce((acc, cur) => {
            acc[cur] = true;
            return acc;
        }, {}),
    };
}

function PrivateStuff({ list, getStatus }) {
    const maturationGraphData = React.useMemo(() => {
        return graphData('Maturation', ['mature jaw', 'fat lip'], getStatus);
    }, [getStatus]);

    const otherGraphData = React.useMemo(() => {
        // last day wound count hidden until fixed
        // const some = ['wound', 'skin spot', 'skin speckles'].map(label => {
        const some = ['skin speckles'].map((label) => {
            const status = getStatus(label);
            return {
                title: getTitle(label),
                labels: [label],
                trendlines: { [label]: true },
                info: [
                    {
                        text: 'Last day %',
                        value: formatNumber(status.head.percentage, {
                            suffix: '%',
                        }),
                    },
                    {
                        text: 'Last day count',
                        value: formatNumber(status.head.count),
                    },
                    {
                        text: 'Last day total count',
                        value: formatNumber(status.head.totalCount),
                    },
                ],
            };
        });
        return some;
    }, [getStatus]);

    return (
        <>
            {maturationGraphData && (
                <Box>
                    <OneGraph list={list} {...maturationGraphData} />
                </Box>
            )}
            <Box>
                <WelfareIndicators
                    labels={PRIVATE_WELFARE_INDICATOR_LABELS}
                    getStatus={getStatus}
                />
            </Box>
            {otherGraphData?.map((props, index) => (
                <Box key={`onegraph_${index}`}>
                    <OneGraph list={list} {...props} />
                </Box>
            ))}
            {STATUS_LABELS.map((label, index) => (
                <Box key={`status_${index}`}>
                    <Status label={label} getStatus={getStatus} />
                </Box>
            ))}
            <Box>
                <AllGraph getStatus={getStatus} />
            </Box>
        </>
    );
}

export default PrivateStuff;
