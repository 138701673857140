import React from 'react';
import { ButtonGroup } from 'reactstrap';

import CopyAsPNG from '../../components/CopyAsPNG';

function LiceChartHeader({ chart }) {
    return (
        <div>
            <ButtonGroup style={{ float: 'right' }}>
                <CopyAsPNG chart={chart} />
            </ButtonGroup>
        </div>
    );
}

export default LiceChartHeader;
