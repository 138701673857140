import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import {
    Button,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    UncontrolledDropdown,
} from 'reactstrap';

import { MeUser } from '@/services/types';

import { ClientList } from './components/ClientList';
import { Logout } from './components/Logout';

import styles from './ClientNav.module.scss';

export interface ClientNavProps {
    match: {
        path: string;
    };
    user: MeUser;
    clientName: string;
    clients: {
        id: number;
        name: string;
        role: string;
        authLicenses: string[];
    }[];
    onLogout: () => Promise<unknown>;
}

export const ClientNav = ({ match, user, clients, clientName, onLogout }) => {
    const { t } = useTranslation();

    const isInternal = match.path === '/internal';

    return (
        <UncontrolledDropdown nav inNavbar>
            <DropdownToggle nav>
                <Button className={styles.clientNavButton} outline color="light">
                    <div className={styles.clientName}>
                        {isInternal ? t('Internal Page') : clientName}
                    </div>
                    <div className={styles.userName}>
                        {user.firstName} {user.lastName}
                    </div>
                </Button>
            </DropdownToggle>
            <DropdownMenu className={styles.dropdownMenu} right>
                {!!user.systemRole && (
                    <>
                        <DropdownItem className={styles.dropdownItem} tag={Link} to="/internal">
                            {t('Internal Page')}
                        </DropdownItem>
                    </>
                )}
                {(!!user.systemRole || clients.length > 1) && (
                    <>
                        <ClientList clients={clients} />
                        <DropdownItem divider className={styles.dropdownItem} />
                    </>
                )}
                <Logout onLogout={onLogout} />
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};
