import type React from 'react';
import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import styles from './CheckBoxSwitch.module.scss';

export interface CheckBoxSwitchProps {
    children?: React.ReactNode;
    label: string;
    checked?: boolean;
    onChangeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckBoxSwitch = ({
    children,
    label,
    checked = false,
    onChangeHandler,
}: CheckBoxSwitchProps) => {
    const id = uuidv4();
    const [isChecked, setIsChecked] = useState<boolean>(checked);

    useEffect(() => {
        setIsChecked(checked);
    }, [checked]);

    return (
        <div className={styles.checkboxswitch}>
            <input
                type="checkbox"
                checked={isChecked}
                id={id}
                onChange={(event) => {
                    setIsChecked(event.target.checked);
                    onChangeHandler?.(event);
                }}
            />
            <label htmlFor={id}>{label}</label>
            {children}
        </div>
    );
};

export default CheckBoxSwitch;
export { CheckBoxSwitch };
