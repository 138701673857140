import React from 'react';

import styles from './CircularProgressBar.module.scss';

const cleanPercentage = (percentage) => {
    const isNegativeOrNaN = !Number.isFinite(+percentage) || percentage < 0; // we can set non-numbers to 0 here
    const isTooHigh = percentage > 100;
    return isNegativeOrNaN ? 0 : isTooHigh ? 100 : +percentage;
};

interface CircularProgressBarProps {
    percentage: number;
    color: string;
    size: number;
}

export const CircularProgressBar = ({
    percentage,
    color,
    size = 150,
}: CircularProgressBarProps) => {
    const pct = cleanPercentage(percentage);
    const r = size / 3;
    const innerRadius = size / 4;
    const circ = 2 * Math.PI * r;
    const strokePct = ((100 - pct) * circ) / 100;
    const fontSize = size / 7;
    const strokeWidth = size === 150 ? 16 : 10;

    return (
        <div className={styles.circularProgressBar}>
            <svg width={size} height={size}>
                <g>
                    <circle
                        r={r}
                        cx="50%"
                        cy="50%"
                        fill="transparent"
                        stroke={strokePct !== circ ? 'lightgrey' : ''} // remove colour as 0% sets full circumference
                        strokeWidth={`${strokeWidth}px`}
                        strokeDasharray={circ}
                        strokeDashoffset={0}
                    />
                    <circle
                        r={r}
                        cx="50%"
                        cy="50%"
                        fill="transparent"
                        stroke={strokePct !== circ ? color : ''} // remove colour as 0% sets full circumference
                        strokeWidth={`${strokeWidth}px`}
                        strokeDasharray={circ}
                        strokeDashoffset={strokePct}
                    />
                </g>
                <circle cx="50%" cy="50%" r={innerRadius} fill={color} />
                <text
                    x="50%"
                    y="50%"
                    dominantBaseline="central"
                    textAnchor="middle"
                    fontSize={`${fontSize}px`}
                    fill="#FFFFFF">
                    {percentage.toFixed(1)}%
                </text>
            </svg>
        </div>
    );
};
