import type React from 'react';

import styles from './ImageViewer.module.scss';

export interface ImageViewerActionMenuProps {
    children?: React.ReactNode;
}
const ImageViewerActionMenu = ({ children }: ImageViewerActionMenuProps) => {
    return <div className={styles.imageViewerActionMenu}>{children}</div>;
};

export default ImageViewerActionMenu;
export { ImageViewerActionMenu };
