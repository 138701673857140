const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;
const WEEK = 7 * DAY;
const MONTH = 31 * DAY;
const YEAR = 356 * DAY;

export default {
    id: 'auto-graph-time-unit',
    afterInit: (chart) => {
        chart.update();
    },
    beforeUpdate: (chart) => {
        for (const scaleKey in chart.scales) {
            const scale = chart.scales[scaleKey];
            if (scale.type === 'time') {
                const duration = scale.max - scale.min;
                if (duration < 3 * WEEK) {
                    scale.options.time.unit = 'day';
                } else if (duration < 3 * MONTH) {
                    scale.options.time.unit = 'week';
                } else if (duration < 3 * YEAR) {
                    scale.options.time.unit = 'month';
                } else {
                    scale.options.time.unit = 'year';
                }
            }
        }
    },
};
