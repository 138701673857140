import moment from 'moment-timezone';

import parseTs from './parseTs';

const DEFAULT_FORMAT = 'D-M-YYYY HH:mm:ss';
const DEFAULT_OPTS = {
    format: DEFAULT_FORMAT,
};

export const formattedTs = (ts, timezone = undefined, opts = DEFAULT_OPTS) => {
    if (timezone === undefined) {
        timezone = moment.tz.guess();
    }

    opts = { ...DEFAULT_OPTS, ...opts };

    const parsed = parseTs(ts);
    if (parsed === null || parsed === undefined) return parsed;

    return moment(parsed).tz(timezone).format(opts.format);
};

export default formattedTs;
