import React from 'react';
import { useTranslation } from 'react-i18next';
import VisibilitySensor from 'react-visibility-sensor';
import cn from 'classnames';
import { COLOR_NEUTRAL, getIcon } from 'utils';

import styles from '../FishHealthColor.module.scss';

function StatusIcon({ status, label, withDescription = false, downGrade }) {
    const { t } = useTranslation();
    const value = status?.tail?.percentage;
    const downGradeValue = downGrade?.tail?.percentage;

    const Icon = getIcon(status.op);
    const color = status.color || COLOR_NEUTRAL;
    const title = Number.isNaN(status.diff)
        ? `${t(status.description)}`
        : `${t('Difference')}: ${status.diff.toFixed(2)}`;
    const [isVisible, setIsVisible] = React.useState(false);
    const onChange = (isVisible) => {
        setIsVisible(isVisible);
    };
    return (
        <div title={title}>
            <span className={`${styles.statusValue} ${'animFadeIn'}`}>
                <VisibilitySensor onChange={onChange} partialVisibility>
                    <span className={isVisible ? styles.rotatingBg : styles.focusBg} />
                </VisibilitySensor>
                {value.toFixed(1)}%
            </span>{' '}
            {` ${t('of fish have signs of')}  ${t(label).toLowerCase()}.`}
            <p className={styles.trendIs}>
                {t('The trend is')}
                <span className={cn('pl-1')}>{t(status.text)}</span>
                <span className={cn('pl-1', styles.icon)} style={{ color }}>
                    <Icon />
                </span>
            </p>
            {label === 'wound' ? (
                <p className="mb-0">
                    {t('Estimated downgrades based on wounds')}:{' '}
                    <strong>{downGradeValue?.toFixed(1)}%</strong>
                </p>
            ) : (
                ''
            )}
            {withDescription && (
                <span className={cn('pl-3', status.description)}>{t(status.description)}</span>
            )}
        </div>
    );
}

export default StatusIcon;
