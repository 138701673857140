import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import mergeQueryParams from 'utils/mergeQueryParams';

// get/update query parameters as is (no extra processing)
function useQueryParams() {
    const location = useLocation();
    const queryParams = useMemo(() => queryString.parse(location.search), [location.search]);

    const history = useHistory();
    const setQueryParams = useCallback(
        (...params) => {
            const merged = mergeQueryParams(queryParams, ...params);
            history.push({
                pathname: location.pathname,
                search: queryString.stringify(merged),
                state: location.state,
            });
        },
        [history, location.pathname, location.state, queryParams]
    );

    return [queryParams, setQueryParams] as const;
}

export default useQueryParams;
export { useQueryParams };
