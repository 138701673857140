import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';
import { Col, Row } from 'reactstrap';
import moment from 'moment-timezone';

import DataSideBar from '@/components/Chart/sidebars/DataSideBar';
import { Chart } from 'components/Chart';
import ChartHeader from 'components/Chart/headers/ChartHeader/ChartHeader';
import { DownloadCsvXlsx } from 'components/DownloadCsvXlsx';

import { getHeadTailStatus, getTitle } from '../../utils';

import options from './options';
import plugins from './plugins';

const DEFAULT_COLORS = ['#5bb784', '#547fa2', '#292e45', '#cb4773', '#eac007', '#7ba44c'];

function Graph({
    title,
    labels,
    trendlines,
    list,
    colors,
    type = 'bar',
    barometerStyle,
    stacked = false,
}) {
    const { t } = useTranslation();

    colors = colors || DEFAULT_COLORS;
    trendlines = trendlines || {};
    if (barometerStyle) {
        labels.shift(); // Not showing the first label on graph if it is barometer Style
    }
    const data = useMemo(() => {
        const statuses = [];
        const datasets = labels.map((label, index) => {
            const dataset = {
                label: t(getTitle(label)),
                backgroundColor: colors[index % colors.length],
                borderColor: colors[index % colors.length],
                pointBackgroundColor: colors[index % colors.length],
                pointRadius: 5,
                showLine: 'origin',
                fill: stacked,
                data: [],
            };

            if (trendlines[label]) {
                dataset.trendline = {
                    style: colors[index % colors.length],
                    lineStyle: 'dotted',
                    width: 1,
                };
            }

            statuses.push([]);
            list.forEach((row) => {
                const status = getHeadTailStatus(label, row);

                if (status?.head?.withCompensatedPerspective) {
                    status.head.postfix = t('with compensated perspective');
                }

                statuses[index].push(status);
                // if a value is more than 100% then assign 100
                if (status.head.percentage > 100) {
                    status.head.percentage = 100;
                }
                dataset.data.push({
                    x: row.day,
                    y: status.head.percentage,
                });
            });

            return dataset;
        });

        return { statuses, datasets };
    }, [labels, t, colors, trendlines, list, stacked]);
    if (!(Array.isArray(list) && list.length)) {
        return <Alert color="warning">{t('No data')}</Alert>;
    }
    const allIndicatorLabels = data.datasets.map((x) => x.label);
    const allIndicatorData = data.datasets.map((x) => x.data);
    const reArrangedData = allIndicatorData.reduce((acc, curr) => {
        curr.forEach((item) => {
            const existingItem = acc.find((i) => i.x === item.x);
            if (existingItem) {
                existingItem[`value${allIndicatorData.indexOf(curr) + 1}`] = item.y;
            } else {
                acc.push({
                    x: item.x,
                    [`value${allIndicatorData.indexOf(curr) + 1}`]: item.y,
                });
            }
        });
        return acc;
    }, []);

    const formattedData = [];
    reArrangedData.forEach((item) => {
        const date = moment(item?.x).format('YYYY-MM-DD');
        const dataToPush = { date };
        const objKeyLength = Object.keys(item).length;
        for (let i = 1; i < objKeyLength; i++) {
            dataToPush[`${allIndicatorLabels[i - 1]} percentage`] = Number.parseFloat(
                Object.values(item)[i]
            ).toFixed(2);
        }
        formattedData.push(dataToPush);
    });
    // need to send columns names in this format
    const locationInfoColumns = ['location.name', 'location.timezone', 'cage.name'];
    const dataColumnNames = Object.keys(formattedData[0]);
    const formattedDataColumnNames = dataColumnNames.map((e) => `day.${e}`);
    const allColumnNames = [...locationInfoColumns, ...formattedDataColumnNames];

    return (
        <>
            <Row>
                <Col xs={12} className="text-right">
                    <>
                        <DownloadCsvXlsx
                            label="CSV"
                            nameToInclude={title}
                            data={formattedData}
                            COLUMNS={allColumnNames}
                        />
                        <DownloadCsvXlsx
                            label="XLSX"
                            nameToInclude={title}
                            data={formattedData}
                            COLUMNS={allColumnNames}
                        />
                    </>
                </Col>
            </Row>
            <Chart
                type={type}
                data={data}
                options={options(stacked)}
                plugins={plugins}
                header={(chart) => <ChartHeader chart={chart} />}
                sidebar={(chart) => <DataSideBar mbd={[]} chart={chart} />}
            />
        </>
    );
}

export default Graph;
