import type React from 'react';
import type { MouseEvent, ReactNode } from 'react';
import type { Chart } from 'chart.js';

import DownloadIcon from 'components/atoms/icons/DownloadIcon';

import styles from './DownloadAsPng.module.scss';
export interface DownloadAsPngProps {
    children?: ReactNode;
    chart?: Chart<'line' | 'bar' | 'doughnut' | 'pie'>;
    className?: string;
    onClick?: (ev: MouseEvent<HTMLButtonElement>) => void;
}

const DownloadAsPng: React.FC<DownloadAsPngProps & React.ButtonHTMLAttributes<HTMLButtonElement>> =
    ({ onClick = (e) => {} }: DownloadAsPngProps) => {
        return (
            <button className={styles.downloadaspng} onClick={onClick}>
                <DownloadIcon size={24} />
            </button>
        );
    };

export default DownloadAsPng;
export { DownloadAsPng };
