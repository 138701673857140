import { useEffect, useRef, useState } from 'react';
import { Doughnut } from 'react-chartjs-2';
// https://github.com/kurkle/chartjs-plugin-autocolors
import { getI18n } from 'react-i18next';
import {
    ArcElement,
    Chart as ChartJS,
    ChartData,
    ChartOptions,
    Legend,
    LinearScale,
    LineElement,
    PluginChartOptions,
    PointElement,
    Title,
    Tooltip,
} from 'chart.js';
import annotationPlugin from 'chartjs-plugin-annotation';
import zoomPlugin from 'chartjs-plugin-zoom';
import cn from 'classnames';
import { graphSettings } from 'config/settings';

import SkeletonLoader from '@/components/atoms/SkeletonLoader';
import { ChartLegend } from 'components/atoms/ChartLegend';
import { ChartHeaderBar } from 'components/molecules/ChartHeaderBar/ChartHeaderBar';

import { ChartActionButtons } from '../OptoMixedChart/OptoMixedChart';
import { htmlLegendPlugin } from '../plugins/htmlLegendPlugin';

import styles from './OptoDoughnutChart.module.scss';

ChartJS.register(
    LinearScale,
    PointElement,
    LineElement,
    ArcElement,
    Legend,
    annotationPlugin,
    htmlLegendPlugin,
    zoomPlugin,
    Tooltip,
    Title
);

const i18n = getI18n();

export interface OptoDoughnutChartProps {
    chartName?: string;
    graphType?: 'doughnut';
    chartData: ChartData<'doughnut'>;
    chartOptions?: (data: ChartData<'doughnut'>) => ChartOptions<'doughnut'>;
    extras?: {
        count: number;
        relativeCount: number;
    }[];
    pluginOptions?: PluginChartOptions<'doughnut'>;
    loading?: boolean;
    actionButtons?: ChartActionButtons;
}

const OptoDoughnutChart = ({
    chartName = '',
    chartData,
    chartOptions,
    loading = false,
    extras = [], // Not a part of the chart.js data structure
    actionButtons = {
        copyAsPng: false,
        resetZoom: false,
        downloadAsPng: false,
        fullScreen: true,
        timeScaleSwitch: false,
    },
}: OptoDoughnutChartProps) => {
    const options = chartOptions(chartData);

    const chartRef = useRef<ChartJS<'doughnut'>>(null);
    const [chart, setChart] = useState(null);
    const chartBoundaries = useRef<HTMLDivElement>(null);

    const [isFullScreen, setisFullscreen] = useState(false);
    const [topheading, setTopheading] = useState(false);

    const showAsFullScreen = () => {
        if (chartBoundaries.current) {
            isFullScreen ? document.exitFullscreen() : chartBoundaries.current.requestFullscreen();
            chartBoundaries.current.onfullscreenchange = () => {
                chart?.resize();
                setisFullscreen(!isFullScreen);
            };
            return;
        }
    };

    useEffect(() => {
        if (chartRef.current) {
            setChart(chartRef.current as ChartJS<'doughnut'>);
            chartRef.current.update();
        }
    }, []);

    options.plugins.tooltip.callbacks.label = (context) => {
        let label = context.label || '';

        if (label) {
            label += `\n Count: ${
                extras.find((extra) => {
                    return extra.relativeCount === context.parsed;
                })?.count
            }\n Relative: ${(context.parsed * 100).toFixed(1)}%`;
        }
        return label;
    };

    const legendData = chartData?.labels?.map((label, index) => {
        return {
            text: label as string,
            value: chartData.datasets?.[0].data?.[index] as number,
            color: graphSettings.SceneLabelTagsColors,
        };
    });

    return (
        <SkeletonLoader loading={loading} name={`${chartName} loading..`}>
            <div className={styles.optodoughnutchart} ref={chartBoundaries}>
                <div className={styles.chartHeaderBar}>
                    <ChartHeaderBar
                        chart={chart}
                        visibleButtons={actionButtons}
                        fullScreenHandler={showAsFullScreen}
                        isFullScreen={isFullScreen}
                    />
                </div>
                <div className={cn(styles.tooltipContainer, 'tooltip-container')} />
                <Doughnut
                    ref={chartRef}
                    className={styles.optoPieCanvas}
                    data={chartData}
                    options={options}
                    plugins={[]}
                />
                <ChartLegend legendItems={legendData} />
            </div>
        </SkeletonLoader>
    );
};

export default OptoDoughnutChart;
export { OptoDoughnutChart };
