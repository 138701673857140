export default {
    id: 'auto-integer-unit',
    afterInit: (chart) => {
        chart.update();
    },
    beforeUpdate: (chart) => {
        for (const scaleKey in chart.scales) {
            const scale = chart.scales[scaleKey];

            //Added a check to reset the min and mix scalling to fix the bug i.e
            //overlaping the yaxes scaling
            if (scaleKey === 'y-axis-0' && scale?.ticks?.length > 5) {
                scale.max = 0;
                scale.min = 0;
            }

            if (scale.type === 'linear') {
                const range = scale.max - scale.min;
                if (range < 1) {
                    scale.options.ticks.stepSize = 0.1;
                } else if (range < 5 * 20) {
                    scale.options.ticks.stepSize = 10;
                } else if (range < 5 * 50) {
                    scale.options.ticks.stepSize = 20;
                } else if (range < 5 * 100) {
                    scale.options.ticks.stepSize = 50;
                } else if (range < 5 * 200) {
                    scale.options.ticks.stepSize = 100;
                } else if (range < 5 * 500) {
                    scale.options.ticks.stepSize = 200;
                } else if (range < 5 * 700) {
                    scale.options.ticks.stepSize = 500;
                } else if (range < 5 * 1000) {
                    scale.options.ticks.stepSize = 700;
                } else if (range < 5 * 2000) {
                    scale.options.ticks.stepSize = 1000;
                } else if (range < 5 * 5000) {
                    scale.options.ticks.stepSize = 2000;
                } else {
                    scale.options.ticks.stepSize = 5000;
                }
            }
        }
    },
};
