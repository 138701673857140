import React from 'react';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const ControlBar = styled.div`
    position: relative;
    color: ${(props) => props.color ?? '#5bb784'};
    background: ${(props) => props.backgroundColor ?? '#000000'};
    padding: 3px;
`;

const ButtonContainer = styled.div`
    display: flex;
    flex-wrap: nowrap;
    text-align: center;
    justify-content: space-between;
`;

const ControlButton = styled.div`
    flex: initial;
    min-height: 100%;
    cursor: 'pointer';
    opacity: ${(props) => (props.disabled ? 0.1 : 0.7)};
    :hover {
        opacity: ${(props) => (props.disabled ? 0.1 : 1.0)};
    }
    padding: 3px;
`;

const CarouselControlIconsPrev = (props) => {
    const { disabled, onClickHandler, onDblClickHandler, showPreviousSingle, showPreviousDouble } =
        props;
    const { t } = useTranslation();

    if (!showPreviousSingle && !showPreviousDouble) return <></>;
    return (
        <ControlButton disabled={disabled}>
            {showPreviousDouble && (
                <i
                    className="fas fa-2x fa-angle-double-left mr-3"
                    onClick={(e) => {
                        e.preventDefault();
                        if (!disabled && typeof onDblClickHandler === 'function')
                            onDblClickHandler();
                    }}
                    data-toggle={'tooltip'}
                    data-placement={'top'}
                    title={t('Go 20 images back')}
                />
            )}
            <i
                className="fas fa-2x fa-angle-left"
                onClick={(e) => {
                    e.preventDefault();
                    if (!disabled && typeof onClickHandler === 'function') onClickHandler();
                }}
                data-toggle={'tooltip'}
                data-placement={'top'}
                title={t('Previous image')}
            />
        </ControlButton>
    );
};

const CarouselControlIconsNext = (props) => {
    const { disabled, onClickHandler, onDblClickHandler, showNextSingle, showNextDouble } = props;
    const { t } = useTranslation();

    if (!showNextSingle && !showNextDouble) return <></>;

    return (
        <ControlButton disabled={disabled}>
            <i
                className="fas fa-2x fa-angle-right"
                onClick={(e) => {
                    e.preventDefault();
                    if (!disabled && typeof onClickHandler === 'function') onClickHandler();
                }}
                data-toggle={'tooltip'}
                data-placement={'top'}
                title={t('Next image')}
            />
            {showNextDouble && (
                <i
                    className="fas fa-2x fa-angle-double-right ml-3"
                    onClick={(e) => {
                        e.preventDefault();
                        if (!disabled && typeof onDblClickHandler === 'function')
                            onDblClickHandler();
                    }}
                    data-toggle={'tooltip'}
                    data-placement={'top'}
                    title={t('Go 20 images forward')}
                />
            )}
        </ControlButton>
    );
};
interface CarouselControlBarInterface {
    showPreviousSingle?: boolean;
    showPreviousDouble?: boolean;
    showNextSingle?: boolean;
    showNextDouble?: boolean;
    previousDisabled?: boolean;
    nextDisabled?: boolean;
    onClickPreviousHandler?: any;
    onDblClickPreviousHandler?: any;
    onClickNextHandler?: any;
    onDblClickNextHandler?: any;
    className?: any;
}

const CarouselControlBar = (props: CarouselControlBarInterface) => {
    const {
        showPreviousSingle = true,
        showPreviousDouble = true,
        showNextSingle = true,
        showNextDouble = true,
        previousDisabled = false,
        nextDisabled = false,
        onClickPreviousHandler = undefined,
        onDblClickPreviousHandler = undefined,
        onClickNextHandler = undefined,
        onDblClickNextHandler = undefined,
        className,
    } = props;

    return (
        <ControlBar className={className}>
            <ButtonContainer>
                <CarouselControlIconsPrev
                    showPreviousSingle={showPreviousSingle}
                    showPreviousDouble={showPreviousDouble}
                    onClickHandler={onClickPreviousHandler}
                    onDblClickHandler={onDblClickPreviousHandler}
                    disabled={previousDisabled}
                />
                <CarouselControlIconsNext
                    showNextSingle={showNextSingle}
                    showNextDouble={showNextDouble}
                    onClickHandler={onClickNextHandler}
                    onDblClickHandler={onDblClickNextHandler}
                    disabled={nextDisabled}
                />
            </ButtonContainer>
        </ControlBar>
    );
};

CarouselControlBar.propTypes = {
    previousDisabled: PropTypes.bool,
    nextDisabled: PropTypes.bool,
    onClickPreviousHandler: PropTypes.func,
    onDblClickPreviousHandler: PropTypes.func,
    onClickNextHandler: PropTypes.func,
    onDblClickNextHandler: PropTypes.func,
    cssModule: PropTypes.object,
    directionText: PropTypes.string,
    className: PropTypes.string,
};

export default CarouselControlBar;
