import { useHistory } from 'react-router-dom';
import { Alert } from 'reactstrap';
import { useUserController_RegisterUser } from 'services/hooks';

import useQueryParams from '@/hooks/useQueryParams';

import { UserRegister } from './UserRegister';

export const UserRegisterContainer = () => {
    const { mutateAsync: registerUser } = useUserController_RegisterUser();
    const [params] = useQueryParams();
    const history = useHistory();

    if (typeof params.token !== 'string') {
        return <Alert color="danger">No token provided</Alert>;
    }

    return (
        <UserRegister
            onSubmit={async (inputs) => {
                await registerUser({ requestBody: inputs });
                setTimeout(() => history.push('/'), 5000);
            }}
            token={params.token}
        />
    );
};
