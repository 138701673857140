import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import apiConfig from 'config/api';
import moment from 'moment';
import {
    useWelfareController_dayWithImages,
    useWelfareController_imagesByDay,
} from 'services/hooks';
import { template, WelfareController_GetFullSizeImage } from 'services/services';

import TimeLineSlider from '@/components/molecules/TimeLineSlider/TimeLineSlider';
import { DownloadImages } from 'components/atoms/DownloadImages/DownloadImages';
import { Loader } from 'components/atoms/Loader';
import { CheckBox } from 'components/Layout';
import FullScreenButtonAlternative from 'components/Layout/Buttons/FullScreenButtonAlternative/FullScreenButtonAlternative';
import FullScreenCard from 'components/Layout/Cards/FullScreenCard/FullScreenCard';
import NoData from 'components/NoData';
import SomethingHappened from 'components/SomethingHappened';
import ZoomInImages from 'components/ZoomInImages/ZoomInImages';

interface ImageSliderProps {
    cageId: number;
    type?: string;
    title?: string;
    showBoxes?: boolean;
}

const checkFieldFishTag = [
    {
        id: 1,
        label: 'Healing',
        tag: 'healing_wound',
        checked: true,
    },
    {
        id: 2,
        label: 'Active',
        tag: 'wound',
        checked: true,
    },
];

const ImageSlider: React.Factory<ImageSliderProps> = ({
    cageId,
    title = 'Detections',
    type = 'full-size-image',
    showBoxes = false,
}) => {
    const { t } = useTranslation();
    const [toggleScreen, setToggleScreen] = useState(true);
    const {
        data: dateData,
        isLoading: dateIsLoading,
        isError: dateIsError,
        isSuccess: dateIsSucess,
    } = useWelfareController_dayWithImages(cageId, { type }, { enabled: !!cageId });

    const dateList = [...(dateData?.data || [])].sort((a, b) => {
        return moment(a.day).diff(moment(b.day));
    });
    const [date, setDate] = useState<string>(undefined);
    const dateToLookUp = date ?? dateList.at(-1)?.day;
    const [fishTags, setFishTags] = useState(checkFieldFishTag);
    const tags = fishTags.filter((filter) => filter.checked).map((o) => o.tag);
    const { data: imagesdata, isFetched: imagesFetched } = useWelfareController_imagesByDay(
        cageId,
        { day: dateToLookUp, type, tags },
        { enabled: !!cageId && !!dateToLookUp }
    );

    const images = imagesdata?.data ?? [];
    const [imageIndex, setImageIndex] = useState(0);

    // Reset image index when changing cage id
    useEffect(() => {
        setImageIndex(0);
        setDate(undefined);
    }, [cageId]);

    const imagePaths = images.map(({ timeStamp, id, ...item }) => {
        return {
            ...item,
            timeStamp: timeStamp,
            path:
                apiConfig.url +
                template(WelfareController_GetFullSizeImage.key, {
                    cageId: cageId,
                    id,
                }),
        };
    });

    if (dateIsLoading) {
        return <Loader />;
    }

    if (dateIsError) {
        return <SomethingHappened />;
    }

    const returnFullScreenValue = (value) => {
        setToggleScreen(value);
    };

    if (imagesdata === undefined && dateIsSucess && dateData.data.length === 0) {
        return (
            <FullScreenCard scrollEnable={true}>
                <div className="float-right mr-4 mb-n4">
                    <FullScreenButtonAlternative returnFullScreenValue={returnFullScreenValue} />
                </div>

                <div className="d-flex justify-content-center" />
                <NoData customText={'No images found'} />
            </FullScreenCard>
        );
    }

    const onAfterChange = (date) => {
        setDate(date);
        setImageIndex(0);
    };

    const handleCheckBoxChange = (id) => {
        //restrict end user from unchecking all checkbox
        const modifiedFishTags = fishTags.map((d) =>
            d.id === id ? { ...d, checked: !d.checked } : d
        );
        if (!modifiedFishTags.some((d) => d.checked)) {
            return;
        }
        setFishTags(modifiedFishTags);
    };

    const BOX_STYLE = {
        paddingTop: `${0}px`,
    };

    return (
        <FullScreenCard scrollEnable={true}>
            <div className="float-right mr-4 mb-n4">
                <DownloadImages imageList={imagePaths} imageIndex={imageIndex} />
                <FullScreenButtonAlternative returnFullScreenValue={returnFullScreenValue} />
            </div>
            {showBoxes && (
                <div className="d-flex justify-content-center">
                    {fishTags.map((tag, index) => (
                        <div key={index}>
                            <CheckBox
                                label={t(tag.label)}
                                value={tag.checked}
                                onChange={() => handleCheckBoxChange(tag.id)}
                            />
                        </div>
                    ))}
                </div>
            )}
            <TimeLineSlider
                dateList={dateList}
                onAfterChangeHandler={onAfterChange}
                value={dateList?.length - 1}
            />
            {imagesFetched ? (
                imagesdata && imagesdata.data.length === 0 ? (
                    <NoData customText={'No images found'} />
                ) : (
                    <ZoomInImages
                        images={imagePaths}
                        toggleScreen={toggleScreen}
                        showThumbnailImages={true}
                        imgIndex={imageIndex}
                        setImgIndex={setImageIndex}
                        showBoxes={showBoxes}
                    />
                )
            ) : (
                <Loader />
            )}
        </FullScreenCard>
    );
};
export default ImageSlider;
