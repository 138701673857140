import { useTranslation } from 'react-i18next';

import WoundSizes from 'components/molecules/WoundSizes';
import { OptoPieChart, WoundDescriptionToPieChartMapper } from 'components/organisms/OptoGraphs';
import { Options, showLegend } from 'components/organisms/OptoGraphs/optionsModifiers';
import { defaultOptoPieChartOptions } from 'components/organisms/OptoGraphs/OptoPieChart';

import styles from './WoundDescription.module.scss';

const MIN_WOUND = 0.0005; // Minimum wound value to display data in Wound description

interface WoundDescriptionProps {
    isColor?: boolean;
    woundsColor: {
        noWounds: number;
        activeWounds: number;
        healingWounds: number;
        bigWound: number;
        mediumWound: number;
        smallWound: number;
    };
}

const WoundDescription = ({ woundsColor, isColor = true }: WoundDescriptionProps) => {
    const { t } = useTranslation();
    const { noWounds, activeWounds, healingWounds, mediumWound, smallWound, bigWound } =
        woundsColor;
    const woundDistribution = [bigWound, mediumWound, smallWound];
    const woundSize = ['Large', 'Medium', 'Small'];
    const largesPortionIndex = woundDistribution.indexOf(Math.max(...woundDistribution));
    const sumWoundSizes = woundDistribution.reduce((a, b) => a + b, 0);
    const sumWoundState = activeWounds + healingWounds;

    let description = '';

    const toPercent = (partialValue: number, totalValue: number) => {
        return Math.abs((100 * partialValue) / totalValue);
    };

    const findWoundState = (
        healingWoundPercentage: number,
        activeWoundPercentage: number
    ): string => {
        let msg = '';
        const ratioHealing = healingWoundPercentage / activeWoundPercentage;
        const ratioActive = activeWoundPercentage / healingWoundPercentage;

        const outOfHealing = Math.round(ratioHealing);
        const outOfActive = Math.round(ratioActive);

        if (healingWoundPercentage > activeWoundPercentage) {
            if (healingWoundPercentage - activeWoundPercentage > 95) {
                msg = `${t('most of the of these wounds are healing.')}`;
            } else {
                msg = `${t('about')} ${outOfHealing} ${t('out of')} ${outOfHealing + 1} ${t(
                    'of these wounds are healing.'
                )}`;
            }
        } else if (activeWoundPercentage > healingWoundPercentage) {
            if (activeWoundPercentage - healingWoundPercentage > 95) {
                msg = `${t('most of the of these wounds are active.')}`;
            } else {
                msg = `${t('about')} ${outOfActive} ${t('out of')} ${outOfActive + 1} ${t(
                    'of these wounds are active.'
                )}`;
            }
        } else {
            msg = t('the share of healing and active wounds are equal');
        }
        return msg;
    };

    const decriptiveWoundState = findWoundState(
        toPercent(healingWounds, sumWoundState),
        toPercent(activeWounds, sumWoundState)
    );

    const notEnoughDataText =
        sumWoundSizes < MIN_WOUND ? t('The wound percentage is too low to show any details.') : '';
    const summaryText =
        sumWoundSizes > 0
            ? `${t('The wounds which are registered are mostly')} ${t(
                  woundSize[largesPortionIndex].toLowerCase()
                  // eslint-disable-next-line no-mixed-spaces-and-tabs
              )} ${t('wounds')} ${t('and')} ${decriptiveWoundState}`
            : 'No wounds';

    description = notEnoughDataText.length === 0 ? summaryText : notEnoughDataText;

    return (
        <>
            <div className={styles.wounddescription}>
                {isColor && (
                    <div className={styles.woundPieChart}>
                        <div>
                            <OptoPieChart
                                chartData={WoundDescriptionToPieChartMapper({
                                    data: [noWounds * 100, activeWounds * 100, healingWounds * 100],
                                    labels: ['No wound', 'Active', 'Healing'],
                                })}
                                chartOptions={Options(defaultOptoPieChartOptions).modify(
                                    showLegend({
                                        position: 'right',
                                        displayLegendValue: true,
                                        disabled: false,
                                    })
                                )}
                                chartName="Wound description"
                            />
                        </div>
                    </div>
                )}

                <div className={styles.wounddescriptionBars}>
                    <div>
                        {woundDistribution.map((_, index) => {
                            return (
                                <WoundSizes
                                    key={woundSize[index]}
                                    woundSize={t(woundSize[index])}
                                    woundValue={woundDistribution[index] * 100}
                                />
                            );
                        })}
                    </div>
                </div>
            </div>
            <p className={styles.descriptionText}>{description}</p>
        </>
    );
};

export default WoundDescription;
export { WoundDescription };
