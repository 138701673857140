import moment from 'moment';

const getTimeUnitFromTimeRange = (from, to): string => {
    if (!from && !to) {
        return 'day';
    }

    // Find the correct timeUnit for the cage.
    const a = moment(from, 'YYYY-MM-DD');
    const b = moment(to, 'YYYY-MM-DD');
    const days = b.diff(a, 'days'); // 1
    let unit = 'day';
    if (days < 30) {
        unit = 'day';
    } else if (days < 365) {
        unit = 'week';
    } else {
        unit = 'month';
    }

    return unit;
};

export default getTimeUnitFromTimeRange;
export { getTimeUnitFromTimeRange };
