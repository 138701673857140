import React from 'react';

import styles from './Alert.module.scss';

export interface AlertProps {
    color: 'warning' | 'danger' | 'neutral' | string;
    children?: React.ReactNode;
    size?: 'small' | 'medium' | 'large';
    contentAlign?: 'left' | 'center' | 'right';
    variant?: 'soft' | 'surface' | 'outline';
}

const Alert = ({
    children,
    size = 'medium',
    color = 'warning',
    contentAlign = 'left',
    variant = 'soft',
}: AlertProps) => {
    return (
        <div
            data-size={size}
            data-variant={variant}
            data-color={color}
            data-content-align={contentAlign}
            className={styles.alert}>
            {children}
        </div>
    );
};

export default Alert;
export { Alert };
