import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col as BCol, Row } from 'reactstrap';
import cn from 'classnames';

import { getTitle } from '../utils';

import StatusIcon from './StatusIcon';

import styles from '../FishHealth.module.scss';

const MAX_NUMBER_OF_COLUMNS = 4;

function Col({ label, getStatus, maxNumberOfColumns = MAX_NUMBER_OF_COLUMNS }) {
    const { t } = useTranslation();
    const status = getStatus(label);
    const title = getTitle(label);
    return (
        <BCol sm={12 / maxNumberOfColumns} className={styles.col}>
            <div className={styles.title}>{t(title)}</div>
            <StatusIcon status={status} />
        </BCol>
    );
}

function Rows({ labels, getStatus, maxNumberOfColumns = MAX_NUMBER_OF_COLUMNS }) {
    labels = labels || [];
    return labels
        .reduce((groups, col, index) => {
            const groupIndex = Math.floor(index / maxNumberOfColumns);

            if (index % maxNumberOfColumns === 0) {
                groups.push([]);
            }

            groups[groupIndex].push(col);

            return groups;
        }, [])
        .map((row, index) => (
            <Row key={index} className={cn(styles.row, styles.withColumns)}>
                {row.map((col, index) => (
                    <Col key={index} label={col} getStatus={getStatus} />
                ))}
            </Row>
        ));
}

function WelfareIndicators({ labels, getStatus, maxNumberOfColumns = MAX_NUMBER_OF_COLUMNS }) {
    const { t } = useTranslation();
    return (
        <>
            <h3 className="pb-3">{t('Health parameters')}</h3>

            <Rows labels={labels} getStatus={getStatus} maxNumberOfColumns={maxNumberOfColumns} />
        </>
    );
}
export default WelfareIndicators;
