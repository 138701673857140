import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Tour from 'reactour';

import { Button } from 'components/atoms/Buttons';

import styles from './overview-tour.module.scss';

function renderTourElements(t) {
    return (
        <div>
            {t('descriptions.statusBar.descriptionTop')}
            <table className={styles.statusBarTable}>
                <tbody>
                    <tr className={styles.statusBarRow}>
                        <td>
                            <div className={styles.statusBarListColorIndicatorGreen} />
                        </td>
                        <td>{t('descriptions.statusBar.greenIndicator')}</td>
                    </tr>
                    <tr className={styles.statusBarRow}>
                        <td>
                            <div className={styles.statusBarListColorIndicatorYellow} />
                        </td>
                        <td>{t('descriptions.statusBar.yellowIndicator')}</td>
                    </tr>
                    <tr className={styles.statusBarRow}>
                        <td>
                            <div className={styles.statusBarListColorIndicatorRed} />
                        </td>
                        <td>{t('descriptions.statusBar.redIndicator')}</td>
                    </tr>
                </tbody>
            </table>
            {t('descriptions.statusBar.descriptionBottom')}
        </div>
    );
}

function OverviewTour({ buttonLabel = '?', doneButtonLabel = 'Done!' }) {
    const { t } = useTranslation();

    const [openTour, setOpenTour] = useState(false);
    const handleCloseTour = () => setOpenTour(false);

    const steps = useMemo(() => {
        const steps = [
            {
                selector: '[data-tut="statusBar"]',
                content: renderTourElements(t),
            },
            {
                selector: '[data-tut="weightGraph"]',
                content: t('descriptions.weightGraph'),
            },
            {
                selector: '[data-tut="bioscopeStatusBar"]',
                content: t('descriptions.bioscopeStatusBar'),
            },
            {
                selector: '[data-tut="numberGraph"]',
                content: t('descriptions.numberGraph'),
            },
            {
                selector: '[data-tut="sceneLabels"]',
                content: t('descriptions.sceneLabels'),
            },
            {
                selector: '[data-tut="weightAndGrowth"]',
                content: t('descriptions.weightAndGrowth'),
            },
            {
                selector: '[data-tut="weightAndGrowthWeight"]',
                content: t('descriptions.weightAndGrowthWeight'),
            },
            {
                selector: '[data-tut="weightAndGrowthGrowth"]',
                content: t('descriptions.weightAndGrowthGrowth'),
            },
            {
                selector: '[data-tut="weightAndGrowthCV"]',
                content: t('descriptions.weightAndGrowthCV'),
            },
            {
                selector: '[data-tut="weightAndGrowthSGR"]',
                content: t('descriptions.weightAndGrowthSGR'),
            },
            {
                selector: '[data-tut="weightAndGrowthRGI"]',
                content: t('descriptions.weightAndGrowthRGI'),
            },
            {
                selector: '[data-tut="weightAndGrowthK"]',
                content: t('descriptions.weightAndGrowthK'),
            },
        ];

        return steps;
    }, [t]);

    return (
        <>
            <Tour
                accentColor="#2f837b"
                steps={steps}
                isOpen={openTour}
                onRequestClose={handleCloseTour}
                scrollDuration={400}
                className={styles.helper}
                maskClassName={styles.mask}
                highlightedMaskClassName={styles.highlightedMask}
                showNavigation={true}
                maskSpace={16}
                rounded={2}
                lastStepNextButton={<Button>{t(doneButtonLabel)}</Button>}
            />
            <Button onClick={() => setOpenTour(true)}>{t(buttonLabel)}</Button>
        </>
    );
}

export default OverviewTour;
