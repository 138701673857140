import { useTranslation } from 'react-i18next';
import { lowerFirst, trim } from 'lodash';

const NON_TRANSLATABLE = ['.', ',', ':', '%'];
const EOS = ['.'];

function useMyTranslation() {
    const { t, ...other } = useTranslation();

    const myTranslate = (text) => {
        if (!Array.isArray(text)) {
            text = [text];
        }

        return text.reduce((acc, cur, index, arr) => {
            const isNonTranslatable = NON_TRANSLATABLE.some((str) => str === trim(cur));

            let str = isNonTranslatable ? cur : t(cur);

            if (index > 0) {
                const isEos = EOS.some((eos) => eos === trim(arr[index - 1]));

                // make lower case first char
                // if it is not new sentence
                if (!isEos) {
                    str = lowerFirst(str);
                }
            }

            return acc.concat(str);
        }, '');
    };

    return { t: myTranslate, ...other };
}

export default useMyTranslation;
