import React, { useState } from 'react';
import {
    useLicenseController_addContractLicense,
    useLicenseController_createContract,
    useLicenseController_deleteContractLicense,
    useLicenseController_getClientContracts,
    useLicenseController_getLicense,
    useLicenseController_updateContract,
    useLicenseController_updateContractLicense,
} from 'services/hooks';
import type { CreateNewContractInput, CreateNewContractLicenseInput } from 'services/types';

import { BioscopeMovingModalContainer } from '@/components/organisms/MoveBioscopeModal/MoveBioscopeModal';
import { BioscopeContractList } from 'components/molecules/BioscopeContractList/BioscopeContractList';

interface MoveCallbackParameters {
    bioscopeId: number;
    cageId: number;
    clientId: number;
    from: string;
    contractId: number;
}

interface BioscopeLicenseContainerProps {
    clientId: number;
}

export const BioscopeLicenseContainer = ({ clientId }: BioscopeLicenseContainerProps) => {
    const [error, setError] = useState(null);

    const { data: licenseData, isLoading: isLicenseDataLoading } =
        useLicenseController_getLicense();

    const {
        data: clientContractData,
        isLoading: isClientContractLoading,
        refetch: refetchList,
    } = useLicenseController_getClientContracts(clientId, { onError: setError });

    const { mutateAsync: createLicense } = useLicenseController_addContractLicense({
        onSuccess: () => refetchList(),
        onError: setError,
    });
    const { mutateAsync: createContract } = useLicenseController_createContract({
        onSuccess: () => refetchList(),
        onError: setError,
    });

    const { mutateAsync: updateContract } = useLicenseController_updateContract({
        onSuccess: () => refetchList(),
        onError: setError,
    });
    const { mutateAsync: updateLicense } = useLicenseController_updateContractLicense({
        onSuccess: () => refetchList(),
        onError: setError,
    });
    const { mutateAsync: deleteLicense } = useLicenseController_deleteContractLicense({
        onSuccess: () => refetchList(),
        onError: setError,
    });

    const [movingBioscope, setMovingBioscope] = useState<number>();
    const [isMovingModalOpen, setIsMovingModalOpen] = useState<boolean>();

    const input = {
        licenseList: licenseData?.data ?? [],
        contractList: clientContractData?.data ?? [],
        isLoading: isLicenseDataLoading || isClientContractLoading,
        error: error,
        openMoveModal: (bioscopeId: number) => {
            setMovingBioscope(bioscopeId);
            setIsMovingModalOpen(true);
        },
        onCreateLicense: async (
            contractId: number,
            newLicense: CreateNewContractLicenseInput
        ): Promise<void> => {
            await createLicense({ id: contractId, requestBody: newLicense });
        },
        onCreateContract: async (newContract: any): Promise<void> => {
            await createContract({ requestBody: { ...newContract, clientId } });
        },
        onUpdateLicense: async (
            licenseId,
            license: CreateNewContractLicenseInput
        ): Promise<void> => {
            await updateLicense({
                licenseId: licenseId,
                requestBody: license,
            });
        },
        onDeleteLicense: (id: number) => {
            deleteLicense({ licenseId: id });
        },
        onUpdateContract: async (contractId: number, contract: CreateNewContractInput) => {
            console.log(contract);
            await updateContract({ id: contractId, requestBody: contract });
        },
    };

    const onCloseBioscopeModal = () => {
        setIsMovingModalOpen(false);
        refetchList();
    };

    return (
        <>
            {
                <BioscopeMovingModalContainer
                    bioscopeId={movingBioscope}
                    isOpen={isMovingModalOpen}
                    onClose={onCloseBioscopeModal}
                />
            }
            <BioscopeContractList {...input} />
        </>
    );
};
