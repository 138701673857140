import type React from 'react';
import LICENSES from 'config/licenses';
import type { CageContextType } from 'contexts/cage-context';

import { ImagePageContainer } from '../pages/Images/ImagePageContainer';

import { FishHealthColorContainer } from './FishHealthColor2/FishHealthColorContainer';
import { FishSpeedPage } from './fishspeed/FishSpeed';
import FishHealth from './FishHealth';
import FishHealthColor from './FishHealthColor';
import Graphs2 from './Graphs2';
import LiceCount from './LiceCount';
import Overview2 from './Overview2';
import { ReportDetailContainer, ReportListContainer } from './ReportListImproved';
import Settings from './Settings';
import WeightDistribution from './WeightDistribution';
export interface Page {
    path?: string;
    label?: string;
    page?: React.ComponentType;
    roles?: string[];
    hidden?: boolean | ((ctx: CageContextType) => boolean);
    exact?: boolean;
    licenses?: string[];
    key: number;
    params?: {
        [key: string]: string;
    };
}

export const isHidden = (page: Page, ctx: CageContextType) => {
    if (typeof page.hidden === 'boolean') {
        return page.hidden;
    }
    if (typeof page.hidden === 'function') {
        return page.hidden(ctx);
    }
    return false;
};

export type Pages = Page[];

const pages: Pages = [
    {
        path: '',
        label: 'Overview',
        page: Overview2,
    },

    {
        path: '/graphs',
        label: 'Graphs',
        page: Graphs2,
        exact: true,
    },

    {
        path: '/graphs/speed',
        label: 'Fish speed',
        page: FishSpeedPage,
        exact: true,
        hidden: true,
    },

    {
        path: '/weight-distribution',
        label: 'Weight distribution',
        page: WeightDistribution,
    },
    {
        path: '/bioscope-image',
        label: 'Images',
        page: ImagePageContainer,
    },
    {
        exact: true, // because we have children
        path: '/reports/v2',
        label: 'Reporting.Reporting',
        page: ReportListContainer,
    },

    {
        path: '/reports/v2/:reportId',
        page: ReportDetailContainer,
    },

    {
        path: '/settings',
        label: 'Settings',
        page: Settings,
    },
    {
        path: '/health',
        label: 'Health',
        exact: true,
        page: FishHealth,
        hidden: true,
        licenses: [LICENSES.FISH_HEALTH.MIN],
    },
    {
        path: '/health/wounds',
        label: 'Health',
        exact: true,
        page: FishHealthColorContainer,
        licenses: [LICENSES.FISH_HEALTH.MIN],
        hidden: (ctx: CageContextType) => ctx.isTrout,
    },
    {
        path: '/health/:healthType',
        exact: true,
        page: FishHealthColorContainer,
        licenses: [LICENSES.FISH_HEALTH.MIN],
    },
    {
        path: '/fish-health',
        label: 'Health (legacy)',
        page: FishHealth,
        licenses: [LICENSES.FISH_HEALTH.MIN],
        hidden: true,
    },
    {
        path: '/fish-health-color',
        label: 'Health (legacy)',
        page: FishHealthColor,
        licenses: [LICENSES.FISH_HEALTH.MIN],
        hidden: true,
    },
    {
        path: '/lice-count',
        label: 'Lice',
        page: LiceCount,
        licenses: [LICENSES.LICE_COUNT.MIN, LICENSES.LICE_COUNT.MAX],
    },
].map((page, index) => ({ key: index, ...page }));

export default pages;
