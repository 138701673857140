import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Alert,
    Button,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';

import Loader from '@/components/atoms/Loader';
import { CageType } from '@/services/types';

import styles from './AddCageModal.module.scss';

export interface AddCageModalProps {
    cageTypesList: CageType[];
    children?: React.ReactNode;
    locationId: number;
    onClose: () => void;
    createNewCage: (locationId: number, name: string, cagetypeid: number) => Promise<unknown>;
}

type Error = {
    key: string;
    message: string;
};

type formField = {
    value: string;
    error: string;
};

type numberFormField = {
    value: number;
    error: string;
};

const AddCageModal = ({
    onClose,
    createNewCage,
    locationId,
    cageTypesList = [],
}: AddCageModalProps) => {
    const { t } = useTranslation();
    const [formState, setFormState] = useState({
        name: {
            value: '',
            error: '',
        } as formField,
        cagetypeid: {
            value: -1,
            error: '',
        } as numberFormField,
        saving: false,
        systemError: '',
    });

    const getError = (propertyName: string): Error | undefined => {
        const errors = [];
        for (const [key, value] of Object.entries(formState)) {
            if (key === 'saving' || key === 'systemError') {
                continue;
            }

            if (typeof value === 'object' && value.error) {
                errors.push({ key, message: value.error });
            }
        }
        return errors.find((e) => e.key === propertyName);
    };

    const hasErrors = () => {
        return getErrorMessages().length > 0;
    };

    const getErrorMessages = () => {
        const errors = [] as Error[];
        for (const [key, value] of Object.entries(formState)) {
            if (key === 'saving' || key === 'systemError') {
                continue;
            }

            if (typeof value === 'object' && value.error) {
                errors.push(getError(key));
            }
        }
        return errors;
    };

    console.log(getErrorMessages());

    const onSubmit = async (event) => {
        setFormState((prevState) => ({
            ...prevState,
            saving: true,
        }));

        try {
            await createNewCage(locationId, formState.name.value, formState.cagetypeid.value);
            setFormState((prevState) => ({
                ...prevState,
                saving: false,
            }));
            onClose();
        } catch (error: any) {
            if (error.error?.messages) {
                setFormState((prevState) => ({
                    ...prevState,
                    saving: false,
                    systemError: error.error.messages,
                }));
            } else {
                setFormState((prevState) => ({
                    ...prevState,
                    saving: false,
                    systemError: t('somethingwrong-trulysorry'),
                }));
            }
        }

        event?.preventDefault();
    };

    return (
        <div className={styles.addcagemodal}>
            <Modal isOpen={!!locationId} toggle={onClose}>
                <ModalHeader toggle={onClose}>{t('Add cage')}</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        <Label for="name">{t('Name')}</Label>
                        <Input
                            id="name"
                            type="text"
                            name="name"
                            placeholder={t('Give it a name')}
                            value={formState.name.value}
                            onChange={(e) =>
                                setFormState((prevState) => ({
                                    ...prevState,
                                    name: {
                                        ...prevState.name,
                                        value: e.target.value,
                                    },
                                }))
                            }
                            invalid={!!getError('name')}
                        />
                        <Label for="cageType">{t('Type')}</Label>
                        <Input
                            id="cageType"
                            type="select"
                            name="cageType"
                            onChange={(e) =>
                                setFormState((prevState) => ({
                                    ...prevState,
                                    cagetypeid: {
                                        ...prevState.cagetypeid,
                                        value: parseInt(e.target.value),
                                    },
                                }))
                            }
                            invalid={!!getError('cageType')}>
                            <option value={-1}>{`--- ${t('choose cage type')} ---`}</option>
                            {cageTypesList?.map((cageType) => {
                                return (
                                    <option key={cageType.id} value={cageType.id as number}>
                                        {t(cageType.name)}
                                    </option>
                                );
                            })}
                        </Input>

                        {getErrorMessages().length > 0 ? (
                            <FormFeedback>
                                {getErrorMessages()?.map((error: Error) => {
                                    return <span key={error.key}>{error.message}</span>;
                                })}
                            </FormFeedback>
                        ) : (
                            ''
                        )}
                    </FormGroup>
                    {getErrorMessages()?.length > 0 ? (
                        <Alert color="danger">
                            {getErrorMessages()?.map((error: Error) => {
                                return <span key={error.key}>{error.message}</span>;
                            })}
                        </Alert>
                    ) : (
                        ''
                    )}
                </ModalBody>
                <ModalFooter>
                    {formState.saving ? (
                        <div className="mr-2">
                            <Loader size={'30px'} />
                        </div>
                    ) : null}
                    <Button color="primary" onClick={(e) => onSubmit(e)}>
                        {t('Add')}
                    </Button>{' '}
                    <Button color="secondary" onClick={onClose}>
                        {t('Cancel')}
                    </Button>
                </ModalFooter>
            </Modal>
        </div>
    );
};

export default AddCageModal;
export { AddCageModal };
