import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Nav } from 'reactstrap';
import BioscopeDetailContainer from 'containers/BioscopeDetailContainer';

import { HeaderLink } from 'components/organisms/Header';

import { HeaderContainer } from '../../components/organisms/Header/HeaderContainer';
import UserManagmentPage from '../../components/UserManagmentPage';

import Bioscopes from './routes/Bioscopes';
import Client from './routes/Client';
import Clients from './routes/Clients';

const Internal = (props) => {
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const changeLanguage = (language) => i18n.changeLanguage(language);
        if (i18n.language !== 'en') {
            changeLanguage('en');
        }
    }, [i18n, i18n.language]);

    return (
        <>
            <HeaderContainer baseUrl={props.match.path} languageBar={false}>
                <Nav navbar={true}>
                    <HeaderLink to={`${props.match.path}/clients`} exact={false}>
                        {t('Clients')}
                    </HeaderLink>
                    <HeaderLink to={`${props.match.path}/bioscopes`}>{t('Bioscopes')}</HeaderLink>
                    <HeaderLink to={`${props.match.path}/users`}>{t('Users')}</HeaderLink>
                </Nav>
            </HeaderContainer>
            <Switch>
                <Route exact path={`${props.match.path}/users`} component={UserManagmentPage} />

                <Route
                    exact
                    path={'/internal/bioscopes/:bioscopeId'}
                    component={BioscopeDetailContainer}
                />
                <Route exact path={`${props.match.path}/bioscopes`} component={Bioscopes} />
                <Route exact path={`${props.match.path}/clients`} component={Clients} />
                <Route exact path={`${props.match.path}/clients/:clientId`} component={Client} />

                <Redirect to={`${props.match.path}/bioscopes`} />
            </Switch>
        </>
    );
};

export default Internal;
export { Internal };
