/* eslint-disable react/no-unknown-property */
import React, { type MouseEvent, type ReactNode, useEffect, useRef, useState } from 'react';
import type { Chart } from 'chart.js';
import classNames from 'classnames';
import { t } from 'i18next';

import { CopyAsPng, DownloadAsPng, ResetZoom } from 'components/atoms/Buttons';
import { FullScreenIcon, FullScreenOffIcon, VerticalElipsisIcon } from 'components/atoms/icons';
import { OptoTooltip } from 'components/atoms/OptoTooltip';
import { SwitchButtons } from 'components/molecules/SwitchButtons';

import styles from './ChartHeaderBar.module.scss';
export interface ChartHeaderBarProps {
    children?: ReactNode;
    chart?: Chart<'line' | 'bar' | 'doughnut' | 'pie'>;
    fullScreenHandler?: (ev: MouseEvent<HTMLButtonElement>) => void;
    downloadAsPngHandler?: (ev: MouseEvent<HTMLButtonElement>) => void;
    copyAsPngHandler?: (ev: MouseEvent<HTMLButtonElement>) => void;
    resetZoomHandler?: (ev: MouseEvent<HTMLButtonElement>) => void;
    switchButtonsHandler?: (ev: MouseEvent<HTMLButtonElement>) => void;
    isFullScreen?: boolean;
    visibleButtons?: {
        copyAsPng?: boolean;
        resetZoom?: boolean;
        downloadAsPng?: boolean;
        fullScreen?: boolean;
        timeScaleSwitch?: boolean;
    };
}

const ChartHeaderBar = ({
    children,
    fullScreenHandler,
    downloadAsPngHandler,
    copyAsPngHandler,
    resetZoomHandler,
    switchButtonsHandler,
    isFullScreen = false,
    visibleButtons = {},
}: ChartHeaderBarProps) => {
    const { copyAsPng, downloadAsPng, fullScreen, resetZoom, timeScaleSwitch } = {
        copyAsPng: true,
        resetZoom: true,
        downloadAsPng: true,
        fullScreen: true,
        timeScaleSwitch: false,
        ...visibleButtons,
    };

    const allOff = visibleButtons && Object.values(visibleButtons).every((v) => !v);

    const [actionMenuOpen, setActionMenuOpen] = useState(false);
    const menuButton = useRef<HTMLButtonElement>(null);
    const actionWrappper = useRef<HTMLDivElement>(null);
    const chartheaderbar = useRef<HTMLDivElement>(null);

    const switchButtonsConfig = {
        switches: [
            {
                label: 'Daily',
                value: 'day',
                selected: true,
            },
            {
                label: 'Weekly',
                value: 'week',
            },
        ],
        size: 'sm' as const,
        onClick: switchButtonsHandler,
    };

    const handleToggleActionMenu = (ev: MouseEvent<HTMLButtonElement>) => {
        setActionMenuOpen(!actionMenuOpen);
    };

    const handleActionMenuClose = (ev) => {
        if (
            actionMenuOpen &&
            !menuButton.current?.contains(ev.target as Node) &&
            !actionWrappper.current?.contains(ev.target as Node)
        ) {
            setActionMenuOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('click', handleActionMenuClose);

        return () => {
            document.removeEventListener('click', handleActionMenuClose);
        };
    }, [chartheaderbar.current?.clientWidth]);

    if (allOff) {
        return null;
    }

    return (
        <div datatype="csscontainer" onResize={handleActionMenuClose}>
            <div datatype="chartheaderbar" className={styles.chartheaderbar} ref={chartheaderbar}>
                <div
                    ref={actionWrappper}
                    className={classNames(
                        styles.actionButtonWrapper,
                        actionMenuOpen ? styles.expandAction : ''
                    )}>
                    {children}
                    {timeScaleSwitch && <SwitchButtons {...switchButtonsConfig} />}

                    {copyAsPng && (
                        <OptoTooltip content={t('Copy chart as PNG')} nub="up-right">
                            <CopyAsPng
                                onClick={copyAsPngHandler}
                                className={styles.copyAsPngButton}
                            />
                        </OptoTooltip>
                    )}

                    {resetZoom && (
                        <OptoTooltip content={t('Reset Zoom')} nub="up-right">
                            <ResetZoom onClick={resetZoomHandler} />
                        </OptoTooltip>
                    )}

                    {downloadAsPng && (
                        <OptoTooltip content={t('Download chart as .png')} nub="up-right">
                            <DownloadAsPng onClick={downloadAsPngHandler} />
                        </OptoTooltip>
                    )}

                    {fullScreen && (
                        <OptoTooltip content={t('Show chart in fullscreen')} nub="up-right">
                            <button
                                className={styles.fullscreenbutton}
                                onClick={fullScreenHandler}
                                type="button">
                                {isFullScreen ? (
                                    <FullScreenOffIcon size={24} />
                                ) : (
                                    <FullScreenIcon size={24} />
                                )}
                            </button>
                        </OptoTooltip>
                    )}
                </div>
                <OptoTooltip content={t('Show action buttons')} nub="up-right">
                    <button
                        type="button"
                        className={styles.elipsisButton}
                        onClick={handleToggleActionMenu}
                        ref={menuButton}>
                        <VerticalElipsisIcon />
                    </button>
                </OptoTooltip>
            </div>
        </div>
    );
};

export default ChartHeaderBar;
export { ChartHeaderBar };
