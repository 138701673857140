import React from 'react';
import { useTranslation } from 'react-i18next';
import type { Contract, CreateNewContractLicenseInput, LicenseInfo } from 'services/types';

import { Loader } from 'components/atoms/Loader';
import { Box } from 'components/Layout';
import { BioscopeContract } from 'components/molecules/BioscopeContract/BioscopeContract';

import ModalCreateBioscopeContract from '../ModalCreateBioscopeContract';

export interface BioscopeLicenseListProps {
    contractList: Contract[];
    licenseList: LicenseInfo[];
    isLoading: boolean;
    error: any | null;
    onCreateLicense: (
        contractId: number,
        newLicense: CreateNewContractLicenseInput
    ) => Promise<void>;
    onCreateContract: (newContract: any) => Promise<unknown>;
    onUpdateContract: (id: number, contract: {}) => Promise<unknown>;
    onUpdateLicense: (licenseId, license: CreateNewContractLicenseInput) => Promise<unknown>;
    onDeleteLicense: (id: number) => void;
    openMoveModal: (bioscopeId: number) => void;
}

export const BioscopeContractList = ({
    contractList,
    licenseList,
    isLoading,
    error,
    onCreateLicense,
    onCreateContract,
    onUpdateContract,
    onUpdateLicense,
    onDeleteLicense,
    openMoveModal,
}: BioscopeLicenseListProps) => {
    const { t } = useTranslation();

    return (
        <Box title={t('Bioscope License')}>
            <Loader loading={isLoading} error={error}>
                {contractList.map((contract) => (
                    <BioscopeContract
                        key={contract.id}
                        contract={contract}
                        licenseList={licenseList}
                        onCreateLicense={(input) => onCreateLicense(contract.id, input)}
                        onUpdateLicense={onUpdateLicense}
                        onDeleteLicense={(id) => onDeleteLicense(id)}
                        openMoveModal={openMoveModal}
                        onUpdateContract={onUpdateContract}
                    />
                ))}
            </Loader>
            <ModalCreateBioscopeContract onSubmitted={onCreateContract} />
        </Box>
    );
};
