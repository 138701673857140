import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import VisibilitySensor from 'react-visibility-sensor';
import { graphSettings } from 'config/settings';

import { formatPercentage } from '@/utils/formatPercentage';
import { TrendIcon } from 'components/atoms/TrendIcon';
// import { getIcon, COLOR_NEUTRAL } from 'utils/fishHealth';
import { CircularProgressBar } from 'components/molecules/CircularProgressBar';

import OptoTooltip from '../OptoTooltip';

import styles from './FishHealthSummary.module.scss';

interface FishHealthSummaryProps {
    label?: string;
    dataRate: number;
    description?: string;
    downgradeRate?: number;
    variant?: 'default' | 'progress' | 'card' | 'inline';
    status: 'positive' | 'negative' | 'neutral';
    direction: 'up' | 'down' | 'flat';
}

const FishHealthSummary = ({
    label = '',
    dataRate,
    downgradeRate,
    variant = 'progress',
    direction,
    status,
}: FishHealthSummaryProps) => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(true);
    //const Icon = getIcon(icon);
    const onChange = (isVisible) => {
        setIsVisible(isVisible);
    };

    const suffixes = new Map([
        ['wound', 's'],
        ['scale loss', 'es'],
        ['maturation', 's'],
    ]);

    const trend = new Map([
        ['negative', 'increasing'],
        ['positive', 'decreasing'],
        ['neutral', 'stable'],
    ]);

    const trendColor = new Map([
        ['negative', graphSettings.statusColors.negative],
        ['positive', graphSettings.statusColors.positive],
        ['neutral', graphSettings.statusColors.neutral],
    ]);

    return (
        <div className={styles.fishHealthSummary} data-variant={variant}>
            {label.length > 0 && variant !== 'inline' && (
                <div data-area="heading">
                    <h3>
                        {t(label)}
                        <TrendIcon
                            direction={direction}
                            disabled={false}
                            status={status}
                            size={32}
                        />
                    </h3>
                </div>
            )}
            <div data-area="currentNumber">
                <div data-area="animation">
                    <span className={`${'animFadeIn'}`}>
                        <VisibilitySensor onChange={onChange} partialVisibility>
                            {variant === 'default' || variant === 'inline' ? (
                                <span className={isVisible ? styles.rotatingBg : styles.focusBg}>
                                    {formatPercentage(dataRate)}
                                </span>
                            ) : (
                                <span className={styles.circularwrap}>
                                    <CircularProgressBar
                                        percentage={dataRate * 100}
                                        color={trendColor.get(status)}
                                        size={100}
                                    />
                                </span>
                            )}
                        </VisibilitySensor>
                    </span>
                </div>

                <div data-area="description">
                    <p>
                        {variant !== 'inline' && <em>{`${formatPercentage(dataRate)} `}</em>}
                        {`${t('of all the fish have signs of')} ${t(
                            `fishHealthIndicators.${label}`,
                            { count: 500 }
                        ).toLowerCase()}.`}
                    </p>
                </div>
            </div>
            <p>
                {`${t('The trend is')} ${t(`fishHealthColorStatus.${trend.get(status)}Text`)}${
                    downgradeRate ? ' ' : '.'
                } `}
                {variant === 'inline' && (
                    <TrendIcon direction={direction} disabled={false} status={status} size={32} />
                )}
            </p>

            {downgradeRate !== undefined && (
                <p>
                    {`${t('Estimated downgrades based on wounds')}:`}
                    <strong>
                        {formatPercentage(downgradeRate)}{' '}
                        <OptoTooltip
                            content={t('Downgrade description')}
                            nub="up-right"
                            maxWidth={150}>
                            <i className="fa fa-info-circle fa-xs" />
                        </OptoTooltip>
                    </strong>
                </p>
            )}
        </div>
    );
};

export default FishHealthSummary;
export { FishHealthSummary };
