import React from 'react';
import { ChangeEventHandler, ReactNode, useEffect, useId, useMemo, useState } from 'react';
import { Input, InputProps } from 'reactstrap';
import { debounceTime, filter, Subject, switchMap } from 'rxjs';

interface AutoCompleteInputProps<T extends { value: string }> {
    fetchData: (input: string) => Promise<T[]> | T[];
    renderer?: (data: T) => ReactNode;
    debounceTimeInMs?: number;
    minLengthBeforeSearch?: number;
    onAutoCompleteSelected?: (data: T | undefined) => unknown;
}

export function AutoCompleteInput<T extends { value: string }>({
    fetchData,
    renderer = (data) => data.value,
    debounceTimeInMs = 500,
    minLengthBeforeSearch = 1,
    onChange,
    onAutoCompleteSelected,

    ...props
}: AutoCompleteInputProps<T> & InputProps) {
    const [data, setData] = useState<T[]>([]);
    const [searchtText, setSearchText] = useState<string>('');
    const { subject, subscription } = useMemo(() => {
        const subject = new Subject<string>();
        const subscription = subject
            .pipe(
                debounceTime(debounceTimeInMs),
                filter((input) => input.length >= minLengthBeforeSearch),
                switchMap((input) => Promise.resolve(fetchData(input)))
            )
            .subscribe((result) => {
                setData(result);
            });

        return {
            subject,
            subscription,
        };
    }, []);

    //We need to update subject every time the searchtext changers
    useEffect(() => {
        subject.next(searchtText);
    }, [searchtText]);

    //It is important to clean up Observables when the component is unmounted
    useEffect(() => {
        return () => {
            subscription.unsubscribe();
            subject.complete();
        };
    }, [subject, subscription]);

    const wrappedOnChange: ChangeEventHandler<HTMLInputElement> = (ev) => {
        onAutoCompleteSelected?.(data.find((data) => data.value === ev.target.value));
        setSearchText(ev.target.value);
        onChange?.(ev);
    };

    const listId = useId();

    return (
        <>
            <Input {...props} list={listId} onChange={wrappedOnChange} />
            <datalist id={listId}>
                {data.map((datum) => (
                    <option key={datum.value} value={datum.value}>
                        {renderer(datum)}
                    </option>
                ))}
            </datalist>
        </>
    );
}
