import React from 'react';
import { Col } from 'reactstrap';
import cn from 'classnames';

import OptoTooltip from '@/components/atoms/OptoTooltip';

import keyNumberGroupContext from '../../../../contexts/keyNumberGroupContext';

import styles from './keyNumber.module.scss';

interface KeyNumberValueProps {
    error?: boolean;
    children?: React.ReactNode;
}

export const KeyNumberValue = ({ error, children }: KeyNumberValueProps) => (
    <span className={cn(styles.value, error ? 'text-danger' : undefined)}>{children}</span>
);

const formattedValues = (value, metric, opts) => {
    if (
        opts?.ifBad !== undefined &&
        (value === undefined || value === null || Number.isNaN(Number(value)))
    ) {
        return [opts.ifBad, undefined];
    }
    if (opts?.ifNaN !== undefined && Number.isNaN(Number(value))) {
        return [opts.ifNaN, undefined];
    }
    if (opts?.ifNull !== undefined && value === null) {
        return [opts.ifNull, undefined];
    }
    if (opts?.ifUndefined !== undefined && value === undefined) {
        return [opts.ifUndefined, undefined];
    }
    if (opts?.ifZero !== undefined && Number(value) === 0) {
        return [opts.ifZero, undefined];
    }
    if (typeof value === 'number' && opts.isFraction) {
        return [
            value.toLocaleString('us', {
                style: 'decimal',
                minimumFractionDigits: opts.minimumFractionDigits,
                maximumFractionDigits: opts.maximumFractionDigits,
            }),
            metric,
        ];
    }
    return [value, metric];
};

const KeyNumber = ({
    label,
    value = undefined,
    tooltipText = undefined,
    metric = undefined,
    ifBad = undefined,
    ifNull = undefined,
    ifUndefined = undefined,
    ifNaN = undefined,
    ifZero = undefined,
    id = undefined,
    children = undefined,
    error = undefined,
    isFraction = false,
    ...other
}) => {
    const { numberOfColumns } = React.useContext(keyNumberGroupContext);

    const [displayValue, displayMetric] = formattedValues(value, metric, {
        ifBad,
        ifNull,
        ifUndefined,
        ifNaN,
        ifZero,
        isFraction,
    });

    return (
        <Col sm={12 / numberOfColumns} className={cn(styles.keyNumber)} id={id}>
            <div {...other}>
                <span className={styles.label}>{label}</span>
                <div>
                    {children ? (
                        children
                    ) : (
                        <>
                            <OptoTooltip nub="up-center" content={tooltipText} maxWidth={150}>
                                <KeyNumberValue error={error}>{displayValue}</KeyNumberValue>
                                <span
                                    className={cn(
                                        styles.metric,
                                        error ? 'text-danger' : undefined
                                    )}>
                                    {displayMetric}
                                </span>
                            </OptoTooltip>
                        </>
                    )}
                </div>
            </div>
        </Col>
    );
};
export default KeyNumber;
export { KeyNumber };
