import { FormEvent, useState } from 'react';
import DateTime from 'react-datetime';
import { useTranslation } from 'react-i18next';
import {
    Alert,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import moment from 'moment';

import { Button } from 'components/atoms/Buttons';

export interface MovingState {
    bioscopeId: number;
    clientId: number | null;
    cageId: number | null;
    locationId: number | null;
    contractId: number | null;
    from: Date | null;
}

export interface MoveCallbackParameters {
    bioscopeId: number;
    cageId: number | null;
    clientId: number | null;
    from: string;
    contractId: number | null;
}

interface BioscopeDetailMovingBioscopeModalProps {
    initalState: MovingState;
    isModalOpenMoveBioscope: boolean;
    clients: {
        id: number;
        name: string;
        locations: {
            id: number;
            name: string;
            cages: {
                id: number;
                name: string;
                historical?: unknown;
            }[];
        }[];
        contract: {
            id: number;
            description: string;
        }[];
    }[];
    canModifyClientOrContract: boolean;

    onCloseModal: () => void;
    onSubmit: (state: MoveCallbackParameters) => Promise<void>;
}

const BioscopeDetailMovingBioscopeModal = ({
    initalState,
    isModalOpenMoveBioscope,
    clients,
    onSubmit,
    onCloseModal,
    canModifyClientOrContract,
}: BioscopeDetailMovingBioscopeModalProps) => {
    const { t } = useTranslation();
    const [submitFailed, setSubmitFailed] = useState<string>('');

    const [state, setState] = useState<MovingState>({ ...initalState });
    const locations = clients.find((client) => client.id === state.clientId)?.locations ?? [];
    const contracts = clients.find((client) => client.id === state.clientId)?.contract ?? [];

    const onClickMoveBioscope = async (ev: FormEvent<HTMLFormElement>) => {
        ev.preventDefault();
        try {
            if (state.from === null) {
                throw new Error('From date is required');
            }
            setSubmitFailed('');
            await onSubmit({ ...state, from: state.from.toISOString() });
            onCloseModal();
        } catch (error: unknown) {
            let message = JSON.stringify(error);
            if (error instanceof Error) {
                message = error?.message;
            }
            setSubmitFailed(
                message ?? "Couldn't move bioscope - contact Customer success for support"
            );
        }
    };

    function onChangeMovingState<T extends keyof MovingState, V = MovingState[T]>(key: T) {
        return (inputValue: V) => {
            const value = Number.isNaN(inputValue) ? null : inputValue;
            if (key === 'clientId') {
                setState((formData) => ({
                    ...formData,
                    locationId: null,
                    contractId: null,
                    cageId: null,
                }));
            }
            if (key === 'locationId') {
                const location = locations.find((location) => location.id === value);
                setState((formData) => ({
                    ...formData,
                    cageId: null,
                }));
            }
            setState((formData) => ({ ...formData, [key]: value }));
        };
    }

    const availableCages =
        locations
            ?.find((item) => item.id === state.locationId)
            ?.cages?.filter((cage) => cage.historical === null)
            ?.toSorted((a, b) => a.name.localeCompare(b.name)) ?? [];
    return (
        <Modal isOpen={isModalOpenMoveBioscope} toggle={onCloseModal}>
            <Form onSubmit={onClickMoveBioscope}>
                <ModalHeader>{`${t('Move')} ${t('Bioscope')} ${state.bioscopeId}`}</ModalHeader>
                <ModalBody>
                    <FormGroup>
                        {submitFailed.length !== 0 && (
                            <Alert color="danger">{t(submitFailed ?? 'Something happened')}</Alert>
                        )}
                        {canModifyClientOrContract && (
                            <>
                                <Label for="client">{t('Choose client')}</Label>
                                <Input
                                    type="select"
                                    name="client"
                                    id="client"
                                    value={state.clientId ?? ''}
                                    onChange={(ev) =>
                                        onChangeMovingState('clientId')(parseInt(ev.target.value))
                                    }>
                                    <option key={0} value={''}>
                                        {t('Non existent client')}
                                    </option>
                                    {clients
                                        .toSorted((a, b) => a.name.localeCompare(b.name))
                                        .map((client) => (
                                            <option key={client.id} value={client.id}>
                                                {client.name}
                                            </option>
                                        ))}
                                </Input>
                            </>
                        )}
                        {state.clientId !== 0 && locations ? (
                            <>
                                <Label for="location">{t('Choose location')}</Label>
                                <Input
                                    type="select"
                                    key={state.clientId}
                                    name="location"
                                    if="location"
                                    value={state.locationId === null ? '' : state.locationId}
                                    onChange={(ev) =>
                                        onChangeMovingState('locationId')(parseInt(ev.target.value))
                                    }>
                                    <option key={0} value={''}>
                                        {t('On land')}
                                    </option>
                                    {locations
                                        .toSorted((a, b) => a.name.localeCompare(b.name))
                                        .map((location) => (
                                            <option key={location.id} value={location.id}>
                                                {location.name}
                                            </option>
                                        ))}
                                </Input>

                                {Number.isInteger(state.locationId) && (
                                    <>
                                        <Label for="cage">{t('Choose cage')}</Label>
                                        <Input
                                            key={state.locationId}
                                            type="select"
                                            name="cage"
                                            id="cage"
                                            invalid={state.cageId === null}
                                            value={state.cageId === null ? '' : state.cageId}
                                            onChange={(ev) =>
                                                onChangeMovingState('cageId')(
                                                    parseInt(ev.target.value)
                                                )
                                            }>
                                            {state.cageId == null && (
                                                <option key="cageIdNull" value={''}>
                                                    -- {t('Choose cage')} --
                                                </option>
                                            )}
                                            {availableCages.map((cage) => (
                                                <option key={cage.id} value={cage.id}>
                                                    {cage.name}
                                                </option>
                                            ))}
                                        </Input>
                                    </>
                                )}
                                {canModifyClientOrContract && (
                                    <>
                                        <Label for="contract">{t('Choose contract')}</Label>
                                        <Input
                                            key={state.clientId}
                                            type="select"
                                            name="contract"
                                            id="contract"
                                            invalid={state.contractId == null}
                                            value={
                                                state.contractId === null ? '' : state.contractId
                                            }
                                            onChange={(ev) =>
                                                onChangeMovingState('contractId')(
                                                    parseInt(ev.target.value)
                                                )
                                            }>
                                            {state.contractId == null && (
                                                <option key="cageIdNull" value={''}>
                                                    -- {t('Choose contract')} --
                                                </option>
                                            )}
                                            {contracts.map((contract) => {
                                                return (
                                                    <option key={contract.id} value={contract.id}>
                                                        {contract.description}
                                                    </option>
                                                );
                                            })}
                                        </Input>
                                    </>
                                )}
                                <Label>{t('When was it moved?')}</Label>
                                <DateTime
                                    value={state.from === null ? '' : state.from}
                                    onChange={(ev) =>
                                        onChangeMovingState('from')(moment(ev).toDate())
                                    }
                                    dateFormat={'DD.MM.YYYY'}
                                    timeFormat={'[kl.] HH:mm'}
                                />
                            </>
                        ) : (
                            <></>
                        )}
                    </FormGroup>
                </ModalBody>
                <ModalFooter>
                    <Button type="submit">{t('Move')}</Button>
                    <Button onClick={onCloseModal}>{t('Cancel')}</Button>
                </ModalFooter>
            </Form>
        </Modal>
    );
};

export default BioscopeDetailMovingBioscopeModal;
export { BioscopeDetailMovingBioscopeModal };
