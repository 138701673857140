import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Form, FormGroup, Input, Label } from 'reactstrap';

import { ClientInfo } from '@/services/types';
import { Button } from 'components/atoms/Buttons';

import { Box } from '../../Layout';

interface UserInviteFormState {
    role: string;
    clientId: number;
    locationId: number;
    email: string;
}

export interface ExtendedClientInfo extends ClientInfo {
    role?: string;
    authLicenses?: string[];
}

interface UserInviteFormProps {
    canInviteForOptoscaleAdmin: boolean;
    canInviteForClient: boolean;
    client?: ExtendedClientInfo;
    clients: {
        id: number;
        name: string;
        locations: {
            id: number;
            name: string;
        }[];
    }[];
    onSubmit: (state: UserInviteFormState) => Promise<unknown>;
}

export function UserInviteForm({
    client,
    canInviteForOptoscaleAdmin,
    clients,
    canInviteForClient,
    onSubmit,
}: UserInviteFormProps) {
    const getDefaultClientId = () => {
        return client ? client.id : undefined;
    };

    const getDefaultLocationId = () => {
        const clientId = getDefaultClientId();
        if (!clientId) {
            return null;
        }

        const client = clients.find((client) => client.id === clientId);

        return client && client.locations.length > 0 ? client.locations[0].id : null;
    };

    const { t } = useTranslation();

    const [state, setState] = useState<UserInviteFormState & { confirmEmail: string }>({
        confirmEmail: '',
        email: '',
        clientId: getDefaultClientId(),
        locationId: getDefaultLocationId(),
        role: canInviteForOptoscaleAdmin
            ? 'OptoScaleAdmin'
            : canInviteForClient
              ? 'ClientAdmin'
              : 'LocationAdmin',
    });
    const [success, setSuccess] = useState<{ user: string } | null>(null);

    const [disabled, setDisabled] = useState(false);

    const updateState = (partialState: Partial<typeof state>) =>
        setState((s) => ({ ...s, ...partialState }));

    const submit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        try {
            e.preventDefault();
            setDisabled(true);
            setSuccess(null);

            await onSubmit(state);

            setSuccess({ user: state.email });
            updateState({
                email: '',
                confirmEmail: '',
            });
        } catch (e) {
            setError(`${t('Something happened')}: ${JSON.stringify(e)}`);
        } finally {
            setDisabled(false);
        }
    };
    const emailNotSet = state.email === '';
    const confirmEmailNotEqualToEmail = state.email !== state.confirmEmail;
    const clientsNotSet =
        (state.role === 'ClientAdmin' || state.role === 'LocationAdmin') &&
        state.clientId === undefined;
    const invalid = emailNotSet || confirmEmailNotEqualToEmail || clientsNotSet;

    const selectedClient = clients.find((client) => client.id === state.clientId);

    const [error, setError] = useState<string | null>(null);

    return (
        <Box title={t('Invite user')}>
            <Form onSubmit={submit}>
                <FormGroup disabled={disabled}>
                    <Label>{t('Role')}</Label>
                    <Input
                        required
                        type="select"
                        name="role"
                        value={state.role}
                        onChange={(event) => {
                            event.preventDefault();

                            const clientId = getDefaultClientId();

                            let locationId = null;

                            if (clientId) {
                                const client = clients.find((client) => client.id === clientId);

                                if (client) {
                                    locationId =
                                        client.locations.length > 0 ? client.locations[0].id : null;
                                }
                            }

                            updateState({
                                role: event.target.value,
                                clientId,
                                locationId,
                            });
                        }}>
                        {canInviteForOptoscaleAdmin && (
                            <option value="OptoScaleAdmin">OptoScale Admin</option>
                        )}
                        {canInviteForClient && (
                            <option value="ClientAdmin">{t('Client')} Admin</option>
                        )}

                        <option value="LocationAdmin">{t('Location')} Admin</option>
                    </Input>
                </FormGroup>
                {(state.role === 'ClientAdmin' || state.role === 'LocationAdmin') && (
                    <FormGroup disabled={disabled}>
                        <Label>{t('Client')}</Label>
                        <Input
                            required
                            type="select"
                            name="clientId"
                            value={state.clientId}
                            disabled={client ? true : false}
                            onChange={(e) => {
                                const clientId = parseInt(e.target.value);
                                let locationId = null;

                                if (clientId) {
                                    const client = clients.find((client) => client.id === clientId);

                                    if (client) {
                                        locationId =
                                            client.locations.length > 0
                                                ? client.locations[0].id
                                                : null;
                                    }
                                }
                                updateState({
                                    clientId: clientId,
                                    locationId: locationId,
                                });
                            }}>
                            <option></option>
                            {clients.map((client) => (
                                <option key={client.id} value={client.id}>
                                    {client.name}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                )}
                {state.role === 'LocationAdmin' && selectedClient && (
                    <FormGroup disabled={disabled}>
                        <Label>{t('Location')}</Label>
                        <Input
                            required
                            type="select"
                            name="locationId"
                            value={state.locationId}
                            onChange={(e) => {
                                updateState({
                                    locationId: parseInt(e.target.value),
                                });
                            }}>
                            {selectedClient.locations.map((location) => (
                                <option key={location.id} value={location.id}>
                                    {location.name}
                                </option>
                            ))}
                        </Input>
                    </FormGroup>
                )}
                <FormGroup disabled={disabled}>
                    <Label>{t('E-mail')}</Label>
                    <Input
                        required
                        name="email"
                        placeholder={t('E-mail')}
                        type="email"
                        value={state.email}
                        onChange={(event) => {
                            event.preventDefault();
                            updateState({
                                email: event.target.value,
                            });
                        }}
                    />
                </FormGroup>
                <FormGroup disabled={disabled}>
                    <Label>{t('Confirm e-mail')}</Label>
                    <Input
                        required
                        name="confirmEmail"
                        placeholder={t('Confirm e-mail')}
                        value={state.confirmEmail}
                        invalid={confirmEmailNotEqualToEmail}
                        type="email"
                        onChange={(event) => {
                            event.preventDefault();
                            updateState({
                                confirmEmail: event.target.value,
                            });
                        }}
                    />
                </FormGroup>
                {error && <Alert color="danger">{error}</Alert>}
                <Button type="submit" color="primary" disabled={invalid}>
                    {t('Invite user')}
                </Button>
                {success && <Alert>{t('User invited', { user: success.user })}</Alert>}
            </Form>
        </Box>
    );
}

export default UserInviteForm;
