import React from 'react';

import styles from './TabbedNavigation.module.scss';

export interface TabbedContentProps {
    children?: React.ReactNode;
    activeTab?: number;
    contentIndex?: number;
}

const TabbedContent = ({ children, activeTab, contentIndex }: TabbedContentProps) => {
    if (activeTab === contentIndex) {
        return <div className={styles.tabbedcontent}>{children}</div>;
    }
    return null;
};

export default TabbedContent;
export { TabbedContent };
