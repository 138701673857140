import React from 'react';

import styles from './NoComponentDataAvailable.module.scss';

export interface NoComponentDataAvailableProps {
    children?: React.ReactNode;
}

const NoComponentDataAvailable = ({ children }: NoComponentDataAvailableProps) => {
    return (
        <div className={styles.nocomponentdataavailable}>
            <p>Sorry, but we can not find any images to display for this cage.</p>
        </div>
    );
};

export default NoComponentDataAvailable;
export { NoComponentDataAvailable };
