import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import formattedWeightUnit from 'utils/formattedWeightUnit';
import toFixed from 'utils/toFixed';

import { KeyNumber, KeyNumberGroup } from 'components/Layout';

import useWeightDistribution from '../weight-distribution-context';

function WeightDistributionStatus() {
    const { t } = useTranslation();
    const { weightUnitTag, datum } = useWeightDistribution();

    const { weight, weightUnit, cv, skewness, kurtosis } = useMemo(() => {
        const { weight, cv, skewness, kurtosis } = datum ?? {};
        const { value: formattedWeight, weightUnit } = formattedWeightUnit(
            weight,
            weightUnitTag,
            3 // someone wanted 3 decimals here explicitly
        );

        return {
            weightUnit,
            cv,
            skewness,
            kurtosis,
            weight: formattedWeight,
        };
    }, [datum, weightUnitTag]);

    return (
        <KeyNumberGroup numberOfColumns={4}>
            <KeyNumber
                label={t('Living weight')}
                metric={t(`weightUnit.${weightUnit}`)}
                value={weight}
                ifBad="-"
            />
            <KeyNumber label="CV" metric="%" value={toFixed(cv, 1)} ifBad="-" />
            <KeyNumber
                label={t('Skewness')}
                value={toFixed(skewness, 3)}
                ifBad="-"
                tooltipText={t('descriptions.skewness')}
            />
            <KeyNumber
                label="Excess kurtosis"
                value={toFixed(kurtosis, 3)}
                ifBad="-"
                tooltipText={t('descriptions.kurtosis')}
            />
        </KeyNumberGroup>
    );
}

export default WeightDistributionStatus;
