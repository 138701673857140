import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import cn from 'classnames';

import ChangeLanguage from 'components/molecules/ChangeLanguage/ChangeLanguage';

import '../public.scss';

interface ResetPasswordProps {
    resetPassword: (props: { newPassword: string }) => Promise<any>;
    hasToken: boolean;
    onSuccessfulReset: () => void;
}

export const ResetPassword = ({
    resetPassword,
    hasToken,
    onSuccessfulReset,
}: ResetPasswordProps) => {
    const [newPassword, setNewPassword] = React.useState('');
    const [error, setError] = React.useState(null);
    const { t } = useTranslation();
    const onSubmit = async () => {
        try {
            await resetPassword({ newPassword });
            onSuccessfulReset();
        } catch (e: any) {
            console.log(e);
            setError(e?.message?.message ?? 'Something went wrong');
        }
    };
    return (
        <div className={cn('pt-5', 'login')}>
            <Container className="mt-5">
                <Row className="justify-content-md-center flex-column">
                    <div className="languageBox">
                        <ChangeLanguage />
                    </div>
                    <Col md={{ size: 6, offset: 3 }} className="loginBox">
                        {hasToken ? (
                            <>
                                <FormGroup>
                                    <Label className="text-white">{t('New password')}</Label>
                                    <Input
                                        type="password"
                                        name="password"
                                        data-testid="password"
                                        placeholder="password"
                                        className="formControl"
                                        value={newPassword}
                                        onChange={(e) => setNewPassword(e.target.value)}
                                    />
                                </FormGroup>

                                {error ? <Alert color="danger">{error}</Alert> : ''}
                                <div className="mt-2 d-flex">
                                    <Button
                                        onClick={onSubmit}
                                        className="btn-block"
                                        data-testid="submitButton">
                                        {t('Save new password')}
                                    </Button>
                                </div>
                            </>
                        ) : (
                            <Alert color="danger">{t('Invalid token')}</Alert>
                        )}
                    </Col>
                </Row>
            </Container>
        </div>
    );
};
