import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';

const SomethingHappened = (props) => {
    const { t } = useTranslation();
    const color = props?.color ?? 'danger';

    return <Alert color={color}>{t('Something happened')}</Alert>;
};

export default SomethingHappened;
