import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useQueryClient } from '@tanstack/react-query';
import { useLicenseController_deleteClientLicenses } from 'services/hooks';

const DeleteLicenseModal = ({ onClose, selectedLicense }) => {
    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const deleteLicensesClientId = useLicenseController_deleteClientLicenses();

    const onSubmit = (event) => {
        if (selectedLicense)
            deleteLicensesClientId.mutateAsync({ id: selectedLicense.id }).finally(() => {
                queryClient.invalidateQueries();
            });
        onClose();
        event.preventDefault();
    };

    return (
        <Modal isOpen={true} toggle={onClose}>
            <ModalHeader toggle={onClose}>{t('Delete License')}</ModalHeader>
            <ModalBody>
                {t('Are you sure you want to delete License')}:{' '}
                {selectedLicense?.licenses?.name ?? ''} ?
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={onSubmit}>
                    {t('Yes')}
                </Button>{' '}
                <Button color="secondary" onClick={onClose}>
                    {t('No')}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default DeleteLicenseModal;
