import React from 'react';
import { Progress } from 'reactstrap';

import styles from './WoundSizes.module.scss';

export interface WoundSizesProps {
    children?: React.ReactNode;
    woundSize: string;
    woundValue: number;
}

const WoundSizes = ({ woundSize, woundValue }: WoundSizesProps) => {
    /*
    const [progress, setProgress] = useState(0);

    useEffect(() => {
        if (progress >= 100) return;

        const interval = setInterval(() => {
            setProgress((prevProgress) => {
                if (prevProgress >= woundValue) {
                    clearInterval(interval);
                    return woundValue;
                } else {
                    return prevProgress + 1;
                }
            });
        }, 5); // Adjust the interval to change the animation speed

        return () => clearInterval(interval);
    }, [progress, woundValue]);
    */
    return (
        <div className={styles.woundSizes}>
            <div className={styles.progressValue}>
                <span>{Number.isNaN(woundValue) ? '-' : `${woundValue.toFixed(1)}%`}</span>
            </div>
            <div className={styles.progressBarContainer}>
                <p>{woundSize}</p>
                <Progress
                    barClassName={styles.progressBar}
                    value={woundValue}
                    className={`${styles.customProgressBar}`}
                />
            </div>
        </div>
    );
};

export default WoundSizes;
export { WoundSizes };
