import moment from 'moment';

import parseTs from './parseTs';

const DAY_FORMAT = 'YYYY-MM-DD';

// similar to formattedDay, but we do not allow to specify the format
function dayToString(ts, opts) {
    const parsed = parseTs(ts, opts);
    if (parsed === null || parsed === undefined) return parsed;

    return moment(parsed).format(DAY_FORMAT);
}

export default dayToString;
export { dayToString };
