import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import MeContext from 'contexts/meContext';
import {
    useBioscopeDetailController_BioscopeDetailNew,
    useBioscopeRestartController_BioscopeRestarts,
    useBioscopeRestartController_BioscopeRouterRestarts,
    useBioscopeRestartController_RestartBioscope,
    useBioscopeRestartController_RestartRouter,
    useMoveBioscopeController_DeleteLastMove,
} from 'services/hooks';

import { getCurrentBioscopeRouterRestartStep } from '@/components/molecules/BioscopeConnection/BioscopeConnection';
import {
    BioscopeRestartsProps,
    getCurrentBioscopeRestartStep,
} from '@/components/molecules/BioscopeRestarts/BioscopeRestarts';
import { BioscopeMovingModalContainer } from '@/components/organisms/MoveBioscopeModal/MoveBioscopeModal';
import SomethingHappened from '@/components/SomethingHappened';
import Loader from 'components/atoms/Loader';
import { Page, PageContent } from 'components/Layout';
import { Breadcrumb, BreadcrumbItem } from 'components/molecules/Breadcrumbs';
import { BioscopeDetail } from 'components/organisms/BioscopeDetail/BioscopeDetail';

const QUERY_TYPES = {
    BIOSCOPE: 'bioscope',
};

const BioscopeDetailContainer = ({ ...props }) => {
    const { isOptoscaleAdmin } = React.useContext(MeContext);
    const bioscopeId = parseInt(props.match.params.bioscopeId);
    const {
        data: bioscopeData,
        isLoading: isBioscopeLoading,
        refetch: refetchBioscopeDetail,
        error: bioscopeError,
    } = useBioscopeDetailController_BioscopeDetailNew(bioscopeId);
    const { mutateAsync: deleteLastBioscopeMove } = useMoveBioscopeController_DeleteLastMove({
        onSuccess: () => refetchBioscopeDetail(),
    });
    const [isModalOpenMoveBioscope, setIsModalOpenMoveBioscope] = useState(false);

    const {
        data: lastBioscopeRestarts,
        error: restartingBioscopeError,
        isLoading: isBioscopeRestartLoading,
        refetch: refetchBioscopeRestarts,
    } = useBioscopeRestartController_BioscopeRestarts(bioscopeId, {
        refetchInterval: (data) => (data?.data.some((d) => !d.isDone) ? 2000 : false),
    });
    const {
        data: lastRouterRestarts,
        isLoading: isBioscopeRouterRestarts,
        error: restartingRouterError,
        refetch: refetchRouterRestarts,
    } = useBioscopeRestartController_BioscopeRouterRestarts(bioscopeId, {
        refetchInterval: (data) => (data?.data.some((d) => !d.isDone) ? 2000 : false),
    });

    const refetch = () => {
        refetchBioscopeRestarts();
        refetchRouterRestarts();
    };

    const { mutateAsync: restartBioscope, isLoading: restartingBioscopes } =
        useBioscopeRestartController_RestartBioscope({ onSettled: refetch });
    const { mutateAsync: restartBioscopeRouter, isLoading: restartingRouter } =
        useBioscopeRestartController_RestartRouter({ onSettled: refetch });

    const { t } = useTranslation();

    const bioscopeRestarts: BioscopeRestartsProps = {
        stepMessage: getCurrentBioscopeRestartStep,
        lastBioscopeRestarts: lastBioscopeRestarts?.data,
        loading: isBioscopeRestartLoading,
        restartBioscope: () => restartBioscope({ bioscopeId }),
    };

    const bioscopeRouterRestarts: BioscopeRestartsProps = {
        stepMessage: getCurrentBioscopeRouterRestartStep,
        lastBioscopeRestarts: lastRouterRestarts?.data,
        loading: isBioscopeRouterRestarts,
        restartBioscope: () => restartBioscopeRouter({ bioscopeId }),
    };

    if (isBioscopeLoading) return <Loader loading={isBioscopeLoading} />;

    const error = bioscopeError || restartingBioscopeError || restartingRouterError;
    if (error) return <SomethingHappened error={error} />;

    return (
        <Page title={'Bioscope details'}>
            <PageContent
                breadcrumb={
                    isOptoscaleAdmin && (
                        <Breadcrumb>
                            <BreadcrumbItem to="/">{t('Internal')}</BreadcrumbItem>
                            <BreadcrumbItem to="/internal/bioscopes">
                                {t('Bioscopes')}
                            </BreadcrumbItem>
                            <BreadcrumbItem active>{bioscopeId}</BreadcrumbItem>
                        </Breadcrumb>
                    )
                }>
                <div className="container">
                    <BioscopeDetail
                        isBioscopeDetailLoading={isBioscopeLoading}
                        isOptoscaleAdmin={isOptoscaleAdmin}
                        bioscope={bioscopeData?.data}
                        moveBioscope={() => setIsModalOpenMoveBioscope(true)}
                        deleteLastBioscopeMove={() =>
                            deleteLastBioscopeMove({ bioscopeId: bioscopeId })
                        }
                        queryTypes={QUERY_TYPES}
                        bioscopeRestarts={bioscopeRestarts}
                        bioscopeRouterRestarts={bioscopeRouterRestarts}
                    />
                </div>
            </PageContent>
            {bioscopeData && (
                <BioscopeMovingModalContainer
                    isOpen={isModalOpenMoveBioscope}
                    bioscopeId={bioscopeId}
                    onClose={() => setIsModalOpenMoveBioscope(false)}
                    key={bioscopeId}
                />
            )}
        </Page>
    );
};

export default BioscopeDetailContainer;
export { BioscopeDetailContainer };
