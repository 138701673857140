import React from 'react';
import headerContext from 'contexts/headerContext';

const HeaderProvider = ({ children }) => {
    const [headerExpanded, setHeaderExpanded] = React.useState(false);

    return (
        <headerContext.Provider
            value={{
                expanded: headerExpanded,
                set: setHeaderExpanded,
            }}>
            {children}
        </headerContext.Provider>
    );
};

export default HeaderProvider;
