/**
 * You can modify this file
 *
 * @version 6
 *
 */
import Axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
//@ts-ignore
import qs from 'qs';
import api from '../config/api';

export const baseConfig: AxiosRequestConfig = {
    baseURL: api.url, // <--- Add your base url
    headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json-patch+json',
    },

    withCredentials: true,
    paramsSerializer: (param) => qs.stringify(param, { indices: false }),
};

let axiosInstance: AxiosInstance;

function getAxiosInstance(security: Security): AxiosInstance {
    if (!axiosInstance) {
        axiosInstance = Axios.create(baseConfig);

        // Response interceptor
        axiosInstance.interceptors.response.use(
            (async (response: AxiosResponse): Promise<SwaggerResponse<any>> => {
                // Any status code that lie within the range of 2xx cause this function to trigger
                // Do something with response data
                /**
                 * Example on response manipulation
                 *
                 * @example
                 *   const swaggerResponse: SwaggerResponse = {
                 *     ...response,
                 *   };
                 *   return swaggerResponse;
                 */
                return response;
            }) as any,
            (error: AxiosError) => {
                // Any status codes that falls outside the range of 2xx cause this function to trigger
                // Do something with response error

                if (error.response) {
                    return Promise.reject(
                        new RequestError(
                            error.response.data as string,
                            error.response.status,
                            error.response
                        )
                    );
                }

                if (error.isAxiosError) {
                    return Promise.reject(new RequestError('noInternetConnection'));
                }
                return Promise.reject(error);
            }
        );
    }

    // ًًRequest interceptor
    axiosInstance.interceptors.request.use(
        async (requestConfig) => {
            // Do something before request is sent
            /** Example on how to add authorization based on security */
            if (security?.[0]) {
                // requestConfig.headers.authorization = "";
            }

            return requestConfig;
        },
        (error) => {
            // Do something with request error
            return Promise.reject(error);
        }
    );

    return axiosInstance;
}

class RequestError extends Error {
    constructor(
        public message: string,
        public status?: number,
        public response?: AxiosResponse
    ) {
        super(message);
    }

    isApiException = true;

    static isRequestError(error: any): error is RequestError {
        return error.isApiException;
    }
}

export type Security = any[] | undefined;

export interface SwaggerResponse<R> extends AxiosResponse<R> {}

export function placeHolder<T>(data: T): SwaggerResponse<T> {
    return {
        config: null,
        data,
        headers: null,
        status: null,
        statusText: null,
    };
}

export { getAxiosInstance, RequestError };
