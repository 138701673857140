const GUTTING_WASTE = 0.17;

/* Old, should be deleted */
const settings = {
    guttingWaste: GUTTING_WASTE,
    slaughteredFactor: 1 - GUTTING_WASTE,
    weightAverageDayDiffThreshold: 5,
    primaryColor: '#5bb784',
    secondaryColor: '#547fa2',
    orangeColor: '#FF7E0E',
    blueColor: '#00E6F0',
    lightBlueColor: '#66CCEE',
    darkGrayColor: '#4A485F',
    abnormalPitchThreshold: 10,
    abnormalRollThreshold: 10,
    abnormalMovementScoreThreshold: 0.2,
    dayFromHour: 7,
    nightFromHour: 18,
    swimspeed: {
        low_speed_threshold: 0.2,
        medium_speed_threshold: 1.5,
        high_speed_threshold: 2.0,
    },
};

type Colors<T = string> = {
    primary: T;
    secondary: T;
    highlight: T;
    tertiery: T;
    light: T;
    dark: T;
    indigo: T;
};

export type FishTypeColors<T = string> = {
    salmon: T;
    rainbow_trout: T;
    trout: T;
};

type DayPartTagColors<T = string> = {
    all: T;
    day: T;
    night: T;
    short_day: T;
    trend_line: T;
};

type MinMaxAvgColors<T = string> = {
    min: T;
    max: T;
    avg: T;
};

type StatusColors<T = string> = {
    positive: T;
    negative: T;
    neutral: T;
};

type AlertColors<T = string> = {
    danger: T;
    warning: T;
    success: T;
    neutral: T;
};

type HeightLengthColors<T = string> = {
    height: T;
    length: T;
};

type FishHealthColors<T = string> = {
    noWound: T;
    active: T;
    healing: T;
    large: T;
    medium: T;
    small: T;
    singleWound: T;
    multipleWounds: T;
    temperature: T;
};

type MaturationColors<T = string> = {
    no: T;
    started: T;
    full: T;
};

type SceneLabelTagsColors<T = string> = {
    dew: T;
    rope: T;
    net: T;
    ufo: T;
    backlight: T;
    shadow: T;
    badangle: T;
    tooclose: T;
    faraway: T;
    empty: T;
    chaos: T;
    singlefish: T;
    school: T;
    lice: T;
};

interface Settings {
    guttingWaste: number;
    slaughteredFactor: number;
    weightAverageDayDiffThreshold: number;
    colors: Colors;
    daytagColors: DayPartTagColors;
    SceneLabelTagsColors: SceneLabelTagsColors;
    abnormalPitchThreshold: number;
    abnormalRollThreshold: number;
    abnormalMovementScoreThreshold: number;
    dayFromHour: number;
    nightFromHour: number;
    fishHealthColors: FishHealthColors;
    minMaxAvgColors: MinMaxAvgColors;
    heightLengthColors: HeightLengthColors;
    statusColors: StatusColors;
    alertColors: AlertColors;
    maturationColors: MaturationColors;
    comparingPensColors: string[];
    fishTypeName: FishTypeColors;
}

const graphSettings: Settings = {
    guttingWaste: GUTTING_WASTE,
    slaughteredFactor: 1 - GUTTING_WASTE,
    weightAverageDayDiffThreshold: 5,
    SceneLabelTagsColors: {
        dew: '#953502',
        rope: '#b52a2c',
        net: '#c3265b',
        ufo: '#c4298c',
        backlight: '#b335bf',
        shadow: '#9848e2',
        badangle: '#7761f5',
        tooclose: '#5182f7',
        faraway: '#379ee7',
        empty: '#27b7ca',
        chaos: '#26caa7',
        singlefish: '#38d67d',
        school: '#55d960',
        lice: '#3366699',
    },
    fishHealthColors: {
        noWound: '#82CFCA',
        active: '#f6c7c2',
        healing: '#99c0d1',
        large: '#63a29c',
        medium: '#6dcac1',
        small: '#adcbd9',
        singleWound: '#5BB784',
        multipleWounds: '#547FA2',
        temperature: '#EAF0FE',
    },
    maturationColors: {
        no: '#5bb784',
        started: '#f8d0d6',
        full: '#ff7c6a',
    },
    daytagColors: {
        all: '#ff7e0e',
        day: '#5bb784',
        night: '#141920',
        short_day: '#cb4874',
        trend_line: '#547fa2',
    },
    minMaxAvgColors: {
        min: '#547fa2',
        max: '#141920',
        avg: '#5bb784',
    },
    heightLengthColors: {
        height: '#5bb784',
        length: '#547fa2',
    },
    colors: {
        primary: '#5bb784',
        secondary: '#547fa2',
        tertiery: '#00E6F0',
        highlight: '#FF7E0E',
        light: '#66CCEE',
        dark: '#4A485F',
        indigo: '#6776b9',
    },
    statusColors: {
        positive: '#5bb784',
        negative: '#dd0004',
        neutral: '#547fa2',
    },
    alertColors: {
        danger: '#ff1f09',
        warning: '#ff7e0e',
        success: '#5bb784',
        neutral: '#547fa2',
    },
    comparingPensColors: [
        '#5bb784',
        '#547fa2',
        '#ff922b',
        '#00E6F0',
        '#4A485F',
        '#871282',
        '#ffe755',
        '#1E90FF',
    ],
    abnormalPitchThreshold: 10,
    abnormalRollThreshold: 10,
    abnormalMovementScoreThreshold: 0.2,
    dayFromHour: 7,
    nightFromHour: 18,
    fishTypeName: {
        salmon: '#5bb784',
        rainbow_trout: '#274f8c',
        trout: '#274f8c',
    },
};

/*
const LOW_SPEED_THRESHOLD = 0.5;
const MEDIUM_SPEED_THRESHOLD = 1.5;
const HIGH_SPEED_THRESHOLD = 2.0;
*/

function hexToRGBA(hex: string, alpha = 1): string {
    const r = Number.parseInt(hex.slice(1, 3), 16);
    const g = Number.parseInt(hex.slice(3, 5), 16);
    const b = Number.parseInt(hex.slice(5, 7), 16);

    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

export default settings;
export { graphSettings, settings, hexToRGBA };
