import { useClientController_AllLiceWeeklyReport } from 'services/hooks';

import { Loader } from 'components/atoms/Loader';
import SomethingHappened from 'components/SomethingHappened';

import LiceReport from './LiceReport';
interface LiceReportProps {
    clientId: number;
    clientName: string;
}

const LiceReportContainer = ({ clientId, clientName }: LiceReportProps) => {
    const { isLoading, data, error } = useClientController_AllLiceWeeklyReport(clientId);
    if (isLoading) {
        return <Loader />;
    }

    if (error) {
        return <SomethingHappened />;
    }

    return <LiceReport clientId={clientId} clientName={clientName} reports={data.data} />;
};

export default LiceReportContainer;
export type { LiceReportProps };
export { LiceReportContainer };
