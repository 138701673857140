import React from 'react';

import styles from './FullScreenOffIcon.module.scss';

export interface FullScreenOffIconProps {
    color?: string;
    size?: number;
}

const FullScreenOffIcon = ({ color, size = 32 }: FullScreenOffIconProps) => {
    const colorStyle = color ? color : 'currentColor';

    return (
        <div className={styles.fullScreenOffIcon}>
            <svg height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M13.66748,7.48438l1.20758,6.811a.5.5,0,0,1-.57959.57959L7.48462,13.66748a.4999.4999,0,0,1-.26624-.8457l1.32856-1.32862L6.27838,7.93268a.5.5,0,0,1,.06806-.62219l.96386-.96412a.5.5,0,0,1,.62232-.06817L11.493,8.54688l1.32868-1.32874A.49992.49992,0,0,1,13.66748,7.48438Zm4.037,7.39062,6.81085-1.20752a.4999.4999,0,0,0,.26624-.8457l-1.32856-1.32862,2.26856-3.56048a.5.5,0,0,0-.06806-.62219l-.96386-.96412a.5.5,0,0,0-.62232-.06817L20.507,8.54688,19.17828,7.21814a.49992.49992,0,0,0-.84576.26624l-1.20758,6.811A.5.5,0,0,0,17.70453,14.875Zm-3.40906,2.25L7.48462,18.33252a.4999.4999,0,0,0-.26624.8457l1.32856,1.32862L6.27838,24.06732a.5.5,0,0,0,.06806.62219l.96386.96412a.5.5,0,0,0,.62232.06817L11.493,23.45312l1.32868,1.32874a.49992.49992,0,0,0,.84576-.26624l1.20758-6.811A.5.5,0,0,0,14.29547,17.125Zm9.15759,3.38184,1.32856-1.32862a.4999.4999,0,0,0-.26624-.8457L17.70453,17.125a.5.5,0,0,0-.57959.57959l1.20758,6.811a.49992.49992,0,0,0,.84576.26624L20.507,23.45312l3.56042,2.26868a.5.5,0,0,0,.62232-.06817l.96386-.96412a.5.5,0,0,0,.06806-.62219Z"
                    fill={colorStyle}
                />
            </svg>
        </div>
    );
};

export default FullScreenOffIcon;
export { FullScreenOffIcon };
