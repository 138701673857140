import React, { createContext, useContext } from 'react';
import { useCage } from 'contexts/cage-context';
import { useMe } from 'contexts/meContext';
import { useCageController_Status } from 'services/hooks';

import { useRestOverview } from './overview-context';

//  Since movementscore is not being calculated properly, this will remain hidden for clients

// const ABNORMAL_BIOSCOPE_MOVEMENT_THRESHOLD = 0.2;

const COLOR_STATUS = [
    { measStatusLevel: 0, color: 'green' },
    { measStatusLevel: 1, color: 'yellow' },
    { measStatusLevel: 2, color: 'red' },
];

const STATUS_TEXT_DISPLAY = ['highVdisp', 'highCalibrationAge'];

export interface RestFormattedOverviewData {
    weight: string;
    weightGrowth: string;
    kfactor: string;
    kfactorGrowth: string;
    expectedKFactor: string;
    cv: string;
    sgr: string;
    rgi: string;
    statusColor2: string;
    statusText2: string[];
    weightNow: string;
    allGood2: boolean;
    disabled: boolean;
    noBioscopeInCage: boolean;
}

export interface RestFormattedOverview {
    loading: boolean;
    data?: RestFormattedOverviewData;
    error?: any;
}

const RestFormattedOverviewContext = createContext<RestFormattedOverview>({ loading: false });

export function useRestFormattedOverview() {
    return useContext(RestFormattedOverviewContext);
}

export const FormattedOverviewProvider: React.Factory<{ children: any }> = ({ children }) => {
    const cage = useCage();
    const overview = useRestOverview();
    const cageStatus = useCageController_Status(cage.cageId);
    const { isOptoscaleAdmin } = useMe();

    const weightNow = Math.round(overview?.data?.estimated_weight).toString();
    const weight = Math.round(overview?.data?.period?.weight).toString();
    const weightGrowth = overview?.data?.period?.weight_growth?.toFixed(1);
    const kfactor = overview?.data?.period?.cf?.toFixed(2);
    const kfactorGrowth = overview?.data?.period?.cf_growth?.toFixed(3);
    const expectedKFactor = overview?.data?.period?.expected_cf?.toFixed(2);
    const cv = overview?.data?.period?.cv?.toFixed(2); // cv is in %
    const sgr = overview?.data?.period?.sgr?.toFixed(2);
    const rgi = overview?.data?.period?.rgi?.toFixed(2);

    const totalCount = overview?.data?.period?.meas_cnt ?? 0;
    const bioscopes = cageStatus?.data?.data?.historicalBioscopes ?? [];
    const statusColor2 =
        COLOR_STATUS[overview?.data?.meas_status_level]?.color || COLOR_STATUS[0]?.color;
    const statusText2 = isOptoscaleAdmin
        ? overview?.data?.meas_status_list
        : overview?.data?.meas_status_list?.filter(
              (status) => !STATUS_TEXT_DISPLAY.includes(status)
              // eslint-disable-next-line no-mixed-spaces-and-tabs
          );

    const allGood2 = statusColor2 === 'green';

    const disabled = bioscopes.length < 1;
    const noBioscopeInCage = totalCount < 1;

    const context: RestFormattedOverview = {
        loading: cage.loading || overview.loading || cageStatus.isLoading,
        error: cage.error || overview.error || cageStatus.error,
        data: {
            weight,
            weightNow,
            weightGrowth,
            kfactor,
            kfactorGrowth,
            expectedKFactor,
            cv,
            sgr,
            rgi,
            disabled,
            noBioscopeInCage,
            statusColor2,
            statusText2,
            allGood2,
        },
    };

    return (
        <RestFormattedOverviewContext.Provider value={context}>
            {children}
        </RestFormattedOverviewContext.Provider>
    );
};
