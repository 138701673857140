import { localizedDateFormat } from '../../../helpers/date';

import styles from './ImageViewer.module.scss';

export interface ImageMetaInfoProps {
    time_stamp?: string;
    numberOfImages: number;
    index: number;
    hasImages?: boolean;
}

const ImageMetaInfo = ({
    time_stamp,
    numberOfImages,
    index,
    hasImages = true,
}: ImageMetaInfoProps) => {
    const formattedTimeStamp = localizedDateFormat({ dateString: time_stamp, onlydate: false });

    return (
        <div className={styles.imageMetaInfo}>
            <ul>
                <li>
                    <time dateTime={time_stamp}>{formattedTimeStamp}</time>
                </li>
                {!hasImages && (
                    <li>
                        No images? There might be no images to show, but try to refresh the page.
                    </li>
                )}
                <li>{`[ ${index + 1}/${numberOfImages} ]`}</li>
            </ul>
        </div>
    );
};

export default ImageMetaInfo;
export { ImageMetaInfo };
