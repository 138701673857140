import { useTranslation } from 'react-i18next';

export const bioscopeAlertCodes = [
    'noMeasurements1d',
    'noMeasurements2h',
    'badAngle',
    'visibility_rope',
    'visibility_dew',
    'humidity',
    'depthMissing',
    'bigVerticalDisparity',
    'bioscopeMoveFailed',
    //Not fixable by user:
    'visibility_net',
    'calibrationAge',
    'weightDistance',
    'bioscopeMoveTakingTooLong',
    'depthTooShallow',
    'depthTooDeep',
    'ledIntensity',
    'fewMeas',
    'uptime',
];

export const isUserActionRequired = (alertCode) => {
    return bioscopeAlertCodes.indexOf(alertCode) < bioscopeAlertCodes.indexOf('visibility_net');
};

export default (alert) => {
    const { t } = useTranslation();

    if (bioscopeAlertCodes.indexOf(alert.alertCode) !== -1) {
        return {
            title: `Bioscope id ${alert.variables.bioscopeId}`,
            content: t(`alert.${alert.alertCode}`),
        };
    }

    return {
        title: 'unknown',
        content: 'unknown',
    };
};
