import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card, CardBody, Col, Collapse, Row } from 'reactstrap';
import cn from 'classnames';
import moment from 'moment-timezone';

import { Chart } from 'components/Chart';
import { DownloadCsvXlsx } from 'components/DownloadCsvXlsx';

import { getHeadTailStatus, getTitle } from '../../utils';

import options from './options';
import plugins from './plugins';

import styles from '../../FishHealth.module.scss';
const DEFAULT_COLORS = ['#2f837b', '#5ec5bb', '#1e6e96', '#cb4773', '#eac007', '#7ba44c'];
const DEFAULT_NUMBER_OF_COLUMNS = 4;
export default ({
    data,
    help,
    header,
    sidebar,
    info,
    title,
    colors,
    graphLabels,
    graphTrendlines,
    nameToInclude,
}) => {
    colors = colors || DEFAULT_COLORS;
    const { t } = useTranslation();
    function HorizontalInfo({ info = [], maxNumberOfColumns = DEFAULT_NUMBER_OF_COLUMNS }) {
        return info
            .reduce((groups, col, index) => {
                const groupIndex = Math.floor(index / maxNumberOfColumns);
                if (index % maxNumberOfColumns === 0) {
                    groups.push([]);
                }
                groups[groupIndex].push(col);
                return groups;
            }, [])
            .map((row, index) => (
                <Row key={index} className={cn(styles.row, styles.withColumns)}>
                    {row.map(({ text, value, title }, index) => (
                        <Col key={index} sm={12 / maxNumberOfColumns} className={styles.col}>
                            <Info text={text} value={value} title={title} />
                        </Col>
                    ))}
                </Row>
            ));
    }

    function Info({ text, value, title }) {
        return (
            <>
                <div className={styles.title}>{t(text)}</div>
                <div className={styles.value} title={title ? t(title) : ''}>
                    {typeof value === 'function' ? value() : value}
                </div>
            </>
        );
    }

    function getWoundTemperatureData(labels, trendlines, list) {
        const statuses = [];
        const datasets = labels.map((label, index) => {
            const isTemperature = label === 'temperature';
            const dataset = {
                label: t(getTitle(label)),
                backgroundColor: colors[index % colors.length],
                borderColor: colors[index % colors.length],
                pointBackgroundColor: colors[index % colors.length],
                pointRadius: isTemperature ? 0 : 5,
                lineTension: 0,
                yAxisID: isTemperature ? 'y-axis-1' : 'y-axis-0',
                fill: false,
                showLine: isTemperature ? true : false,
                data: [],
            };

            if (trendlines[label]) {
                dataset.trendline = {
                    style: colors[index % colors.length],
                    lineStyle: 'dotted',
                    width: 1,
                };
            }
            statuses.push([]);
            list[index].forEach((row) => {
                const status = getHeadTailStatus(label, row.y);
                if (status?.head?.withCompensatedPerspective) {
                    status.head.postfix = t('with compensated perspective');
                }
                statuses[index].push(status);
                // if a value is more than 100% then assign 100
                if (status.head.percentage > 100) {
                    status.head.percentage = 100;
                }
                dataset.data.push({
                    x: row.x,
                    y: isTemperature ? row?.y?.temperature : status.head.percentage,
                });
            });
            return dataset;
        });
        return { statuses, datasets };
    }
    const [isOpen, setIsOpen] = React.useState(false);
    const woundTemperatureData = getWoundTemperatureData(graphLabels, graphTrendlines, data ?? []);
    const woundData = woundTemperatureData.datasets[0].data;
    const formattedData = [];
    woundData.forEach((item) => {
        const day = moment(item?.x).format('YYYY-MM-DD');
        const percentage = Number.parseFloat(item?.y).toFixed(2);
        formattedData.push({ day, percentage });
    });
    // need to send columns names in this format
    const locationInfoColumns = ['location.name', 'location.timezone', 'cage.name'];
    const dataColumnNames = Object.keys(formattedData[0]);
    const formattedDataColumnNames = dataColumnNames.map((e) => `day.${e}`);
    const allColumnNames = [...locationInfoColumns, ...formattedDataColumnNames];

    const toggle = () => setIsOpen(!isOpen);
    return (
        <>
            <h3 className="pb-3">
                {t(title)}{' '}
                {help && (
                    <span style={{ verticalAlign: true }} onClick={toggle}>
                        <i className="fa fa-info-circle fa-xs" />
                    </span>
                )}
            </h3>
            <Collapse isOpen={isOpen}>
                <Card>
                    <CardBody dangerouslySetInnerHTML={{ __html: t(help) }} />
                </Card>
            </Collapse>{' '}
            <Row>
                <Col xs={10}>
                    <HorizontalInfo info={info} />
                </Col>
                <Col xs={2} className="text-right">
                    <>
                        <DownloadCsvXlsx
                            label="CSV"
                            nameToInclude={nameToInclude}
                            data={formattedData}
                            COLUMNS={allColumnNames}
                        />
                        <DownloadCsvXlsx
                            label="XLSX"
                            nameToInclude={nameToInclude}
                            data={formattedData}
                            COLUMNS={allColumnNames}
                        />
                    </>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Chart
                        type="line"
                        data={getWoundTemperatureData(graphLabels, graphTrendlines, data ?? [])}
                        options={options(graphLabels)}
                        plugins={plugins}
                        header={header}
                        sidebar={sidebar}
                    />
                </Col>
            </Row>
        </>
    );
};
