import moment from 'moment';
import {
    useBioscopeDetailController_BioscopeDetailNew,
    useClientController_GetAllClientsWithLocations,
    useMoveBioscopeController_MoveBioscope,
} from 'services/hooks';

import BioscopeDetailMovingBioscopeModal from '@/components/molecules/BioscopeDetailMovingBioscopeModal/BioscopeDetailMovingBioscopeModal';
import { useMe } from '@/contexts/meContext';
import { MoveBioscopeQueryParams } from '@/services/types';

export interface BioscopeMovingModalContainerProps {
    bioscopeId: number;
    isOpen: boolean;
    onClose: () => void;
}

export const BioscopeMovingModalContainer = ({
    bioscopeId,
    isOpen,
    onClose,
}: BioscopeMovingModalContainerProps) => {
    const { isOptoscaleAdmin } = useMe();
    const { data: bioscopeData, refetch: refetchBioscopeDetail } =
        useBioscopeDetailController_BioscopeDetailNew(bioscopeId, {
            enabled: bioscopeId !== undefined,
        });
    const updateBioscopeLocation = useMoveBioscopeController_MoveBioscope();
    const { data: clientWithLocations } = useClientController_GetAllClientsWithLocations();

    const onSubmit = async (data: MoveBioscopeQueryParams) => {
        await updateBioscopeLocation.mutateAsync({ bioscopeId, requestBody: data });
        refetchBioscopeDetail();
    };

    return (
        bioscopeData &&
        clientWithLocations &&
        isOpen && (
            <BioscopeDetailMovingBioscopeModal
                isModalOpenMoveBioscope={isOpen}
                clients={clientWithLocations.data}
                initalState={{
                    bioscopeId: bioscopeId,
                    clientId: bioscopeData.data.clientId,
                    contractId: bioscopeData.data.contractId,
                    locationId: bioscopeData.data.lastLocation?.cage?.location?.id,
                    cageId: bioscopeData.data.lastLocation?.cage?.id,
                    from: moment().startOf('hour').toDate(),
                }}
                onCloseModal={onClose}
                onSubmit={onSubmit}
                canModifyClientOrContract={isOptoscaleAdmin}
            />
        )
    );
};
