import './opto-check';
import './opto-dash';

import styles from './checkbox.css?inline';

export const isAttributeValueTruthy = (value) => [null, 'false'].includes(value) === false;

const template = document.createElement('template');
template.innerHTML = `
	<style>
        ${styles}
    </style>
	<div class="opto-checkbox-container">
		<span part="icon" class="customcheck">
			<opto-check></opto-check>
			<opto-dash></opto-dash>
		</span>	
		<input type="checkbox" id="wcb" name="">
	</div>
	<label part="label" for="wcb"></label>
`;

const observedAttributes = ['disabled', 'checked', 'value', 'name', 'showlabel', 'label'];

/**
 * @element opto-checkbox
 *
 * Attributes:
 * @attr disabled {Boolean} Prevents user actions on the checkbox
 * @attr value {String} Value submitted with a form if checked
 * @attr checked {Boolean}
 * @attr name {String} The name of the checkbox as submitted with a form
 * @attr label {String} The label for the checkbox
 * @attr showlabel {Boolean} Show the label for the checkbox
 *
 * @fires change - Fired when the checked state of the checkbox changes
 * @fires click - Fired when the checkbox is clicked
 * @fires input - Fired when the checked state of the checkbox changes
 */
class optoCheckbox extends HTMLElement {
    checkbox: HTMLInputElement;
    labelEl: HTMLLabelElement;
    container: HTMLDivElement;

    constructor() {
        super();
        this.attachShadow({ mode: 'open' });
        this.shadowRoot.appendChild(template.content.cloneNode(true));

        this.checkbox = this.shadowRoot.querySelector('#wcb');
        this.labelEl = this.shadowRoot.querySelector('label');
        this.container = this.shadowRoot.querySelector('.opto-checkbox-container');
    }
    connectedCallback() {
        this.checkbox.addEventListener('click', this.checkedHandler);
    }

    disconnectedCallback() {
        this.checkbox.removeEventListener('click', this.checkedHandler);
    }

    set disabled(bool) {
        this.setAttribute('disabled', bool.toString());
    }
    get disabled() {
        return this.hasAttribute('disabled') && this.getAttribute('disabled') !== 'false';
    }

    static observedAttributes = observedAttributes;

    attributeChangedCallback(name, oldValue, newValue) {
        if (!this.checkbox || !this.labelEl || !this.container) {
            this.checkbox = this.shadowRoot.querySelector('#wcb');
            this.labelEl = this.shadowRoot.querySelector('label');
            this.container = this.shadowRoot.querySelector('.opto-checkbox-container');
        }

        if (name in this._syncAttribute) this._syncAttribute[name](newValue);
    }

    _syncAttribute = {
        disabled: (value) => {
            if (isAttributeValueTruthy(value)) {
                this.checkbox.setAttribute('disabled', '');
                this.container.classList.add('disabled');
                this.labelEl.classList.add('disabled');
            } else {
                this.checkbox.removeAttribute('disabled');
                this.container.classList.remove('disabled');
                this.labelEl.classList.remove('disabled');
            }
        },
        showlabel: (value) => {
            if (isAttributeValueTruthy(value)) {
                this.style.setProperty('--label-display', 'inline-block');
            } else {
                this.style.setProperty('--label-display', 'none');
            }
        },
        label: (value) => {
            this.labelEl.innerText = value;
            this.checkbox.setAttribute('data-label', value);
        },
        name: (value) => {
            this.checkbox.setAttribute('name', value);
        },
        value: (value) => {
            this.checkbox.setAttribute('value', value);
        },
        checked: (value) => {
            if (isAttributeValueTruthy(value)) {
                this.checkbox.setAttribute('checked', '');
                this.container.classList.add('checked');
            } else {
                this.checkbox.removeAttribute('checked');
                this.container.classList.remove('checked');
            }
        },
    };

    checkedHandler = (e) => {
        e.target.checked
            ? this.container.classList.add('checked')
            : this.container.classList.remove('checked');

        e.target.indeterminate = false;

        this.dispatchEvent(
            new CustomEvent('change', {
                detail: {
                    checked: e.target.checked,
                    name: e.target?.name,
                    value: e.target?.value,
                    id: this.getAttribute('id') || '',
                },
                bubbles: true,
                composed: true,
            })
        );
    };
}

const optoCheckboxName = 'opto-checkbox';

if (!customElements.get(optoCheckboxName)) {
    customElements.define(optoCheckboxName, optoCheckbox);
}

export { optoCheckbox };
