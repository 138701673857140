import { getI18n } from 'react-i18next';
import { TRANSLATE_THIS_LABEL } from 'utils/formattedDay';
import toFixed from 'utils/toFixed';
export default (stacked) => {
    const i18n = getI18n();
    return {
        elements: {
            line: {
                tension: 0.2,
            },
            point: {
                radius: 1,
            },
        },
        tooltips: {
            mode: 'index',
            axis: 'x',
            intersect: false,
            callbacks: {
                title: (tooltipItem) => {
                    return i18n.language === 'nb'
                        ? TRANSLATE_THIS_LABEL(tooltipItem[0].xLabel)
                        : tooltipItem[0].xLabel;
                },
                label: (tooltipItem, data) => {
                    const label = data.datasets[tooltipItem.datasetIndex].label || '';
                    const value = toFixed(tooltipItem.yLabel, 1);
                    return `${label}: ${value}%`;
                },
                beforeBody: (ttItem, data) => {
                    const status = data.statuses[0][ttItem[0].index];
                    const totalCount = status.head.totalSpecificDay.toLocaleString('fr');
                    return `    ${i18n.t('Total')}: ${ttItem
                        .reduce((acc, val) => acc + val.yLabel, 0)
                        .toFixed(1)}%  ${i18n.t('of')} ${totalCount} ${i18n.t('fish')}`;
                },
            },
            itemSort: (a, b) => a.datasetIndex - b.datasetIndex,
            filter: () => {
                return true; // return false on yLabel == 0 to disable 0 values (tooltipItem.yLabel !== 0;)
            },
        },
        hover: {
            mode: 'index',
            axis: 'x',
            intersect: false,
        },
        scales: {
            xAxes: [
                {
                    type: 'time',
                    time: {
                        tooltipFormat: 'MMMM D, YYYY',
                    },

                    stacked: stacked,
                    offset: true,
                    ticks: {
                        callback: (label) =>
                            i18n.language === 'nb' ? TRANSLATE_THIS_LABEL(label) : label,
                    },
                },
            ],
            yAxes: [
                {
                    ticks: {
                        callback: (value) => `${value}%`,
                        suggestedMax: 15,
                        min: 0,
                    },
                    stacked: stacked,
                },
            ],
        },
    };
};
