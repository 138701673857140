import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'reactstrap';
import { Table } from 'reactstrap';
import {
    useLicenseController_getClientLicenses,
    useLicenseController_getLicense,
} from 'services/hooks';

import { Button as CustomButton } from 'components/atoms/Buttons';
import { Loader } from 'components/atoms/Loader';

import { Box } from '../../../../../components/Layout';

import AddLicenseModal from './AddLicenseModal';
import DeleteLicenseModal from './DeleteLicenseModal';
import UpdateLicenseModal from './UpdateLicenseModal';

/**
 * Add License Id to show more license on Client Page
 */
const LICENSES = [
    {
        id: 2,
        name: 'Fish health minimal',
    },
    {
        id: 7,
        name: 'Lice Count data minimal',
    },
    {
        id: 8,
        name: 'Lice Count data maximum',
    },
    {
        id: 9,
        name: 'Fish Health Inspection',
    },
];

const ClientLicense = ({ clientId }) => {
    const { t } = useTranslation();
    const { data: clientLicnese, isLoading } = useLicenseController_getClientLicenses(clientId);
    const { data: licenseList } = useLicenseController_getLicense();

    const [modal, setModal] = useState(null);

    /**
     * Filtering out the remaining valid Licenses
     */
    const filterLicenseList = licenseList?.data.filter((license) =>
        LICENSES.some((L) => L.id === license.id)
    );

    return (
        <Box title={t('License Detail')}>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <Table>
                        <thead>
                            <tr>
                                <th>{t('License Name')}</th>
                                <th>{t('Remaining')}</th>
                                <th>{t('Actions')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {clientLicnese?.data?.map((license, index) => {
                                return (
                                    <tr key={index}>
                                        <td>{license.licenses.name}</td>
                                        <td>
                                            {license?.left}/{license?.numLicenses}
                                        </td>
                                        <td width="260">
                                            <Button
                                                className={'mr-1'}
                                                onClick={(e) => {
                                                    setModal(
                                                        <UpdateLicenseModal
                                                            onClose={() => setModal(null)}
                                                            selectedLicense={license}
                                                        />
                                                    );
                                                }}>
                                                {t('Edit')}
                                            </Button>
                                            <Button
                                                className={'mr-1'}
                                                color="danger"
                                                onClick={(e) => {
                                                    setModal(
                                                        <DeleteLicenseModal
                                                            onClose={() => setModal(null)}
                                                            selectedLicense={license}
                                                        />
                                                    );
                                                }}>
                                                {t('Delete')}
                                            </Button>
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </Table>
                    <CustomButton
                        className={'mr-3 mb-3'}
                        onClick={(e) => {
                            setModal(
                                <AddLicenseModal
                                    clientId={clientId}
                                    /**
                                     * @missingLicenses Licenses which Client dont have
                                     **/
                                    missingLicenses={filterLicenseList
                                        .filter(
                                            (l) =>
                                                !clientLicnese?.data.some(
                                                    (cl) => cl.licenseId === l.id
                                                )
                                        )
                                        .map((license) => license)}
                                    onClose={() => setModal(null)}
                                />
                            );
                        }}>
                        {t('Add License')}
                    </CustomButton>
                    {modal ?? <></>}
                </>
            )}
        </Box>
    );
};

export default ClientLicense;
