import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'reactstrap';

import styles from './styles.module.scss';
const MOUSE_UP = 'mouseup';

function useOutsideClick(handleClose) {
    const handleClick = useCallback(() => {
        handleClose();
    }, [handleClose]);

    useEffect(() => {
        document.addEventListener(MOUSE_UP, handleClick);
        return () => {
            document.removeEventListener(MOUSE_UP, handleClick);
        };
    }, [handleClick]);
}

const Fab = ({ onClick }) => {
    const { t } = useTranslation();

    return (
        <nav className={styles['fab-container']}>
            <button
                type="button"
                className={styles['fab-item']}
                title={t('HOW MUCH IS THE FISH')}
                onClick={onClick}>
                <span role="img" aria-label="Fish" className="far">
                    🐟
                </span>
            </button>
        </nav>
    );
};

const HowMuchIsTheFish = () => {
    const [isOpen, setOpen] = useState(false);
    useOutsideClick(() => setOpen(false));
    return (
        <div>
            <Fab onClick={() => setOpen(true)} />
            <Modal isOpen={isOpen}>
                <iframe
                    title="howmuchisthefish"
                    id="ytplayer"
                    width="640"
                    height="360"
                    src="https://www.youtube.com/embed/cbB3iGRHtqA?autoplay=1&fs=1&mute=1"
                />
            </Modal>
        </div>
    );
};

export default HowMuchIsTheFish;
export { HowMuchIsTheFish };
