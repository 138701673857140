export default {
    id: 'trendline',
    beforeDraw: (chart) => {
        var xScale = chart.scales['x-axis-0'];
        var yScale = chart.scales['y-axis-0'];
        var ctx = chart.ctx;

        chart.data.datasets.forEach((dataset, index) => {
            if (dataset.trendline) {
                var datasetMeta = chart.getDatasetMeta(index);
                addFitter(datasetMeta, ctx, dataset, xScale, yScale);
            }
        });

        ctx.setLineDash([]);
    },
};

function addFitter(datasetMeta, ctx, dataset, xScale, yScale) {
    var style = dataset.trendline.style || dataset.borderColor;
    var lineWidth = dataset.trendline.width || dataset.borderWidth;
    var lineStyle = dataset.trendline.lineStyle || 'solid';

    style = style !== undefined ? style : 'rgba(169,169,169, .6)';
    lineWidth = lineWidth !== undefined ? lineWidth : 3;

    if (!dataset || !dataset.data || dataset.data.length < 1) {
        return;
    }

    var lastIndex = dataset.data.length - 1;
    var startPos = datasetMeta.data[0]._model !== undefined ? datasetMeta.data[0]._model.x : NaN;
    var endPos =
        datasetMeta.data[lastIndex]._model !== undefined
            ? datasetMeta.data[lastIndex]._model.x
            : NaN;

    var fitter = new LineFitter();

    if (xScale.options.type === 'time') {
        dataset.data.forEach((data, index) => {
            fitter.add(index, parseFloat(data.y));
        });
    } else {
        dataset.data.forEach((data, index) => {
            fitter.add(index, parseFloat(data));
        });
    }

    ctx.lineWidth = lineWidth;
    if (lineStyle === 'dotted') {
        ctx.setLineDash([2, 3]);
    }
    ctx.beginPath();
    ctx.moveTo(startPos, yScale.getPixelForValue(fitter.project(0)));
    ctx.lineTo(endPos, yScale.getPixelForValue(fitter.project(lastIndex)));
    ctx.strokeStyle = style;
    ctx.stroke();
}

function LineFitter() {
    this.count = 0;
    this.sumX = 0;
    this.sumX2 = 0;
    this.sumXY = 0;
    this.sumY = 0;
}

LineFitter.prototype = {
    add: function (x, y) {
        this.count++;
        this.sumX += x;
        this.sumX2 += x * x;
        this.sumXY += x * y;
        this.sumY += y;
    },
    project: function (x) {
        var det = this.count * this.sumX2 - this.sumX * this.sumX;
        var offset = (this.sumX2 * this.sumY - this.sumX * this.sumXY) / det;
        var scale = (this.count * this.sumXY - this.sumX * this.sumY) / det;
        return offset + x * scale;
    },
};
