import React from 'react';
import VisibilitySensor from 'react-visibility-sensor';
import { Card, CardBody, Col, Collapse, Row, Tooltip } from 'reactstrap';
import cn from 'classnames';

import FullScreenCard from 'components/Layout/Cards/FullScreenCard/FullScreenCard';
import { CircularProgressBar } from 'components/molecules/CircularProgressBar';

import useMyTranslation from '../../../../../../../hooks/useMyTranslation';
import multipleWounds from '../../../../../../../svg/multipleWounds.svg';
import noWound from '../../../../../../../svg/noWound.svg';
import singleWound from '../../../../../../../svg/singleWound.svg';

import Graph from './Graph';

import styles from '../../FishHealthColor.module.scss';
const DEFAULT_NUMBER_OF_COLUMNS = 4;

const WoundsValueToolTip = ({ toolTipId, helpText }) => {
    const [tooltipOpen, setTooltipOpen] = React.useState(false);
    return (
        <>
            <span className={styles.valueToolTip} id={toolTipId}>
                <i className="fa fa-info-circle fa-xs" />
            </span>
            <Tooltip
                isOpen={tooltipOpen}
                placement="top"
                target={toolTipId}
                toggle={() => {
                    setTooltipOpen(!tooltipOpen);
                }}>
                {helpText}
            </Tooltip>
        </>
    );
};

function Info({ text, value, description, title, onlyValue, barometerStyle, showDiff }) {
    const toolTipId = text.replace(/\./g, '-') ?? 'any';
    const { t } = useMyTranslation();
    const baroMeterValue = typeof value === 'function' ? value() : value;
    const onlyPercentageValue = baroMeterValue?.props?.children[0].replace(/%/g, '') ?? ''; // showing only the value and removing % from the string
    const WoundNumberIndicators = ({ colour, fishIcon }) => {
        const [isVisible, setIsVisible] = React.useState(false);
        const onChange = (isVisible) => {
            setIsVisible(isVisible);
        };
        return (
            <div className={`animatedComponent ${isVisible ? 'fadeUnfold' : ''}`}>
                <VisibilitySensor onChange={onChange} partialVisibility>
                    <div className={styles.barometerStyle}>
                        <div className={styles.svgWrapper}>
                            <CircularProgressBar percentage={onlyValue} color={colour} size={150} />
                        </div>
                        <img src={fishIcon} alt="" />
                        <div className={styles.title}>{t(text)}</div>
                    </div>
                </VisibilitySensor>
            </div>
        );
    };

    const renderElement = () => {
        if (text === 'No wound') {
            return <WoundNumberIndicators colour={'#82CFCA'} fishIcon={noWound} />;
        }
        if (text === 'fishHealthIndicators.oneWound') {
            return <WoundNumberIndicators colour={'#5bb784'} fishIcon={singleWound} />;
        }
        if (text === 'fishHealthIndicators.moreThanOneWound') {
            return <WoundNumberIndicators colour={'#547FA2'} fishIcon={multipleWounds} />;
        }
    };
    if (barometerStyle) {
        return <>{renderElement()}</>;
    }
    return (
        <>
            <div className={styles.title}>{t(text)}</div>
            <div className={!showDiff ? styles.valueWithTooltip : styles.value}>
                <span title={title ? t(title) : ''}>
                    {!showDiff
                        ? `${onlyPercentageValue}%`
                        : typeof value === 'function'
                          ? value()
                          : value}
                </span>
                {!showDiff ? (
                    <WoundsValueToolTip toolTipId={toolTipId} helpText={t(description)} />
                ) : (
                    ''
                )}
            </div>
        </>
    );
}

function HorizontalInfo({
    info = [],
    barometerStyle,
    description = [],
    showTotalCounts,
    showDiff,
    maxNumberOfColumns = DEFAULT_NUMBER_OF_COLUMNS,
}) {
    if (!showTotalCounts) {
        maxNumberOfColumns = 3;
    }
    return info
        .reduce((groups, col, index) => {
            const groupIndex = Math.floor(index / maxNumberOfColumns);

            if (index % maxNumberOfColumns === 0) {
                groups.push([]);
            }

            groups[groupIndex].push(col);

            return groups;
        }, [])
        .map((row, index) => (
            <Row key={index} className={cn(styles.row, styles.withColumns)}>
                {row.map(({ text, value, title, description, onlyValue }, index) => (
                    <Col
                        key={index}
                        sm={12 / maxNumberOfColumns}
                        className={`${styles.col} text-center`}>
                        <Info
                            text={text}
                            value={value}
                            title={title}
                            description={description}
                            onlyValue={onlyValue}
                            barometerStyle={barometerStyle ?? false}
                            showDiff={showDiff ?? true}
                        />
                    </Col>
                ))}
            </Row>
        ));
}
function OneGraph({
    title = '',
    info = undefined,
    description = [],
    help = undefined,
    type,
    barometerStyle = false,
    showDiff = true,
    showTotalCounts = true,
    showHorizontalInfo = true,
    yAxisDefaultMax = 16,
    ...other
}) {
    const { t } = useMyTranslation();
    const [isOpen, setIsOpen] = React.useState(false);
    const toggle = () => setIsOpen(!isOpen);
    return (
        <>
            <h3 className="pb-3">
                {t(title)}{' '}
                {help && (
                    <span style={{ verticalAlign: 'middle' }} onClick={toggle}>
                        <i className="fa fa-info-circle fa-xs" />
                    </span>
                )}
            </h3>
            <Collapse isOpen={isOpen}>
                <Card>
                    <CardBody dangerouslySetInnerHTML={{ __html: t(help) }} />
                </Card>
            </Collapse>{' '}
            {showHorizontalInfo && (
                <Row>
                    <Col>
                        <HorizontalInfo
                            info={info}
                            barometerStyle={barometerStyle ?? false}
                            description={description}
                            showTotalCounts={showTotalCounts ?? true}
                            showDiff={showDiff ?? true}
                        />
                    </Col>
                </Row>
            )}
            <Row>
                <Col xs={12}>
                    <div className="pt-3">
                        <FullScreenCard>
                            <Graph
                                labels={undefined}
                                trendlines={undefined}
                                list={undefined}
                                colors={undefined}
                                title={title}
                                type={type}
                                barometerStyle={barometerStyle ?? false}
                                yAxisDefaultMax={yAxisDefaultMax}
                                {...other}
                            />
                        </FullScreenCard>
                    </div>
                </Col>
            </Row>
        </>
    );
}

export { OneGraph };
