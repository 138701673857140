import React, { createContext, useMemo } from 'react';
import { useQueryParams } from 'hooks/useQueryParams';
import moment from 'moment';
import queryString from 'query-string';
import isObject from 'utils/isObject';

const DEFAULT_FROM = undefined;
const DEFAULT_TO = undefined;
const DEFAULT_DAYPART_TAG = 'day';
const DEFAULT_WEIGHT_UNIT_TAG = 'g';
const DATE_FORMAT = 'YYYY-MM-DD';

export interface CageFilter {
    setFilter?: (any) => void;
    daypartTag?: string;
    weightUnitTag?: string;
    from?: string;
    to?: string;
    normalizedFrom?: string;
    normalizedTo?: string;
}
const CageFilterContext = createContext<CageFilter>({});

function useCageFilter() {
    return React.useContext(CageFilterContext);
}

/*
 * TODO: daypartTag and weightUnitTag filtering is not
 *       fully implemented
 *
 * 1. daypartTags and weightUnitTags is not part of filter.
 *    each component must load it from somewhere else, but
 *    make sure values are correct.
 * 2. UI must be added to allow user to select daypartTag and
 *    weightUnitTag
 * 3. default daypartTag and weightUnitTag may be preconfigured
 *    and in DB (configurable by user) and loaded higher up
 *    as daypartTags and weightUnitTags (currently constants)
 */

function CageFilterProvider(props) {
    // we store values in url query and update url query
    const [queryParams, setQueryParams] = useQueryParams();
    const context = useMemo(() => {
        const [from, to] = [
            queryParams?.from
                ? moment(queryParams.from).startOf('day')
                : props?.from
                  ? moment(props.from).startOf('day')
                  : DEFAULT_FROM,
            queryParams?.to
                ? moment(queryParams.to).startOf('day')
                : props?.to
                  ? moment(props.to).startOf('day')
                  : DEFAULT_TO,
        ];

        // daypartTag url param is 'd'
        const daypartTag = queryParams?.d
            ? queryParams.d
            : props?.daypartTag
              ? props.daypartTag
              : DEFAULT_DAYPART_TAG;

        // weightUnitTag url param is 'w'
        const weightUnitTag = queryParams?.w
            ? queryParams.w
            : props?.weightUnitTag
              ? props.weightUnitTag
              : DEFAULT_WEIGHT_UNIT_TAG;

        const filterReducers = {
            from: (filter, value) => ({
                ...filter,
                from:
                    value === null || value === undefined
                        ? null
                        : moment(value).format(DATE_FORMAT),
            }),
            to: (filter, value) => ({
                ...filter,
                to:
                    value === null || value === undefined
                        ? null
                        : moment(value).format(DATE_FORMAT),
            }),
            daypartTag: (filter, value) => ({
                ...filter,
                // daypartTag url param is 'd'
                d: value === DEFAULT_DAYPART_TAG ? null : value,
            }),
            weightUnitTag: (filter, value) => ({
                ...filter,
                // weightUnitTag url param is 'w'
                w: value === DEFAULT_WEIGHT_UNIT_TAG ? null : value,
            }),
        };

        const setFilter = (filter) => {
            if (filter === null || filter === undefined) {
                setQueryParams(null);
                return;
            }

            // lets support passing filter as string of values, like in url
            if (!isObject(filter)) filter = queryString.parse(String(filter));
            const newParams = Object.keys(filter)
                // eslint-disable-next-line no-prototype-builtins
                .filter((key) => filterReducers[key])
                .reduce((acc, key) => {
                    const value = filter[key];
                    const reducer = filterReducers[key];
                    return reducer(acc, value);
                }, {});

            setQueryParams(newParams);
        };
        return {
            setFilter,
            daypartTag,
            weightUnitTag,
            from,
            to,
            normalizedFrom: from,
            // add extra day, because UI has [], but we want to use [) for query
            // from >= timestamp, to < timestamp

            // 1. from = '01 00:00', to = '02 00:00' then from inclusve and to is not
            // 2. from = '02 00:00', to = '03 00:00' then from inclusve and to is not
            // 3. from = '03 00:00', to = '04 00:00' then from inclusve and to is not
            normalizedTo: to ? moment(to) : to,
        };
    }, [queryParams, props, setQueryParams]);

    return (
        <CageFilterContext.Provider value={context}>{props.children}</CageFilterContext.Provider>
    );
}

export default useCageFilter;
export {
    useCageFilter,
    CageFilterProvider,
    DEFAULT_FROM,
    DEFAULT_TO,
    DEFAULT_DAYPART_TAG,
    DEFAULT_WEIGHT_UNIT_TAG,
};
