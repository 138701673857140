import { useCage } from 'contexts/cage-context';
import { useCageFilter } from 'contexts/cage-filter-context';
import { useCageController_Overview } from 'services/hooks';
import dayToString from 'utils/dayToString';

export function useRestOverviewQuery() {
    const { cageId, loading: cageLoading, error: cageError } = useCage();
    const { normalizedFrom, normalizedTo, daypartTag, weightUnitTag } = useCageFilter();

    const dayFrom = normalizedFrom ? dayToString(normalizedFrom) : undefined;
    const dayTo = normalizedTo ? dayToString(normalizedTo) : undefined;

    const {
        isLoading: restOverviewLoading,
        error: restOverviewError,
        data: restOverviewData,
    } = useCageController_Overview(
        cageId,
        { daypartTag, weightUnitTag, from: dayFrom, to: dayTo },
        { staleTime: 1000 * 60 * 5 }
    );

    return {
        loading: cageLoading || restOverviewLoading,
        error: cageError || restOverviewError,
        data: restOverviewData?.data,
    };
}
