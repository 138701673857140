import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Slider from 'react-rangeslider';

// To include the default styles
import { localizedDateFormat } from '@/helpers/date';

import 'react-rangeslider/lib/index.css';
import styles from './timelineslider.module.scss';

export interface TimeLineSliderProps {
    dateList: { day: string; count: number }[];
    onAfterChangeHandler: (value: string) => void;
    showCurrentTime?: boolean;
    value?: number;
}

const TimeLineSlider = ({
    dateList = [],
    onAfterChangeHandler,
    showCurrentTime = false,
    value = 0,
}: TimeLineSliderProps) => {
    const [state, setState] = useState(value);
    const { t } = useTranslation();

    if (!dateList?.length) {
        return null;
    }

    if (state >= dateList.length || state < 0) {
        setState(dateList.length - 1);
    }

    const onSliderChange = (index: number) => {
        setState(index);
    };

    const formatTooltip = (value) => {
        const dateString = localizedDateFormat({
            dateString: dateList[value]?.day,
            onlydate: true,
        });
        return `${dateList[value].count} ${t('taken on')} ${dateString}`;
    };

    const labels: { [key: string]: string } = dateList.reduce(
        (acc, item, index: number) => {
            const currentIndex: string = index.toString();
            acc[currentIndex] = '';
            return acc;
        },
        {} as { [key: string]: string }
    );

    return (
        <div
            className={styles.timelineWrapper}
            data-from={dateList[0]}
            data-to={dateList[dateList.length - 1]}>
            {showCurrentTime && (
                <time className="current" dateTime={dateList[state]?.day}>
                    {localizedDateFormat({ dateString: dateList[state]?.day, onlydate: true })}
                </time>
            )}
            <div className={styles.sliderWrapper}>
                <time className="from" dateTime={dateList[0].day}>
                    {localizedDateFormat({ dateString: dateList[0]?.day, onlydate: true })}
                </time>
                <Slider
                    className={styles.timelineslider}
                    min={0}
                    max={dateList.length - 1}
                    value={state}
                    step={1}
                    tooltip={true}
                    labels={labels}
                    format={formatTooltip}
                    onChange={onSliderChange}
                    onChangeComplete={() => {
                        onAfterChangeHandler(dateList[state]?.day);
                    }}
                />
                <time className="to" dateTime={dateList[dateList.length - 1]?.day}>
                    {localizedDateFormat({
                        dateString: dateList[dateList.length - 1]?.day,
                        onlydate: true,
                    })}
                </time>
            </div>
        </div>
    );
};

export default TimeLineSlider;
export { TimeLineSlider };
