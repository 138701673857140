import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import styles from './box.module.scss';

export interface BoxProps {
    title?: string;
    bottomText?: string;
    error?: boolean;
    loading?: boolean;
    customStyle?: React.CSSProperties;
    customStyleOuter?: React.CSSProperties;
    className?: string;
    children?: React.ReactNode;
}

const Box = ({
    children,
    title = '',
    bottomText = '',
    error = undefined,
    loading = undefined,
    customStyle = undefined,
    customStyleOuter = undefined,
    ...other
}: BoxProps) => {
    const { t } = useTranslation();
    return (
        <section className={styles.box} style={customStyleOuter} {...other}>
            {title && title.length > 0 && (
                <>
                    <h2>
                        {title}
                        {error && (
                            <i className="fa fa-exclamation-triangle small text-danger ml-2" />
                        )}
                        {!!loading && <i className="fa fa-spinner fa-spin fa-fw small ml-2" />}
                    </h2>
                </>
            )}
            <div className={cn(other.className, styles.boxContent)} style={customStyle}>
                {children}
            </div>
            {bottomText && (
                <div className={styles.bottomText}>
                    {' '}
                    {`${t('Last measurement day')}:`} {bottomText}
                </div>
            )}
        </section>
    );
};

export default Box;
