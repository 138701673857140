import React from 'react';

import { FormattedOverviewProvider } from '../Overview2/formatted-overview-context';
import { GraphDataProvider } from '../Overview2/graph-data-context';
import { OverviewProvider } from '../Overview2/overview-context';

import { FishSpeed } from './fishSpeedContainer';

function FishSpeedPage() {
    return (
        <OverviewProvider>
            <FormattedOverviewProvider>
                <GraphDataProvider>
                    <FishSpeed />
                </GraphDataProvider>
            </FormattedOverviewProvider>
        </OverviewProvider>
    );
}

export default FishSpeedPage;
export { FishSpeedPage };
