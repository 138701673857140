import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FeedingConversionRateResult, MeasurmentOverviewResult } from 'services/types';

import { defaultBarChartOptions } from '@/components/organisms/OptoGraphs/OptoBarChart/defaultBarChartOptions';
import {
    OptoBarChart,
    OptoMixedChart,
    OverViewToBarChartMapper,
} from 'components/organisms/OptoGraphs';
import {
    autoTimeScale,
    chartTitle,
    formatAxsis,
    formatTooltip,
    minMax,
    multipleYAxes,
    noDots,
    Options,
    reverseYaxsis,
    showLegend,
    smoothLines,
    stackedAxis,
    startYAxisAtZero,
    StepSizeAxsis,
    suggestedMaxMin,
    timeScale,
    TypeNames,
    weightAndLengthUnitConverter,
} from 'components/organisms/OptoGraphs/optionsModifiers';
import {
    defaultLineChartOptions,
    FeedingConverstionRateChartMapper,
    OverViewToCoefficientOfVariantChartMapper,
    OverViewToConditionFactorChartMapper,
    OverViewToDepthChartMapper,
    OverViewToGrowthChartMapper,
    OverViewToHeightAndLengthChartMapper,
    OverViewToLivingWeightChartMapper,
    OverViewToPitchRollChartMapper,
    OverViewToSceneLabelChartMapper,
    OverViewToVerticalDisparityChartMapper,
    OverViewToVisibilityChartMapper,
} from 'components/organisms/OptoGraphs/OptoMixedChart';
import { ChartActionButtons } from 'components/organisms/OptoGraphs/OptoMixedChart/OptoMixedChart';

import styles from './Graphs.module.scss';

export interface GraphsPageContentProps {
    isOptoscaleAdmin?: boolean;
    loading: boolean;
    weightUnitTag: 'kg' | 'g' | 'mm' | 'cm' | 'm' | 'oz' | 'lb' | '%';
    daypartTag: string;
    data: MeasurmentOverviewResult;
    disabeledDayTags: string[];
    FeedingConversionRateGraphData: FeedingConversionRateResult;
    timeUnitTag: string;
    actionButtons?: ChartActionButtons;
}

const GraphPageContent = ({
    isOptoscaleAdmin = false,
    disabeledDayTags = [],
    daypartTag = 'day',
    FeedingConversionRateGraphData,
    loading = false,
    data,
    timeUnitTag = 'day',
    weightUnitTag = 'g',
    actionButtons = {
        copyAsPng: true,
        resetZoom: true,
        downloadAsPng: true,
        fullScreen: true,
        timeScaleSwitch: false,
    },
}: GraphsPageContentProps) => {
    const { t, i18n } = useTranslation();

    function toLocale(language: string) {
        return language === 'nb' ? 'nb-NO' : language === 'es' ? 'es-ES' : 'en-US';
    }

    const [locale, setLocale] = useState('nb-NO');

    useEffect(() => {
        setLocale(toLocale(i18n.language));
    }, [i18n.language]);

    return (
        <>
            {locale && (
                <>
                    <section className={styles.graphsGridLayout}>
                        <OptoMixedChart
                            chartName={t('Living weight')}
                            chartData={OverViewToLivingWeightChartMapper({
                                overview: data as MeasurmentOverviewResult,
                                dayTags: [daypartTag],
                                inactiveSetLegend: [...disabeledDayTags],
                            })}
                            actionButtons={{ ...actionButtons }}
                            chartOptions={Options(defaultLineChartOptions).modify(
                                autoTimeScale(),
                                chartTitle({ title: t('Living weight') }),
                                weightAndLengthUnitConverter({
                                    locale: locale,
                                    type: TypeNames.weight,
                                    fromUnit: weightUnitTag,
                                    toUnit: weightUnitTag,
                                })
                            )}
                            loading={loading}
                        />

                        <OptoBarChart
                            chartName={t('Measurements')}
                            chartData={OverViewToBarChartMapper({
                                overview: data as MeasurmentOverviewResult,
                                dayTags: [daypartTag],
                                inactiveSetLegend: [...disabeledDayTags],
                            })}
                            actionButtons={{ ...actionButtons }}
                            chartOptions={Options(defaultBarChartOptions).modify(
                                chartTitle({ title: t('Measurements') }),
                                weightAndLengthUnitConverter({
                                    locale: locale,
                                    type: TypeNames.number,
                                    maximumFractionDigits: 0,
                                    minimumFractionDigits: 0,
                                    toUnit: '',
                                }),
                                suggestedMaxMin({ min: 0, max: 20000, axsisID: 'y' })
                            )}
                            loading={loading}
                        />
                        <OptoMixedChart
                            chartName={t('Growth')}
                            chartData={OverViewToGrowthChartMapper({
                                overview: data as MeasurmentOverviewResult,
                                inactiveSetLegend: [...disabeledDayTags],
                            })}
                            actionButtons={{ ...actionButtons }}
                            chartOptions={Options(defaultLineChartOptions).modify(
                                smoothLines({ tension: 0.4 }),
                                weightAndLengthUnitConverter({
                                    locale: locale,
                                    type: TypeNames.weight,
                                    fromUnit: weightUnitTag,
                                    toUnit: weightUnitTag,
                                }),
                                autoTimeScale(),
                                chartTitle({ title: t('Growth') }),
                                startYAxisAtZero({})
                            )}
                            loading={loading}
                        />

                        <OptoMixedChart
                            chartName={t('Depth')}
                            chartData={OverViewToDepthChartMapper({
                                overview: data as MeasurmentOverviewResult,
                                dayTags: [daypartTag],
                            })}
                            actionButtons={{ ...actionButtons }}
                            chartOptions={Options(defaultLineChartOptions).modify(
                                autoTimeScale(),
                                chartTitle({ title: t('Depth') }),
                                noDots(),
                                startYAxisAtZero({}),
                                reverseYaxsis(),
                                suggestedMaxMin({ min: 0, max: 5 }),
                                formatAxsis({
                                    axsisID: 'y',
                                    tickType: TypeNames.meter,
                                    descimals: 0,
                                }),
                                formatTooltip({
                                    locale: locale,
                                    tickType: TypeNames.meter,
                                    descimals: 2,
                                })
                            )}
                            loading={loading}
                        />

                        <OptoMixedChart
                            chartName={t('Visibility')}
                            chartData={OverViewToVisibilityChartMapper({
                                overview: data as MeasurmentOverviewResult,
                                dayTags: [daypartTag],
                                inactiveSetLegend: [...disabeledDayTags],
                            })}
                            actionButtons={{ ...actionButtons }}
                            chartOptions={Options(defaultLineChartOptions).modify(
                                autoTimeScale(),
                                chartTitle({ title: t('Visibility') }),
                                weightAndLengthUnitConverter({
                                    locale: locale,
                                    type: TypeNames.percent,
                                    fromUnit: '%',
                                    toUnit: '%',
                                }),
                                startYAxisAtZero({}),
                                noDots(),
                                minMax({ min: 0, max: 100 }),
                                formatAxsis({
                                    axsisID: 'y',
                                    tickType: TypeNames.percent,
                                    descimals: 0,
                                })
                            )}
                            loading={loading}
                        />

                        <OptoMixedChart
                            chartName={t('Size')}
                            chartData={OverViewToHeightAndLengthChartMapper({
                                overview: data as MeasurmentOverviewResult,
                                dayTags: [daypartTag],
                                inactiveSetLegend: [...disabeledDayTags],
                            })}
                            actionButtons={{ ...actionButtons }}
                            chartOptions={Options(defaultLineChartOptions).modify(
                                autoTimeScale(),
                                timeScale({ unit: timeUnitTag }),
                                chartTitle({ title: t('Size') }),

                                weightAndLengthUnitConverter({
                                    locale: locale,
                                    type: TypeNames.length,
                                    fromUnit: 'mm',
                                    toUnit: 'cm',
                                }),
                                StepSizeAxsis({ stepSize: 10, axsisID: 'yAxisRight' }),
                                StepSizeAxsis({ stepSize: 2, axsisID: 'yAxisLeft' }),
                                multipleYAxes({
                                    right: {
                                        text: t('Length'),
                                        unit: 'cm',
                                    },
                                    left: {
                                        text: t('Height'),
                                        unit: 'cm',
                                    },
                                }),
                                formatAxsis({
                                    axsisID: 'yAxisRight',
                                    tickType: 'height',
                                    descimals: 1,
                                    multiplier: 0.1,
                                }),
                                formatAxsis({
                                    axsisID: 'yAxisLeft',
                                    tickType: 'length',
                                    descimals: 1,
                                    multiplier: 0.1,
                                })
                            )}
                            loading={loading}
                        />

                        <OptoMixedChart
                            chartName={t('CV')}
                            chartData={OverViewToCoefficientOfVariantChartMapper({
                                overview: data as MeasurmentOverviewResult,
                                inactiveSetLegend: [...disabeledDayTags],
                            })}
                            actionButtons={{ ...actionButtons }}
                            chartOptions={Options(defaultLineChartOptions).modify(
                                autoTimeScale(),
                                chartTitle({ title: t('CV') }),
                                weightAndLengthUnitConverter({
                                    locale: locale,
                                    type: 'percent',
                                    fromUnit: '%',
                                    toUnit: '%',
                                }),
                                suggestedMaxMin({ min: 0, max: 0.5 }),
                                startYAxisAtZero({}),
                                formatAxsis({
                                    axsisID: 'y',
                                    tickType: 'percent',
                                    descimals: 0,
                                })
                            )}
                            loading={loading}
                        />

                        <OptoMixedChart
                            chartName={t('Condition factor')}
                            chartData={OverViewToConditionFactorChartMapper({
                                overview: data as MeasurmentOverviewResult,
                            })}
                            actionButtons={{ ...actionButtons }}
                            chartOptions={Options(defaultLineChartOptions).modify(
                                autoTimeScale(),
                                smoothLines({ tension: 0.2 }),
                                suggestedMaxMin({ min: 1.1, max: 1.5 }),
                                chartTitle({ title: t('Condition factor') }),
                                weightAndLengthUnitConverter({
                                    locale: locale,
                                    type: 'number',
                                    toUnit: '',
                                })
                            )}
                            loading={loading}
                        />

                        <OptoMixedChart
                            chartName={t('Vertical disparity')}
                            chartData={OverViewToVerticalDisparityChartMapper({
                                overview: data as MeasurmentOverviewResult,
                                inactiveSetLegend: [...disabeledDayTags],
                            })}
                            actionButtons={{ ...actionButtons }}
                            chartOptions={Options(defaultLineChartOptions).modify(
                                autoTimeScale(),
                                smoothLines({ tension: 0.4 }),
                                chartTitle({ title: t('Vertical disparity') }),

                                formatAxsis({
                                    axsisID: 'y',
                                    tickType: 'pixel',
                                    descimals: 1,
                                }),
                                suggestedMaxMin({ min: -1, max: 1 })
                            )}
                            loading={loading}
                        />
                        <OptoMixedChart
                            chartName={t('Feeding conversion ratio')}
                            chartData={FeedingConverstionRateChartMapper({
                                conversionRate: FeedingConversionRateGraphData,
                                dayTags: [daypartTag],
                                inactiveSetLegend: [...disabeledDayTags],
                            })}
                            actionButtons={{ ...actionButtons }}
                            chartOptions={Options(defaultLineChartOptions).modify(
                                autoTimeScale(),
                                chartTitle({ title: t('Feeding conversion ratio') }),
                                weightAndLengthUnitConverter({
                                    locale: locale,
                                    type: 'number',
                                    toUnit: '',
                                }),
                                startYAxisAtZero({}),
                                suggestedMaxMin({ min: 0.5, max: 1.5 })
                            )}
                            loading={loading}
                        />
                    </section>
                    {!!isOptoscaleAdmin && (
                        <>
                            <h3 className={styles.onlyAdmins}>
                                {t('Only visible for internal OptoScale admin users')}
                            </h3>

                            <section className={styles.graphsGridLayout}>
                                <OptoMixedChart
                                    chartName={t('Pitch & Roll')}
                                    chartData={OverViewToPitchRollChartMapper({
                                        overview: data as MeasurmentOverviewResult,
                                        dayTags: [daypartTag],
                                        inactiveSetLegend: [...disabeledDayTags],
                                    })}
                                    actionButtons={{ ...actionButtons }}
                                    chartOptions={Options(defaultLineChartOptions).modify(
                                        autoTimeScale(),
                                        chartTitle({ title: t('Pitch & Roll') }),
                                        weightAndLengthUnitConverter({
                                            locale: locale,
                                            type: 'number',
                                            fromUnit: '°',
                                            toUnit: '°',
                                        }),
                                        formatAxsis({
                                            axsisID: 'y',
                                            tickType: 'angle',
                                            descimals: 1,
                                        }),
                                        startYAxisAtZero({}),
                                        noDots()
                                    )}
                                    loading={loading}
                                />
                                <OptoMixedChart
                                    chartName={t('Scene labels')}
                                    chartData={OverViewToSceneLabelChartMapper({
                                        overview: data as MeasurmentOverviewResult,
                                        dayTags: ['day', 'all', 'night', 'short_day'],
                                        inactiveSetLegend: [],
                                    })}
                                    actionButtons={{ ...actionButtons }}
                                    chartOptions={Options(defaultLineChartOptions).modify(
                                        autoTimeScale(),
                                        chartTitle({ title: 'Scene Labels' }),
                                        weightAndLengthUnitConverter({
                                            type: 'percent',
                                            toUnit: '%',
                                            maximumFractionDigits: 1,
                                            minimumFractionDigits: 1,
                                        }),
                                        stackedAxis({ x: false, y: true }),
                                        minMax({
                                            min: 0,
                                            max: 100,
                                        }),
                                        formatAxsis({
                                            axsisID: 'y',
                                            tickType: 'percent',
                                            descimals: 0,
                                        }),
                                        showLegend({
                                            displayLegendValue: false,
                                        })
                                    )}
                                    loading={loading}
                                />
                            </section>
                        </>
                    )}
                </>
            )}
        </>
    );
};
export default GraphPageContent;
export { GraphPageContent as PageContent };
