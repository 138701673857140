import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useLocation } from 'react-router-dom';
import { Alert, Container } from 'reactstrap';
import moment from 'moment';
import { OverviewLocation } from 'services/types';

import LabelBadgeBlock from '@/components/atoms/LabelBadgeBlock';
import Notice from '@/components/atoms/Notice/Notice';
import UmerBox from '@/components/atoms/UmerBox';
import AverageHeader from '@/components/molecules/AverageHeader';
import { Button } from 'components/atoms/Buttons';
import { DownloadCsvXlsx } from 'components/DownloadCsvXlsx';
import { Page, PageContent } from 'components/Layout';
import { LocationsDropdownMenu } from 'components/molecules/LocationsDropdownMenu/LocationsDropdownMenu';

import { Location } from './Location/Location';

import styles from './overview.module.scss';

export const hasActiveBioscope = (location) =>
    location?.cages?.some((cage) => cage?.activeBioscopes?.length > 0);

const DownloadData = ({ locations }: { locations: OverviewLocation[] }) => {
    const { t } = useTranslation();
    const allData = useMemo(
        () =>
            locations?.flatMap((el) => {
                return el.cages.flatMap((x) => {
                    const dataMin = {
                        LocationName: el?.name,
                        PenName: x?.name,
                        LastMeasDateForWeight: moment(x?.weight?.day).format('YYYY-MM-DD') ?? '',
                        Weight: x?.weight?.livingWeight
                            ? `${x?.weight?.livingWeight?.toFixed(2)} g`
                            : '',
                        Growth: x?.weight?.growth ? `${x?.weight?.growth.toFixed(2)} g/day` : '',
                        BioscopeStatus: x?.weight?.measStatusList[0]
                            ? t(`measurementStatusTexts.${x?.weight?.measStatusList[0]}`)
                            : t('All good'),
                        ActiveBioscope: x?.activeBioscopes[0] ?? '',
                    };
                    const dataWithFishHealth = {
                        LastMeasDateForWounds: moment(x?.welfare?.day).format('YYYY-MM-DD') ?? '',
                        Wounds: x?.welfare?.woundRate?.toFixed(2) ?? '',
                    };
                    const dataWithLice = {
                        LastMeasDateForLice: moment(x?.lice?.day).format('YYYY-MM-DD') ?? '',
                        FemaleLice: x?.lice?.femaleAvg?.toFixed(2) ?? '',
                    };
                    return {
                        ...dataMin,
                        ...dataWithFishHealth,
                        ...dataWithLice,
                    };
                });
            }) ?? [],
        [locations]
    );

    const dataColumnNames = allData.length > 0 ? Object.keys(allData[0]) : [];
    const formattedDataColumnNames = dataColumnNames.map((e) => `day.${e}`);
    return (
        <>
            <DownloadCsvXlsx
                label="CSV"
                data={allData}
                nameToInclude={'SiteOverview'}
                COLUMNS={formattedDataColumnNames}
            />
            <DownloadCsvXlsx
                label="XLSX"
                data={allData}
                nameToInclude={'SiteOverview'}
                COLUMNS={formattedDataColumnNames}
            />
        </>
    );
};

export interface SortedLocationsProps {
    locations: OverviewLocation[];
    status: any;
    clientId: number;
    hasAccessToMarkHistorical: boolean;
    markHistorical: (cageId: number, historical: boolean) => Promise<unknown>;
    isOptoscaleAdmin?: boolean;
    renderLazy?: boolean;
}

const SortedLocations = ({
    locations,
    hasAccessToMarkHistorical,
    status,
    clientId,
    markHistorical,
    isOptoscaleAdmin = false,
    renderLazy = false,
}: SortedLocationsProps) => {
    if (!locations.length) {
        return null;
    }

    const { t } = useTranslation();

    const { pathname } = useLocation();
    const createCageUrl = (cageId: number) => `${pathname}/${cageId}`;
    const createCompareUrl = (cageIds: number[]) => {
        const cagesToCompare = locations
            .flatMap((l) => l.cages)
            .filter((c) => cageIds.includes(c.id));
        const queryString = cagesToCompare
            .map((item) => `${encodeURIComponent(item.name)}~${encodeURIComponent(item.id)}`)
            .join(',');
        return cagesToCompare.length > 1 ? `/c/${clientId}/compare?data=${queryString}` : '#';
    };

    return (
        <>
            {locations.map((location) => {
                return (
                    <UmerBox key={location.id} id={location.name.replace(/\s+/g, '')}>
                        {renderLazy ? (
                            <>
                                <h2
                                    className={`${styles.header} ${styles.activeHeader}`}
                                    data-species={location.fishTypes.name.toLowerCase()}>
                                    <span data-type="name">{location.name}</span>
                                    <div>
                                        <LabelBadgeBlock
                                            label={t('species')}
                                            term={t(location.fishTypes?.name)}
                                            fishTypeName={location.fishTypes?.name.toLowerCase()}
                                        />
                                    </div>
                                </h2>
                                <AverageHeader location={location} />
                                <hr />
                                <Location
                                    key={location.id}
                                    location={location}
                                    createCageUrl={createCageUrl}
                                    createCompareUrl={createCompareUrl}
                                    hasAccessToMarkHistorical={hasAccessToMarkHistorical}
                                    markHistorical={markHistorical}
                                />
                            </>
                        ) : (
                            <></>
                        )}
                    </UmerBox>
                );
            })}
        </>
    );
};

interface OverviewProps {
    clientId: number;
    locations: OverviewLocation[];
    hasAccessToMarkHistorical: boolean;
    markHistorical: (cageId: number, historical: boolean) => Promise<unknown>;
    isOptoscaleAdmin?: boolean;
}

const Overview = ({
    clientId,
    locations,
    markHistorical,
    hasAccessToMarkHistorical,
    isOptoscaleAdmin = false,
}: OverviewProps) => {
    const { t } = useTranslation();

    //The block below ensures we scroll to the correct location when the page is loaded
    //It is a workaround for the fact that the page is rendered before the data is loaded
    //and therefore the page does not know where to scroll to
    //The block below is based on https://ericdcobb.medium.com/scrolling-to-an-anchor-in-react-when-your-elements-are-rendered-asynchronously-8c64f77b5f34
    const { hash, pathname } = useLocation();
    const [showInactive, setShowInactive] = useState(false);
    const [dropDownOpen, setDropDownOpen] = useState(false);

    {
        const scrolledRef = React.useRef(false);

        React.useEffect(() => {
            if (hash && !scrolledRef.current) {
                const id = hash.replace('#', '');
                const element = document.getElementById(id);
                if (element) {
                    element.scrollIntoView({ behavior: 'smooth' });
                    scrolledRef.current = true;
                }
            }
        });
    }

    const dropDownIsOpenHandler = (open: boolean) => {
        if (open) {
            setDropDownOpen(open);
            setShowInactive(true);
        }
    };

    const hasAccessToLice = locations.some((location) => location.cages.some((cage) => cage.lice));

    locations.sort((a, b) => a.name.localeCompare(b.name));
    const activeLocations = useMemo(
        () => locations.filter((location) => hasActiveBioscope(location)),
        [locations]
    );

    function maxDate(cages: { weight?: { day: string } }[]) {
        const candidateCages = cages.filter((cage) => cage.weight?.day);
        const max = Math.max(...candidateCages.map((cage) => new Date(cage.weight.day).valueOf()));
        return isNaN(max) ? -1 : max;
    }

    const inactiveLocations = useMemo(
        () =>
            locations
                .filter((location) => !hasActiveBioscope(location))
                .sort((a, b) => maxDate(a.cages) - maxDate(b.cages))
                .reverse(),
        [locations]
    );

    if (!locations) {
        return <Alert color="danger">{t('No locations')}</Alert>;
    }

    const dropdownLocations = locations.map((location) => {
        return {
            name: location.name,
            href: `#${location.name.replace(/\s+/g, '')}`,
            isActive: hasActiveBioscope(location),
        };
    });

    const { ref, inView } = useInView({
        /* Optional options */
        initialInView: false,
        triggerOnce: true,
        rootMargin: '100px',
        onChange: (inView, entry) => {
            if (inView || dropDownOpen) {
                setShowInactive(true);
            }
        },
    });
    const showNotice = useMemo(() => {
        const currentDate = moment();
        const removalDate = moment('2024-06-24T08:00:00'); // Target date and time to remove the notice
        return currentDate.isBefore(removalDate);
    }, []);

    return (
        <Page title="Overview">
            <PageContent>
                <Container fluid>
                    {showNotice && (
                        <Notice
                            heading={t('NoticeHeading')}
                            message={t('NoticeMessage')}
                            variant="default"
                        />
                    )}
                    <div className={styles.siteOverviewPage}>
                        <div className={styles.headerBlock}>
                            <LocationsDropdownMenu
                                locations={dropdownLocations}
                                styles={styles}
                                dropDownOpenHandler={dropDownIsOpenHandler}
                            />
                            {hasAccessToLice ? (
                                <Button to={`/c/${clientId}/cages/lice-report`}>
                                    {t('Lice report')}
                                </Button>
                            ) : (
                                <></>
                            )}
                            <DownloadData locations={locations} />
                        </div>

                        <SortedLocations
                            renderLazy={true}
                            locations={activeLocations}
                            status={'active'}
                            clientId={clientId}
                            hasAccessToMarkHistorical={hasAccessToMarkHistorical}
                            markHistorical={markHistorical}
                            isOptoscaleAdmin={isOptoscaleAdmin}
                        />
                        <div ref={ref}>
                            <hr className={styles.divider} data-label={t('Inactive locations')} />
                            <SortedLocations
                                renderLazy={showInactive}
                                locations={inactiveLocations}
                                status={'inActive'}
                                clientId={clientId}
                                hasAccessToMarkHistorical={hasAccessToMarkHistorical}
                                markHistorical={markHistorical}
                            />
                        </div>
                    </div>
                </Container>
            </PageContent>
        </Page>
    );
};

export default Overview;
