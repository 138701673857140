import isNumberOk from 'utils/isNumberOk';
import toFixed from 'utils/toFixed';

const DEFAULT_DOWNSCALE_DECIMALS = 2;
const DEFAULT_UPSCALE_DECIMALS = undefined; // nothing to do
const DEFAULT_DECIMALS = 2;

const BAD_SCALER_RESULT = {
    weightUnit: undefined,
    convert: () => undefined,
};

const weightUnitDownscalers = {
    g: {
        weightUnit: 'kg',
        convert: (value, decimals = DEFAULT_DOWNSCALE_DECIMALS) =>
            isNumberOk(value) ? toFixed(value / 1000, decimals) : NaN,
    },
    kg: {
        weightUnit: 'kg',
        convert: (value, decimals = DEFAULT_DOWNSCALE_DECIMALS) => toFixed(value, decimals),
    },
    oz: {
        weightUnit: 'oz',
        convert: (value, decimals = DEFAULT_DOWNSCALE_DECIMALS) => toFixed(value, decimals),
        //to convert any unit to desire weight unit use bottom line
        //isNumberOk(value) ? toFixed(value / 16, decimals) : NaN,
    },
    lb: {
        weightUnit: 'lb',
        convert: (value, decimals = DEFAULT_DOWNSCALE_DECIMALS) => toFixed(value, decimals),
    },
};

const weightUnitUpscalers = {
    g: {
        weightUnit: 'g',
        convert: (value, decimals = DEFAULT_UPSCALE_DECIMALS) => toFixed(value, decimals),
    },
    kg: {
        weightUnit: 'g',
        convert: (value, decimals = DEFAULT_UPSCALE_DECIMALS) =>
            isNumberOk(value) ? toFixed(value * 1000, decimals) : NaN,
    },
    oz: {
        weightUnit: 'oz',
        convert: (value, decimals = DEFAULT_UPSCALE_DECIMALS) => toFixed(value, decimals),
    },
    lb: {
        weightUnit: 'lb',
        convert: (value, decimals = DEFAULT_UPSCALE_DECIMALS) => toFixed(value, decimals),
    },
};

const weightUnitNoscalers = {
    g: {
        weightUnit: 'g',
        convert: (value, decimals) => toFixed(value, decimals),
    },
    kg: {
        weightUnit: 'kg',
        convert: (value, decimals) => toFixed(value, decimals),
    },
    oz: {
        weightUnit: 'oz',
        convert: (value, decimals) => toFixed(value, decimals),
    },
    lb: {
        weightUnit: 'lb',
        convert: (value, decimals) => toFixed(value, decimals),
    },
};

function weightUnitDownscaler(weightUnit) {
    return weightUnitDownscalers?.[weightUnit] ?? BAD_SCALER_RESULT;
}

function weightUnitUpscaler(weightUnit) {
    return weightUnitUpscalers?.[weightUnit] ?? BAD_SCALER_RESULT;
}

function weightUnitNoscaler(weightUnit) {
    return weightUnitNoscalers?.[weightUnit] ?? BAD_SCALER_RESULT;
}

const defaultWeightUnitScaler = weightUnitDownscaler;

function formattedWeightUnit(value, weightUnit, decimals = DEFAULT_DECIMALS, scaled = true) {
    const scaler = scaled ? defaultWeightUnitScaler(weightUnit) : weightUnitNoscaler(weightUnit);

    return {
        value: scaler.convert(value, decimals),
        weightUnit: scaler.weightUnit,
    };
}

export default formattedWeightUnit;
export {
    formattedWeightUnit,
    defaultWeightUnitScaler,
    weightUnitDownscaler,
    weightUnitUpscaler,
    weightUnitNoscaler,
};
