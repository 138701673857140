import { useTranslation } from 'react-i18next';
import { CellContext, ColumnDef, createColumnHelper } from '@tanstack/react-table';
import moment from 'moment';

import { Button } from 'components/atoms/Buttons';
import { OptoTable } from 'components/Table/Table';

import styles from './MoveBioscope.module.scss';

interface Bioscope {
    id: number;
    lastCage?: string;
    updatedAt?: string;
    lastLocation?: string;
    lastMeasurement?: string;
}

interface MoveBioscopeContainerProps {
    bioscopes: Bioscope[];
    canMoveBioscope: boolean;
    onMoveBioscope: (bioscopeId: number) => void;
    onDeleteBioscope: (bioscopeId: number) => void;
}
export const MoveBioscope = ({
    bioscopes,
    canMoveBioscope,
    onDeleteBioscope,
    onMoveBioscope,
}: MoveBioscopeContainerProps) => {
    const { t } = useTranslation();
    const columnHelper = createColumnHelper<Bioscope>();

    function parseDate(date: string | null) {
        return date ? moment(date).toDate() : null;
    }
    /*
    function dateSort<T extends object>(f: (T) => Date) {
        return (aOrig: T, bOrig: T) => {
            const a = f(aOrig);
            const b = f(bOrig);
            if (a === null && b === null) {
                return 0;
            } else if (a === null) {
                return -1;
            } else if (b === null) {
                return 1;
            } else {
                return a.getTime() - b.getTime();
            }
        };
    }
    */
    const timestampCell = (cell: CellContext<Bioscope, Date>) =>
        cell.getValue() ? moment(cell.getValue()).format('DD.MM.YYYY [kl.] HH.mm') : null;

    const columns: ColumnDef<Bioscope>[] = [
        columnHelper.accessor((row) => row.id, {
            header: t('Id'),
            id: 'Id',
        }),
        columnHelper.accessor((row) => row.lastCage ?? t('Land'), {
            header: t('Cage'),
            id: 'Cage',
        }),
        columnHelper.accessor((row) => row.lastLocation ?? 'Land', {
            header: t('Facility'),
            id: 'Facility',
        }),
        columnHelper.accessor((row) => parseDate(row.updatedAt), {
            header: t('Last moved'),
            id: 'LastMoved',
            cell: timestampCell,
        }),
        columnHelper.accessor((row) => parseDate(row.lastMeasurement), {
            header: t('Last measurement'),
            id: 'LastMeasurement',
            cell: timestampCell,
        }),
        columnHelper.accessor((row) => row.id, {
            header: t('Actions'),
            id: 'Actions',

            cell: (cell) => (
                <div className={styles['not-break']}>
                    <Button
                        disabled={!canMoveBioscope}
                        className="mr-2"
                        onClick={() => onMoveBioscope(cell.getValue())}>
                        {t('Move')}
                    </Button>
                    <Button
                        disabled={!canMoveBioscope}
                        confirmationText={t('Are you sure you want to delete last bioscope move?')}
                        onClick={() => onDeleteBioscope(cell.getValue())}>
                        {t('Delete last move')}
                    </Button>
                </div>
            ),
        }),
    ];

    return (
        <>
            <OptoTable
                data={bioscopes}
                columns={columns}
                initialSort={[{ id: 'Id', desc: false }]}
            />
        </>
    );
};
