import { matchPath } from 'react-router-dom';

const getClientId = (path): number => {
    const match = matchPath(path, {
        path: '/c/:userId',
        exact: false,
        strict: true,
    });

    return match ? parseInt(match.params.userId) : null;
};

export default getClientId;
export { getClientId };
