import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

const states = {
    created: 'created',
    started: 'started',
    error: 'error',
    stopped: 'stopped',
    finished: 'finished',
    stalled: 'stalled',
    warning: 'warning',
};
interface StatusProps {
    status: keyof typeof states;
}

const Badge = styled.div`
    -webkit-box-align: center;
    align-items: center;
    background-color: ${(props) => props.color ?? 'rgb(4, 155, 74)'};
    border-radius: 24px;
    color: white;
    display: inline-flex;
    height: 28px;
    padding: 4px 12px 4px 8px;
    margin: 0px 8px 0px 0px;
`;

export function Status({ status }: StatusProps) {
    const { t } = useTranslation();

    const color =
        status === 'stalled' || status === 'warning'
            ? 'rgb(233, 89, 0)'
            : status === 'finished'
              ? 'rgb(4, 155, 74)'
              : status === 'error' || status === 'stopped'
                  ? 'rgb(242, 70, 70)'
                  : 'rgb(85, 85, 85)';

    const iconClassName =
        status === 'stalled' || status === 'warning'
            ? 'fas fa-fw fa-exclamation'
            : status === 'finished'
              ? 'fas fa-fw fa-check'
              : status === 'error' || status === 'stopped'
                  ? 'fas fa-fw fa-exclamation'
                  : 'fa fa-fw fa-spinner fa-spin';

    const label = t(`batchServiceStatus.${status}`);

    return (
        <Badge color={color}>
            <i className={iconClassName} />
            <span className="ml-1">{label}</span>
        </Badge>
    );
}
