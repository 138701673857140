import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { useLocationController_DeleteLocation } from 'services/hooks';

import Loader from 'components/atoms/Loader';

const DeleteLocationConfirm = ({ onClose, isOpen, location, refetch }) => {
    const { t } = useTranslation();
    const [error, setError] = React.useState(false);
    const deleteLocation = useLocationController_DeleteLocation();

    React.useEffect(() => {
        if (!location) {
            setError(false);
        }
    }, [location, isOpen]);

    return (
        <Modal isOpen={isOpen} toggle={onClose}>
            <ModalHeader toggle={onClose}>{t('Delete Location')}</ModalHeader>
            <ModalBody>
                {t('Are you sure you want to delete this location?')}
                {error ? <Alert color="danger">{t('Something happened')}</Alert> : ''}
            </ModalBody>
            <ModalFooter>
                {error && (
                    <div className="mr-2">
                        <Loader size={'30px'} />
                    </div>
                )}
                <Button
                    color="primary"
                    onClick={() => {
                        deleteLocation
                            .mutateAsync({
                                locationId: location,
                            })
                            .finally(() => {
                                onClose();
                                refetch();
                            });
                    }}>
                    {t('Yes')}
                </Button>{' '}
                <Button color="secondary" onClick={onClose}>
                    {t('No')}
                </Button>
            </ModalFooter>
        </Modal>
    );
};

export default DeleteLocationConfirm;
