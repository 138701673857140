import { useEffect, useRef } from 'react';

/*
How this works:
https://dev.to/luispa/how-to-add-a-dynamic-title-on-your-react-app-1l7k

This function handles prevailOnUnmount configuration.
This is optional, but the idea is to maintain the same title if the component using this hook is unmounted.
If you don't want to use it, the default value is false.

*/

function useDocumentTitle(title, prevailOnUnmount = true) {
    const defaultTitle = useRef(document.title);
    const siteName = 'OptoScale web';

    useEffect(() => {
        document.title = `${siteName} - ${title}`;
    }, [title]);

    useEffect(
        () => () => {
            if (!prevailOnUnmount) {
                document.title = defaultTitle.current;
            }
        },
        [prevailOnUnmount]
    );
}

export default useDocumentTitle;
export { useDocumentTitle };
