import { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import queryString from 'query-string';
import isObject from 'utils/isObject';
import mergeQueryParams from 'utils/mergeQueryParams';
import { joinUrlPath } from 'utils/urlPath';

function useGotoUrl() {
    const history = useHistory();
    const location = useLocation();

    const queryParams = useMemo(() => queryString.parse(location.search), [location.search]);

    return useCallback(
        (...parts) => {
            // if past argument is an object treat it as query params
            const opts = isObject(parts[parts.length - 1]) ? parts.pop() : { query: {} }; // if we do not specify query here, we will clear all query params
            const mergedQueryParams = mergeQueryParams(queryParams, opts?.query);

            history.push({
                pathname: joinUrlPath(opts?.base ?? location.pathname, ...parts),
                search: queryString.stringify(mergedQueryParams),
                state: location.state,
            });
        },
        [location.pathname, location.state, queryParams, history]
    );
}

export default useGotoUrl;
