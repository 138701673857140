/**
 * @element opto-check
 */
class OptoCheck extends HTMLElement {
    connectedCallback() {
        this.render();
    }

    render() {
        this.innerHTML = `
    <svg width="16" height="16" viewBox="0 0 12 12" fill="#14192d" xmlns="http://www.w3.org/2000/svg">
      <path fill-rule="evenodd" clip-rule="evenodd" d="M5.09531 6.87838L8.68439 2.95529L9.79112 3.96779L5.67424 8.46779L5.14644 9.04471L4.59201 8.49334L2.20889 6.12335L3.26662 5.05976L5.09531 6.87838Z" fill="#14192d"/>
    </svg>

    `;

        this.style.pointerEvents = 'none';
    }
}

if (!customElements.get('opto-check')) {
    customElements.define('opto-check', OptoCheck);
}

export { OptoCheck };
