import { getI18n } from 'react-i18next';
import {
    DISPLAY_LONG_DAY_FORMAT,
    DISPLAY_WEEK_FORMAT,
    DISPLAY_WEEK_FORMAT_NO,
    TRANSLATE_THIS_LABEL,
} from 'utils/formattedDay';
export default (showYLabel = true, maintainAspectRatio = true) => {
    const i18n = getI18n();
    return {
        maintainAspectRatio,
        tooltips: {
            mode: 'index',
            axis: 'x',
            intersect: false,
            callbacks: {
                title: (tooltipItem) => {
                    return i18n.language === 'nb'
                        ? TRANSLATE_THIS_LABEL(tooltipItem[0].xLabel)
                        : tooltipItem[0].xLabel;
                },
            },
        },
        hover: {
            mode: 'index',
            axis: 'x',
            intersect: false,
        },
        legend: {
            onClick: (e) => e.stopPropagation(),
        },
        scales: {
            xAxes: [
                {
                    type: 'time',
                    time: {
                        tooltipFormat: DISPLAY_LONG_DAY_FORMAT,
                        displayFormats: {
                            week:
                                i18n.language === 'nb'
                                    ? DISPLAY_WEEK_FORMAT_NO
                                    : DISPLAY_WEEK_FORMAT,
                        },
                    },
                    ticks: {
                        callback: (label) =>
                            i18n.language === 'nb' ? TRANSLATE_THIS_LABEL(label) : label,
                    },
                    offset: true,
                },
            ],
            yAxes: [
                {
                    scaleLabel: {
                        display: showYLabel,
                        labelString: i18n.t('Fish count'),
                        fontSize: 20,
                    },
                    ticks: {
                        maxTicksLimit: 12,
                        beginAtZero: true,
                    },
                },
            ],
        },
    };
};
