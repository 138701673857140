import React from 'react';
import { Route, Switch } from 'react-router-dom';
const Sentry = import('@sentry/browser');

import MeContext from 'contexts/meContext';

import Loader from '../components/atoms/Loader';

import LoggedInRoutes from './LoggedInRoutes';
import Public from './Public';

const loggedOutRoutes = () => (
    <Switch>
        <Route component={Public} />
    </Switch>
);
const Routes = () => {
    const { state } = React.useContext(MeContext);

    if (state.isAuthenticating) {
        return <Loader />;
    }

    if (state.isAuthenticated) {
        Sentry.then((s) =>
            s.configureScope((scope) => {
                scope.setUser({
                    id: state.user.id,
                    email: state.user.email,
                });
            })
        );

        return <LoggedInRoutes />;
    }

    return loggedOutRoutes();
};

export default Routes;
export { Routes };
