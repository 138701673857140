/* 
    AutoAdjustZoomOutLimits based on datasets
    *This plugin is an extension to chartjs-plugin-zoom 
*/

export default {
    id: 'double-click-reset-zoom',
    afterEvent: (chart, event, options) => {
        if (event.type === 'click' && chart.resetZoom) {
            if (options.lastClick && new Date() - options.lastClick < 500) {
                chart.resetZoom();
                chart.update(0);
                chart.update(0);
            }
            options.lastClick = new Date();
        }
    },
};
