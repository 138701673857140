import React, { useEffect, useRef, useState } from 'react';
import { Chart as ChartJs, ChartType } from 'chartjs2';

import styles from './chart.module.scss';
const Chart = ({
    data,
    header = undefined,
    options = undefined,
    plugins = undefined,
    sidebar = undefined,
    type = undefined,
    style = undefined,
}) => {
    const ref = useRef();
    const [chart, setChart] = useState();
    useEffect(() => {
        const currentPos = document.documentElement.scrollTop;
        // Destroy old chart to avoid drawing on same canvas
        if (chart) {
            const destroyChart = chart as ChartJs;
            destroyChart.destroy();
        }

        // Get context from canvas
        if (ref?.current) {
            const ctx = (ref.current as HTMLCanvasElement).getContext('2d');
            // Create new chart
            setChart(
                new ChartJs(ctx, {
                    type: type,
                    data: data,
                    options: options,
                    plugins: plugins,
                } as ChartType<['type']>)
            );
        }
    }, [type, options, plugins, data]);

    return (
        <div className={styles.container} style={style}>
            <div className={styles.header}>{!!header && header(chart)}</div>
            <div className={styles.chart}>
                <canvas ref={ref} />
            </div>
            <div className={styles.right}>{!!sidebar && sidebar(chart)}</div>
        </div>
    );
};

export default Chart;
export { Chart };
