import React from 'react';
import { useTranslation } from 'react-i18next';

import { FishType } from '@/services/types';
import { Button } from 'components/atoms/Buttons';

interface Location {
    name: string;
    timezone: string;
    aquacultureSiteId: string | number;
    fishType: FishType;
    id: number;
}

export type LocationCageGroupProps = {
    location: Location;
    cages: {
        id: number;
        name: string;
        historical: Date | null;
        markHistorical: (marked: boolean) => Promise<unknown>;
    }[];
    fishTypesList: FishType[];
    expanded: boolean; // Add the 'expanded' property
    onToggle: () => void;
    onAddCageToLocation: (locationId: number) => void;
    onDeleteCageToLocation: (locationId: number) => void;
    deleteCage: (cageId: number) => Promise<unknown>;
    onToggleLocationSelect: (checked: boolean) => void;
    locationSelected: boolean;
    onUpdateLocation: (location: Location) => void;
};

const LocationCageGroup = ({
    location,
    cages,
    expanded,
    fishTypesList = [] as FishType[],
    onToggle,
    onAddCageToLocation,
    onDeleteCageToLocation,
    deleteCage,
    onToggleLocationSelect,
    locationSelected,
    onUpdateLocation,
}: LocationCageGroupProps) => {
    const { t } = useTranslation();

    return (
        <>
            <tr>
                <td>
                    <input
                        type="checkbox"
                        checked={locationSelected}
                        onChange={(e) => {
                            onToggleLocationSelect(e.target.checked);
                        }}
                    />
                </td>
                <td>
                    {cages && cages.length > 0 && (
                        <span className={'pl-3 pr-3'} onClick={onToggle}>
                            {expanded ? (
                                <i className="fas fa-chevron-up" />
                            ) : (
                                <i className="fas fa-chevron-down" />
                            )}
                        </span>
                    )}
                </td>
                <td colSpan={2}>{location.name}</td>
                <td>{location.timezone}</td>
                <td>{location.aquacultureSiteId}</td>
                <td>{t(location.fishType.name)}</td>
                <td>-</td>
                <td>
                    <Button
                        className={'mr-1'}
                        onClick={(e) => {
                            onUpdateLocation(location);
                            e.stopPropagation();
                        }}>
                        {t('Edit')}
                    </Button>
                    <Button
                        className={'mr-1'}
                        onClick={(e) => {
                            onAddCageToLocation(location.id);
                            e.stopPropagation();
                        }}>
                        {t('Add cage')}
                    </Button>
                    <Button
                        color="secondary"
                        className={'mr-1'}
                        onClick={(e) => {
                            onDeleteCageToLocation(location.id);
                            e.stopPropagation();
                        }}>
                        {t('Delete')}
                    </Button>
                </td>
            </tr>

            {expanded &&
                cages.map((c) => (
                    <tr key={`cage_${c.id}`}>
                        <td />
                        <td>
                            <i className="pl-3 pr-3 fas fa-arrow-right" />
                        </td>
                        <td colSpan={4}>{c.name}</td>
                        <td>
                            <Button onClick={() => c.markHistorical(!c.historical)}>
                                {c.historical ? t('Remove historical') : t('Mark historical')}
                            </Button>

                            <Button
                                confirmationText="Do you really want to delete the cage?"
                                onClick={() => deleteCage(c.id)}
                                color="primary">
                                {t('Delete')}
                            </Button>
                        </td>
                    </tr>
                ))}
        </>
    );
};

export default LocationCageGroup;
