import { getI18n } from 'react-i18next';
import isNumberOk from 'utils/isNumberOk';
import toFixed from 'utils/toFixed';

export default (showYLabel = true, maintainAspectRatio = true) => {
    const i18n = getI18n();

    return {
        maintainAspectRatio,
        animation: false,
        elements: {
            line: {
                tension: 0,
            },
        },
        hover: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            zoom: {
                zoom: {
                    enabled: true,
                    drag: {
                        backgroundColor: 'rgba(47, 131, 123, 0.2)',
                    },
                    mode: 'x',
                    onZoomComplete: ({ chart }) => {
                        chart.update(0);
                    },
                },
            },
            datalabels: {
                anchor: 'end',
                align: 'top',
                formatter: (value) => {
                    return value.y;
                },
                font: {
                    weight: 'bold',
                },
                display: true,
            },
        },
        scales: {
            xAxes: [
                {
                    id: 'xAxis1',
                    type: 'time',
                    time: {
                        unit: 'week',
                        displayFormats: {
                            week: 'W',
                        },
                        tooltipFormat: 'YYYY-W',
                    },
                    ticks: {
                        source: 'data',
                    },
                    offset: true,
                    maxBarThickness: 70,
                },
                {
                    id: 'xAxis2',
                    type: 'time',
                    time: {
                        unit: 'month',
                        displayFormats: {
                            month: 'MMMM',
                        },
                    },
                    ticks: {
                        source: 'data',
                        callback: (label) => i18n.t(label),
                    },
                    offset: true,
                    maxBarThickness: 70,
                },
            ],
            yAxes: [
                {
                    scaleLabel: {
                        display: showYLabel,
                        labelString: i18n.t('Lice per fish'),
                        fontSize: 20,
                    },
                    ticks: {
                        maxTicksLimit: 12,
                        beginAtZero: true,
                        callback: (label) => `${label}`,
                        min: 0,
                        suggestedMax: 0.3,
                    },
                },
            ],
        },
        tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
                label: (tooltipItem, data) => {
                    let label = data.datasets?.[tooltipItem.datasetIndex]?.label ?? '';
                    if (label) {
                        label += ': ';
                    }
                    const value = toFixed(tooltipItem.yLabel, 2);
                    if (!isNumberOk(value)) {
                        return label;
                    }
                    return `${label}${value}`;
                },
            },
        },
    };
};
