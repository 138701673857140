import React from 'react';

import styles from './SearchIcon.module.scss';

export interface SearchIconProps {
    color?: string;
    size?: number;
}

const SearchIcon = ({ color, size = 32 }: SearchIconProps) => {
    const colorStyle = color ? color : 'currentColor';

    return (
        <div className={styles.searchIcon}>
            <svg height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M27.207,24.37866,20.6106,17.78235a9.03069,9.03069,0,1,0-2.82825,2.82825L24.37878,27.207a1,1,0,0,0,1.41425,0l1.414-1.41418A1,1,0,0,0,27.207,24.37866ZM13,19a6,6,0,1,1,6-6A6.00657,6.00657,0,0,1,13,19Z"
                    fill={colorStyle}
                />
            </svg>
        </div>
    );
};

export default SearchIcon;
export { SearchIcon };
