import React, { useContext } from 'react';
import { ButtonGroup } from 'reactstrap';
import FullScreenContext from 'contexts/fullScreenContext';

import FullScreenButton from 'components/Layout/Buttons/FullScreenButton/FullScreenButton';

import DownloadAsPNG from '../../components/DownloadAsPNG';

import style from './chartheader.module.scss';

export default ({ chart }) => {
    const { fullScreen } = useContext(FullScreenContext);
    return (
        <div
            className={fullScreen ? style.fullscreen : null}
            style={{ width: '100%', overflow: 'hidden' }}>
            <ButtonGroup style={{ float: 'right' }}>
                <DownloadAsPNG chart={chart} />
                <FullScreenButton />
            </ButtonGroup>
        </div>
    );
};
