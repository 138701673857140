import React from 'react';

import styles from './VerticalElipsisIcon.module.scss';

export interface VerticalElipsisIconProps {
    color?: string;
    size?: number;
}

const VerticalElipsisIcon = ({ color, size = 18 }: VerticalElipsisIconProps) => {
    return (
        <div className={styles.verticalelipsisicon}>
            <svg xmlns="http://www.w3.org/2000/svg" height={size} viewBox="0 0 128 512">
                <path
                    fill={color}
                    d="M64 360a56 56 0 1 0 0 112 56 56 0 1 0 0-112zm0-160a56 56 0 1 0 0 112 56 56 0 1 0 0-112zM120 96A56 56 0 1 0 8 96a56 56 0 1 0 112 0z"
                />
            </svg>
        </div>
    );
};

export default VerticalElipsisIcon;
export { VerticalElipsisIcon };
