import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { Table } from 'reactstrap';

import { Button } from 'components/atoms/Buttons';

import styles from './BioscopeContractListOfBioscopes.module.scss';

export interface BioscopeContractProps {
    bioscopes: {
        bioscopeId: number;
        cages: {
            locations: {
                name: string;
            };
            name: string;
        };
    }[];
    openMoveModal: (bioscopeId: number) => void;
}

export const BioscopeContractListOfBioscopes = ({
    bioscopes,
    openMoveModal,
}: BioscopeContractProps) => {
    const { t } = useTranslation();

    return (
        <div className={styles.BioscopeContractListOfBioscopes}>
            <Table>
                <thead>
                    <tr>
                        <th>{t('Bioscope')}</th>
                        <th>{t('Location')}</th>
                        <th>{t('Cage')}</th>
                        <th>{t('Actions')}</th>
                    </tr>
                </thead>
                <tbody>
                    {bioscopes.map((bioscope) => (
                        <tr key={bioscope.bioscopeId.toString()}>
                            <td>
                                {
                                    <Link to={`/internal/bioscopes/${bioscope.bioscopeId}`}>
                                        {bioscope.bioscopeId}
                                    </Link>
                                }
                            </td>
                            <td>{bioscope?.cages?.locations?.name ?? '-'}</td>
                            <td>{bioscope?.cages?.name ?? '-'}</td>
                            <td>
                                <Button onClick={() => openMoveModal(bioscope.bioscopeId)}>
                                    {t('Move')}
                                </Button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </Table>
        </div>
    );
};
