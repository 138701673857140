import type React from 'react';
import type { MouseEvent } from 'react';
import SearchIcon from 'components/atoms/icons/SearchIcon';

import styles from './ResetZoom.module.scss';

export interface ResetZoomProps extends React.HTMLAttributes<HTMLButtonElement> {
    onClick?: (ev: MouseEvent<HTMLButtonElement>) => void;
}

const ResetZoom: React.FC<ResetZoomProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
    onClick,
}: ResetZoomProps) => {
    return (
        <button className={styles.resetzoom} name="zoomReset" onClick={onClick}>
            <SearchIcon size={24} />
        </button>
    );
};

export default ResetZoom;
export { ResetZoom };
