import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Container, Row } from 'reactstrap';
import { useQueryClient } from '@tanstack/react-query';
import {
    useClientController_GetClient,
    useLicenseController_getAllClientUser,
    useLicenseController_getAllClientWithUser,
    useLicenseController_getAllUser,
    useLicenseController_getClientLicenses,
    useUserController_DeleteUser,
    useUserController_ImpersonateUser,
    useUserController_InviteUser,
} from 'services/hooks';

import { useMe } from '../../contexts/meContext';
import { Page, PageContent, PageTitle } from '../Layout';

import UserInviteForm from './Components/UserInviteForm';
import UserTable from './Components/UserTable';

const UserPage = ({ clientId }) => {
    const {
        isClientAdmin,
        isOptoscaleAdmin,
        state: { clients },
    } = useMe();

    const queryClient = useQueryClient();

    const { t } = useTranslation();

    const { data: userDataClientID, isFetching: userDataClientIDFetching } =
        useLicenseController_getAllClientUser(clientId, { enabled: !!clientId });
    const { data: client } = useClientController_GetClient(clientId, {
        enabled: !!clientId,
    });

    const {
        data: userDataAll,
        isFetching: userDataFetching,
        refetch,
    } = useLicenseController_getAllUser({
        enabled: !clientId,
    });
    const { data: clientList } = useLicenseController_getAllClientWithUser({
        enabled: isOptoscaleAdmin,
    });
    const { data: clientData } = useLicenseController_getClientLicenses(clientId, {
        enabled: !!clientId,
    });

    const userData = clientId ? userDataClientID : userDataAll;
    const isFetching = clientId ? userDataClientIDFetching : userDataFetching;

    const { mutateAsync: inviteUser } = useUserController_InviteUser();

    const { mutateAsync: deleteUser } = useUserController_DeleteUser({
        onSettled: () => {
            refetch();
        },
    });

    const { mutateAsync: impersonateUser } = useUserController_ImpersonateUser({
        onSuccess: () => {
            queryClient.invalidateQueries();
            window.location.replace('/');
        },
    });

    deleteUser;
    return (
        <Page title={'Users'}>
            <PageContent>
                <Container fluid>
                    <Row>
                        <Col className="text-center">
                            <PageTitle>{t('Users')}</PageTitle>
                        </Col>
                    </Row>

                    <Row className="flex-lg-row-reverse">
                        <Col lg={3}>
                            {clientList && (
                                <UserInviteForm
                                    client={client?.data}
                                    clients={clients}
                                    canInviteForOptoscaleAdmin={!clientId}
                                    canInviteForClient={!clientId || isClientAdmin(clientId)}
                                    onSubmit={(inputs) => inviteUser({ requestBody: inputs })}
                                />
                            )}
                        </Col>

                        <Col lg={9}>
                            <UserTable
                                users={userData?.data}
                                canDeleteUsers={!clientId}
                                canImpersonateUsers={!clientId}
                                isFetching={isFetching}
                                clientId={clientId}
                                deleteUser={(userId: number) => deleteUser({ userId })}
                                impersonateUser={(userId: number) => impersonateUser({ userId })}
                            />
                        </Col>
                    </Row>
                </Container>
            </PageContent>
        </Page>
    );
};

UserPage.propTypes = {};

export default UserPage;
