import React from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Button, Form, FormGroup, Input, Label } from 'reactstrap';

export interface UserRegisterFormSubmit {
    firstName: string;
    lastName: string;
    password: string;
    token: string;
}

interface UserRegisterFormState {
    firstName: string;
    lastName: string;
    password: string;
    confirmPassword: string;
}

const defaultState = {
    firstName: '',
    lastName: '',
    password: '',
    confirmPassword: '',
};

interface UserInviteFormProps {
    token: string;
    onSubmit: (state: UserRegisterFormSubmit) => Promise<unknown>;
}

export const UserRegister = ({ token, onSubmit }: UserInviteFormProps) => {
    const [state, setState] = React.useState<UserRegisterFormState>(defaultState);
    const { t } = useTranslation();
    const [disabled, setDisabled] = React.useState(false);
    const [error, setError] = React.useState(null);
    const [success, setSuccess] = React.useState(false);
    const invalidPassword = state.password !== state.confirmPassword;
    const invalid = invalidPassword;

    const updateState = (partialState: Partial<UserRegisterFormState>) =>
        setState((s) => ({ ...s, ...partialState }));

    const submit = async (e: React.FormEvent<HTMLFormElement>): Promise<void> => {
        try {
            e.preventDefault();
            setError(null);
            setDisabled(true);
            await onSubmit({ ...state, token });
            setSuccess(true);
        } catch (e) {
            setError(`Something happened: ${JSON.stringify(e?.response?.data?.message)}`);
            setDisabled(false);
            setSuccess(false);
        }
    };

    return (
        <Form onSubmit={submit}>
            <FormGroup disabled={disabled}>
                <Label>{t('First name')}</Label>
                <Input
                    type="text"
                    name="firstName"
                    data-testid="firstName"
                    placeholder={t('First name')}
                    value={state.firstName}
                    onChange={(e) =>
                        updateState({
                            firstName: e.target.value,
                        })
                    }
                />
            </FormGroup>
            <FormGroup disabled={disabled}>
                <Label>{t('Last name')}</Label>
                <Input
                    type="text"
                    name="lastName"
                    data-testid="lastName"
                    placeholder={t('Last name')}
                    value={state.lastName}
                    onChange={(e) =>
                        updateState({
                            lastName: e.target.value,
                        })
                    }
                />
            </FormGroup>
            <FormGroup disabled={disabled}>
                <Label>{t('Password')}</Label>
                <Input
                    type="password"
                    name="password"
                    placeholder="Passord"
                    data-testid="password"
                    value={state.password}
                    onChange={(e) =>
                        updateState({
                            password: e.target.value,
                        })
                    }
                />
            </FormGroup>
            <FormGroup disabled={disabled}>
                <Label>{t('Repeat password')}</Label>
                <Input
                    type="password"
                    name="confirmPassword"
                    placeholder={t('Password')}
                    value={state.confirmPassword}
                    invalid={invalidPassword && state.password.length > 0}
                    data-testid="confirmPassword"
                    onChange={(e) =>
                        updateState({
                            confirmPassword: e.target.value,
                        })
                    }
                />
            </FormGroup>

            {error && <Alert color="danger">{error}</Alert>}
            {success && (
                <Alert>
                    {t('Thanks for registering - you will shortly be redirected to the login page')}
                </Alert>
            )}
            <Button
                type="submit"
                color="primary"
                data-testid="submitButton"
                disabled={disabled || invalid}>
                {t('Create user')}
            </Button>
        </Form>
    );
};
