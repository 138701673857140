import { useCallback } from 'react';
import { useCage } from 'contexts/cage-context';
import { useMe } from 'contexts/meContext';

function useCheckPageRoles() {
    const me = useMe();
    const { client } = useCage();
    const clientId = client?.id;

    const checkRole = useCallback(
        (role) => {
            const fn = {
                SUPER: () => me?.isOptoscaleAdmin,
                CLIENT_ADMIN: () => me?.isClientAdmin(clientId),
            }?.[role];

            return fn ? fn : () => false;
        },
        [clientId, me]
    );

    return useCallback(
        (page) => {
            if (!page) return false;
            return (page?.roles ?? []).every((role) => checkRole(role)());
        },
        [checkRole]
    );
}

export default useCheckPageRoles;
