import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row, Table } from 'reactstrap';
import formattedDay from 'utils/formattedDay';
import formattedTs from 'utils/formattedTs';

//Temporary converting g into kg
//In future when adding more weight unit it would be change dynamically
import { Button } from 'components/atoms/Buttons';
import { Status } from 'components/atoms/Status';
import { Box } from 'components/Layout';

import { NewReport, NewReportProps } from './NewReport';

import styles from './ReportList.module.scss';

interface Report {
    id: number;
    status: string;
    updatedAt: Date;
    lastFeedDay: Date;
    slaughterDay: Date;
    onClick?: () => void;
    onDelete?: () => void;
}

interface ReportListProps {
    reports: Report[];
    onCreateReport: NewReportProps['onSubmitted'];
}

export function ReportList({ reports, onCreateReport }: ReportListProps) {
    const { t } = useTranslation();

    return (
        <>
            <Row>
                <Col>
                    <Box>
                        <Row className="mb-3 mr-3">
                            <Col>
                                <NewReport onSubmitted={onCreateReport} />
                            </Col>
                        </Row>
                        <Row className="mb-3 mr-3">
                            <Col>
                                <Table striped hover>
                                    <thead>
                                        <tr>
                                            <th>{t('Status')}</th>
                                            <th>{t('Reporting.Last feeding date')}</th>
                                            <th>{t('Reporting.Harvest date')}</th>
                                            <th>{t('Updated')}</th>
                                            <th />
                                            <th />
                                        </tr>
                                    </thead>
                                    <tbody className={styles['report-list']}>
                                        {reports.length === 0 && (
                                            <tr>
                                                <td colSpan={6}>
                                                    <i>{t('No data')}</i>
                                                </td>
                                            </tr>
                                        )}
                                        {reports.map((report) => (
                                            <tr
                                                key={report.id}
                                                className={styles[report.status]}
                                                title={`${t('Report')}:${report.id}`}>
                                                <td>
                                                    <Status status={report.status as 'finished'} />
                                                </td>
                                                <td>{formattedDay(report.lastFeedDay)}</td>
                                                <td>{formattedDay(report.slaughterDay) ?? '-'}</td>
                                                <td>{formattedTs(report.updatedAt)}</td>
                                                <td>
                                                    <Button
                                                        onClick={() => report.onClick?.()}
                                                        color="primary">
                                                        {t('Show')}
                                                    </Button>
                                                </td>
                                                <td>
                                                    <Button
                                                        color="secondary"
                                                        confirmationText={t(
                                                            'Are you sure you want to delete this Report'
                                                        )}
                                                        onClick={() => report.onDelete?.()}>
                                                        {t('Delete')}
                                                    </Button>
                                                </td>
                                            </tr>
                                        ))}
                                    </tbody>
                                </Table>
                            </Col>
                        </Row>
                    </Box>
                </Col>
            </Row>
        </>
    );
}

export default ReportList;
