import React from 'react';

import { WeightDistributionProvider } from './weight-distribution-context';
import WeightDistribution from './WeightDistribution';

function WeightDistributionContainer() {
    return (
        <WeightDistributionProvider>
            <WeightDistribution />
        </WeightDistributionProvider>
    );
}

export default WeightDistributionContainer;
