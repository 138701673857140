export default (maintainAspectRatio = true) => {
    return {
        maintainAspectRatio,
        tooltips: {
            mode: 'index',
            axis: 'x',
            intersect: false,
        },
        hover: {
            mode: 'index',
            axis: 'x',
            intersect: false,
        },
        legend: {
            display: false,
        },
        scales: {
            xAxes: [
                {
                    ticks: {
                        beginAtZero: true,
                        suggestedMin: 0,
                        suggestedMax: 100,
                        callback: (value) => (value ? `${value}%` : ''),
                    },
                },
            ],
        },
    };
};
