import React, { MouseEvent } from 'react';
import cn from 'classnames';

import { AnchorButton } from 'components/atoms/Buttons/AnchorButton';

import styles from './button.module.scss';

const useClasses = (filledBackground, color, fullWidth, disabled, className) =>
    cn(className, styles.button, styles[`${filledBackground ? 'filled' : 'normal'}_${color}`], {
        [styles.fullWidth]: fullWidth,
        [styles.disabled]: disabled,
    });

interface ButtonProps {
    onClick?: (ev: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
    confirmationText?: string;
    filledBackground?: boolean;
    fullWidth?: boolean;
    disabled?: boolean;
    className?: string;
    buttonType?: 'button' | 'submit' | 'reset';
    color?: 'primary' | 'secondary' | 'normal_secondary';
    size?: 'small' | 'medium' | 'large';
    to?: string;
    defaultButton?: boolean;
    children: React.ReactNode | React.ReactNode[];
}

const Button: React.FC<ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>> = ({
    children,
    confirmationText = null,
    filledBackground = false,
    buttonType = 'button',
    color = 'primary',
    size = 'medium',
    fullWidth = false,
    disabled = false,
    className = undefined,

    to = undefined,
    onClick = () => {},
    ...buttonProps
}) => {
    const computedClassName = useClasses(filledBackground, color, fullWidth, disabled, className);

    const confirmationOnclick = confirmationText
        ? (ev: MouseEvent<HTMLButtonElement>) => {
              ev.stopPropagation();
              const confirm = window?.confirm(confirmationText);

              if (confirm) {
                  typeof onClick === 'function' && onClick(ev);
              }
          }
        : (ev: MouseEvent<HTMLButtonElement>) => {
              ev.stopPropagation();
              typeof onClick === 'function' && onClick(ev);
          };

    return to ? (
        <AnchorButton size={size} disabled={disabled} to={to} variant={color ? color : 'primary'}>
            {children}
        </AnchorButton>
    ) : (
        <button
            disabled={disabled}
            data-navigation={to}
            data-size={size}
            type={buttonType}
            {...buttonProps}
            onClick={confirmationOnclick}
            className={computedClassName}>
            {children}
        </button>
    );
};

export default Button;
export { Button };
