import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './BlockTitle.module.scss';

export interface BlockTitleProps {
    heading: string;
}

const BlockTitle = ({ heading }: BlockTitleProps) => {
    const { t } = useTranslation();

    return (
        <div className={styles.blocktitle}>
            <h3>{t(heading)}</h3>
        </div>
    );
};

export default BlockTitle;
export { BlockTitle };
