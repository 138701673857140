import { bbox, dimensions } from '@/components/organisms/ImageViewer/ImageMapper';

export type CalculateFishWoundingBoxReturn = {
    leftProsent: number;
    topProsent: number;
    PoiWidth: number;
    PoiHeight: number;
    meta: {
        tag: string;
        size_x_mm?: number;
        size_y_mm?: number;
        score?: number;
    };
    tag: string;
};

interface formatTooltipProps {
    meta: {
        tag: string;
        score: number;
        size_x_mm?: number;
        size_y_mm?: number;
    };
    instanceKey: string;
    isOptoScaleAdmin?: boolean;
    t: any; //UseTranslationResponse<'translation', undefined>;
}

// Use confidence score for lice detections Ex adult female 85% and no box boder
export const formatTooltip = ({
    meta,
    instanceKey = 'default',
    t,
    isOptoScaleAdmin = false,
}: formatTooltipProps) => {
    const { tag, score, size_x_mm, size_y_mm } = meta;

    if (size_x_mm === undefined || size_y_mm === undefined || score === undefined) {
        return '';
    }

    if (instanceKey !== 'licedetections') {
        return `${t(tag)} - ${((size_x_mm * size_y_mm) / 100).toFixed(2)}cm²`;
    }
    if (isOptoScaleAdmin && instanceKey === 'licedetections') {
        return `${t(tag)} - ${(score * 100).toFixed(0)}%`;
    }
    if (instanceKey === 'licedetections') {
        return `${t(tag)}`;
    }

    return '';
};

interface CalculateFishWoundingBoxProps {
    dimensions: dimensions;
    bbox: bbox;
}

export function CalculateFishWoundingBox({
    bbox,
    dimensions,
}: CalculateFishWoundingBoxProps): CalculateFishWoundingBoxReturn {
    const imgWidth = dimensions.width;
    const imgHeight = dimensions.height;
    const [topX, topY, bottomX, bottomY] = bbox.bbox;
    const { tag, size_x_mm, size_y_mm, score } = bbox;

    const fromTop = topY;
    const fromLeft = topX;

    const leftProsent = (fromLeft * 100) / imgWidth;
    const topProsent = (fromTop * 100) / imgHeight;
    const PoiWidth = ((bottomX - topX) * 100) / imgWidth;
    const PoiHeight = ((bottomY - topY) * 100) / imgHeight;

    return {
        leftProsent,
        topProsent,
        PoiWidth,
        PoiHeight,
        meta: {
            tag,
            size_x_mm,
            size_y_mm,
            score,
        },
        tag,
    };
}
