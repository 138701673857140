import { useState } from 'react';

import Notice from './Notice';

interface NoticeContainerProps {
    enableNotice: boolean;
    noticeId: string;
    heading: string;
    message: any;
    showCloseButton?: boolean;
    variant: 'default' | 'warning';
}

const NoticeContainer = ({
    enableNotice,
    noticeId,
    heading,
    message,
    showCloseButton,
    variant,
}: NoticeContainerProps) => {
    const [showNotice, setShowNotice] = useState(() => {
        const isNoticeHidden = localStorage.getItem(`isNotice${noticeId}Hidden`);
        if (!enableNotice) {
            // If enableNotice is false, remove the flag
            localStorage.removeItem(`isNotice${noticeId}Hidden`);
            return false;
        }
        if (isNoticeHidden === 'true') {
            return false;
        }
        return enableNotice;
    });

    const handleCloseNotice = () => {
        localStorage.setItem(`isNotice${noticeId}Hidden`, 'true');
        setShowNotice(false);
    };

    return (
        <>
            {showNotice && (
                <Notice
                    heading={heading}
                    message={message}
                    showCloseButton={showCloseButton}
                    onClose={handleCloseNotice}
                    variant={variant}
                />
            )}
        </>
    );
};

export default NoticeContainer;
export { NoticeContainer };
