import React, { useEffect, useLayoutEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ReactImageMagnify from 'react-image-magnify';
import moment from 'moment-timezone';

import CarouselControlBar from '../CarouselControlBar/CarouselControlBar';

import styles from './ZoomInImages.module.scss';

const WIDTH_FACTOR = 2.11;
const FULLSCREEN_WIDTH_FACTOR = 1.61;

/* Get Window width */
function getWindowDimensions() {
    const { innerWidth: width, innerHeight: height } = window;
    return {
        width,
        height,
    };
}

export interface ZoomInImagesProps {
    images: {
        timeStamp: string;
        path: string;
        bboxes: {
            tag: string;
            bbox: number[];
            size_x_mm?: number;
            size_y_mm?: number;
            score: number;
        }[];
    }[];
    toggleScreen: boolean;
    showThumbnailImages?: boolean;
    showBoxes?: boolean;
    imgIndex: number;
    setImgIndex: (number) => void;
}

const ZoomInImages: React.Factory<ZoomInImagesProps> = ({
    images,
    toggleScreen,
    showThumbnailImages = false,
    imgIndex,
    showBoxes = false,
    setImgIndex,
}) => {
    if (images[imgIndex] === undefined) {
        return;
    }

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());
    const [componentRefWidth, setComponentRefWidth] = useState(0);
    const [objectURL, setObjectURL] = useState(images[imgIndex].path);
    const [, setPromise] = useState(Promise.resolve<string>(null));

    const promisedImage = useMemo(async () => {
        const image = images[imgIndex];
        if (!showBoxes || image.bboxes.length === 0) {
            return image.path;
        }
        const img = document.createElement('img');
        img.id = 'imgId';
        img.src = image.path;
        img.width = 1;
        img.height = 1;
        img.crossOrigin = 'use-credentials';

        await new Promise((resolve) => img.addEventListener('load', resolve));

        const canvas = document.createElement('canvas');
        canvas.width = img.naturalWidth;
        canvas.height = img.naturalHeight;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0);

        for (const box of image.bboxes) {
            ctx.lineWidth = 2;
            ctx.strokeStyle = 'red';
            ctx.globalAlpha = 0.4;
            ctx.beginPath();
            if (ctx.roundRect === undefined) {
                ctx.rect(
                    box.bbox[0],
                    box.bbox[1],
                    box.bbox[2] - box.bbox[0],
                    box.bbox[3] - box.bbox[1]
                );
            } else {
                ctx.roundRect(
                    box.bbox[0],
                    box.bbox[1],
                    box.bbox[2] - box.bbox[0],
                    box.bbox[3] - box.bbox[1],
                    4
                );
            }
            ctx.stroke();
        }

        const blob = await new Promise<Blob>((resolve) => canvas.toBlob(resolve));

        const url = URL.createObjectURL(blob);
        canvas.remove();
        img.remove();
        return url;
    }, [images, imgIndex, showBoxes]);

    useEffect(() => {
        setPromise(async (prevstate) => {
            const oldUrl = await prevstate;
            const newUrl = await promisedImage;
            setObjectURL(newUrl);
            URL.revokeObjectURL(oldUrl);
            return newUrl;
        });
    }, [promisedImage]);

    useLayoutEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions());
        }

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, [componentRefWidth]);

    const NiceTimestamp = ({ timestamp }) => {
        const { t } = useTranslation();
        const niceTimestamp = timestamp
            ? moment(timestamp).format(t('dateAndTimeFormat', 'YYYY-MM-DD HH:mm:ss'))
            : null;

        return (
            <div className="pb-1" title={timestamp}>
                {niceTimestamp}
            </div>
        );
    };
    const previous = () => {
        imgIndex === 0 ? setImgIndex(images.length - 1) : setImgIndex(imgIndex - 1);
    };
    const next = () => {
        imgIndex === images.length - 1 ? setImgIndex(0) : setImgIndex(imgIndex + 1);
    };
    const getWidthFactor = () => {
        const a = toggleScreen ? WIDTH_FACTOR : FULLSCREEN_WIDTH_FACTOR;
        return a;
    };
    return (
        <div
            ref={(componentRef) => setComponentRefWidth(componentRef?.clientWidth)}
            className="zoom-in-image-wrapper">
            <div className={styles.left}>
                <NiceTimestamp timestamp={moment(images[imgIndex].timeStamp).valueOf() ?? null} />
                <div className={styles.left_2}>
                    <CarouselControlBar
                        showNextSingle={false}
                        showNextDouble={false}
                        showPreviousDouble={false}
                        onClickPreviousHandler={previous}
                        className={styles.CarouselControlBar_left}
                    />
                    <ReactImageMagnify
                        {...{
                            style: {
                                width: `${660}px`,
                                height: `${300}px`,
                            },
                            smallImage: {
                                alt: '',
                                width: windowDimensions.width / getWidthFactor(),
                                height: ((windowDimensions.width / getWidthFactor()) * 1) / 2,
                                src: objectURL,
                            },
                            largeImage: {
                                src: objectURL,
                                width: 3050,
                                height: 1500,
                            },
                            enlargedImageContainerStyle: {
                                top: '50%',
                                transform: 'translateY(-50%)',
                            },
                            enlargedImageContainerDimensions: {
                                width: '50%',
                                height: '80%',
                            },
                        }}
                    />
                    <CarouselControlBar
                        showPreviousSingle={false}
                        showPreviousDouble={false}
                        showNextDouble={false}
                        onClickNextHandler={next}
                        className={styles.CarouselControlBar_right}
                    />
                </div>
                {showThumbnailImages && (
                    <div className={styles.left_1}>
                        {images.map((image, i) => (
                            <div
                                className={
                                    i === imgIndex
                                        ? `${styles.img_wrap} ${styles.active}`
                                        : styles.img_wrap
                                }
                                key={i}
                                onClick={() => setImgIndex(i)}>
                                <img src={image.path} width="200" alt="" />
                            </div>
                        ))}
                    </div>
                )}
            </div>
            <div className={styles.right} />
        </div>
    );
};

export default ZoomInImages;
