import React, { useState } from 'react';
import {
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';

import { Button } from '@/components/atoms/Buttons';
import { CreateNewClientInput } from '@/services/types';
export interface NewClientProps {
    onSubmitted: (ev: CreateNewClientInput) => void;
}
const ModalAddClient = ({ onSubmitted }) => {
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);

    const [formData, setFormData] = useState<{
        name: string;
    }>();

    function updateForm<T extends keyof CreateNewClientInput>(key: T) {
        return (value: CreateNewClientInput[T]) =>
            setFormData((formData) => ({ ...formData, [key]: value }));
    }

    const isValid = !!formData?.name;
    const onFormDataOnSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
        if (isValid) {
            onSubmitted({
                name: formData.name,
            });
            setOpen(false);
        }
        ev.preventDefault();
    };
    return (
        <>
            <Button
                id="openAddClientModal"
                data-testid="openAddClientModal"
                onClick={() => setOpen(true)}
                className={'mr-2'}>
                {'Add Client'}
            </Button>
            <Modal isOpen={open} toggle={toggle}>
                <ModalHeader toggle={toggle}>Add New Client</ModalHeader>
                <Form onSubmit={onFormDataOnSubmit}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="license">Client Name</Label>
                            <Input
                                id="ContractDescription"
                                name="ContractDescription"
                                type="text"
                                value={formData?.name ?? ''}
                                onChange={(ev) => updateForm('name')(ev.target.value)}
                                placeholder={''}
                                required={false}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit" disabled={!isValid}>
                            {'Add'}
                        </Button>{' '}
                        <Button color="secondary" onClick={() => setOpen(false)}>
                            {'Cancel'}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};

export default ModalAddClient;
export { ModalAddClient };
