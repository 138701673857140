import React from 'react';
import { withTranslation } from 'react-i18next';
import {
    Alert,
    Button,
    FormFeedback,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import PropTypes from 'prop-types';

import Loader from '../../../../../components/atoms/Loader';

class AddBioscope extends React.Component {
    constructor() {
        super();
        this.state = {
            clientId: '',
            bioscopeId: '',
            errors: [],
            saving: false,
        };
    }

    getError(key) {
        const error = this.state.errors.find((e) => e.key === key);

        return error ? error.message : false;
    }

    onSubmit(event) {
        this.setState({ saving: true });
        this.props
            .createBioscope(this.state.clientId, this.state.bioscopeId)
            .then(() => {
                this.setState({ saving: false });
                this.props.onClose();
            })
            .catch((error) => {
                if (error.error?.messages) {
                    this.setState({
                        errors: error.error.messages,
                        saving: false,
                    });
                } else {
                    this.setState({
                        error: 'Something happened',
                        saving: false,
                    });
                }
            });

        event.preventDefault();
    }

    buildContent() {
        const bioscopeIdError = this.getError('bioscopeId');

        return (
            <React.Fragment>
                <FormGroup>
                    <Label>Id</Label>
                    <Input
                        type="number"
                        name="id"
                        placeholder={this.props.t('Auto generate id')}
                        value={this.state.bioscopeId}
                        onChange={(e) => {
                            this.setState({
                                bioscopeId: e.target.value,
                            });
                        }}
                        invalid={!!bioscopeIdError}
                    />
                    {bioscopeIdError ? <FormFeedback>{bioscopeIdError}</FormFeedback> : ''}
                </FormGroup>
                <FormGroup>
                    <Label>{this.props.t('Client')}</Label>
                    <Input
                        type="select"
                        name="clientId"
                        value={this.state.clientId}
                        onChange={(e) => {
                            this.setState({
                                clientId: e.target.value,
                            });
                        }}>
                        <option value="">{this.props.t('None')}</option>
                        {this.props.clients.map((location) => (
                            <option key={location.id} value={location.id}>
                                {location.name}
                            </option>
                        ))}
                    </Input>
                </FormGroup>
                {this.state.error ? <Alert color="danger">{this.state.error}</Alert> : ''}
            </React.Fragment>
        );
    }

    render() {
        return (
            <Modal isOpen={this.props.isOpen} toggle={this.props.onClose}>
                <ModalHeader toggle={this.props.onClose}>
                    {this.props.t('Add bioscope')}
                </ModalHeader>
                <ModalBody>{this.buildContent()}</ModalBody>
                <ModalFooter>
                    {this.state.saving ? (
                        <div className="mr-2">
                            <Loader size={'30px'} />
                        </div>
                    ) : null}
                    <Button color="primary" onClick={() => this.onSubmit()}>
                        Lagre
                    </Button>{' '}
                    <Button color="secondary" onClick={this.props.onClose}>
                        Cancel
                    </Button>
                </ModalFooter>
            </Modal>
        );
    }
}

AddBioscope.propTypes = {
    isOpen: PropTypes.bool.isRequired,
    clients: PropTypes.array.isRequired,
    createBioscope: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default withTranslation()(AddBioscope);
