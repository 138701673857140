import React from 'react';
import { Row } from 'reactstrap';

import keyNumberGroupContext from '../../../../contexts/keyNumberGroupContext';

import styles from './keyNumberGroup.module.scss';

export default ({ children, numberOfColumns }) => {
    const elements = React.Children.toArray(children);

    return (
        <keyNumberGroupContext.Provider
            value={{
                numberOfColumns,
            }}>
            {elements
                .reduce((groups, element, index) => {
                    const groupIndex = Math.floor(index / numberOfColumns);

                    if (index % numberOfColumns === 0) {
                        groups.push([]);
                    }

                    groups[groupIndex].push(element);

                    return groups;
                }, [])
                .map((row, index) => (
                    <Row className={styles.keyNumberRow} key={index}>
                        {row}
                    </Row>
                ))}
        </keyNumberGroupContext.Provider>
    );
};
