import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { DropdownItem, Input } from 'reactstrap';

import styles from '../ClientNav.module.scss';

interface ClientListProps {
    clients: {
        id: number;
        name: string;
    }[];
}

export const ClientList = ({ clients }: ClientListProps) => {
    const { t } = useTranslation();
    const [filter, setFilter] = useState('');

    const filteredClients = clients
        .filter((client) => client.name.toLowerCase().includes(filter.toLowerCase()))
        .sort((a, b) => a.name.localeCompare(b.name));

    const placeholderText = `${t('Filter')} ${t('Clients').toLowerCase()}`;
    return (
        <>
            <legend className={styles.searchInput}>
                <label>{placeholderText}</label>
                <Input
                    placeholder={placeholderText}
                    type="text"
                    onChange={(e) => {
                        setFilter(e.target.value);
                    }}
                />
            </legend>
            {filteredClients.map((client, index) => (
                <DropdownItem
                    className={styles.dropdownItem}
                    key={client.id}
                    tag={Link}
                    to={`/c/${client.id}`}>
                    {client.name}
                </DropdownItem>
            ))}
        </>
    );
};
export default ClientList;
