import { useTranslation } from 'react-i18next';

import BlockTitle from '@/components/atoms/BlockTitle';
import NoComponentDataAvailable from '@/components/atoms/NoComponentDataAvailable';
import UmerBox from '@/components/atoms/UmerBox';
import PageHeaderBar from '@/components/molecules/PageHeaderBar';
import { ImageState } from '@/hooks/image-state';
import { ImageViewContainer } from 'components/organisms/ImageViewer';

import { CagePageTitle } from '../../components/CagePageTitle';

export interface ImagesPageContentProps {
    source: string;
    detectionImageState: ImageState;
    bioscopeImageState: ImageState;
    healthType?: string;
    isOptoscaleAdmin?: boolean;
    cageId?: number;
}

const ImagePageContent = ({
    detectionImageState,
    bioscopeImageState,
    source,
    isOptoscaleAdmin = false,
}: ImagesPageContentProps) => {
    const { t } = useTranslation();

    const isColor = source === 'color';

    return (
        <>
            <PageHeaderBar>
                <CagePageTitle />
            </PageHeaderBar>

            <UmerBox>
                <BlockTitle heading={t('Catch of the Day')} />
                {detectionImageState.dayswithimages.length === 0 ? (
                    <NoComponentDataAvailable />
                ) : (
                    <ImageViewContainer
                        key="detections"
                        instanceKey="detections"
                        isColor={isColor}
                        isOptoScaleAdmin={isOptoscaleAdmin}
                        viewerConfig={{
                            showMagnifyer: true,
                            scrollActivated: true,
                            showPois: false,
                            zoomDisabled: true,
                            showWoundLabel: false,
                            magnifyerDisabled: false,
                        }}
                        {...detectionImageState}
                    />
                )}
            </UmerBox>
            <UmerBox>
                <BlockTitle heading={t('Bioscope inspection')} />
                {bioscopeImageState.dayswithimages.length === 0 ? (
                    <NoComponentDataAvailable />
                ) : (
                    <ImageViewContainer
                        key="bioscope"
                        instanceKey="bioscope"
                        isOptoScaleAdmin={isOptoscaleAdmin}
                        isColor={isColor}
                        viewerConfig={{
                            showMagnifyer: false,
                            scrollActivated: true,
                            showPois: false,
                            zoomDisabled: true,
                            magnifyerDisabled: true,
                            showWoundLabel: false,
                        }}
                        {...bioscopeImageState}
                    />
                )}
            </UmerBox>
        </>
    );
};

export default ImagePageContent;
export { ImagePageContent };
