import React from 'react';

import { FormattedOverviewProvider } from '../Overview2/formatted-overview-context';
import { GraphDataProvider } from '../Overview2/graph-data-context';
import { OverviewProvider } from '../Overview2/overview-context';

import Graphs from './Graphs';

function GraphContainer() {
    return (
        <OverviewProvider>
            <FormattedOverviewProvider>
                <GraphDataProvider>
                    <Graphs />
                </GraphDataProvider>
            </FormattedOverviewProvider>
        </OverviewProvider>
    );
}

export default GraphContainer;
