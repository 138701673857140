import * as ChartDataLabels from 'chartjs-plugin-datalabels';
import * as Zoom from 'chartjs-plugin-zoom';

import AutoGraphTimeUnit from 'components/Chart/plugins/AutoGraphTimeUnit';
import AutoIntegerUnit from 'components/Chart/plugins/AutoIntegerUnit';
import AutoZoomLimits from 'components/Chart/plugins/AutoZoomLimits';
import AutoZoomOutLimit from 'components/Chart/plugins/AutoZoomOutLimit';
import DoubleClickResetZoom from 'components/Chart/plugins/DoubleClickResetZoom';
import DrawBackground from 'components/Chart/plugins/DrawBackground';
import ZoomInLimit from 'components/Chart/plugins/ZoomInLimit';

export default [
    Zoom,
    DoubleClickResetZoom,
    DrawBackground,
    AutoGraphTimeUnit,
    AutoIntegerUnit,
    ZoomInLimit,
    AutoZoomOutLimit,
    AutoZoomLimits,
    ChartDataLabels,
];
