import React, { useMemo } from 'react';
import MeContext from 'contexts/meContext';
import { useUserController_Me } from 'services/hooks';

interface AuthProviderProps {
    children?: any;
}

function AuthProvider({ children }: AuthProviderProps) {
    const {
        isLoading: loading,
        error,
        data: rawData,
    } = useUserController_Me({
        refetchOnWindowFocus: false,
        retry: (count, error) => {
            if (count >= 5) {
                return false;
            }
            if ((error as any).response?.status === 401) {
                return false;
            }
            return true;
        },
    });
    const data = rawData?.data;

    const value = useMemo(() => {
        const user = data?.user ?? undefined;
        const clients =
            data?.clients?.map((c) => ({
                role: c.role,
                authLicenses: c.authLicenses,
                ...c.client,
            })) ?? [];

        const isSuper = user?.systemRole === 'ADMIN';
        const state = {
            isAuthenticated: !!user,
            isAuthenticating: loading,
            user,
            clients,
        };

        const isClientAdmin = (clientId) => {
            if (isSuper) return true;
            const client = clients.some(
                (client) => client.id === clientId && client.role === 'ADMIN'
            );

            return !!client;
        };

        const hasUserFeature = (flags, opts) => {
            // check if admin has super features
            if (isSuper && !opts?.noSuper) return true;

            if (!Array.isArray(flags)) flags = [flags];
            return flags.every((flag) => user?.featureFlags?.indexOf(flag) >= 0);
        };

        const canMoveBioscopes = (clientId: number) => isSuper || isClientAdmin(clientId);

        return {
            state,
            isSuper,
            isClientAdmin,
            hasUserFeature,
            canMoveBioscopes,
            loading,
            error,
            isOptoscaleAdmin: isSuper, // backwards compatibility
        };
    }, [data, error, loading]);

    return <MeContext.Provider value={value}>{children}</MeContext.Provider>;
}

export default AuthProvider;
