import React, { useEffect, useState } from 'react';
import { Card, CardBody } from 'reactstrap';

import FullScreenContext from '../../../../contexts/fullScreenContext';

import styles from './fullScreenCard.module.scss';

export default ({ children, scrollEnable = false }) => {
    const [fullScreen, setFullScreen] = useState(false);

    useEffect(() => {
        if (fullScreen) {
            document.body.classList.add('modal-open');
        } else {
            document.body.classList.remove('modal-open');
        }

        return () => {
            document.body.classList.remove('modal-open');
        };
    }, [fullScreen]);

    const toggleFullScreen = () => {
        setFullScreen(!fullScreen);
    };

    return (
        <FullScreenContext.Provider value={{ fullScreen, toggleFullScreen }}>
            <Card
                style={{ border: '0px' }}
                className={
                    fullScreen
                        ? scrollEnable
                            ? styles.fullScreen_scrollEnable
                            : styles.fullScreen
                        : null
                }>
                <CardBody
                    style={{ padding: '0px' }}
                    className={fullScreen ? styles.noPadding : null}>
                    {children}
                </CardBody>
            </Card>
        </FullScreenContext.Provider>
    );
};
