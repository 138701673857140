import moment from 'moment';

import parseTs from './parseTs';

function tsToString(ts, opts) {
    const parsed = parseTs(ts, opts);
    if (parsed === null || parsed === undefined) return parsed;

    return moment(parsed).toISOString();
}

export default tsToString;
