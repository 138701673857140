import { createContext, useContext } from 'react';
import { Me } from 'services/types';

const INITIAL_ME_CONTEXT = {
    isOptoscaleAdmin: false,
    state: {
        isAuthenticated: false,
        isAuthenticating: true,
        user: null as Me['user'] | null,
        clients: [] as {
            id: number;
            name: string;
            role: string;
            locations: {
                id: number;
                name: string;
            }[];
            authLicenses: string[];
        }[],
    },
    isClientAdmin: (_clientId: number) => false,
    hasUserFeature: (_flags: string | string[], _opts?: { noSuper: boolean }) => false,
    canMoveBioscopes: (clientId: number) => false,
    loading: false as boolean,
    error: undefined as any,
};

const MeContext = createContext(INITIAL_ME_CONTEXT);

function useMe() {
    return useContext(MeContext);
}

export default MeContext;
export { useMe };
