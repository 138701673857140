import type { ChartOptions } from 'chart.js';

import { toFixedNum } from '@/utils';

type OptoPieChartOptions = ChartOptions<'pie'>;

const defaultOptoPieChartOptions: OptoPieChartOptions = {
    layout: {
        padding: 16,
    },
    plugins: {
        legend: {
            display: false,
        },
        tooltip: {
            enabled: true,
            position: 'nearest',
            callbacks: {
                label: (context) => {
                    const formattedValue = toFixedNum(
                        Number.parseFloat(context.formattedValue.replaceAll(',', '.')),
                        1
                    );
                    return `${context.label} : ${`${formattedValue}%` || ''}`;
                },
                title: (context) => {
                    const timeObj = context[0].raw as { x: number; y: number };
                    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    return Intl.DateTimeFormat(navigator.language, {
                        month: 'short',
                        day: 'numeric',
                        timeZone: timeZone,
                    }).format(timeObj.x);
                },
            },
            // Using optoScales own tooltip handler
            // external: externalTooltipHandler,
        },
        title: {
            display: false,
            text: 'Scene Labels',
        },
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        htmlLegend: {
            containerClass: 'legend-container',
            disabled: false,
            displayLegendValue: true,
            position: 'top',
            showLegend: true,
        },
    },
};

export default defaultOptoPieChartOptions;
export { defaultOptoPieChartOptions };
