import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';

import { COLOR_NEUTRAL, getIcon } from '../utils';

import styles from '../FishHealth.module.scss';

function StatusIcon({ status, withDescription = false }) {
    const { t } = useTranslation();

    const Icon = getIcon(status.op);
    const color = status.color || COLOR_NEUTRAL;
    const title = Number.isNaN(status.diff)
        ? `${t(status.description)}`
        : `${t('Difference')}: ${status.diff.toFixed(2)}`;

    return (
        <div title={title}>
            <span className={styles.icon} style={{ color }}>
                <Icon />
            </span>
            <span className={cn('pl-3', styles.text)}>{t(status.text)}</span>
            {withDescription && (
                <span className={cn('pl-3', status.description)}>{t(status.description)}</span>
            )}
        </div>
    );
}

export default StatusIcon;
