import { getI18n } from 'react-i18next';
import { DISPLAY_LONG_DAY_FORMAT } from 'utils/formattedDay';
import isNumberOk from 'utils/isNumberOk';
import toFixed from 'utils/toFixed';

export default (graphLabels) => {
    const i18n = getI18n();
    return {
        elements: {
            point: {
                radius: 1,
            },
        },
        tooltips: {
            mode: 'index',
            axis: 'x',
            intersect: false,
            callbacks: {
                label: (tooltipItem, data) => {
                    const label = data.datasets?.[tooltipItem.datasetIndex]?.label ?? '';

                    const value = toFixed(tooltipItem.yLabel, 1);
                    const status = data.statuses[tooltipItem.datasetIndex][tooltipItem.index];
                    const count = status.head.count;
                    const totalCount = status.head.totalCount;
                    if (!isNumberOk(value)) {
                        return label;
                    }
                    if (label === 'Wound' || label === 'Sår')
                        return `${i18n.t(label)}: ${value}% ${i18n.t('on')} ${totalCount} ${i18n.t(
                            'fish'
                        )}`;
                    if (label === 'Mature_jaw')
                        return `${i18n.t(
                            'imageTags.fish_health.tags.mature_jaw'
                        )}: ${value}% (${count}/${totalCount})`;
                    return `${label}: ${value}`;
                },
            },
        },
        hover: {
            mode: 'index',
            axis: 'x',
            intersect: false,
        },
        scales: {
            xAxes: [
                {
                    type: 'time',
                    time: {
                        tooltipFormat: DISPLAY_LONG_DAY_FORMAT,
                    },
                },
            ],
            yAxes: [
                {
                    id: 'y-axis-0',
                    scaleLabel: {
                        display: true,
                        labelString: i18n.t(`imageTags.fish_health.tags.${graphLabels[0]}`),
                        fontSize: 20,
                    },
                    ticks: {
                        min: 0,
                        callback: (value) => `${value}%`,
                    },
                },
                {
                    id: 'y-axis-1',
                    position: 'right',
                    gridLines: {
                        color: 'rgba(0, 0, 0, 0)',
                    },
                    scaleLabel: {
                        display: true,
                        labelString: i18n.t('Temperature'),
                        fontSize: 20,
                    },
                    ticks: {
                        max: 25,
                        min: 0,
                        callback: (value) => `${value}°C`,
                    },
                },
            ],
        },
    };
};
