import type React from 'react';
import { useRef } from 'react';
import { Alert } from 'reactstrap';
import clsx from 'clsx';
import { useMe } from 'contexts/meContext';
import useMyTranslation from 'hooks/useMyTranslation';
import type { FullWelfareColorResult } from 'services/types';
import { COLOR_NEUTRAL, formatNumber, getIcon, getTitle } from 'utils';

import { Box } from 'components/Layout';
import { WoundDescription } from 'components/molecules/WoundDescription';

import ImageSlider from '../../components/ImageSlider';

import ColorWelfareStatus from './components/ColorWelfareStatus';
import { OneGraph } from './components/OneGraph';
import ScrollToDivButton from './components/ScrollToDivButton';

import styles from './FishHealthColor.module.scss';

const COLOR_WELFARE_INDICATOR_LABELS = ['wound', 'skin speckles', 'Mature jaw'];

function LabelValue(status) {
    const { t } = useMyTranslation();

    const percentage = formatNumber(status.tail.percentage, {
        suffix: '%',
        decimals: 1,
    });
    const diff = formatNumber(status.diff, {
        suffix: '%',
        decimals: Math.abs(status.diff) < 10 ? 1 : 0,
    });

    const Icon = getIcon(status.op);
    const color = status.color || COLOR_NEUTRAL;

    return (
        <>
            {percentage}
            <span
                title={status.diff ? `${t('Week change')}: ${status.diff.toFixed(2)}` : ''}
                className="pl-2">
                (
                {(status.op > 0 || status.op < 0) && (
                    <span className={clsx('pr-2', styles.icon)} style={{ color }}>
                        <Icon />
                    </span>
                )}
                {diff})
            </span>
        </>
    );
}

function formatLabel(label, description, status, opts = {}) {
    return {
        text: getTitle(label),
        value: () => LabelValue(status),
        description: description,
        title: status.tail.percentage
            ? ['Weekly average', ': ', status.tail.percentage.toFixed(2)]
            : undefined,
    };
}
function formatNumberOfWoundLabel(label, status, opts = {}) {
    return {
        text: getTitle(label),
        value: () => LabelValue(status),
        title: status.tail.percentage
            ? ['Weekly average', ': ', status.tail.percentage.toFixed(2)]
            : undefined,
        onlyValue: status?.tail?.percentage ?? [],
    };
}
function graphData(title, labels, description, showTotal, getStatus, barometerStyle) {
    if (!labels || typeof getStatus !== 'function') {
        return {};
    }
    let formattedWoundNumberLabels = [];
    if (barometerStyle) {
        formattedWoundNumberLabels = labels.map((label) => {
            return formatNumberOfWoundLabel(label, getStatus(label));
        });
        const adjustedNoWounds =
            100 -
            (formattedWoundNumberLabels[1].onlyValue + formattedWoundNumberLabels[2].onlyValue); // No wounds percentage calculation
        formattedWoundNumberLabels[0].onlyValue = adjustedNoWounds;
    }
    const formattedLabels = labels.map((label, index) => {
        return formatLabel(label, description[index], getStatus(label));
    });
    const extraFormattedLabels = [
        {
            text: getTitle('total'),
            value: formatNumber(getStatus('total').tail.totalCount),
        },
    ];

    const info = showTotal
        ? [...formattedLabels, ...extraFormattedLabels]
        : barometerStyle
          ? [...formattedWoundNumberLabels]
          : [...formattedLabels]; // no need to reformat if only one value

    // extra is not shown on graph
    return {
        title,
        labels,
        info,
        trendlines: labels.reduce((acc, cur) => {
            acc[cur] = true;
            return acc;
        }, {}),
    };
}

const useDivRefs = (COLOR_WELFARE_INDICATOR_LABELS) => {
    const divRefs = useRef({});

    const tmp = {};
    COLOR_WELFARE_INDICATOR_LABELS.forEach((label, i) => {
        const targetId = label.replace(/\s+/g, '');
        tmp[targetId] = divRefs.current[targetId] || { current: null };
    });
    divRefs.current = tmp;
    return divRefs.current as any;
};

interface FishHealthProps {
    data: FullWelfareColorResult['cage'];
    dataBW: any;
    getStatus: any;
    getStatusBW: any;
}

const FishHealth: React.Factory<FishHealthProps> = ({ data, dataBW, getStatus, getStatusBW }) => {
    const { t } = useMyTranslation();
    const { isOptoscaleAdmin } = useMe();
    const cageId = data?.head?.cageId;

    const list = data?.list;
    const listWithCountsAbove50 = list.filter((item) => item.count >= 50);
    const listBW = dataBW?.list;
    const listBWWithCountsAbove50 = listBW.filter((item) => item.count >= 50);
    const head = data?.head;
    let showTotal = true;
    let barometerStyle = false;

    const woundsGraphData = graphData(
        'Wounds',
        ['wound'],
        [''],
        showTotal,
        getStatus,
        barometerStyle
    );

    const maturationGraphData = graphData(
        'Maturation',
        ['mature jaw'],
        [''],
        showTotal,
        getStatusBW,
        barometerStyle
    );

    const colorWoundData = graphData(
        'Types of wounds',
        ['active wounds', 'healing wounds'],
        ['Active wounds description', 'Healing wounds description'],
        (showTotal = false),
        getStatus,
        barometerStyle
    );
    const woundClassesData = graphData(
        'Size distribution',
        ['big wound', 'medium wound', 'small wound'],
        ['> 50mm', '20mm - 50mm', '< 20mm'],
        (showTotal = false),
        getStatus,
        barometerStyle
    );
    const numberOfWoundsData = graphData(
        'Distribution of wounds',
        ['No wound', 'one wound', 'more than one wound'],
        [''],
        (showTotal = false),
        getStatus,
        (barometerStyle = true)
    );

    const newMaturtaionGraphData = graphData(
        'Color Maturation',
        ['fully mature', 'started maturation'],
        [''],
        (showTotal = false),
        getStatus,
        (barometerStyle = false)
    );

    const color_welfare_indicator_labels = [...COLOR_WELFARE_INDICATOR_LABELS];

    const divRefs = {
        detectionsSection: useRef(null),
    };
    const divRefsWelfareParam = useDivRefs(COLOR_WELFARE_INDICATOR_LABELS);

    const scrollToDiv = (targetId) => {
        const targetRef = divRefs[targetId] || divRefsWelfareParam[targetId];
        if (targetRef?.current) {
            targetRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    };

    if (!list.length || !listBW.length) {
        return <Alert color="warning">{t('No data')}</Alert>;
    }
    return (
        <>
            <ScrollToDivButton
                onClick={scrollToDiv}
                targetId="detectionsSection"
                buttonText={t('Images')}
                classNames={`${styles.imagesButton} ${'btn btn-primary float-right'}`}
            />
            <Box>
                <ColorWelfareStatus
                    labels={color_welfare_indicator_labels}
                    getStatus={getStatus}
                    getStatusBW={getStatusBW}
                    scrollToDiv={scrollToDiv}
                />
            </Box>
            <Box title={t('Wound description')}>
                <WoundDescription woundsColor={head as any} />
                <div ref={divRefsWelfareParam.wound} id="wound" />
            </Box>
            <Box>
                <OneGraph
                    {...colorWoundData}
                    type={'line'}
                    colors={['#ff7c6b', '#99C0D1', 'rgba(30, 110, 150, 0.2)']}
                    list={listWithCountsAbove50}
                    showTotalCounts={false}
                    showDiff={false}
                    stacked={false}
                    trendlines={false}
                />
            </Box>
            {!!woundsGraphData && (
                <>
                    <Box>
                        <OneGraph
                            {...woundClassesData}
                            type={'line'}
                            colors={[
                                'rgba(47, 131, 123, 0.5)',
                                'rgba(94, 197, 187, 0.7)',
                                'rgba(30, 110, 150, 0.2)',
                            ]}
                            list={listWithCountsAbove50}
                            showTotalCounts={false}
                            showDiff={false}
                            stacked={true}
                            trendlines={false}
                        />
                    </Box>
                    <Box>
                        <OneGraph
                            {...numberOfWoundsData}
                            type={'line'}
                            barometerStyle={true}
                            showTotalCounts={false}
                            showDiff={false}
                            list={listWithCountsAbove50}
                            stacked={false}
                            trendlines={false}
                            help={'Distribution of wounds help'}
                        />
                    </Box>
                </>
            )}
            <div ref={divRefsWelfareParam.Maturejaw} id="Maturejaw" />
            {!!maturationGraphData && (
                <Box>
                    {
                        <OneGraph
                            type={'line'}
                            list={listBWWithCountsAbove50}
                            {...maturationGraphData}
                            yAxisDefaultMax={1}
                        />
                    }
                </Box>
            )}
            <div ref={divRefs.detectionsSection} id="detectionsSection" />
            {
                <ImageSlider
                    title="Detections"
                    cageId={cageId}
                    type="color-welfare-image"
                    showBoxes={true}
                />
            }
            {!!isOptoscaleAdmin && (
                <>
                    <h3 style={{ color: '#547FA2' }}>
                        {t('Only visible for internal OptoScale admin users')}
                    </h3>
                    <hr />
                    {!!newMaturtaionGraphData && (
                        <Box>
                            <OneGraph
                                {...newMaturtaionGraphData}
                                type={'line'}
                                colors={['#ff7c6b', '#99C0D1']}
                                list={listWithCountsAbove50}
                                showTotalCounts={false}
                                showDiff={false}
                                stacked={false}
                                trendlines={false}
                                showHorizontalInfo={false}
                                yAxisDefaultMax={1}
                            />
                        </Box>
                    )}
                </>
            )}
        </>
    );
};

export default FishHealth;
