import { lazy, Suspense } from 'react';
import React from 'react';

import Loader from '@/components/atoms/Loader';

export function SuspendedLazy<T extends React.FunctionComponent<any>>(
    params: () => Promise<{ default: T }>
) {
    const LazyComponent = lazy<T>(params);

    return (props: Parameters<T>[0]) => {
        return (
            <Suspense fallback={<Loader />}>
                <LazyComponent {...props} />
            </Suspense>
        );
    };
}
