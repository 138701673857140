import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { MeasurmentOverviewResult } from 'services/types';

import { Box } from 'components/Layout';
import {
    defaultBarChartOptions,
    OptoBarChart,
    OverViewToBarChartMapper,
} from 'components/organisms/OptoGraphs';
import { dayTagValues } from 'components/organisms/OptoGraphs/commonOptoChartHelpers';
import Options, {
    chartTitle,
    weightAndLengthUnitConverter,
} from 'components/organisms/OptoGraphs/optionsModifiers';

// import MeasurementCount from '../../../components/graphs/MeasurementCount';
import useGraphData from '../graph-data-context';

function MeasurementCountBox() {
    const { t, i18n } = useTranslation();
    const { data, daypartTag } = useGraphData();

    function toLocale(language: string) {
        return language === 'nb' ? 'nb-NO' : language === 'es' ? 'es-ES' : 'en-US';
    }

    const [locale, setLocale] = useState('nb-NO');

    useEffect(() => {
        setLocale(toLocale(i18n.language));
    }, [i18n.language]);

    // Fetch chosen daytag from menu date time filter.
    const disabeledDayTags = dayTagValues.filter((dayTag) => dayTag !== daypartTag);
    // title={t('Measurements')}
    return (
        <Box data-tut="numberGraph" customStyleOuter={{ padding: 0 }}>
            <OptoBarChart
                chartName={t('Measurements')}
                chartData={OverViewToBarChartMapper({
                    overview: data as MeasurmentOverviewResult,
                    dayTags: [daypartTag],
                    inactiveSetLegend: [...disabeledDayTags],
                })}
                actionButtons={{
                    copyAsPng: true,
                    resetZoom: true,
                    downloadAsPng: true,
                    fullScreen: true,
                    timeScaleSwitch: false,
                }}
                chartOptions={Options(defaultBarChartOptions).modify(
                    chartTitle({ title: t('Measurements') }),
                    weightAndLengthUnitConverter({
                        locale: locale,
                        type: 'number',
                        maximumFractionDigits: 0,
                        minimumFractionDigits: 0,
                        toUnit: '',
                    })
                )}
                loading={false}
            />
        </Box>
    );
}

export default MeasurementCountBox;
export { MeasurementCountBox };
