import { max, mean, min } from 'lodash';

const SECOND = 1000;
const MINUTE = 60 * SECOND;
const HOUR = 60 * MINUTE;
const DAY = 24 * HOUR;

export default {
    beforeUpdate: (chart) => {
        if (chart.options.plugins.zoom) {
            const stepSize = chart.options.scales.yAxes[0].ticks.stepSize
                ? chart.options.scales.yAxes[0].ticks.stepSize
                : 1;

            const minX = chart.scales['x-axis-0'].min - DAY;
            const maxX = chart.scales['x-axis-0'].max + DAY;

            const { visibleYData, lineMedians } = chart.data.datasets.reduce(
                ({ visibleYData, lineMedians }, dataset, index) => {
                    if (chart.isDatasetVisible(index) && dataset.data.length > 0) {
                        const yData = dataset.data.reduce((yData, data) => {
                            if (
                                data.x >= minX &&
                                data.x <= maxX &&
                                data.y != null &&
                                !isNaN(data.y)
                            )
                                return yData.concat(parseInt(data.y));
                            return yData;
                        }, []);

                        const median =
                            (yData[Math.floor((yData.length - 1) / 2)] +
                                yData[Math.ceil((yData.length - 1) / 2)]) /
                            2;

                        return {
                            visibleYData: visibleYData.concat(yData),
                            lineMedians:
                                dataset.showLine && yData.length > 0
                                    ? lineMedians.concat(median)
                                    : lineMedians,
                        };
                    }
                    return { visibleYData, lineMedians };
                },
                { visibleYData: [], lineMedians: [] }
            );
            if (visibleYData.length > 1) {
                const minY = min(visibleYData);
                const maxY = max(visibleYData);
                const center = lineMedians.length > 0 ? mean(lineMedians) : mean(visibleYData);
                const padding = Math.max(center - minY, maxY - center);

                chart.options.scales.yAxes[0].ticks.max =
                    Math.ceil((center + padding) / (stepSize / 2)) * (stepSize / 2);
                chart.options.scales.yAxes[0].ticks.min =
                    Math.floor((center - padding) / (stepSize / 2)) * (stepSize / 2);
            }
        }
    },
};
