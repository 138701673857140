function checkLicense(license: { code: string }, patterns: string[]) {
    if (!license || !license.code) {
        return false;
    }

    return patterns.some((pattern) => {
        const re = new RegExp(pattern, 'i');
        if (!re.test(license.code)) {
            return false;
        }
        return true;
    });
}

function hasLicense(licenses: { code: string }[], patterns: string[]) {
    if (!patterns.length) {
        return true;
    }

    return licenses.some((license) => checkLicense(license, patterns));
}

export { hasLicense };
