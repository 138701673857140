import React from 'react';
import { Table } from 'reactstrap';

import styles from './CustomTable.module.scss';

interface TablesProps {
    className?: string;
    children?: React.ReactNode;
}

const CustomTable = ({ className, children }: TablesProps) => {
    return (
        <Table className={`${styles.Table} ${className ?? ''}`} hover responsive>
            {children}
        </Table>
    );
};

export default CustomTable;
export { CustomTable };
