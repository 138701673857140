import type React from 'react';

import { ArrowSoftIcon } from 'components/atoms/icons/Arrows/ArrowSoft';

import styles from './ImageViewer.module.scss';

export interface ImageControlProps {
    handleOnClick: React.MouseEventHandler<HTMLButtonElement>;
    children?: React.ReactNode;
}

const ImageControl = ({ children, handleOnClick }: ImageControlProps) => {
    return (
        <div className={styles.imageControl}>
            <button
                type="button"
                tabIndex={2}
                onClick={handleOnClick}
                className={styles.control_button}
                value={'previous'}>
                <ArrowSoftIcon />
            </button>
            <div>{children}</div>
            <button
                type="button"
                tabIndex={1}
                onClick={handleOnClick}
                className={styles.control_button}
                value={'next'}>
                <ArrowSoftIcon />
            </button>
        </div>
    );
};

export default ImageControl;
export { ImageControl };
