import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { CustomInput } from 'reactstrap';
import { orderBy } from 'lodash';
import formattedBucket from 'utils/formattedBucket';
import formattedDay from 'utils/formattedDay';
import { weightUnitUpscaler } from 'utils/formattedWeightUnit';
import toFixed from 'utils/toFixed';

import { Chart } from '@/components/Chart';

import useWeightDistribution from '../weight-distribution-context';

function WeightDistributionHistogram() {
    const { t } = useTranslation();
    const { histogram, weightUnitTag, day, distributionType, setDistributionType } =
        useWeightDistribution();

    const data = useMemo(() => {
        const sortedHistogram = orderBy(
            histogram ?? [],
            ['bucketFrom', 'bucketTo'],
            ['asc', 'asc']
        );

        const weightUnitScaler = weightUnitUpscaler(weightUnitTag);
        const formattedWeightUnit = t(`weightUnit.${weightUnitScaler.weightUnit}`);

        const labels = sortedHistogram.map((item) => {
            const formattedBucketFrom = weightUnitScaler.convert(item?.bucket?.from);
            const formattedBucketTo = weightUnitScaler.convert(item?.bucket?.to);

            return formattedBucket({
                from: formattedBucketFrom,
                to: formattedBucketTo,
                metric: formattedWeightUnit,
            });
        });

        return {
            formattedWeightUnit,
            labels,
            formattedDay: formattedDay(day),
            datasets: [
                {
                    label: t('Probability density'),
                    backgroundColor: '#3D5F7D',
                    borderColor: '#3D5F7D',
                    fill: false,
                    data: sortedHistogram.map((item) => item?.cntPct),
                },
            ],
            maxY: Math.max(...sortedHistogram.map((item) => item?.cntPct)),
        };
    }, [day, histogram, t, weightUnitTag]);

    const wantAgg = distributionType === 'agg';
    const toggleWantAgg = useCallback(
        () => setDistributionType(wantAgg ? 'avg' : 'agg'),
        [setDistributionType, wantAgg]
    );

    return (
        <>
            <h2>{t('Living weight')}</h2>
            <h2 className="text-center">{data.formattedDay}</h2>
            <CustomInput
                id="aggragation-switch"
                type="switch"
                label={t('Aggregated')}
                onChange={toggleWantAgg}
                checked={wantAgg}
                disabled={!Array.isArray(data.labels) || data.labels.length < 1}
            />
            <Chart
                type="bar"
                data={data}
                options={{
                    legend: {
                        display: false,
                    },
                    animation: false,
                    hover: {
                        mode: 'index',
                        intersect: false,
                    },
                    tooltips: {
                        mode: 'index',
                        intersect: false,
                        callbacks: {
                            label: (tooltipItem, data) => {
                                let label = data.datasets?.[tooltipItem.datasetIndex]?.label ?? '';

                                if (label) {
                                    label += ': ';
                                }

                                return `${label + toFixed(tooltipItem.yLabel, 3)}%`;
                            },
                        },
                    },
                    scales: {
                        yAxes: [
                            {
                                display: true,
                                ticks: {
                                    beginAtZero: true,
                                    max: Math.ceil(data?.maxY),
                                    callback: (value) => `${value}%`,
                                },
                            },
                        ],
                        xAxes: [
                            {
                                barPercentage: 1,
                                categoryPercentage: 1,
                            },
                        ],
                    },
                }}
            />
        </>
    );
}

export default WeightDistributionHistogram;
