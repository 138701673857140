import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { v4 as uuidv4 } from 'uuid';

import Poi from 'components/atoms/Poi';
import {
    CalculateFishWoundingBox,
    formatTooltip,
} from 'components/atoms/PoiInfoLayer/PoiInfoLayerFunctions';

import styles from './PoiInfoLayer.module.scss';
interface PoiInfoLayerProps {
    isColor?: boolean;
    path?: string;
    text?: string;
    lazyLoad?: boolean;
    alt?: string;
    time_stamp?: string;
    dimensions: {
        width: number;
        height: number;
    };
    bboxes?: {
        tag: string;
        bbox: number[];
        size_x_mm?: number;
        size_y_mm?: number;
        score: number;
    }[];
    poiPositionHandler?: (poiData, boundingRects) => void;
    showPoiMeta?: boolean;
    show: boolean;
    instanceKey?: string;
    isOptoScaleAdmin?: boolean;
}

const PoiInfoLayer = ({
    isColor = true,
    bboxes,
    dimensions,
    poiPositionHandler = () => {},
    showPoiMeta,
    show = true,
    instanceKey = 'default',
    isOptoScaleAdmin = false,
}: PoiInfoLayerProps) => {
    const { t } = useTranslation();

    let calculateFishWoundCords = [];
    if (!bboxes || bboxes.length > 0) {
        calculateFishWoundCords = bboxes?.map((bbox) => {
            return {
                ...CalculateFishWoundingBox({
                    bbox,
                    dimensions,
                }),
                score: bbox.score,
            };
        });
    }

    const poiRefCallback = useCallback(
        (node: HTMLDivElement) => {
            if (node !== null) {
                const poiDomreferenceObject = [...calculateFishWoundCords];
                const poiElements = node.querySelectorAll('div');
                poiElements.forEach((poiElement, index) => {
                    poiDomreferenceObject[index].poilayer = node.getBoundingClientRect();
                    poiDomreferenceObject[index].domRef = poiElement.getBoundingClientRect();
                    poiDomreferenceObject[index].toolTip =
                        poiElement.style.getPropertyValue('--mouseOverText');
                    poiElement.setAttribute('data-tag', poiDomreferenceObject[index].tag);
                    poiElement.setAttribute('data-type', instanceKey);
                });
                poiPositionHandler(poiDomreferenceObject, node.getBoundingClientRect());
            }
        },
        [calculateFishWoundCords, poiPositionHandler]
    );

    return (
        <div
            className={classNames(styles.poiinfolayer, show ? styles.show : '')}
            ref={poiRefCallback}>
            {showPoiMeta &&
                calculateFishWoundCords?.map((poiCords) => {
                    const id = uuidv4();
                    return (
                        <Poi
                            isColor={isColor}
                            showPoiMeta={showPoiMeta}
                            poiCords={poiCords}
                            toolTip={formatTooltip({
                                meta: poiCords.meta,
                                instanceKey,
                                t: t,
                                isOptoScaleAdmin,
                            })}
                            key={`${id}-poi`}
                            instanceKey={instanceKey}
                        />
                    );
                })}
        </div>
    );
};

export default PoiInfoLayer;
export { PoiInfoLayer };
