import React from 'react';
import { Spinner } from 'reactstrap';

import { Button } from 'components/atoms/Buttons';

import { useDownloadCSVXLSX } from '../hooks/download-CSV-XLSX';
export const DownloadCsvXlsx = ({ label, data, nameToInclude = undefined, COLUMNS = [] }) => {
    const { loadingdownloadCSV, loadingXLSX, downloadCSV, downloadXLSX } = useDownloadCSVXLSX(
        data,
        nameToInclude,
        COLUMNS
    );
    if (label === 'CSV') {
        return (
            <Button onClick={() => downloadCSV({ label, data })}>
                {loadingdownloadCSV ? <Spinner size="sm" /> : label}
            </Button>
        );
    }
    return (
        <Button onClick={() => downloadXLSX({ label, data })}>
            {loadingXLSX ? <Spinner size="sm" /> : label}
        </Button>
    );
};
