// remove `/` from the begining of the path
function stripUrlPathLeft(path) {
    return path ? String(path).replace(/^\//, '') : undefined;
}

// remove `/`, `?` or `/?` from the end of the path
function stripUrlPathRight(path) {
    return path
        ? String(path).replace(/\/$/, '').replace(/\?$/, '').replace(/\\?$/, '')
        : undefined;
}

function stripUrlPath(path) {
    return stripUrlPathLeft(stripUrlPathRight(path));
}

// joinUrlPath('/hello', '/world/', '/', 'how/are/you/') => '/hello/world/show/are/you'
function joinUrlPath(...parts) {
    const head = stripUrlPathRight(parts?.[0]);
    const tail = parts.slice(1).map((part) => stripUrlPath(part));
    return [head, ...tail].filter((part) => part?.length > 0).join('/');
}

export { joinUrlPath };
