import { useEffect, useRef } from 'react';

function useInterval(callback, delay) {
    const savedCallback = useRef();
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    useEffect(() => {
        function tick() {
            if (typeof savedCallback.current === 'function') savedCallback.current();
        }

        if (delay !== null && delay !== undefined) {
            const id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [callback, delay]);
}

export default useInterval;
