/**
 * @element opto-dash
 */
class OptoDash extends HTMLElement {
    connectedCallback() {
        this.render();
    }

    render() {
        this.innerHTML = `
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path fill-rule="evenodd" clip-rule="evenodd" d="M2 5.25H10V6.75H2V5.25Z" fill="currentColor"/>
        </svg>
    `;
    }
}

if (!customElements.get('opto-dash')) {
    customElements.define('opto-dash', OptoDash);
}

export { OptoDash };
