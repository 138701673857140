import isNumberOk from 'utils/isNumberOk';

// const x = toFixed(data?.somewhere?.something?.value, config?.if?.have?.decimals);
// <KeyNumber value={x} ifBad='-' />
function toFixed(value: number, decimals: number): string | number {
    if (!isNumberOk(value)) return NaN;

    if (decimals === undefined || decimals === null) return value;
    if (decimals === 0) return Math.round(value);
    return Number(value).toFixed(decimals);
}

function toFixedNum(value: number, decimals: number): number {
    return Number(toFixed(value, decimals));
}

function stringToFixedNum(value: string, decimals: number): number {
    return Number(toFixed(parseFloat(value), decimals));
}

export default toFixed;
export { toFixed, toFixedNum, stringToFixedNum };
