import React, { ReactNode } from 'react';
import { UncontrolledTooltip } from 'reactstrap';
import { uniqueId } from 'lodash';

interface SimpleToolTipProps {
    helpText: string | string;
    children?: ReactNode;
    placement?: 'top' | 'bottom' | 'left' | 'right';
}

export const SimpleToolTip = ({ helpText, children, placement = 'top' }: SimpleToolTipProps) => {
    const [id] = React.useState(uniqueId('tooltip'));

    return (
        <>
            <span id={id}>{children}</span>
            <UncontrolledTooltip placement={placement} target={id}>
                {helpText}
            </UncontrolledTooltip>
        </>
    );
};

export default SimpleToolTip;
