import { useTranslation } from 'react-i18next';
import moment from 'moment-timezone';
import { LastLocation } from 'services/types';

import { Box } from 'components/Layout';

import styles from './LastPlacement.module.scss';

interface LastPlacementProps {
    title: string;
    lastLocation: LastLocation;
}

const LastPlacement = ({ title, lastLocation }: LastPlacementProps) => {
    const { t } = useTranslation();

    return (
        <Box title={t(title)} customStyleOuter={{ borderRadius: '0px' }}>
            {!lastLocation ? (
                t('Non existent client')
            ) : (
                <table className={styles.bioscopeDetailsTable}>
                    <tbody>
                        {!lastLocation.cage ? (
                            <>
                                <tr>
                                    <th>Location</th>
                                    <td>{t('On land')}</td>
                                </tr>
                            </>
                        ) : (
                            <>
                                <tr>
                                    <th>{t('Facility')}</th>
                                    <td>{lastLocation.cage.location.name}</td>
                                </tr>
                                <tr>
                                    <th>{t('Cage')}</th>
                                    <td>{lastLocation.cage.name}</td>
                                </tr>
                            </>
                        )}
                        <tr>
                            <th>{t('From')}</th>
                            <td>{moment(lastLocation.from).format('DD.MM.YYYY [kl.] HH.mm')}</td>
                        </tr>
                    </tbody>
                </table>
            )}
        </Box>
    );
};

export default LastPlacement;
export { LastPlacement };
