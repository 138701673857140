import { useCallback, useEffect, useState } from 'react';
import { SingleDatePicker } from 'react-dates';
import { useTranslation } from 'react-i18next';
import { FormGroup, Input, Label } from 'reactstrap';
import moment from 'moment';
import { DISPLAY_DAY_FORMAT } from 'utils/formattedDay';

import { Button } from 'components/atoms/Buttons';

function CageFilterCompare({ onFormSubmit }) {
    const { t } = useTranslation();
    const dayparts = [
        {
            id: 1,
            tag: 'all',
        },
        {
            id: 2,
            tag: 'day',
        },
        {
            id: 3,
            tag: 'night',
        },
        {
            id: 4,
            tag: 'short_day',
        },
    ];

    const [fromFocused, setFromFocused] = useState();
    const [toFocused, setToFocused] = useState();
    const [from, setFrom] = useState(null);
    const [to, setTo] = useState(null);
    const [daypartTag, setDaypartTag] = useState('day');
    const handleFromFocusChange = useCallback((focused) => {
        setToFocused(false);
        setFromFocused(focused);
    }, []);

    const handleToFocusChange = useCallback((focused) => {
        setFromFocused(false);
        setToFocused(focused);
    }, []);

    useEffect(() => {
        onFormSubmit(from, to, daypartTag);
    }, [from, to, daypartTag, onFormSubmit]);

    const handleResetFilter = useCallback(() => {
        setFrom(null);
        setTo(null);
        setDaypartTag('day');
    }, []);

    /* console.log('firstDateMeasurement', firstDateMeasurement);
    console.log('from', from, moment('1999-01-01')); */
    const handleShowAllFilter = useCallback(() => {
        setFrom(moment('1999-01-01'));
        setTo(null);
    }, []);

    return (
        <div className="text-white">
            <div>
                <FormGroup>
                    <Label>{t('From')}</Label>
                    <div>
                        <SingleDatePicker
                            date={from}
                            placeholder={t('fromFilterPlaceholder')}
                            isOutsideRange={() => false}
                            onDateChange={setFrom}
                            focused={fromFocused}
                            onFocusChange={({ focused }) => handleFromFocusChange(focused)}
                            block={true}
                            numberOfMonths={1}
                            displayFormat={DISPLAY_DAY_FORMAT}
                            showClearDate={false}
                        />
                    </div>
                </FormGroup>
                <FormGroup>
                    <Label>{t('To')}</Label>
                    <div>
                        <SingleDatePicker
                            date={to}
                            placeholder={t('toFilterPlaceholder')}
                            isOutsideRange={() => false}
                            onDateChange={setTo}
                            focused={toFocused}
                            required
                            onFocusChange={({ focused }) => handleToFocusChange(focused)}
                            block={true}
                            numberOfMonths={1}
                            displayFormat={DISPLAY_DAY_FORMAT}
                            showClearDate={false}
                        />
                    </div>
                </FormGroup>
                <FormGroup>
                    <Label>{t('Day part')}</Label>
                    <Input
                        type="select"
                        name="selectDaypartFilter"
                        id="selectDaypartFilter"
                        value={daypartTag}
                        onChange={(e) => setDaypartTag(e.target.value)}>
                        {dayparts?.map((daypart) => (
                            <option key={daypart.id} value={daypart.tag}>
                                {t(`daypartTag.${daypart.tag}`)}
                            </option>
                        ))}
                    </Input>
                </FormGroup>
            </div>
            <Button
                filledBackground
                fullWidth
                color="secondary"
                className="mt-2"
                onClick={handleShowAllFilter}>
                {t('Show all')}
            </Button>
            <Button
                filledBackground
                fullWidth
                color="secondary"
                className="mt-2"
                onClick={handleResetFilter}>
                {t('Reset filters')}
            </Button>
        </div>
    );
}

export default CageFilterCompare;
