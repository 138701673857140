import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useResizeDetector } from 'react-resize-detector';
import VisibilitySensor from 'react-visibility-sensor';
import classNames from 'classnames';

import { SimpleToolTip } from 'components/atoms/SimpleToolTip';
import { CircularProgressBar } from 'components/molecules/CircularProgressBar';

import styles from './KeyNumberSummary.module.scss';

export interface KeyNumberSummaryProps {
    heading: string;
    keyNumber: number;
    variant?: 'animated' | 'default';
    children?: React.ReactNode;
    tooltip?: string;
    color?: string;
    unit?: string;
    fractionDigits?: number;
}

const KeyNumberSummary = ({
    children,
    heading,
    keyNumber,
    variant = 'default',
    tooltip,
    color = '#547FA2',
    unit = '%',
    fractionDigits = 1,
}: KeyNumberSummaryProps) => {
    const { t } = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const ref = useRef(null);
    const [circularSize, setCircularSize] = useState(150);

    useResizeDetector({
        handleWidth: true,
        targetRef: ref,
        onResize: (width) => {
            const size = width > 200 ? 150 : 100;
            setCircularSize(size);
        },
    });

    const onChange = (isVisible) => {
        setIsVisible(isVisible);
    };

    return (
        <div className={styles.keynumbersummary} data-variant={variant} ref={ref}>
            <h3>{t(heading)}</h3>
            {children}
            {variant === 'animated' ? (
                <figure>
                    <div
                        data-variant={variant}
                        className={classNames(
                            `animatedComponent ${isVisible ? 'fadeUnfold' : ''}`,
                            styles.svgresponsive
                        )}>
                        <VisibilitySensor onChange={onChange} partialVisibility>
                            <CircularProgressBar
                                color={color}
                                percentage={keyNumber}
                                size={circularSize}
                            />
                        </VisibilitySensor>
                    </div>
                </figure>
            ) : (
                <>
                    <div>
                        <span>{`${keyNumber.toFixed(fractionDigits)}${unit}`}</span>
                        {tooltip && (
                            <SimpleToolTip helpText={tooltip} placement="top">
                                <i className={`${styles.valueToolTip} fa fa-info-circle fa-xs`} />
                            </SimpleToolTip>
                        )}
                    </div>
                </>
            )}
        </div>
    );
};

export default KeyNumberSummary;
export { KeyNumberSummary };
