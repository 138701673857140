import React from 'react';
import { Link } from 'react-router-dom';

import styles from './breadcrumItem.module.scss';
export interface BreadcrumbItemProps {
    active?: boolean;
    to?: string;
    children?: React.ReactNode | string;
    _text?: string;
}

const BreadcrumbItem = ({ active = undefined, to, children }: BreadcrumbItemProps) => (
    <li className={styles.item}>
        {active ? <span>{children}</span> : <Link to={to}>{children}</Link>}
    </li>
);

export default BreadcrumbItem;
export { BreadcrumbItem };
