import { createContext, useContext } from 'react';

const context = createContext({
    expanded: false,
    set: () => {},
});

export const useHeaderContext = () => useContext(context);

export default context;
