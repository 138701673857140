import React from 'react';
import cn from 'classnames';

import { useHeaderContext } from '../../../contexts/headerContext';

import styles from './leftColumn.module.scss';

const LeftColumn = ({ children }) => {
    const { expanded } = useHeaderContext();

    return (
        <div
            className={cn(styles.leftColumn, {
                [styles.expanded]: expanded,
            })}>
            {children}
        </div>
    );
};

export default LeftColumn;
