import { useTranslation } from 'react-i18next';
import { Alert } from 'reactstrap';

import { Button } from 'components/atoms/Buttons';

import styles from './CurrentBioscopePlacement.module.scss';

export interface CurrentBioscopePlacementProps {
    loading: boolean;
    error: boolean;
    errorText?: string;
    title: string;
    client: {
        id: number;
        name: string;
    };
    onUpdateClientIsOpen?: (isOpen: boolean) => void;
    deleteLastBioscopeMove?: () => void;
    moveBioscope?: () => void;
}

const CurrentBioscopePlacement = ({
    loading,
    error,
    errorText = 'Something went wrong',
    title,
    client,
    deleteLastBioscopeMove,
    moveBioscope,
}: CurrentBioscopePlacementProps) => {
    const { t } = useTranslation();
    const clientName = client ? client.name : t('None');

    return (
        <div className={styles.currentbioscopeplacement}>
            <h2>{t(title)}</h2>
            <p>{clientName}</p>
            {loading ? <i className="fa fa-spinner fa-spin fa-fw" /> : null}
            {error ? <Alert color="danger">{t(errorText)}</Alert> : null}
            <div>
                <Button name="bioscope_move" onClick={moveBioscope}>
                    {t('Move')}
                </Button>
                <Button
                    name="bioscope_delete_last_move"
                    onClick={deleteLastBioscopeMove}
                    confirmationText="Are you sure you want to delete last bioscope move?">
                    {t('Delete last move')}
                </Button>
            </div>
        </div>
    );
};

export default CurrentBioscopePlacement;
export { CurrentBioscopePlacement };
