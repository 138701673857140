import React from 'react';

import styles from './TabbedNavigation.module.scss';

export interface TabbedNavigationProps {
    children?: React.ReactNode;
    activeTab?: number;
    changeTab?: (tab: number) => void;
}

const TabbedNavigation = ({ children, changeTab }: TabbedNavigationProps) => {
    const clickHandler = (event) => {
        let clickedTabIndex = event.target.getAttribute('data-index');
        clickedTabIndex = clickedTabIndex
            ? clickedTabIndex
            : event.target.parentElement.getAttribute('data-index');
        changeTab(parseInt(clickedTabIndex));
    };

    return (
        <div className={styles.tabbednavigation}>
            <nav onClick={clickHandler}>{children}</nav>
        </div>
    );
};

export default TabbedNavigation;
export { TabbedNavigation };
