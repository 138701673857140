import { Redirect, Route, Switch } from 'react-router-dom';

import { Login } from './Login/Login';
import { ResetPasswordContainer } from './ResetPassword/ResetPasswordContainer';
import { ResetPasswordRequestContainer } from './ResetPasswordRequest/ResetPasswordRequestContainer';
import { UserRegisterContainer } from './UserRegister/UserRegisterContainer';

const Public = () => {
    return (
        <Switch>
            <Route exact path="/login" component={Login} />
            <Route exact path="/reset-password-request" component={ResetPasswordRequestContainer} />
            <Route exact path="/reset-password" component={ResetPasswordContainer} />
            <Route exact path="/register" component={UserRegisterContainer} />
            <Redirect to={`/login?redirectTo=${encodeURIComponent(window.location.href)}`} />
        </Switch>
    );
};

export default Public;
