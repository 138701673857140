import { CageProvider } from 'contexts/cage-context';
import { CageFilterProvider } from 'contexts/cage-filter-context';

import Cage from './Cage';

interface CageContainerProps {
    cageId: number;
}

const CageContainer = ({ cageId }: CageContainerProps) => {
    return (
        <CageFilterProvider>
            <CageProvider cageId={cageId}>
                <Cage />
            </CageProvider>
        </CageFilterProvider>
    );
};

export default CageContainer;
