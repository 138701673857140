import { createContext, useContext } from 'react';
import useCageFilter from 'contexts/cage-filter-context';
import { MeasurmentOverviewResult } from 'services/types';

import { useRestOverviewQuery } from './overview-query';

const RestOverviewContext = createContext<{
    loading: boolean;
    error?: any;
    daypartTag?: string;
    weightUnitTag?: string;
    data?: MeasurmentOverviewResult;
}>({ loading: true });

export function useRestOverview() {
    return useContext(RestOverviewContext);
}

function OverviewProvider({ children }) {
    const { daypartTag, weightUnitTag } = useCageFilter();
    const restOverview = useRestOverviewQuery();
    const restContext = {
        ...restOverview,
        daypartTag,
        weightUnitTag,
    };

    return (
        <RestOverviewContext.Provider value={restContext}>{children}</RestOverviewContext.Provider>
    );
}

export { OverviewProvider };
