import React from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Row } from 'reactstrap';

import { formatNumber, getTitle } from '../utils';

import StatusIcon from './StatusIcon';

import styles from '../FishHealth.module.scss';

function Status({ label, getStatus }) {
    const { t } = useTranslation();
    const status = getStatus(label);
    const title = getTitle(label);
    return (
        <>
            <h3 className="pb-3">{t(title)}</h3>
            <Row>
                <Col xs={9}>
                    <StatusIcon status={status} withDescription />
                </Col>
                <Col xs={3}>
                    <div className={styles.title}>{t('Last day %')}</div>
                    <div className={styles.value}>
                        {formatNumber(status.head.percentage, {
                            suffix: '%',
                        })}
                    </div>
                </Col>
            </Row>
        </>
    );
}
export default Status;
