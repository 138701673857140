import moment from 'moment';

export const niceDateTime = (dateToFormat) => moment(dateToFormat).format('D. MMM YYYY HH:mm');
export const niceDate = (date) => moment(date).format('DD.MM.YYYY');
export default { niceDateTime, niceDate };
// TODO - Make this locale dynamic
export interface localizedDateFormatProps {
    dateString: string;
    locale?: string;
    onlydate?: boolean;
}
export function localizedDateFormat({
    dateString,
    locale = navigator.language,
    onlydate = false,
}): string {
    if (!dateString) {
        return '';
    }

    try {
        return new Intl.DateTimeFormat(locale, {
            dateStyle: 'short',
            timeStyle: onlydate ? undefined : 'short',
            timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        }).format(new Date(dateString));
    } catch (error) {
        return new Date(dateString).toString();
    }
}
