import { useClientController_Overview, useLocationController_MarkHistorical } from 'services/hooks';

import { useMe } from '@/contexts/meContext';
import { OverviewLocation } from '@/services/types';
import { Loader } from 'components/atoms/Loader';
import SomethingHappened from 'components/SomethingHappened';

import Overview from './Overview';

const OvervewContainer = ({ clientId }: { clientId: number }) => {
    const { isOptoscaleAdmin } = useMe();

    const { isLoading, data, error, refetch } = useClientController_Overview(clientId, {
        staleTime: 5 * 60 * 1000,
        cacheTime: 5 * 60 * 1000,
    });

    const { mutateAsync: markHistorical } = useLocationController_MarkHistorical({
        onSettled: () => refetch(),
    });

    const markHistoricalWrapper = async (cageId: number, historical: boolean) => {
        await markHistorical({ cageId: cageId, queryParams: { historical } });
        //We want to block the request until we have refetched the location data
        await refetch();
    };

    if (isLoading) {
        return <Loader />;
    }

    if (error) {
        return <SomethingHappened />;
    }

    return (
        <Overview
            locations={data.data.locations as OverviewLocation[]}
            clientId={clientId}
            markHistorical={markHistoricalWrapper}
            hasAccessToMarkHistorical={isOptoscaleAdmin}
            isOptoscaleAdmin={isOptoscaleAdmin}
        />
    );
};

export default OvervewContainer;
export { OvervewContainer };
