import React from 'react';

import styles from './OverflowToDropdownlist.module.scss';

export interface OverflowToDropdownlistProps {
    children?: React.ReactNode;
}

const OverflowToDropdownlist = ({ children }: OverflowToDropdownlistProps) => {
    return <div className={styles.overflowtodropdownlist}>{children}</div>;
};

export default OverflowToDropdownlist;
export { OverflowToDropdownlist };
