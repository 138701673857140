import React from 'react';
import { useTranslation } from 'react-i18next';
import dateUtils from 'helpers/date';
import { Connection } from 'services/types';

import styles from './BioscopeConnection.module.scss';

const getCurrentBioscopeRestartStep = (t, bioscopeRestart) => {
    if (bioscopeRestart.isDone) {
        return '';
    }

    if (bioscopeRestart.shutdownSmsId && bioscopeRestart.startSmsId) {
        return t('Waiting for cabinet to start');
    }

    if (bioscopeRestart.shutdownSmsId) {
        return t('Waiting for cabinet to stop');
    }

    return t('Waiting for cabinet status');
};

const getCurrentBioscopeRouterRestartStep = (t, bioscopeRouterRestart) => {
    if (bioscopeRouterRestart.isDone) {
        return '';
    }

    if (!bioscopeRouterRestart.rebootSmsId) {
        return t('Waiting for router to stop');
    }

    if (bioscopeRouterRestart.rebootSmsId && !bioscopeRouterRestart.rebootResponseSmsId) {
        return t('Waiting for router to start');
    }

    return t('Waiting for router status');
};

interface BioscopeConnectionProps {
    title?: string;
    connection: Connection;
}

const BioscopeConnection = ({ title = 'Connection', connection }: BioscopeConnectionProps) => {
    const { t } = useTranslation();

    return (
        <div className={styles.BioscopeConnection}>
            <h2>{t(title)}</h2>
            <div className="mt-3">
                <table className="text-nowrap">
                    <tbody>
                        <tr>
                            <td>{t('Connection')}</td>
                            <td>
                                {connection
                                    ? dateUtils.niceDateTime(connection.lastSeen)
                                    : t('Never updated')}
                            </td>
                        </tr>
                        {connection && (
                            <>
                                <tr>
                                    <td>{t('Cabinet')}</td>
                                    <td>
                                        {connection.cabinet ? connection.cabinet : t('Unknown')}
                                    </td>
                                </tr>
                                <tr>
                                    <td>IP</td>
                                    <td>{connection.ip ? connection.ip : t('Unknown')}</td>
                                </tr>
                                {connection.phoneNumber ? (
                                    <tr>
                                        <td>{t('Phone number')}</td>
                                        <td>{connection.phoneNumber}</td>
                                    </tr>
                                ) : (
                                    ''
                                )}
                                {connection.router ? (
                                    <tr>
                                        <td>{t('Router')}</td>
                                        <td>{connection.router}</td>
                                    </tr>
                                ) : (
                                    ''
                                )}
                            </>
                        )}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default BioscopeConnection;
export { BioscopeConnection, getCurrentBioscopeRouterRestartStep, getCurrentBioscopeRestartStep };
