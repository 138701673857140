import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Table, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { niceDateTime } from 'helpers/date';
import { Button } from 'components/atoms/Buttons';
import { Box } from 'components/Layout';

export interface ApiUsersProps {
    apiUsers: {
        id: number;
        createdAt: Date;
        lastUsed: Date;
    }[];
    createApiUser: () => Promise<unknown>;
}

export const ApiUsers = ({ apiUsers: initialApiUsers, createApiUser }: ApiUsersProps) => {
    const { t } = useTranslation();
    const [apiUsers, setApiUsers] = useState(initialApiUsers);
    const [createdApiUser, setCreatedApiUser] = useState(null);
    const [error, setError] = useState(false);
    const [modal, setModal] = useState(false);
    const [modalMessage, setModalMessage] = useState('');
    const [passwordCopied, setPasswordCopied] = useState<string | null>(null);

    useEffect(() => {
        if (createdApiUser) {
            setModal(true);
            setApiUsers((prevApiUsers) => [
                ...prevApiUsers,
                {
                    id: createdApiUser.data.id,
                    createdAt: new Date(createdApiUser.data.createdAt),
                    lastUsed: createdApiUser.data.lastUsed
                        ? new Date(createdApiUser.data.lastUsed)
                        : null,
                },
            ]);
        }
    }, [createdApiUser]);

    const toggleModal = () => {
        setModal(!modal);
        setError(false);
        setModalMessage('');
    };

    const copyToClipboardHandler = (text: string) => {
        navigator.clipboard.writeText(text).then(
            () => {
                setPasswordCopied('Password copied to clipboard!');
                setTimeout(() => setPasswordCopied(null), 3000);
            },
            () => {
                setPasswordCopied('Failed to copy to clipboard');
                setTimeout(() => setPasswordCopied(null), 3000);
            }
        );
    };

    const handleCreateApiUser = () => {
        createApiUser()
            .then(setCreatedApiUser)
            .catch(() => {
                setError(true);
                setModalMessage('Something happened');
                setModal(true);
            });
    };

    const renderModalContent = () =>
        error ? (
            <p>{modalMessage}</p>
        ) : (
            <p>
                Created new api client with client id <strong>{createdApiUser?.data?.id} </strong>
                and password <strong>{createdApiUser?.data?.password}</strong>. Please save the
                password immediately as you will not have access to it again.
            </p>
        );

    return (
        <Box title={t('Api users')}>
            <p>There is no way to get an already generated password</p>
            <Table>
                <thead>
                    <tr>
                        <th>Client id</th>
                        <th>{t('Created at')}</th>
                        <th>{t('Last used')}</th>
                    </tr>
                </thead>
                <tbody>
                    {apiUsers.map(({ id, createdAt, lastUsed }) => (
                        <tr key={id}>
                            <td>{id}</td>
                            <td>{niceDateTime(createdAt)}</td>
                            <td>{lastUsed ? niceDateTime(lastUsed) : t('Never')}</td>
                        </tr>
                    ))}
                </tbody>
            </Table>
            <Button onClick={handleCreateApiUser}>Create new</Button>
            <Modal isOpen={modal} toggle={toggleModal}>
                <ModalHeader toggle={toggleModal}>New API User Created</ModalHeader>
                <ModalBody>
                    {renderModalContent()}
                    {passwordCopied && (
                        <Alert color="info" className="mt-3">
                            {passwordCopied}
                        </Alert>
                    )}
                </ModalBody>
                <ModalFooter>
                    {!error && (
                        <Button
                            color="primary"
                            onClick={() => copyToClipboardHandler(createdApiUser?.data?.password)}>
                            Copy Password
                        </Button>
                    )}
                    <Button color="secondary" onClick={toggleModal}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>
        </Box>
    );
};
