import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { BioscopeDetailContainer } from 'containers/BioscopeDetailContainer';

import BioscopeList2 from './BioscopeList2';

const Index = (props) => {
    return (
        <Switch>
            <Route exact path={props.match.path} component={BioscopeList2} />
            <Route
                exact
                path={`${props.match.path}/:bioscopeId`}
                component={BioscopeDetailContainer}
            />
        </Switch>
    );
};

Index.propTypes = {};

export default Index;
