import React from 'react';
import { Link, matchPath, RouteComponentProps, withRouter } from 'react-router-dom';
import { NavItem } from 'reactstrap';
import cn from 'classnames';

import { HelpIcon } from 'components/atoms/icons/HelpIcon';
import { MoveIcon } from 'components/atoms/icons/MoveIcon';
import { OverviewIcon } from 'components/atoms/icons/OverviewIcon';
import { UserIcon } from 'components/atoms/icons/UserIcon';

import styles from './headerLink.module.scss';

export interface Location {
    pathname: string;
}

export interface HeaderLinkProps {
    children: React.ReactNode;
    location: Location;
    to: string;
    className?: string;
    exact?: boolean;
    iconName?: string;
}

const HeaderLink = ({
    children,
    location,
    to,
    className,
    exact = true,
    iconName = '',
}: HeaderLinkProps & RouteComponentProps) => {
    const match = matchPath(location.pathname, {
        path: to,
        exact,
    });

    const icon = (iconName) => {
        switch (iconName) {
            case 'overviewIcon':
                return <OverviewIcon />;
            case 'helpIcon':
                return <HelpIcon />;
            case 'userIcon':
                return <UserIcon />;
            case 'moveIcon':
                return <MoveIcon />;
            default:
                return null;
        }
    };

    return (
        <NavItem className={styles.navItem}>
            <Link
                to={to}
                className={cn(styles.headerLink, className, {
                    [styles.active]: match,
                })}>
                {icon(iconName)}
                <span data-linkname={iconName}>{children}</span>
            </Link>
        </NavItem>
    );
};

export default withRouter(HeaderLink);
export { HeaderLink };
