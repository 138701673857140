import React from 'react';
import { matchPath, useHistory, useLocation } from 'react-router-dom';
import { useClientController_GetClient, useUserController_Logout } from 'services/hooks';

import { ClientInfo } from '@/services/types';

import { useHeaderContext } from '../../../../contexts/headerContext';
import { useMe } from '../../../../contexts/meContext';
import { Header } from '../Header/Header';

export interface HeaderContainerProps {
    children?: React.ReactNode;
    baseUrl?: string;
    languageBar?: boolean;
}

const HeaderContainer = ({ children, baseUrl, languageBar }: HeaderContainerProps) => {
    const { set, expanded } = useHeaderContext();
    const location = useLocation();
    const history = useHistory();

    const headerMenuItemHandler = (to): void => {
        history.push(to);
    };

    const { mutateAsync: onLogout } = useUserController_Logout();

    const me = useMe();

    const getClientId = (path) => {
        const match = matchPath(path, {
            path: '/c/:userId',
            exact: false,
            strict: true,
        });

        return match ? parseInt(match.params.userId) : null;
    };

    const clientId = getClientId(location.pathname);

    const client = useClientController_GetClient(clientId, {
        enabled: clientId !== null,
    });

    const clientInfo: ClientInfo = client.data?.data ? client.data.data : null;

    return (
        <Header
            expanded={expanded}
            setExpandedHandler={set}
            location={location}
            menuItemHandler={headerMenuItemHandler}
            user={me.state.user}
            client={clientInfo}
            clients={me.state.clients}
            languageBar={languageBar}
            onLogout={onLogout}>
            {children}
        </Header>
    );
};

export default HeaderContainer;
export { HeaderContainer };
