import { MdNotInterested, MdTrendingDown, MdTrendingFlat, MdTrendingUp } from 'react-icons/md';
import { camelCase, capitalize } from 'lodash';
import moment from 'moment';

const VALUES = [
    'total',
    'mature jaw',
    'deformed upper jaw',
    'wound',
    'lice',
    'skin speckles',
    'deformed lower jaw',
    'hump',
    'tail fin damage severe',
    'deformed spine',
    'tail fin split',
    'skin spot',
    'fat lip',
    'food pellet',
    'back fin split',
    'snout wound',
    'no wound',
    'one wound',
    'more than one wound',
    'small wound',
    'medium wound',
    'big wound',
    'average wound size',
    'healing wounds',
    'active wounds',
    'fully mature',
    'started maturation',
];

const NEEDS_COMPENSATION = [
    'wound',
    'lice',
    'skin speckles',
    'skin spot',
    'no wound',
    'one wound',
    'more than one wound',
    'small wound',
    'medium wound',
    'big wound',
];

const NEEDS_WOUND_COMPENSATION = [
    'wound',
    'no wound',
    'one wound',
    'more than one wound',
    'small wound',
    'medium wound',
    'big wound',
];

// Allows to convert label to title: label => title, default label
const TITLES = {};

// Some default colors to use for value status
const COLOR_ERROR = '#CB4773';
const COLOR_OK = '#407EAC';
const COLOR_GOOD = '#2F837B';
const COLOR_NEUTRAL = '#333333';

/*
 * Configuration for values status calculation per each label (key),
 * meaningful defaults will be chosen if no ops are specified.
 *
 *  .eqThreshold:    threshold for checking if value is changed
 *
 *  .eq.color:       color code to use when values equal
 *  .eq.text:        text when values equal
 *  .eq.description: description when values equal
 *
 *  .le.color:       ... when value is less than tail value
 *  .le.text:
 *  .le.description:
 *
 *  .gt.color:       ... when value is greater than tail value
 *  .gt.text:
 *  .gt.description:
 */

const DEFAULT_EQ_THRESHOLD = 2;
const VALUE_OPS = {};
const FUTURE_VALUE_OPS = {};

function getValue(label, row) {
    if (!row) return undefined;
    return row[camelCase(label)];
}

function getTitle(label) {
    if (label in TITLES) {
        return TITLES.label; // overrides
    }

    // enable the code bellow to support fishHealthIndicators.wounds
    // or fishHealthIndicators.skinSpot type translations
    if (VALUES.includes(label)) {
        return `fishHealthIndicators.${camelCase(label)}`;
    }

    return capitalize(label);
}

function getStatus(common, valueOps) {
    const eqThreshold = valueOps.eqThreshold || DEFAULT_EQ_THRESHOLD;

    if (Number.isNaN(common.diff)) {
        const color = valueOps.nan?.color || COLOR_NEUTRAL;
        const text = valueOps.nan?.text || 'fishHealthColorStatus.unknownText';
        const description = valueOps.nan?.description || 'fishHealthColorStatus.unknownDescription';

        return {
            ...common,
            color,
            text,
            description,
            op: undefined,
        };
    }
    if (Math.abs(common.diff) <= eqThreshold) {
        const color = valueOps.eq?.color || COLOR_OK;
        const text = valueOps.eq?.text || 'fishHealthColorStatus.stableText';
        const description = valueOps.eq?.description || 'fishHealthColorStatus.stableDescription';

        return {
            ...common,
            color,
            text,
            description,
            op: 0,
        };
    }
    if (common.diff > 0) {
        const color = valueOps.gt?.color || COLOR_ERROR;
        const text = valueOps.gt?.text || 'fishHealthColorStatus.increasingText';
        const description =
            valueOps.gt?.description || 'fishHealthColorStatus.increasingDescription';

        return {
            ...common,
            color,
            text,
            description,
            op: 1,
        };
    }
    const color = valueOps.lt?.color || COLOR_GOOD;
    const text = valueOps.lt?.text || 'fishHealthColorStatus.decreasingText';
    const description = valueOps.lt?.description || 'fishHealthColorStatus.decreasingDescription';

    return {
        ...common,
        color,
        text,
        description,
        op: -1,
    };
}

function getHeadTailStatus(label, head, tail) {
    const compensate = NEEDS_COMPENSATION.findIndex((i) => i === label) !== -1;
    const headRatio = getValue(label, head) ?? 0;
    const headTotalCount = head ? head.total : 0;
    const headTotalSpecificDay = head ? head.count : 0;
    const tailRatio = getValue(label, tail) ?? 0;
    const tailTotalCount = tail ? tail.total : 0;
    const tailTotalSpecificDay = tail ? tail.count : 0;

    const headPercentage = 100 * headRatio;
    const tailPercentage = 100 * tailRatio;
    const diff = headPercentage - tailPercentage;

    const common = {
        head: {
            day: head?.day,
            percentage: headPercentage,
            totalSpecificDay: headTotalSpecificDay,
            totalCount: headTotalCount,
            withCompensatedPerspective: compensate,
        },
        tail: {
            day: tail ? tail.day : '',
            percentage: tailPercentage,
            totalSpecificDay: tailTotalSpecificDay,
            totalCount: tailTotalCount,
            withCompensatedPerspective: compensate,
        },
        diff,
    };

    const valueOps = label in VALUE_OPS ? VALUE_OPS[label] : {};

    return getStatus(common, valueOps);
}

function formatNumber(
    value,
    {
        prefix = '',
        suffix = '',
        alwaysShowSign = false,
        decimals = 0,
        ifBad = '\u2014',
        ifUndefined = '\u2014',
        ifNull = '\u2014',
        ifNaN = '\u2014',
        ifZero = undefined,
    } = {}
) {
    if (
        ifBad !== undefined &&
        (value === undefined || value === null || Number.isNaN(Number(value)))
    ) {
        return ifBad;
    }

    if (ifUndefined !== 'undefined' && value === undefined) {
        return ifUndefined;
    }

    if (ifNull !== 'undefined' && value === null) {
        return ifNull;
    }

    value = Number(value);
    if (ifNaN !== undefined && Number.isNaN(value)) {
        return ifNaN;
    }

    if (decimals !== undefined && decimals !== null) {
        value = value.toFixed(decimals);
    }

    if (ifZero !== undefined && value === 0) {
        return ifZero;
    }

    if (alwaysShowSign && value >= 0) {
        value = `+${value}`;
    }

    return `${prefix}${value}${suffix}`;
}

function getIcon(op) {
    op = Number(op);
    if (Number.isNaN(op)) {
        return MdNotInterested;
    }
    if (op > 0) {
        return MdTrendingUp;
    }
    if (op < 0) {
        return MdTrendingDown;
    }
    return MdTrendingFlat;
}

function getFutureStatus(label, head, stats, days = 7) {
    const hours = 7 * 24; // stats regression slope is in hours
    const name = camelCase(label);
    const regressionSlope = stats?.[name]?.regressionSlope;
    const diff = regressionSlope * hours;

    const tailRatio = getValue(label, head);
    const tailTotalCount = head ? head.total : undefined;
    const tailPercentage = tailRatio * 100;
    const headTotalSpecificDay = head ? head.count : 0;

    const headPercentage = tailPercentage + diff;
    const headTotalCount = tailTotalCount;
    const tailTotalSpecificDay = headTotalSpecificDay;

    const common = {
        head: {
            day: head ? moment(head.day).add(days, 'day').format('YYYY-MM-DD') : '',
            percentage: headPercentage,
            totalCount: headTotalCount,
            totalSpecificDay: headTotalSpecificDay,
        },
        tail: {
            day: head ? head.day : '',
            percentage: tailPercentage,
            totalCount: tailTotalCount,
            totalSpecificDay: tailTotalSpecificDay,
        },
        diff,
    };

    const valueOps = label in FUTURE_VALUE_OPS ? FUTURE_VALUE_OPS[label] : {};

    return getStatus(common, valueOps);
}

export {
    NEEDS_WOUND_COMPENSATION,
    getHeadTailStatus,
    getFutureStatus,
    getTitle,
    formatNumber,
    getIcon,
    COLOR_NEUTRAL,
};
