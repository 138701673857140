import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './FishHealthMetaCard.module.scss';

export interface FishHealthMetaCardProps {
    tag: string;
    size_x_mm: number;
    size_y_mm: number;
    score: number;
    poiData?: {
        topProsent: number;
        leftProsent: number;
        PoiWidth: number;
        PoiHeight: number;
    };
    onClickhandler?: (poiData) => void;
    showWoundLabel?: boolean;
}

const FishHealthMetaCard = ({
    tag = '',
    size_x_mm = 0,
    size_y_mm,
    score = 0,
    poiData,
    onClickhandler = () => {},
    showWoundLabel = true,
}: FishHealthMetaCardProps) => {
    const { t } = useTranslation();
    const wound = (tag: string) => {
        const sSplit = tag.split('_');
        return {
            label: sSplit.length === 1 ? 'status' : sSplit[1],
            value: sSplit.length === 1 ? sSplit[1] : sSplit[0],
        };
    };

    return (
        <div className={styles.fishhealthmetacard}>
            <ul className={styles.list}>
                {/* biome-ignore lint/a11y/useKeyWithClickEvents: <explanation> */}
                <li
                    className="wound"
                    onClick={(e) => {
                        onClickhandler?.(poiData);
                    }}>
                    <span role="button">
                        <span>{wound(tag).label}</span>
                        <span>{wound(tag).value}</span>
                    </span>
                </li>
                <li className="size">
                    <span role="button">
                        <span>Size</span>
                        <span>{`${size_x_mm}x${size_y_mm}`}</span>
                    </span>
                </li>
                <li className="score">
                    <span role="button">
                        <span>Score</span>
                        <span>{score}</span>
                    </span>
                </li>
            </ul>
        </div>
    );
};

export interface FishHealthNavigationCardProps {
    key?: string;
    healing: number;
    active: number;
    skottelus: number;
    adult_female_lice: number;
    movable: number;
    imageId: string;
    imagePath: string;
    currentVisibleImageId: string | undefined;
    onClickHandler?: (e) => void;
    onMouseOverHandler?: (e) => void;
    onMouseOutHandler?: (e) => void;
    showWoundLabel?: boolean;
    showLiceLabel?: boolean;
}

const FishHealthNavigationCard = ({
    healing,
    active,
    skottelus,
    adult_female_lice,
    movable,
    imageId,
    imagePath,
    currentVisibleImageId,
    onClickHandler = () => {},
    onMouseOverHandler = () => {},
    onMouseOutHandler = () => {},
    showWoundLabel = true,
    showLiceLabel = false,
}: FishHealthNavigationCardProps) => {
    const isMatchingVisibleImage = currentVisibleImageId === imageId;
    let timeOutReference = null;
    const { t } = useTranslation();

    return (
        <button
            type="button"
            name="fishHealthNavigationCard"
            value={imageId}
            data-iscurrent={isMatchingVisibleImage}
            className={classNames(
                styles.fishHealthNavigationCard,
                isMatchingVisibleImage ? styles.active : ''
            )}
            onClick={onClickHandler}
            onMouseOver={(e) => {
                timeOutReference = setTimeout(() => {
                    onMouseOverHandler(e);
                    timeOutReference = null;
                }, 1000);
            }}
            onFocus={(e) => {
                //console.log('Focus', e.target);
            }}
            onBlur={(e) => {
                // console.log('Blur', e.target);
            }}
            onMouseOut={(e) => {
                if (timeOutReference) {
                    clearTimeout(timeOutReference);
                    timeOutReference = null;
                    onMouseOutHandler(e);
                }
            }}>
            <figure>
                <img
                    data-src={imagePath}
                    src="/assets/glyphicons/glyphicons-basic-790-fish.svg"
                    alt={`Peep show salmon id: ${imageId}`}
                />
            </figure>
            {showWoundLabel && (
                <ul>
                    <li data-type="active" data-hasvalue={active > 0}>
                        <span data-type="color" />
                        <span>{t('Active')}</span>
                        <span>{active}</span>
                    </li>
                    <li data-type="healing" data-hasvalue={healing > 0}>
                        <span data-type="color" />
                        <span>{t('Healing')}</span>
                        <span>{healing}</span>
                    </li>
                </ul>
            )}
            {showLiceLabel && (
                <ul>
                    <li data-type="adult_female_lice" data-hasvalue={adult_female_lice > 0}>
                        <span data-type="color" />
                        <span>{t('adult_female_lice')}</span>
                        <span>{adult_female_lice}</span>
                    </li>

                    <li data-type="movable" data-hasvalue={movable > 0}>
                        <span data-type="color" />
                        <span>{t('movable')}</span>
                        <span>{movable}</span>
                    </li>
                    <li data-type="skottelus" data-hasvalue={skottelus > 0}>
                        <span data-type="color" />
                        <span>{t('skottelus')}</span>
                        <span>{skottelus}</span>
                    </li>
                </ul>
            )}
        </button>
    );
};

export default FishHealthMetaCard;
export { FishHealthMetaCard, FishHealthNavigationCard };
