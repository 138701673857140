import React, { useContext } from 'react';
import { Button } from 'reactstrap';
import { FullScreenIcon, FullScreenOffIcon } from 'components/atoms/icons';
import FullScreenContext from '../../../../contexts/fullScreenContext';

export default ({ returnFullScreenValue }) => {
    const { fullScreen, toggleFullScreen } = useContext(FullScreenContext);

    return (
        <Button
            title="Toggle Fullscreen"
            onClick={() => {
                toggleFullScreen();
                returnFullScreenValue(fullScreen);
            }}
            outline>
            {fullScreen ? <FullScreenOffIcon size={24} /> : <FullScreenIcon size={24} />}
        </Button>
    );
};
