import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import MeContext from '../contexts/meContext';

import Clients from './Clients';
import Internal from './Internal';

const LoggedInRoutes = () => {
    const { isOptoscaleAdmin } = React.useContext(MeContext);

    return (
        <Switch>
            {isOptoscaleAdmin && <Route path="/internal" component={Internal} />}
            <Route path="/c" component={Clients} />
            <Redirect to={isOptoscaleAdmin ? '/internal' : '/c'} />
        </Switch>
    );
};

export default LoggedInRoutes;
