import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './ImageViewer.module.scss';

export interface ViewerHelpTextBlocksProps {
    children?: React.ReactNode;
}

const ViewerHelpTextBlocks = () => {
    const { t } = useTranslation();

    return (
        <div className={styles.infoTip}>
            <h2>{t('How to use')}</h2>
            <p>{t('magnifyer')}</p>
            <p>
                {t('clickviewer')}
                <br />
                {t('swipeviewer')}
            </p>
        </div>
    );
};

export default ViewerHelpTextBlocks;
export { ViewerHelpTextBlocks };
