import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Col, Row } from 'reactstrap';
import settings from 'config/settings';
import moment from 'moment';

import { Chart } from 'components/Chart';
import FullScreenCard from 'components/Layout/Cards/FullScreenCard/FullScreenCard';

import { getTitle } from '../../utils';

import options from './options';
import plugins from './plugins';

const DEFAULT_COLUMNS = [
    'mature jaw',
    'deformed upper jaw',
    'wound',
    'lice',
    'skin speckles',
    'deformed lower jaw',
    'hump',
    'tail fin damage severe',
    'deformed spine',
    'tail fin split',
    'skin spot',
    'fat lip',
    'food pellet',
    'back fin split',
    'snout wound',
];

const TITLE = 'Fish health counts';

export default ({ getStatus, columns = DEFAULT_COLUMNS }) => {
    const { t } = useTranslation();

    const labels = useMemo(() => {
        return columns.map((col) => t(getTitle(col)));
    }, [t, columns]);

    const datasets = useMemo(() => {
        return [
            {
                label: t('Last'),
                backgroundColor: settings.primaryColor,
                borderColor: settings.primaryColor,
                data: columns.map((label) => {
                    const status = getStatus(label);
                    return status ? status.head.percentage.toFixed(2) : undefined;
                }),
            },
            {
                label: t('Previous'),
                backgroundColor: settings.secondaryColor,
                borderColor: settings.secondaryColor,
                data: columns.map((label) => {
                    const status = getStatus(label);
                    return status ? status.tail.percentage.toFixed(2) : undefined;
                }),
            },
        ];
    }, [t, columns, getStatus]);

    if (!datasets || !datasets.length) {
        return <Alert color="warning">{t('No data')}</Alert>;
    }

    const totalStatus = getStatus('total');
    const day =
        totalStatus.head.day === '' ? '' : moment(totalStatus.head.day).format('MMMM D, YYYY');
    const title = `${t(TITLE)}: ${day}`;

    return (
        <>
            <h3 className="pb-3">{title}</h3>
            <Row>
                <Col>
                    <FullScreenCard>
                        <Chart
                            type="horizontalBar"
                            data={{
                                datasets,
                                labels,
                            }}
                            options={options(totalStatus.head.day)}
                            plugins={plugins}
                        />
                    </FullScreenCard>
                </Col>
            </Row>
        </>
    );
};
