import React from 'react';

import styles from './PageActionButtons.module.scss';

export interface PageActionButtonsProps {
    children?: React.ReactNode;
}

const PageActionButtons = ({ children }: PageActionButtonsProps) => {
    return <div className={styles.pageactionbuttons}>{children}</div>;
};

export default PageActionButtons;
export { PageActionButtons };
