import React from 'react';

import styles from './DownloadIcon.module.scss';

export interface DownloadIconProps {
    color?: string;
    size?: number;
}

const DownloadIcon = ({ color, size = 32 }: DownloadIconProps) => {
    const colorStyle = color ? color : 'currentColor';

    return (
        <div className={styles.downloadIcon}>
            <svg height={size} viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M24.5,4H7.5A3.50424,3.50424,0,0,0,4,7.5v17A3.50424,3.50424,0,0,0,7.5,28h17A3.50424,3.50424,0,0,0,28,24.5V7.5A3.50424,3.50424,0,0,0,24.5,4ZM25,24.5a.50641.50641,0,0,1-.5.5H7.5a.50641.50641,0,0,1-.5-.5V7.5A.50641.50641,0,0,1,7.5,7h17a.50641.50641,0,0,1,.5.5Zm-4.18994-7.92981-3.98859,5.74628a1,1,0,0,1-1.643,0l-3.98853-5.74628A1,1,0,0,1,12.01141,15H14V11a1,1,0,0,1,1-1h2a1,1,0,0,1,1,1v4h1.98859A1,1,0,0,1,20.81006,16.57019Z"
                    fill={colorStyle}
                />
            </svg>
        </div>
    );
};

export default DownloadIcon;
export { DownloadIcon };
