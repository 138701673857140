import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Spinner, Table } from 'reactstrap';
import dateUtils from 'helpers/date';

import Loader from '@/components/atoms/Loader';
import { Button } from 'components/atoms/Buttons';

export const getCurrentBioscopeRestartStep = (t: (string) => string, bioscopeRestart: any) => {
    if (bioscopeRestart.isDone) {
        return '';
    }

    if (bioscopeRestart.shutdownSmsId && bioscopeRestart.startSmsId) {
        return t('Waiting for cabinet to start');
    }

    if (bioscopeRestart.shutdownSmsId) {
        return t('Waiting for cabinet to stop');
    }

    return t('Waiting for cabinet status');
};

export const getCurrentBioscopeRouterRestartStep = (
    t: (string) => string,
    bioscopeRouterRestart: any
) => {
    if (bioscopeRouterRestart.isDone) {
        return '';
    }

    if (!bioscopeRouterRestart.rebootSmsId) {
        return t('Waiting for router to stop');
    }

    if (bioscopeRouterRestart.rebootSmsId && !bioscopeRouterRestart.rebootResponseSmsId) {
        return t('Waiting for router to start');
    }

    return t('Waiting for router status');
};

export interface BioscopeRestartsProps {
    loading: boolean;
    lastBioscopeRestarts: {
        updatedAt: string;
        isDone: boolean;
        message: string;
    }[];
    restartBioscope: () => Promise<unknown>;
    stepMessage: (t: (string) => string, bioscopeRestart: any) => string;
}

const BioscopeRestarts = ({
    loading,
    lastBioscopeRestarts,
    restartBioscope,
    stepMessage,
}: BioscopeRestartsProps) => {
    const { t } = useTranslation();

    const [restartingBioscope, setRestartingBioscope] = useState<boolean>(false);
    const [restartBioscopeError, setRestartBioscopeError] = useState<string>();

    const wrappedRestartBioscope = async () => {
        try {
            setRestartingBioscope(true);
            await restartBioscope();
        } catch (e) {
            console.log(e);
            setRestartBioscopeError(e?.message?.message);
        } finally {
            setRestartingBioscope(false);
        }
    };

    return (
        <>
            <div className="mt-3">
                <Button onClick={wrappedRestartBioscope}>
                    {restartingBioscope ? <Spinner size="sm" /> : t('Restart')}
                </Button>
            </div>
            <div>
                {restartBioscopeError && <Alert color="danger">{restartBioscopeError}</Alert>}
                <div className="mt-3">
                    <Table>
                        <thead>
                            <tr>
                                <th>{t('Last update')}</th>
                                <th>{t('Is done')}</th>
                                <th>{t('Step')}</th>
                                <th>{t('Message')}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {lastBioscopeRestarts?.map((lastRestart) => (
                                <tr key={lastRestart.updatedAt}>
                                    <td>{dateUtils.niceDateTime(lastRestart.updatedAt)}</td>
                                    <td>{lastRestart.isDone ? t('Yes') : t('No')}</td>
                                    <td>{stepMessage(t, lastRestart)}</td>
                                    <td>{lastRestart.message}</td>
                                </tr>
                            ))}
                        </tbody>
                        {loading && <Loader />}
                    </Table>
                </div>
            </div>
        </>
    );
};

export default BioscopeRestarts;
export { BioscopeRestarts };
