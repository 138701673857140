import React from 'react';

import styles from './ImageViewer.module.scss';

export interface MagnifyerProps {
    path: string;
    coordinate: {
        x: number;
        y: number;
    };
    dimensions: {
        width: number;
        height: number;
    };
    useMagnifyer?: boolean;
}
const Magnifyer = ({
    path,
    coordinate = { x: 0, y: 0 },
    dimensions,
    useMagnifyer = false,
}: MagnifyerProps) => {
    const handleMouseMove = (divElement: HTMLDivElement): void => {
        if (!divElement || !coordinate || !dimensions) {
            return;
        }

        // const frame = frameRef.current as HTMLDivElement;
        divElement.style.setProperty('--magnifyerX', `${coordinate.x}px`);
        divElement.style.setProperty('--magnifyerY', `${coordinate.y}px`);
    };

    return (
        <div
            className={styles.magnifyer}
            style={{ visibility: useMagnifyer ? 'visible' : 'hidden' }}
            ref={handleMouseMove}>
            <img
                src={path}
                alt="magnifyed area"
                width={dimensions.width}
                height={dimensions.height}
            />
        </div>
    );
};

export default Magnifyer;
export { Magnifyer };
