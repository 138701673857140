import React from 'react';

import styles from './FishWounds.module.scss';

export interface MultiWoundProps {
    color?: string;
    size?: number;
}

const MultiWound = ({ color, size = 18 }: MultiWoundProps) => {
    return (
        <div className={styles.fishWounds}>
            <svg
                version="1.1"
                id="Layer_1"
                xmlns="http://www.w3.org/2000/svg"
                x="0px"
                y="0px"
                width="211px"
                height="70px"
                viewBox="0 0 211 70">
                {' '}
                <image
                    id="image0"
                    width="211"
                    height="70"
                    x="0"
                    y="0"
                    href="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAANMAAABGCAYAAABWrKEpAAAABGdBTUEAALGPC/xhBQAAACBjSFJN
                    AAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAABmJLR0QA/wD/AP+gvaeTAAAA
                    CXBIWXMAAAsTAAALEwEAmpwYAAAPfUlEQVR42u2deZAcZ3mHn55rV3tfWmu1kixLsteyBGaQGCxj
                    QDbGwQq4jOI4ccDO4SLBiZwYCFRSIUNlKxiSOAe5KiEHVVCJcf5IUpCrIAQqBhMmgcGx7GB0GN3X
                    arU70h4zuzOdP3493mtmtnumZ3p2pp+qLml2unu+7plfv9/3fu/7foZpmjhlNJlwfIxPWQwA7wNu
                    BP4M8G98HRCPxgr+PeB1w3yK8lbgS8CTwDuBLV43yKc0Ia8b4LOCDuAJ4ENAj/W3iLX51DG+mOqL
                    m4GngB9e9ve0tfnUMb6Y6of7gN8Dthd4r83afOoYf8xUH3wQ+CyFhQTq4nV43Uif0viWyVvagN9A
                    Y6RS30UE6Pe6sT6l8cXkHV2oW/eojX1ngbNeN9inNH43zxs6gE9hT0gArcDd+NaprvHFVHvakEX6
                    KYfHvQt40OvG+xTHF1Pt+U0U1eCUDuATwCGvL8CnML6YastHgF+o4Phu4HeBOBD0+mJ8luKLqXa8
                    B0U1VBrJEAHeD/yI1xfksxRfTLXhLcCvAoMunW8IdRW3eX1hPgv4rvHqsw0YBXa5fN4o8EbguNcX
                    2MC0A28AdgKbgU3A8Ggy0WO9/zLw4Xg0dgZ8MVWbdcDHUAS42/QD+4FngJzXF9pA9AEHgQPogbW1
                    yH4TwAeAS/k/+GKqLr+OxkrVYgpfSG4QRFb+EeBeVk93GQfujUdjS/LLfDFVj18Dfonqet12ATcA
                    r3h9sSWIAHuB3cALqGs07nWjLELIun8EeBv2fAjzwKHlQsLmwT7OGAL+Armvqx3pfRuazK1H2oCf
                    Br4LPAv8OfAc8BmvG4ZE9E6rXV8G3o49LWSBv4lHY08XO6mPfQJA2Npa0AC1Ew1O9wL7rK23Ru3p
                    QvNWLwH/7vXNWcTtwJ8Aryvw3huAh4CnnZzQJUJIOB+12uiEHPA14LFSJ28mgkgEnSiLtQtYj8Rw
                    HZoUbUeOg3Xo6dpqHZPfFr+Xf+2lhb8JuIP6EFM78HMoymNdkX2GkHU6hAT1xzVoVwB1M59kZeKl
                    XeZRt32m2A6NJqYQ+kIHkEh2AiNILEPIA9aDRNSOLMxaZRo4DfwAOAMMo27ILJCxtizgvGJOedyA
                    ojPebWPfFmQZ9qDv5/EqtqsPWaLH0IOxHLLAXwEvltppLYupEwnk9cgTM4LmAQaRhWkBDK8bWUXa
                    0A94GFmmP0QCmkVevhlrmwJSyJU7AVwAvg8cAy4DV4Br6AdTbjseRmPEjQ6PbUHTBp3AVZfvTxiN
                    i56i8sntGWRtS7IWxBRCY5CNaFySn0TbDmyguWPU8uO3PK3I6trBRMKbRAI7DZwADiOnwTnkdZui
                    sNA6gXcgT9jeCq6h2zr+qy7el0EUme/GtEQO+AY28snqTUy9yLrsAW5F9eKuRxaoF9/76CYGC+O/
                    Deh+L2YWiWnM2sZR1zJs7T+Cc0tUiBbrfG4QRK7uT+NeqNU08GE7O3otpj40MLwPWZwRNNbxReM9
                    rUgsbgimFFeQNayUNuAXgY/j3u8nixw7L9jZuZZiCqH+fRS4C7lNb0ZOgaYST8AwCBm65DkzRzlV
                    dRuEDPAf2PyxlqAb+C3kSXQTEzhvd+dqislAXbS70Ozya5HpbeqSVQOtrVzf0UVfSwtZ02Qinebc
                    zDQXZqbJNZ+oTgN/XeE5epHz5b1VaF8O+I7dnd0WUwfyrj2AXJ+3UHy+oekY6e5lz8Agw23thAIB
                    TEzmcjkmMhm+P3mF74xdYmp+zutm1ooTwB8A367gHP0osqJauV1pNFFrCzfEtB64E/hRNO7ZTJN1
                    21bDMAx2dHbzlg0buW5dGwFjwWMfCQRpD4XpiURoD4V59vzZZhHURhS9EQX+AfgmcnTYwQBegyxS
                    NSLy8+SdMLYoV0zrkVv0ARQ+M0Bjz+lURHc4wu6+/hVCWkx7KMzOnl7Oz0zz/PhYM4yjwsjhNIKK
                    y1wBjlrbMTRBegJFHpjIITKExPd61POxOw1QDiaaMkjZPcC2mEaTiV4U1/TjwJuQoHwB2aA7EmF9
                    67oVQsqZJjnTJGgYGIZBWyjM9s4ujqcmSc1lvG52LTGQZzdmbfVAFkgCtrsJJcVkZRTuB34CCWgI
                    X0COCRjGkn6vydKbmH8dALoiLXSEw80mpnrEwGG9joJiGk0m7kQFEvejvq0voApIZTJMzmUYyFsn
                    0wTLGgVYeXP9m702WSKm0WTiHhRjdTv+d0okGKQjGMbEJJWdJ5stL3xtIpPm5LWrbG7voDUYAsNY
                    Oiayun8mkM5mmS3zc3xcJYvD+a8QwGgysQFNej2MLyIA9vSvJzowSFc4Qg6TVCbDkdQE37p0gYzD
                    H3vWNDmammSku5dN7R0YgGkJymDhhpvA1bkM09l5ry/fR1+Howzm0GgysQP4Agoe9QEe2LqDkZ5e
                    goscBl3hCMPtHdzat54vnDzOyWvOgpwvzkxzJDXBQGsrrcGQRLTMITE1N8fpqWukfctUD+RwWPkp
                    APw+vpAACAaDHLx+Ozt7+5YIKY8B9LW08OANN7Klo9PRuU3gpSvjnJmeWuH2zpmyfC9cucyR1EQz
                    RkLUG/mYvKSTg0L43bpXGens5qae3lVvSFsoxF0bN/O5Yy87GkddTs/y/OUxWoMhusMR5nI55swc
                    k5k0RyYnOHxlnFm/i+c1JrJID+Fw6dMQ8Ek0i9z0K9Nt6egkHLAXvDG0ro1t7Z0cSU04+oyjqQlm
                    s/O0h8LMmybp7Dzj6TRX0rM1S4n1KUka+Q+mnB4YikdjX7e8eO9DM8ppFE9noFnn7cAOmsCCBQ37
                    UVBBw2Cord2xmGazWY6mJpc4HXzqCpMyF5YLAcSjsW+i2KgVjCYTrSjm7mFUoG+YBhXW2alrvK5/
                    oOB4qRDBCu6CL6K6JYTKp/2r0wONxYPh0WTifhTl8E/AmXg0tqRa6Ggy0Y3iqA4hi9VQoloXDPIz
                    I7vob1m97kbWNPmXUz8gefmSjTP7rDEmkAE5WujNeLRwxNOrYhpNJt6DVvwOoOC+4yj9N2Gd9Fo8
                    Gsta+4JSeZ+gwUKM9g1u4O7hzRirXFI6m+VzR7/H2WnHXWuf+ieDFu5+stCbxcS0OALiLhZE0YUy
                    Yf8U9UhOAZdGk4kTSGRHge+hohUfQjn8DSGoxMXz7B24jt6WlpL7XZydYSw963VzfapDCLiHImIq
                    dVCeo6yMwcR6vcXa9hQ4R5YGEVL+Yr5+4SwHNm8tOna6Opfha+dOO46EKIafxl53BFD5uHuAL9k9
                    aLGYPo1KI92CM3E0XKmt5OVL3NLTx7au7iVet5xpcmFmmi+efIULM9OufJafxl63BFDelHMxxaOx
                    y6PJxLuBf0QREQ1jbcrhy2dPsm9+iJ5IC/O5HNfm53jlaooXx8fKrta4HD+NnSwa7F9CiXgXUbZt
                    ChWlnGWhZl8Hynlaj3pJO6hu5SQDhys9Gsu7FFYO0ydRDpOzmJkGJG923YyWy6ex7x8aLpp9OzU/
                    x4tXxhs9jf1lVJ7L9tPfwkC/zT0oVeheJDQ3yaHpojuWv7GqN285o8nEdlQD+n70JGhqS+UmPZEW
                    7ty4iV09fUXT2EFjs6+eO9PoaeyTSExPIc9xOWwBfhktYeNmJM80Skd6fvEfi4mp6JR/PBo7Fo/G
                    nkCFKx5Ck1i28+F9ilMqjX0+t+CAyKexd4bW8voCq98OVIznLyl/IYWTaIWKfTi3cqUIA3fb3XnV
                    +Jl4NHY1Ho09E4/GDqDqqx9FSVP+8o9lUiiNnQKvF6exNwE9yINWLiaqDHs/slJu5P0bKDjBFo5K
                    csWjsVPxaOzjqK/6EPA/+KJyTD6N/VVvnfWvYRgEDKNZ09gv4qB4SQlmUD2+u4EjFZ4rBDyIemer
                    Um59uzng75BZ/SAqzdSwnXq3yaexZ3KWWyOfxp4XV/OlsU8Afwt8y6XzZdESm3cCn6/wXN3IGbcq
                    lRaLnAc+hZYEiaOJX99SrUI+jX1sVhEUBkhQ1v+bMI29B5WQc7t09hkUS/qTlL+IdgjVMH/Haju6
                    VXl1Ai0GtRfF6yVw15vccOTT2PPJgAYaSy1OZW+iNHYT/fDdmQlfShrFnMZQ96+cz+gF/h7Vzi+K
                    22WMJ4E/QgPJA8hDcwLfWq3AT2NfwizlWw67jCHHxJuBZ3D+sG9B6+8Wncg1ypm/sKLG7dKNhPUu
                    FEw7SNOMqVdnd28/bxzc0Oxp7MfQGl0v1ejzgsBtKPPhAPZd8iYayjwaj8aeXf5mLcS0mEGr8Qes
                    ixmmyYv8twaDbGrvqKc09ix6ip9nYRnOABrXbKU6ITxfQUGlte7BBJFn+nEkZju1y00k/jvi0diF
                    xW/UWkyL6WJhxe1d6IvaRpOuHFjjNPY5tDj0ZRQTdwp1x48D/4cWkE6x8sfdjcpkP4AWX17vQlsu
                    Igvx2dpcekECaD7pUTRPNbLK/iZaCufH4tHYq+XAvBRTIXqRuHYj3/4tKOi2C79rWAn5p+nnUYTA
                    RWR5KlllfStyNr0XrZPklCngOeCLwGesttQDbcBBa3szWuGlECZaqeO+eDT2CtSfmAoxhBwat6Hl
                    RF4DXEcTWi8X+AZaE+n5Sk+0iJ0oK/UgztJxZlDazxNe35QiGKhLewDNp+5Ca4/1IWdEnsPIS/iV
                    tSCm5XQjccWQuHaicPymiLlxgReBnwf+08VzhoAfQpmpr7V5zATwCeC3vb4hDuhC4/4NyBr3o+j1
                    /wUSa1FMywkBN7EgrJvQfMBm68IbLnnRBZ4DPkD5UdrFGEDu50NAe4n95oGngUe8vhHlYKcGxFpl
                    HrlUl7tVO9Fq7jtQiP5mYBPqIvYjc91Dc4rtdlS/43EclgBehTHgV1CC6e9QIBfIIgTcisbGh72+
                    GW7RCGIqxlXgv61tORFksjexILBhND7Li60TPV3bUB+51TougrvOkDnkgr6EHAOn0CrkU1Y7bkQW
                    dwtL++qV8ibkQXs/Gku5yX8hb99jqOBOoUH8BuRs8sW0xsmgp+gY8N0i++RXjguj+9TGgrjaWBBX
                    mAWR5fddvOXXMzORFZ1DM/5TaNxwFjiHxF/MsxZGY5G3onpue4EbqNyq7gY+hlzCp1y+x5MoY/uf
                    0eIQb1v2fgp3HSGe06xisoOJ4rryxdsnPGzLHJrX+Lb1uhWNEfez4Igpd5X7tyOXeTXGUKDct4PA
                    z6IuYN6Nfp7aRTzUBF9Ma5NZVJ8gX9K6DQlqHxqL3IzmgQZsnu925FV7BGWtuk0KpaX/G0ou3Ynm
                    lxpq4V5fTI3BNBr35Mc++dXLb0az+RuRZ3MATYx3oloJ7da/BhqnratyOw+jVIvFmSYNw/8Dukld
                    FLGqissAAAAldEVYdGRhdGU6Y3JlYXRlADIwMjItMTItMjBUMDg6MjQ6MzkrMDA6MDD3sIrkAAAA
                    JXRFWHRkYXRlOm1vZGlmeQAyMDIyLTEyLTIwVDA4OjI0OjM5KzAwOjAwhu0yWAAAAABJRU5ErkJg
                    gg=="
                />
            </svg>
        </div>
    );
};

export default MultiWound;
export { MultiWound };
