import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import { useCage } from 'contexts/cage-context';
import { useLocationName } from 'hooks/cage-page-title';
import { useDocumentTitle } from 'hooks/useDocumentTitle';
import { useClientController_GetAllClientsWithLocations } from 'services/hooks';

import { LocationsAndCages } from '@/components/molecules/LocationsAndCages';
import { PageTitle } from 'components/Layout';

export const hasActiveBioscope = (location) =>
    location?.cages?.some((cage) => cage?.activeBioscopes?.length > 0);

const CagePageTitle = () => {
    const history = useHistory();
    const { client, location, cage } = useCage();
    const currentLocationName = location?.name;
    const clientId = client?.id;
    const { isLoading, data: clientWithLocations } =
        useClientController_GetAllClientsWithLocations();

    const locations = useMemo(() => {
        const filteredLocations = clientWithLocations?.data?.filter((loc) => loc.id === clientId);
        return filteredLocations?.[0]?.locations.sort((a, b) => a.name.localeCompare(b.name)) ?? [];
    }, [clientWithLocations, clientId]);

    const locationName = useLocationName();
    useDocumentTitle(`${locationName} - Cages`);

    function getUrlLastPart() {
        const url = window.location.href;
        const parts = url.split('/');
        const lastPart = parts[parts.length - 1];
        const lastPartNumber = Number.parseInt(lastPart);
        if (!isNaN(lastPartNumber)) {
            return '';
        }
        const secondLastPart = parts[parts.length - 2];
        const secondLastPartNumber = Number.parseInt(secondLastPart);
        if (isNaN(secondLastPartNumber)) {
            const combinedLastPart = `${secondLastPart}/${lastPart}`;
            return combinedLastPart;
        }
        return lastPart;
    }

    return (
        <PageTitle>
            <div>
                <LocationsAndCages
                    currentLocationName={currentLocationName}
                    currentCageName={cage?.name}
                    fishTypeName={location?.fishTypes?.name}
                    clientId={clientId}
                    isLoading={isLoading}
                    locations={locations}
                    history={history}
                    lastPart={getUrlLastPart()}
                />
            </div>
        </PageTitle>
    );
};

export default CagePageTitle;
export { CagePageTitle };
