import { useMemo } from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import { useIsPageAvailable } from 'hooks/is-page-available';

import DefaultLayout from '../layouts/DefaultLayout';

function Layout({ pages, layout, children }) {
    const Layout = layout ?? DefaultLayout;
    return <Layout pages={pages}>{children}</Layout>;
}

function CageRoutes({ pages }) {
    const match = useRouteMatch();
    const isPageAvailable = useIsPageAvailable();

    const availablePages = useMemo(() => {
        return (pages ?? []).filter((page) => (!isPageAvailable(page) ? false : true));
    }, [isPageAvailable, pages]);

    return (
        <Switch>
            {availablePages.map((page) => (
                <Route
                    key={page.key}
                    exact={page?.exact ?? page?.path === ''}
                    path={`${match.url}${page?.path ?? ''}`}
                    params={{ test: 'hepp' }}
                    render={(props) => (
                        <>
                            <Layout pages={pages} layout={page?.layout}>
                                {page?.page ? <page.page {...props} /> : null}
                            </Layout>
                        </>
                    )}
                />
            ))}
        </Switch>
    );
}

export default CageRoutes;
