import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import FishHealthFilter from '@/components/molecules/FishHealthFilter';
import SwitchButtons from '@/components/molecules/SwitchButtons';
import { ImageViewContainer } from '@/components/organisms/ImageViewer';
import { defaultBarChartOptions, OptoBarChart } from '@/components/organisms/OptoGraphs';
import { CombinedWelfareResultMapper } from '@/components/organisms/OptoGraphs/OptoBarChart/OptoBarChartMappers';
import { FullMaturationMapper } from '@/components/organisms/OptoGraphs/OptoMixedChart/OptoMixedChartMappers';
import { ImageState } from '@/hooks/image-state';
import { formatPercentage } from '@/utils/formatPercentage';
import BlockTitle from 'components/atoms/BlockTitle';
import FishHealthSummary from 'components/atoms/FishHealthSummary/FishHealthSummary';
import { MultiWound, NoWoundFish, SingleWound } from 'components/atoms/icons';
import KeyNumberSummary from 'components/atoms/KeyNumberSummary';
import { TrendIcon } from 'components/atoms/TrendIcon';
import { UmerBox } from 'components/atoms/UmerBox';
import KeyNumberSummarySection from 'components/molecules/KeyNumberSummarySection';
import { Tab, TabbedContent, TabbedNavigation } from 'components/molecules/TabbedNavigation';
import tabstyles from 'components/molecules/TabbedNavigation/TabbedNavigation.module.scss';
import { WoundDescription } from 'components/molecules/WoundDescription';
import { FILTER_TAGS } from 'components/organisms/ImageViewer';
import {
    autoTimeScale,
    categoryScale,
    chartTitle,
    formatAxsis,
    layoutPadding,
    multipleYAxes,
    Options,
    smoothLines,
    stackedAxis,
    startYAxisAtZero,
    StepSizeAxsis,
    suggestedMaxMin,
    tooltipFooterCalculationModifier,
    TypeNames,
    Unit,
    weightAndLengthUnitConverter,
} from 'components/organisms/OptoGraphs/optionsModifiers';
import {
    defaultLineChartOptions,
    FishHealthToDistributionOfWoundsMapper,
    FishHealthToSizeDistributionnMapper,
    FishHealthToSkinSpecklesMapper,
    FishHealthToTypesOfWoundsMapper,
    MaturationDailyDevelopmentMapper,
    OptoMixedChart,
} from 'components/organisms/OptoGraphs/OptoMixedChart';
import type { ChartActionButtons } from 'components/organisms/OptoGraphs/OptoMixedChart/OptoMixedChart';

import styles from './FishHealthColor.module.scss';

interface BaseInterface {
    averageWoundSize: number;
    bigWound: number;
    mediumWound: number;
    moreThanOneWound: number;
    noWound: number;
    oneWound: number;
    skinSpeckles: number;
    smallWound: number;
    wound: number;
    matureJaw?: number;
    hasActiveWounds?: number;
    hasHealingAndNotActiveWounds?: number;
    fullyMature?: number;
    startedMaturation?: number;
    notSuperior?: number;
}

interface Days extends BaseInterface {
    day: string;
    count: number;
    total: number;
    temperature?: number | undefined;
}

interface Trend extends BaseInterface {
    day: string;
}

interface Weekly extends BaseInterface {
    isoWeek: number;
    isoYear: number;
    count: number;
}

export type FishHealthData = {
    countForStats: number;
    lastEstimate: BaseInterface;
    slopePerDay: BaseInterface;
    lastDay: string;
    days: Days[];
    trend: Trend[];
    weekly?: Weekly[];
};

export interface FishHealthColorPageContentProps {
    source: string;
    fishHealthData: FishHealthData;
    actionButtons?: ChartActionButtons;
    healthType?: string;
    isOptoscaleAdmin?: boolean;
    imageStateByTag: Record<string, ImageState>;
}

const DEFAULT_EQ_THRESHOLD = 0.02 / 7; //More than 2 % per week is considered a significant change

export const FishHealthColorPageContent = ({
    source,
    fishHealthData,
    isOptoscaleAdmin = false,
    actionButtons = {
        copyAsPng: true,
        resetZoom: true,
        downloadAsPng: true,
        fullScreen: true,
        timeScaleSwitch: false,
    },
    healthType = 'wounds',
    imageStateByTag,
}: FishHealthColorPageContentProps) => {
    const { t } = useTranslation();
    const [activeTab, setActiveTab] = useState(
        healthType === 'wounds' ? 1 : healthType === 'scaleloss' ? 2 : 3
    );

    /* Preparing data for ImageViewer */

    const [selectedImageFilterTag, setSelectedImagedFilterTag] = useState<string>(
        FILTER_TAGS[0].tag
    );

    const imageState = imageStateByTag[selectedImageFilterTag];

    // biome-ignore lint/correctness/useExhaustiveDependencies: We want an effect whenever selectedImageFilterTag changes
    useEffect(() => {
        const lastDate = imageState.dayswithimages.findLast(
            (day) => day.day <= imageState.selectedImageDate
        );
        imageState.onAfterTimelineChangeHandler(
            lastDate?.day ?? imageState.dayswithimages.at(0)?.day ?? ''
        );
    }, [selectedImageFilterTag]);

    const onFilterGroupChangeHandler = (tag: string) => {
        setSelectedImagedFilterTag(tag);
    };

    /* -------------------------------- */

    const { lastEstimate, slopePerDay } = fishHealthData;
    const [switchValue, setSwitchValue] = useState<'weekly' | 'daily'>('weekly');
    const switchButtonsConfig = {
        switches: [
            {
                label: 'Weekly',
                value: 'weekly',
                selected: true,
            },
            {
                label: 'Daily',
                value: 'daily',
            },
        ],
        size: 'lg' as const,
        onClick: (e) => setSwitchValue(e.currentTarget.name as 'weekly' | 'daily'),
    };
    const isColor = source === 'color';
    const maturationValueCombined = isColor ? lastEstimate.fullyMature : lastEstimate.matureJaw;
    const maturationChangeCombined = isColor ? slopePerDay.fullyMature : slopePerDay.matureJaw;

    const getStatus = (value: number) =>
        value > DEFAULT_EQ_THRESHOLD
            ? 'negative'
            : value < -DEFAULT_EQ_THRESHOLD
              ? 'positive'
              : 'neutral';
    const getDirection = (value: number) =>
        value > DEFAULT_EQ_THRESHOLD ? 'up' : value < -DEFAULT_EQ_THRESHOLD ? 'down' : 'flat';

    const summary = {
        wound: {
            value: lastEstimate.wound,
            slope: slopePerDay.wound,
            status: getStatus(slopePerDay.wound),
            direction: getDirection(slopePerDay.wound),
        },
        skinSpeckles: {
            value: lastEstimate.skinSpeckles,
            slope: slopePerDay.skinSpeckles,
            status: getStatus(slopePerDay.skinSpeckles),
            direction: getDirection(slopePerDay.skinSpeckles),
        },
        maturation: {
            value: maturationValueCombined,
            slope: maturationChangeCombined,
            status: getStatus(slopePerDay.fullyMature),
            direction: getDirection(slopePerDay.fullyMature),
        },
    } as const;
    function descimalToPercentage(value: number): number {
        return Number(value * 100);
    }

    return (
        <div className={styles.FishHealthColorPageContent}>
            {/* <CagePageTitle /> */}

            <TabbedNavigation activeTab={activeTab} changeTab={setActiveTab}>
                <Tab active={activeTab === 1} index={1} to="wounds">
                    <span>{t('Wounds')}</span>
                    <span className={tabstyles.tabicon}>
                        {formatPercentage(summary.wound.value)}{' '}
                        <TrendIcon
                            direction={summary.wound.direction}
                            disabled={false}
                            status={summary.wound.status}
                            size={32}
                        />
                    </span>
                </Tab>
                <Tab active={activeTab === 2} index={2} to="scaleloss">
                    <span>{t('fishHealthIndicators.skinSpeckles')}</span>
                    <span className={tabstyles.tabicon}>
                        {formatPercentage(summary.skinSpeckles.value)}
                        <TrendIcon
                            direction={summary.skinSpeckles.direction}
                            disabled={false}
                            status={summary.skinSpeckles.status}
                            size={32}
                        />
                    </span>
                </Tab>
                <Tab active={activeTab === 3} index={3} to="maturation">
                    <span>{t('Maturation')}</span>
                    <span className={tabstyles.tabicon}>
                        {formatPercentage(summary.maturation.value)}
                        <TrendIcon
                            direction={summary.maturation.direction}
                            disabled={false}
                            status={summary.maturation.status}
                            size={32}
                        />
                    </span>
                </Tab>
            </TabbedNavigation>

            <TabbedContent activeTab={activeTab} contentIndex={1}>
                <UmerBox>
                    <FishHealthSummary
                        dataRate={summary.wound.value}
                        variant="inline"
                        label="wound"
                        downgradeRate={lastEstimate?.notSuperior}
                        status={summary.wound.status}
                        direction={summary.wound.direction}
                    />
                    <BlockTitle heading={t('Wound description')} />
                    <WoundDescription
                        isColor={isColor}
                        woundsColor={{
                            noWounds: lastEstimate.noWound,
                            activeWounds: lastEstimate.hasActiveWounds,
                            healingWounds: lastEstimate.hasHealingAndNotActiveWounds,
                            bigWound: lastEstimate.bigWound,
                            mediumWound: lastEstimate.mediumWound,
                            smallWound: lastEstimate.smallWound,
                        }}
                    />
                </UmerBox>

                {isColor && (
                    <UmerBox>
                        <BlockTitle heading={t('Types of wounds')} />
                        <KeyNumberSummarySection>
                            <KeyNumberSummary
                                heading={'Active'}
                                keyNumber={descimalToPercentage(lastEstimate.hasActiveWounds)}
                                unit="%"
                                tooltip={t('Active wounds description')}
                            />
                            <KeyNumberSummary
                                heading={'Healing'}
                                keyNumber={descimalToPercentage(
                                    lastEstimate.hasHealingAndNotActiveWounds
                                )}
                                unit="%"
                                tooltip={t('Healing wounds description')}
                            />
                        </KeyNumberSummarySection>
                        <hr />

                        <OptoMixedChart
                            chartName={t('Types of wounds')}
                            chartData={FishHealthToTypesOfWoundsMapper({
                                fishHealth: fishHealthData,
                                inactiveSetLegend: [],
                            })}
                            actionButtons={{ ...actionButtons }}
                            chartOptions={Options(defaultLineChartOptions).modify(
                                autoTimeScale(),
                                chartTitle({ title: t('Types of wounds') }),
                                layoutPadding({ padding: 0 }),
                                weightAndLengthUnitConverter({
                                    type: TypeNames.percent,
                                    fromUnit: Unit.percent,
                                    toUnit: Unit.percent,
                                    minimumFractionDigits: 1,
                                    maximumFractionDigits: 1,
                                }),
                                tooltipFooterCalculationModifier({
                                    t,
                                    operation: 'SUM',
                                    unit: Unit.percent,
                                    showTotalFish: true,
                                    fishHealthData: fishHealthData,
                                    exclude: ['temperature'],
                                }),
                                StepSizeAxsis({ stepSize: 0.5 }),
                                multipleYAxes({
                                    left: {
                                        unit: Unit.percent,
                                        text: 'Wounds',
                                    },
                                    right: { unit: Unit.celcius, text: 'Temperature' },
                                }),
                                suggestedMaxMin({ min: 0, max: 20, axsisID: 'yAxisRight' }),
                                startYAxisAtZero({ axsisID: 'yAxisLeft' }),
                                formatAxsis({
                                    axsisID: 'yAxisLeft',
                                    tickType: TypeNames.percent,
                                    descimals: 0,
                                }),

                                formatAxsis({
                                    axsisID: 'yAxisRight',
                                    tickType: TypeNames.celcius,
                                    descimals: 0,
                                })
                            )}
                        />
                    </UmerBox>
                )}

                <UmerBox>
                    <BlockTitle heading={'Size distribution'} />
                    <KeyNumberSummarySection>
                        <KeyNumberSummary
                            heading={t('fishHealthIndicators.bigWound')}
                            keyNumber={descimalToPercentage(lastEstimate.bigWound)}
                            tooltip={'> 50mm'}
                        />

                        <KeyNumberSummary
                            heading={t('fishHealthIndicators.mediumWound')}
                            keyNumber={descimalToPercentage(lastEstimate.mediumWound)}
                            tooltip={'20-50mm'}
                        />

                        <KeyNumberSummary
                            heading={t('fishHealthIndicators.smallWound')}
                            keyNumber={descimalToPercentage(lastEstimate.smallWound)}
                            tooltip={'< 20mm'}
                        />
                    </KeyNumberSummarySection>
                    <hr />
                    <OptoMixedChart
                        chartName={t('Size distribution')}
                        chartData={FishHealthToSizeDistributionnMapper({
                            fishHealth: fishHealthData as FishHealthData,
                            inactiveSetLegend: [],
                        })}
                        actionButtons={{ ...actionButtons }}
                        chartOptions={Options(defaultLineChartOptions).modify(
                            autoTimeScale(),
                            chartTitle({ title: t('Size distribution') }),
                            weightAndLengthUnitConverter({
                                type: TypeNames.percent,
                                fromUnit: Unit.percent,
                                toUnit: Unit.percent,
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 1,
                            }),
                            stackedAxis({ x: false, y: true }),
                            startYAxisAtZero({}),
                            layoutPadding({ padding: 0 }),
                            tooltipFooterCalculationModifier({
                                t,
                                operation: 'SUM',
                                unit: Unit.percent,
                                showTotalFish: true,
                                fishHealthData: fishHealthData,
                            }),
                            formatAxsis({
                                axsisID: 'y',
                                tickType: TypeNames.percent,
                                descimals: 0,
                            }),
                            suggestedMaxMin({ min: 0, max: 5 }),
                            smoothLines({})
                        )}
                    />
                </UmerBox>

                <UmerBox>
                    <BlockTitle heading={'Distribution of wounds'} />
                    <KeyNumberSummarySection>
                        <KeyNumberSummary
                            heading={'fishHealthIndicators.noWound'}
                            keyNumber={descimalToPercentage(lastEstimate.noWound)}
                            variant="animated"
                            color="#82CFCA">
                            <NoWoundFish />
                        </KeyNumberSummary>

                        <KeyNumberSummary
                            heading={'fishHealthIndicators.oneWound'}
                            keyNumber={descimalToPercentage(lastEstimate.oneWound)}
                            variant="animated"
                            color="#5bb784">
                            <SingleWound />
                        </KeyNumberSummary>

                        <KeyNumberSummary
                            heading={'fishHealthIndicators.moreThanOneWound'}
                            keyNumber={descimalToPercentage(lastEstimate.moreThanOneWound)}
                            variant="animated"
                            color="#547FA2">
                            <MultiWound />
                        </KeyNumberSummary>
                    </KeyNumberSummarySection>
                    <hr />
                    <OptoMixedChart
                        chartName={t('Distribution of wounds')}
                        chartData={FishHealthToDistributionOfWoundsMapper({
                            fishHealth: fishHealthData as FishHealthData,
                            inactiveSetLegend: [],
                        })}
                        actionButtons={{ ...actionButtons }}
                        chartOptions={Options(defaultLineChartOptions).modify(
                            autoTimeScale(),
                            chartTitle({ title: t('Distribution of wounds') }),
                            weightAndLengthUnitConverter({
                                type: 'weight',
                                fromUnit: Unit.percent,
                                toUnit: Unit.percent,
                                minimumFractionDigits: 1,
                                maximumFractionDigits: 1,
                            }),
                            startYAxisAtZero({}),
                            layoutPadding({ padding: 0 }),
                            tooltipFooterCalculationModifier({
                                t,
                                operation: 'SUM',
                                unit: Unit.percent,
                                showTotalFish: true,
                                fishHealthData: fishHealthData,
                            }),
                            formatAxsis({
                                axsisID: 'y',
                                tickType: TypeNames.percent,
                                descimals: 0,
                            }),
                            suggestedMaxMin({ min: 0, max: 5 }),
                            layoutPadding({ padding: 0 })
                        )}
                    />
                </UmerBox>
                <UmerBox>
                    <BlockTitle heading={'Detections'} />
                    <ImageViewContainer
                        key="detections"
                        instanceKey="detections"
                        isOptoScaleAdmin={isOptoscaleAdmin}
                        isColor={isColor}
                        {...imageState}
                    />
                    <div>
                        <FishHealthFilter
                            filterStateList={FILTER_TAGS}
                            onFilterGroupChange={onFilterGroupChangeHandler}
                        />
                    </div>
                </UmerBox>
            </TabbedContent>

            <TabbedContent activeTab={activeTab} contentIndex={2}>
                <UmerBox>
                    <FishHealthSummary
                        dataRate={summary.skinSpeckles.value} // what would be the scale loss percentage?
                        status={summary.skinSpeckles.status}
                        variant="inline"
                        direction={summary.skinSpeckles.direction}
                        label="skinSpeckles"
                    />
                    <hr />
                    <OptoMixedChart
                        chartName={t('fishHealthIndicators.skinSpeckles')}
                        chartData={FishHealthToSkinSpecklesMapper({
                            fishHealth: fishHealthData,
                            inactiveSetLegend: [],
                        })}
                        actionButtons={{ ...actionButtons }}
                        chartOptions={Options(defaultLineChartOptions).modify(
                            autoTimeScale(),
                            chartTitle({
                                title: t('fishHealthIndicators.skinSpeckles'),
                            }),
                            startYAxisAtZero({}),
                            weightAndLengthUnitConverter({
                                type: TypeNames.percent,
                                fromUnit: Unit.percent,
                                toUnit: Unit.percent,
                            }),
                            formatAxsis({
                                axsisID: 'y',
                                tickType: TypeNames.percent,
                                descimals: 0,
                            }),
                            layoutPadding({ padding: 0 })
                        )}
                    />
                </UmerBox>
            </TabbedContent>

            <TabbedContent activeTab={activeTab} contentIndex={3}>
                {isColor && (
                    <>
                        <UmerBox>
                            <FishHealthSummary
                                dataRate={summary.maturation.value} // what would be the maturation percentage?
                                status={summary.maturation.status}
                                variant="inline"
                                label="maturation"
                                direction={summary.maturation.direction}
                            />
                        </UmerBox>
                        <UmerBox>
                            <div className={styles.switchWrapper}>
                                <SwitchButtons {...switchButtonsConfig} />
                            </div>
                            {switchValue === 'weekly' ? (
                                <OptoBarChart
                                    chartData={CombinedWelfareResultMapper({
                                        welfareResult: fishHealthData as FishHealthData,
                                        dayTags: [],
                                        inactiveSetLegend: ['noMaturation'],
                                    })}
                                    chartName="Weekly development of Maturation"
                                    chartOptions={Options(defaultBarChartOptions).modify(
                                        categoryScale({
                                            xAxsisUnitName: 'Week',
                                        }),
                                        suggestedMaxMin({ min: 0, max: 5 }),
                                        startYAxisAtZero({}),
                                        chartTitle({
                                            title: t('Weekly development of Maturation'),
                                        }),
                                        formatAxsis({
                                            axsisID: 'y',
                                            tickType: TypeNames.percent,
                                            descimals: 0,
                                        })
                                    )}
                                />
                            ) : (
                                <>
                                    <OptoMixedChart
                                        chartData={FullMaturationMapper({
                                            fishHealth: fishHealthData,
                                        })}
                                        chartName={t('Daily development of maturation')}
                                        actionButtons={{ ...actionButtons }}
                                        chartOptions={Options(defaultLineChartOptions).modify(
                                            autoTimeScale(),
                                            weightAndLengthUnitConverter({
                                                type: 'weight',
                                                fromUnit: '%',
                                                toUnit: '%',
                                                minimumFractionDigits: 1,
                                                maximumFractionDigits: 1,
                                            }),
                                            stackedAxis({ x: true, y: false }),
                                            startYAxisAtZero({}),
                                            layoutPadding({ padding: 0 }),
                                            tooltipFooterCalculationModifier({
                                                t,
                                                operation: 'SUM',
                                                unit: '%',
                                                showTotalFish: true,
                                                fishHealthData: fishHealthData,
                                            }),
                                            suggestedMaxMin({ min: 0, max: 5 }),
                                            layoutPadding({ padding: 0 }),
                                            smoothLines({}),
                                            chartTitle({
                                                title: t('Daily development of maturation'),
                                            }),
                                            formatAxsis({
                                                axsisID: 'y',
                                                tickType: TypeNames.percent,
                                                descimals: 0,
                                            })
                                        )}
                                    />
                                </>
                            )}
                        </UmerBox>
                    </>
                )}
                {!isColor && (
                    <UmerBox>
                        <FishHealthSummary
                            dataRate={summary.maturation.value} // what would be the maturation percentage?
                            status={summary.maturation.status}
                            direction={summary.maturation.direction}
                            variant="inline"
                            label="maturation"
                        />

                        <hr />

                        <UmerBox>
                            <OptoMixedChart
                                chartName={t('Daily development of maturation')}
                                chartData={MaturationDailyDevelopmentMapper({
                                    fishHealth: fishHealthData as FishHealthData,
                                    source,
                                    inactiveSetLegend: [],
                                })}
                                actionButtons={{ ...actionButtons }}
                                chartOptions={Options(defaultLineChartOptions).modify(
                                    autoTimeScale(),
                                    weightAndLengthUnitConverter({
                                        type: 'weight',
                                        fromUnit: '%',
                                        toUnit: '%',
                                    }),
                                    chartTitle({
                                        title: t('Daily development of maturation'),
                                    }),
                                    stackedAxis({ x: true, y: false }),
                                    startYAxisAtZero({}),
                                    suggestedMaxMin({ min: 0, max: 5 }),
                                    layoutPadding({ padding: 0 }),
                                    smoothLines({})
                                )}
                            />
                        </UmerBox>
                    </UmerBox>
                )}
            </TabbedContent>
        </div>
    );
};
