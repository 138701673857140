import { useTranslation } from 'react-i18next';
import settings from 'config/settings';
import Slider from 'rc-slider';

import { Button } from 'components/atoms/Buttons';
import NoData from 'components/NoData';

import styles from './BioscopeConfiguration.module.scss';

export interface BioscopeConfigurationProps {
    title?: string;
    isOptoscaleAdmin: boolean;
    BioscopeConfig?: {
        LUX?: number;
        bioscopeId?: number;
        depth?: number;
        light_intensity?: number;
        pitch?: number;
        roll?: number;
        temperature?: number;
    };
    canSubmitBioscopeConfiguration?: boolean;
    onSliderChange?: (lux: number) => void;
    onSubmitBioscopeConfiguration?: () => void;
    onClickRevertSubmit?: () => void;
}

const BioscopeConfiguration = ({
    title = 'Bioscope Configuration',
    isOptoscaleAdmin,
    BioscopeConfig,
    canSubmitBioscopeConfiguration = false,

    onSliderChange,
    onSubmitBioscopeConfiguration,
    onClickRevertSubmit,
}: BioscopeConfigurationProps) => {
    const { t } = useTranslation();
    return (
        <div className={styles.BioscopeConfiguration}>
            <h2>{t(title)}</h2>
            {!BioscopeConfig ? (
                <NoData color={'warning'} />
            ) : (
                <table>
                    <tbody>
                        <tr>
                            <td>{t('LUX')}</td>
                            <td style={{ width: '200px' }}>
                                <Slider
                                    min={0}
                                    max={100}
                                    value={BioscopeConfig?.LUX ?? 0}
                                    onChange={isOptoscaleAdmin && onSliderChange}
                                    railStyle={{
                                        height: 2,
                                    }}
                                    handleStyle={{
                                        height: 15,
                                        width: 15,
                                        backgroundColor: settings.primaryColor,
                                        border: 0,
                                    }}
                                    trackStyle={{
                                        background: 'none',
                                    }}
                                />
                            </td>
                            <td>
                                <span
                                    className="badge badge-secondary"
                                    style={{ marginLeft: '15px' }}>
                                    {BioscopeConfig?.LUX}%
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>{t('Pitch')}</td>
                            <td className="badge badge-secondary ml-2">
                                <span>{BioscopeConfig?.pitch}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>{t('Roll')}</td>
                            <td className="badge badge-secondary ml-2">
                                <span>{BioscopeConfig?.roll}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>{t('Depth')}</td>
                            <td className="badge badge-secondary ml-2">
                                <span>{BioscopeConfig?.depth}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>{t('Light Intensity')}</td>
                            <td className="badge badge-secondary ml-2">
                                <span>{BioscopeConfig?.light_intensity}</span>
                            </td>
                        </tr>
                        <tr>
                            <td>{t('Temperature')}</td>
                            <td className="badge badge-secondary ml-2">
                                <span>{BioscopeConfig?.temperature}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            )}
            {isOptoscaleAdmin && BioscopeConfig && (
                <div style={{ float: 'right' }}>
                    <Button onClick={onClickRevertSubmit}>{t('Revert')}</Button>

                    <Button
                        style={{ marginLeft: '10px' }}
                        onClick={onSubmitBioscopeConfiguration}
                        disabled={!canSubmitBioscopeConfiguration}>
                        {t('Apply Changes')}
                    </Button>
                </div>
            )}
        </div>
    );
};

export default BioscopeConfiguration;
export { BioscopeConfiguration };
