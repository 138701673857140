function formatBucketValue(from, to) {
    if (from === undefined && to === undefined) {
        return null;
    }

    if (from === undefined) {
        if (to === null) return '∞';
        return `${to}`;
    }

    if (to === undefined) {
        if (from === null) return '∞';
        return `${from}`;
    }

    if (from === null && to === null) return '∞';
    if (from === null) return `<${to}`;
    if (to === null) return `${from}+`;

    // from !== null && to !== null
    return `${from} - ${to}`;
}

function formattedBucket({ from, to, metric = '' }) {
    const bucketFrom =
        from === null ? null : Array.isArray(from) ? formatBucketValue(from?.[0], from?.[1]) : from;
    const bucketTo =
        to === null ? null : Array.isArray(to) ? formatBucketValue(to?.[0], to?.[1]) : to;

    const formatted = formatBucketValue(bucketFrom, bucketTo);

    return [metric ? `${formatted} ${metric}` : formatted];
}

export default formattedBucket;
