import React from 'react';

import { FormattedOverviewProvider } from './formatted-overview-context';
import { GraphDataProvider } from './graph-data-context';
import Overview from './Overview';
import { OverviewProvider } from './overview-context';

function OverviewContainer() {
    return (
        <OverviewProvider>
            <FormattedOverviewProvider>
                <GraphDataProvider>
                    <Overview />
                </GraphDataProvider>
            </FormattedOverviewProvider>
        </OverviewProvider>
    );
}

export default OverviewContainer;
