import React from 'react';
import { useTranslation } from 'react-i18next';

import { Chart } from 'components/Chart';

import WeeklyGraphOptions from './WeeklyGraphOptions';
import weeklyGraphPlugins from './weeklyGraphPlugins';

interface WeeklyGraphProps {
    data: {
        x: string;
        year: number;
        weekNumber: number;
        femaleAvg: number;
        caligusElongatusAvg: number;
        movingStageAvg: number;
        isUncertainLiceCount: boolean;
    }[];
    height?: number;
    header?: any;
    sidebar?: any;
    showYLabel?: boolean;
    maintainAspectRatio?: boolean;
    showMaximumLiceInformation?: boolean;
}

export default ({
    height,
    data,
    header,
    sidebar,
    showYLabel,
    maintainAspectRatio,
    showMaximumLiceInformation,
}: WeeklyGraphProps) => {
    const { t } = useTranslation();
    const weekDates = () => data.map((datum) => datum.weekNumber);

    function bgcolor(
        data: { isUncertainLiceCount: boolean }[],
        certainColor: string,
        uncertainColor: string
    ) {
        return data.map((item) => (item.isUncertainLiceCount ? uncertainColor : certainColor));
    }

    const datasets = [
        {
            label: t('Adult Female Lice'),
            backgroundColor: bgcolor(data, 'rgba(91, 183, 132, 0.5)', '#5bb784'),
            borderColor: '#5bb784',
            fill: false,
            hidden: false,
            showLine: true,
            data: data.map((item) => ({ x: item.x, y: item.femaleAvg })),
        },
    ];

    if (showMaximumLiceInformation) {
        datasets.push(
            {
                label: t('Mobile Lice'),
                backgroundColor: bgcolor(data, 'rgba(157, 212, 182, 0.4)', '#9dd4b6'),
                borderColor: '#9dd4b6',
                fill: false,
                hidden: false,
                showLine: true,
                data: data.map((item) => ({ x: item.x, y: item.movingStageAvg })),
            },
            {
                label: t('Caligus elongatus'),
                backgroundColor: bgcolor(data, 'rgba(53, 77, 120, 0.5)', '#354d78'),
                borderColor: '#354d78',
                fill: false,
                hidden: false,
                showLine: true,
                data: data.map((item) => ({ x: item.x, y: item.caligusElongatusAvg })),
            }
        );
    }

    return (
        <Chart
            style={
                height && {
                    height,
                }
            }
            type="bar"
            data={{
                labels: weekDates,
                datasets: datasets,
            }}
            options={WeeklyGraphOptions(showYLabel, maintainAspectRatio)}
            plugins={weeklyGraphPlugins}
            header={header}
            sidebar={sidebar}
        />
    );
};
