import type { Point } from 'chart.js';
import moment from 'moment';
import {
    MeasurementOverviewDayResult,
    MeasurmentOverviewResult,
    ReportDetail,
} from 'services/types';
import { stringToFixedNum } from 'utils';

const dayTagValues: string[] = ['all', 'day', 'short_day', 'night'];

/**
 *
 * @param days
 * @returns Array of unique daypart tags
 */
const getUniqueDayPartTags = (days: MeasurementOverviewDayResult[]) => {
    const labelLegends = new Set<string>();
    for (const { daypartTag } of days) {
        labelLegends.add(daypartTag);
    }

    return Array.from(labelLegends);
};

type MapDaypartTagAsIndex<T> = Record<string, T>;
interface MapDaypartTagAsIndexProps {
    days: MeasurementOverviewDayResult[];
    property?: string;
    chartType?: string;
    dayTags?: string[];
}

const mapDaypartTagAsIndex = ({
    days,
    property = 'measCnt',
    dayTags = ['all', 'day', 'short_day', 'night'],
}: MapDaypartTagAsIndexProps) => {
    const daypartTagIndex = {};

    for (const { daypartTag, data, day } of days) {
        if (!daypartTagIndex[daypartTag]) {
            daypartTagIndex[daypartTag] = [];
        }

        const chartPoint = {
            x: getMillisecondsFromDay(day),
            y: stringToFixedNum(data[property], 2),
        };

        dayTags.includes(daypartTag) ? daypartTagIndex[daypartTag].push(chartPoint) : null;
    }

    return daypartTagIndex as MapDaypartTagAsIndex<Point[]>;
};

const mapByDaypartTag = (days: MeasurementOverviewDayResult[]) => {
    const byDay =
        days?.reduce((acc: Record<string, Record<string, MeasurementOverviewDayResult>>, cur) => {
            if (!(cur.day in acc)) acc[cur.day] = {};
            acc[cur.day][cur.daypartTag] = cur;
            return acc;
        }, {}) ?? {};

    return byDay;
};

const sortDays = (days: MeasurementOverviewDayResult[]) => {
    const sortedDays =
        [...new Set(days?.map((day) => day.day))]?.sort((a: any, b: any) =>
            a.day > b.day ? -1 : a.day < b.day ? 1 : 0
        ) ?? [];

    return sortedDays;
};

const getSortedDaysInDataSet = (days: MeasurementOverviewDayResult[]) => {
    return (
        days
            .flatMap(({ day }) => day)
            //  .filter((value, index, self) => self.indexOf(value) === index)
            .sort()
    );
};

type Period = MeasurmentOverviewResult['period'] & {
    day_from: string;
    day_to: string;
};

const getFromToDateRange = (period: Period) => {
    const { day_from, day_to } = period;
    return {
        min: new Date(day_from).valueOf(),
        max: new Date(day_to).valueOf(),
    };
};

const getMillisecondsFromDay = (dateString: string): number => {
    return moment(dateString).toDate().valueOf();
};

const getFromToDataTypeDateRange = (data: ReportDetail | MeasurmentOverviewResult) => {
    return ((data) => {
        if (isMeasurmentOverviewResult(data)) {
            return getFromToDateRange(data.period as Period);
        }
        return {
            min: new Date(data.data.start).valueOf(),
            max: new Date(data.data.stop).valueOf(),
        };
    })(data);
};

function isMeasurmentOverviewResult(
    data: MeasurmentOverviewResult | ReportDetail
): data is MeasurmentOverviewResult {
    return (data as MeasurmentOverviewResult)?.period !== undefined;
}

function sortLabelsByDate(labels) {
    return labels.filter((value, index, self) => self.indexOf(value) === index).sort();
}

const resizeGraphHandler = (chart) => {
    try {
        let daParent = chart.canvas;
        const currentChart = chart;
        while (daParent && daParent.getAttribute('data-pagetype') !== 'page-content') {
            daParent = daParent?.parentElement;
        }

        const timeoutFunc = () => {
            if (daParent && currentChart) {
                daParent.style.overflow = 'hidden';
                if (currentChart.canvas) {
                    currentChart?.update();
                }
            }
        };

        if (daParent) {
            daParent.classList.add('autoResize');
            daParent.style.overflow = 'auto';
            setTimeout(timeoutFunc, 500);
        }
    } catch (e) {
        console.warn(e);
    }
};

export default getUniqueDayPartTags;
export {
    getUniqueDayPartTags,
    mapDaypartTagAsIndex,
    getSortedDaysInDataSet,
    getFromToDateRange,
    getMillisecondsFromDay,
    getFromToDataTypeDateRange,
    isMeasurmentOverviewResult,
    sortLabelsByDate,
    dayTagValues,
    sortDays,
    mapByDaypartTag,
    resizeGraphHandler,
};
