import React from 'react';
import { Button } from 'reactstrap';
import { saveAs } from 'file-saver';
import DownloadIcon from 'components/atoms/icons/DownloadIcon';
export const DownloadAsPng = ({ chart }) => (
    <Button
        title="Download as .png"
        onClick={() => {
            chart.canvas.toBlob((blob) => saveAs(blob, 'chart.png'));
        }}
        outline>
        <DownloadIcon size={24} />
    </Button>
);

export default DownloadAsPng;
