import React from 'react';
import { useTranslation } from 'react-i18next';
import isNumberOk from 'utils/isNumberOk';

import { KeyNumber } from 'components/Layout';
import { KeyNumberValue } from 'components/Layout/KeyNumbers/KeyNumber';

import styles from './kfactor.module.scss';

const KFACTOR_GROWTH_DIFF_THRESHOLD = 0;

const KFactorGrowth = ({ value }) => {
    const { t } = useTranslation();

    if (!isNumberOk(value)) {
        return null;
    }

    return (
        <div className={styles.value}>
            <span title={`${t('KFactor growth')}: ${value}`}>
                {Math.abs(value) < KFACTOR_GROWTH_DIFF_THRESHOLD ? (
                    ''
                ) : value >= 0 ? (
                    <span>
                        {t('KFactor raising')}
                        <i className="fa fa-caret-up text-danger ml-1" />
                    </span>
                ) : (
                    <span>
                        {t('KFactor falling')}
                        <i className="fa fa-caret-down text-danger ml-1" />
                    </span>
                )}
            </span>
        </div>
    );
};

export default ({
    kfactor,
    kfactorGrowth,
    expectedKFactor,
    weight,
    weightUnit = 'kg', // for backwards compatibility
    tooltipText,
}) => {
    const { t } = useTranslation();

    if (!isNumberOk(expectedKFactor) || !isNumberOk(weight)) {
        return <KeyNumber label="K-factor" value={kfactor} ifBad="-" />;
    }

    return (
        <KeyNumber
            label={t('K-factor')}
            value={kfactor}
            ifBad="-"
            data-tut="weightAndGrowthK"
            tooltipText={tooltipText}>
            <div className={styles.content}>
                <div>
                    <KeyNumberValue ifBad="-">{kfactor}</KeyNumberValue>
                </div>
                <KFactorGrowth value={kfactorGrowth} />
            </div>
            <div className="text-muted">
                {t('kFactorDetails', {
                    weight,
                    weightUnit,
                    expectedKFactor: expectedKFactor,
                })}
            </div>
        </KeyNumber>
    );
};
