import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { LastMeasurement as LastMeasurementType } from 'services/types';

import { Box } from 'components/Layout';

interface LastMeasurementProps {
    lastMeasurement: LastMeasurementType;
}

const LastMeasurement = ({ lastMeasurement }: LastMeasurementProps) => {
    const { t } = useTranslation();
    return (
        <Box title={t('Last measurement')} customStyleOuter={{ borderRadius: '0px' }}>
            {lastMeasurement
                ? moment(lastMeasurement.timestamp).format('DD.MM.YYYY [kl.] HH.mm')
                : t('None')}
        </Box>
    );
};

export default LastMeasurement;
export { LastMeasurement };
