import React from 'react';

//import moment from 'moment';
import styles from './graph.module.scss';
//import DateNumbers from './DateNumbers';

// function formatDate(date) {
//     if (!date) return '';
//     return (moment.isMoment(date) ? date.toDate() : date).toDateString();
// }

const GraphModal = ({ currentMeasurement, isOptoscaleAdmin }) => {
    // this is very old code and ether needs to be removed or
    // refactored according to new data structure. mbd (in parent)
    // and currentMeasurement does not point to selected data anymore
    return (
        <>
            <div className={styles.modalSidebar} />
        </>
    );

    // return (
    //     <>
    //         <div className={styles.modalSidebar}>
    //             <div className={styles.modalSidebarHeader}>
    //                 <h4>
    //                     {currentMeasurement &&
    //                         formatDate(currentMeasurement.startOfDay)}
    //                 </h4>
    //             </div>
    //             <div className={styles.modalSidebarBody}>
    //                 <DateNumbers
    //                     isOptoscaleAdmin={isOptoscaleAdmin}
    //                     currentMeasurement={currentMeasurement}
    //                 />
    //             </div>
    //         </div>
    //     </>
    // );
};
export default GraphModal;
