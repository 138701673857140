import React from 'react';
import { Col, Container, Row } from 'reactstrap';

import { CageType, FishType } from '@/services/types';

import { PageTitle } from '../../../../components/Layout';

import { BioscopeLicenseContainer } from './BioscopeLicenseContainer/BioscopeContractListContainer';
import ClientLicense from './ClientLicense/ClientLicense';
import { ApiUsers, ApiUsersProps } from './ApiUsers';
import ClientLocations from './ClientLocations';

export type ClientProps = {
    client: {
        id: number;
        name: string;
        locations: {
            id: number;
            name: string;
            timezone: string;
            aquacultureSiteId: number;
            latitude: number;
            longitude: number;
            fishType: FishType;

            [x: string]: any;
        }[];
    };
    cageTypesList: CageType[];
    fishTypesList: FishType[];
    createNewCage: (locationId: number, name: string, cagetypeid: number) => Promise<unknown>;
    updateLocation: (input: {
        id: number;
        name: string;
        timezone: string;
        aquacultureSiteId?: number;
        latitude?: number;
        longitude?: number;
        fishtypeid: FishType['id'];
    }) => Promise<unknown>;

    createNewLocation: (input: {
        name: string;
        timezone: string;
        aquacultureSiteId?: number;
        latitude?: number;
        longitude?: number;
        fishtypeid: FishType['id'];
    }) => Promise<unknown>;
    refetch: () => void;
} & ApiUsersProps;

export const Client = ({
    client,
    apiUsers,
    createApiUser,
    createNewCage,
    createNewLocation,
    updateLocation,
    refetch,
    cageTypesList,
    fishTypesList,
}: ClientProps) => (
    <Container>
        <Row>
            <Col>
                <PageTitle>{client.name}</PageTitle>
            </Col>
        </Row>
        <Row>
            <Col md={12}>
                <ApiUsers apiUsers={apiUsers} createApiUser={createApiUser} />
            </Col>
        </Row>
        <Row>
            <Col md={12}>
                <BioscopeLicenseContainer clientId={client.id} />
            </Col>
        </Row>
        <Row>
            <Col>
                <ClientLocations
                    cageTypesList={cageTypesList}
                    fishTypesList={fishTypesList}
                    client={client}
                    createNewCage={createNewCage}
                    createNewLocation={createNewLocation}
                    updateLocation={updateLocation}
                    refetch={refetch}
                    apiUsers={[]}
                    createApiUser={(): Promise<unknown> => {
                        // TODO: Implement this function
                        throw new Error('Function not implemented.');
                    }}
                />
            </Col>
        </Row>
        <Row>
            <Col>
                <ClientLicense clientId={client.id} />
            </Col>
        </Row>
    </Container>
);
