import React from 'react';

export const ArrowSoftIcon = () => {
    return (
        <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M8.94 8L6 11C5.86303 11.2868 5.9217 11.6288 6.14645 11.8536C6.37119 12.0783 6.7132 12.137 7 12L10.5 8.5C10.7925 8.20718 10.7925 7.73282 10.5 7.44L7 4C6.7132 3.86303 6.37119 3.9217 6.14645 4.14645C5.9217 4.37119 5.86303 4.7132 6 5L8.94 8Z"
                fill="currentColor"
                fillOpacity="0.7"
            />
        </svg>
    );
};
