import React from 'react';

import styles from './PageHeaderBar.module.scss';

export interface PageHeaderBarProps {
    children?: React.ReactNode;
}

const PageHeaderBar = ({ children }: PageHeaderBarProps) => {
    return <div className={styles.pageheaderbar}>{children}</div>;
};

export default PageHeaderBar;
export { PageHeaderBar };
