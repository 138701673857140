import React from 'react';
import cn from 'classnames';

import styles from './pageTitle.module.scss';

const PageTitle = ({ children, className = undefined }) => {
    return <h1 className={cn(styles.pageTitle, className)}>{children}</h1>;
};

export default PageTitle;
export { PageTitle };
