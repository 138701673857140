import React from 'react';
import { MdNotInterested, MdTrendingDown, MdTrendingFlat, MdTrendingUp } from 'react-icons/md';
import classNames from 'classnames';

import styles from './TrendIcon.module.scss';

export interface TrendIconProps {
    disabled: boolean;
    direction: 'up' | 'down' | 'flat';
    status?: 'negative' | 'positive' | 'neutral';
    size?: number;
}

const TrendIcon = ({ disabled, status, direction, size }: TrendIconProps) => {
    if (disabled) {
        return (
            <MdNotInterested size={size} className={classNames(styles.trendicon, styles[status])} />
        );
    }

    if (direction === 'flat') {
        return (
            <MdTrendingFlat size={size} className={classNames(styles.trendicon, styles[status])} />
        );
    }
    if (direction === 'up') {
        return (
            <MdTrendingUp size={size} className={classNames(styles.trendicon, styles[status])} />
        );
    }
    if (direction === 'down') {
        return (
            <MdTrendingDown size={size} className={classNames(styles.trendicon, styles[status])} />
        );
    }
};

export default TrendIcon;
export { TrendIcon };
