import React, { useEffect } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import styles from './StickyAlert.module.scss';

export type Position = 'top' | 'bottom';

export interface StickyAlertProps {
    position: Position;
    children?: React.ReactNode;
}

const StickyAlert = ({ children, position = 'top' }: StickyAlertProps) => {
    const { ref, height } = useResizeDetector();

    const updateHeightVariable = (height: number | undefined, position: string) => {
        if (height) {
            if (position === 'top') {
                document.documentElement.style.setProperty('--topStickyAlertHeight', `${height}px`);
            } else if (position === 'bottom') {
                document.documentElement.style.setProperty(
                    '--bottomStickyAlertHeight',
                    `${height}px`
                );
            }
        }
    };

    useEffect(() => {
        updateHeightVariable(height, position);
    }, [height, position]);

    return (
        <div ref={ref} data-size={position} className={styles.stickyalert}>
            {children}
        </div>
    );
};

export default StickyAlert;
export { StickyAlert };
