import React, { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { DownloadIcon } from 'components/atoms/icons/DownloadIcon';
import { OptoTooltip } from 'components/atoms/OptoTooltip/OptoTooltip';

import styles from './DownloadButton.module.scss';

export interface DownloadButtonProps {
    onClick?: (ev: MouseEvent<HTMLAnchorElement | HTMLButtonElement>) => void;
    open?: boolean;
}

const DownloadButton = ({ onClick = () => {}, open = false }: DownloadButtonProps) => {
    const { t } = useTranslation();
    return (
        <div className={styles.downloadbutton}>
            <OptoTooltip content={t('Download images')} nub="up-right">
                <button className={styles.downloadbutton_element} type="button" onClick={onClick}>
                    <span>
                        <DownloadIcon size={24} />

                        <i
                            className={classnames({
                                fas: true,
                                'fa-caret-up': open,
                                'fa-caret-down': !open,
                            })}
                        />
                    </span>
                </button>
            </OptoTooltip>
        </div>
    );
};

export default DownloadButton;
export { DownloadButton };
