import { max, min } from 'lodash';

/* 
    AutoAdjustZoomOutLimits based on datasets
    *This plugin is an extension to chartjs-plugin-zoom 
*/

export default {
    beforeUpdate: (chart) => {
        if (chart.options.plugins.zoom) {
            const [xValues, yValues] = chart.data.datasets.reduce(
                ([xValues, yValues], dataset) => {
                    return [
                        xValues.concat(dataset.data.map((data) => data.x)),
                        yValues.concat(dataset.data.map((data) => data.y)),
                    ];
                },
                [[], []]
            );

            const xMin = min(xValues);
            const xMax = max(xValues);
            const yMin = min(yValues);
            const yMax = max(yValues);

            if (chart.options.plugins.zoom.zoom) {
                chart.options.plugins.zoom.zoom.rangeMax = {
                    x: xMax,
                };
                chart.options.plugins.zoom.zoom.rangeMin = {
                    x: xMin,
                };
            }

            if (chart.options.plugins.zoom.pan) {
                chart.options.plugins.zoom.pan.rangeMax = {
                    x: xMax,
                    y: yMax,
                };
                chart.options.plugins.zoom.pan.rangeMin = {
                    x: xMin,
                    y: yMin,
                };
            }
        }
    },
};
