import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';

import { Button } from 'components/atoms/Buttons';

import styles from './ModalCreateBioscopeContract.module.scss';

interface NewContractFormValues {
    description: string;
    count: number;
}

export interface ModalCreateBioscopeContractProps {
    onSubmitted: (ev: NewContractFormValues) => void;
}

const ModalCreateBioscopeContract = ({ onSubmitted }: ModalCreateBioscopeContractProps) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [formData, setFormData] = useState<Partial<NewContractFormValues>>({});

    function updateForm<T extends keyof NewContractFormValues>(key: T) {
        return (value: NewContractFormValues[T]) =>
            setFormData((formData) => ({ ...formData, [key]: value }));
    }

    const toggle = () => setOpen(!open);
    const isValid = formData.count >= 0 && !!formData.description;

    const onFormDataonSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
        if (isValid) {
            onSubmitted(formData as NewContractFormValues);
            setOpen(false);
        }
        ev.preventDefault();
    };

    const onCancel = () => {
        setFormData({});
        setOpen(false);
    };
    return (
        <div className={styles.modaladdbiocopeoncontract}>
            <Button
                id="openModalCreateBioscopeContract"
                data-testid="openModalCreateBioscopeContract"
                onClick={() => setOpen(true)}>
                {t('Add new Contract')}
            </Button>
            <Modal isOpen={open} toggle={toggle}>
                <ModalHeader toggle={toggle}>{t('Create Bioscope Contract')}</ModalHeader>
                <Form onSubmit={onFormDataonSubmit} onReset={onCancel}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="ContractDescription">{t('Description')}</Label>
                            <Input
                                id="ContractDescription"
                                name="ContractDescription"
                                type="text"
                                value={formData.description}
                                onChange={(ev) => updateForm('description')(ev.target.value)}
                                placeholder={t('')}
                                required={false}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label for="ContractNumber">{t('Bioscope Count')}</Label>
                            <Input
                                id="ContractNumber"
                                name="ContractNumber"
                                type="number"
                                value={formData.count}
                                onChange={(ev) => updateForm('count')(parseInt(ev.target.value))}
                                placeholder={t('')}
                                required={false}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit" disabled={!isValid}>
                            {t('Create New Report')}
                        </Button>
                        <Button color="secondary" type="reset">
                            {t('Cancel')}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </div>
    );
};

export default ModalCreateBioscopeContract;
export { ModalCreateBioscopeContract };
