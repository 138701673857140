import { useCage } from 'contexts/cage-context';
import { useCageFilter } from 'contexts/cage-filter-context';
import {
    useWelfareController_FullWelfare,
    useWelfareController_FullWelfareColor,
} from 'services/hooks';
import tsToString from 'utils/tsToString';

import { PageHeaderBar } from '@/components/molecules/PageHeaderBar';
import { Loader } from 'components/atoms/Loader';
import SomethingHappened from 'components/SomethingHappened';

import CagePageTitle from '../../components/CagePageTitle';

import FishHealthColor from './FishHealthColor';

function FishHealthColorContainer() {
    const { cageId, timezone, loading: cageLoading } = useCage();
    const { normalizedFrom, normalizedTo, daypartTag } = useCageFilter();
    const from = normalizedFrom ? tsToString(normalizedFrom, timezone) : undefined;
    const to = normalizedTo ? tsToString(normalizedTo, timezone) : undefined;

    const fishHealthColor = useWelfareController_FullWelfareColor(
        cageId,
        { from: from, to: to, daypartTag: daypartTag },
        { staleTime: 5 * 60 * 1000 }
    );

    const fishHealth = useWelfareController_FullWelfare(
        cageId,
        { from: from, to: to, daypartTag: daypartTag },
        { staleTime: 5 * 60 * 1000 }
    );

    const loading = cageLoading || fishHealthColor.isLoading || fishHealth.isLoading;

    if (loading) {
        return <Loader />;
    }

    const error = fishHealthColor.error;
    const dataColor = fishHealthColor?.data?.data;
    const dataBW = fishHealth?.data?.data;

    if (error || !Array.isArray(dataColor?.cage?.list) || !Array.isArray(dataBW?.cage?.list)) {
        return <SomethingHappened error={error} />;
    }

    return (
        <>
            <PageHeaderBar>
                <CagePageTitle />
            </PageHeaderBar>
            <FishHealthColor
                data={dataColor.cage}
                dataBW={dataBW.cage}
                getStatus={(label) => {
                    //getFutureStatus(label, dataColor?.cage?.head, dataColor?.cage?.stats);
                }}
                getStatusBW={(label) => {
                    // getFutureStatus(label, dataBW?.cage?.head, dataBW?.cage?.stats)
                }}
            />
        </>
    );
}

export default FishHealthColorContainer;
