import { useCage } from 'contexts/cage-context';
import { useCageFilter } from 'contexts/cage-filter-context';
import { useWelfareController_FullWelfare } from 'services/hooks';
import tsToString from 'utils/tsToString';

import { Loader } from 'components/atoms/Loader';
import SomethingHappened from 'components/SomethingHappened';

import CagePageTitle from '../../components/CagePageTitle';

import FishHealth from './FishHealth';
import { getFutureStatus } from './utils';

function FishHealthContainer() {
    const { cageId, timezone, loading: cageLoading } = useCage();
    const { normalizedFrom, normalizedTo, daypartTag } = useCageFilter();
    const from = normalizedFrom ? tsToString(normalizedFrom, timezone) : undefined;
    const to = normalizedTo ? tsToString(normalizedTo, timezone) : undefined;

    const fishHealth = useWelfareController_FullWelfare(
        cageId,
        { from: from, to: to, daypartTag: daypartTag },
        { staleTime: 5 * 60 * 1000 }
    );

    const loading = cageLoading || fishHealth.isLoading;

    if (loading) {
        return <Loader />;
    }

    const error = fishHealth.error;
    const data = fishHealth.data.data;

    if (error || !Array.isArray(data?.cage?.list)) {
        return <SomethingHappened error={error} />;
    }

    return (
        <>
            <CagePageTitle />
            <FishHealth
                data={data.cage}
                getStatus={(label) => getFutureStatus(label, data?.cage?.head, data?.cage?.stats)}
            />
        </>
    );
}

export default FishHealthContainer;
