import React, { useContext, useEffect, useState } from 'react';
import FullScreenContext from 'contexts/fullScreenContext';
import MeContext from 'contexts/meContext';
import { debounce } from 'lodash';

import Modal from './components/Modal';

export default ({ chart, mbd }) => {
    const { isOptoscaleAdmin } = useContext(MeContext);
    const { fullScreen } = useContext(FullScreenContext);
    const [clickedPoint] = useState(0);
    const [mouseOverPoint, setMouseOverPoint] = useState();
    const [activePoint, setActivePoint] = useState();

    const onHover = debounce((event, elements) => {
        if (elements[0]?._index) {
            setMouseOverPoint(elements[0]._index);
        } else {
            setMouseOverPoint(null);
        }
    }, 100);

    useEffect(() => {
        if (chart) {
            if (!chart.options.hover) chart.options.hover = {};
            chart.options.hover.onHover = onHover;
            chart.update(0);
        }
    }, [onHover, chart]);

    useEffect(() => {
        if (Array.isArray(mbd) && mbd.length > 1) {
            setActivePoint(mbd[mouseOverPoint || clickedPoint]);
        }
    }, [activePoint, clickedPoint, mbd, mouseOverPoint]);

    return (
        <>
            {activePoint && fullScreen ? (
                <Modal currentMeasurement={activePoint} isOptoscaleAdmin={isOptoscaleAdmin} />
            ) : null}
        </>
    );
};
