import { graphSettings, hexToRGBA } from 'config/settings';
import i18next from 'i18next';

import { FishHealthData } from '@/routes/Client/Cage2/pages/FishHealthColor2/FishHealthColorPageContent';

import { mapDaypartTagAsIndex, sortDays } from '../commonOptoChartHelpers';

const OverViewToBarChartMapper = ({ overview, dayTags = [], inactiveSetLegend = [] }) => {
    const { days } = overview;

    const tranformedMeasureCounts = mapDaypartTagAsIndex({
        days,
        chartType: 'bar',
        property: 'measCnt',
    });

    const sortedDays = sortDays(days);

    const dataSets = [];
    Object.entries(tranformedMeasureCounts).forEach(([key, value]) => {
        dataSets.push({
            data: tranformedMeasureCounts[key],
            hidden: inactiveSetLegend.includes(key),
            label: i18next.t(`daypartTag.${key}`),
            backgroundColor: hexToRGBA(graphSettings.daytagColors[key], 0.8),
            borderColor: hexToRGBA(graphSettings.daytagColors[key]),
            borderWidth: 1,
        });
    });

    return {
        labels: sortedDays,
        datasets: dataSets,
    };
};

export interface CombinedWelfareResultMapperProps {
    welfareResult: FishHealthData;
    dayTags: string[];
    inactiveSetLegend: string[];
}
const CombinedWelfareResultMapper = ({
    welfareResult,
    dayTags = [],
    inactiveSetLegend = [],
}: CombinedWelfareResultMapperProps) => {
    const { weekly } = welfareResult;

    weekly?.sort((a, b) => {
        const aWeek = `${a.isoYear}${String(a.isoWeek).padStart(2, '0')}`;
        const bWeek = `${b.isoYear}${String(b.isoWeek).padStart(2, '0')}`;
        return Number(aWeek) - Number(bWeek);
    });

    const labels = weekly.map((week) => {
        return {
            week: week.isoWeek,
            year: week.isoYear,
        };
    });

    const dataSets = [];
    const labelsWithText = labels.map((label) => {
        return `week ${label.week}`;
    });

    /* dataSets.push({
        data: weekly.map((week) => {
            return week.startedMaturation * 100;
        }),
        hidden: inactiveSetLegend.includes('startedMaturation'),
        backgroundColor: hexToRGBA(graphSettings.colors.primary, 0.7),
        borderColor: hexToRGBA(graphSettings.colors.primary),
        label: i18next.t('fishHealthIndicators.startedMaturation'),
        borderWidth: 2,
    }); */

    dataSets.push({
        data: weekly.map((week) => {
            return Number(week.fullyMature) * 100;
        }),
        hidden: inactiveSetLegend.includes('fullyMature'),
        backgroundColor: hexToRGBA(graphSettings.colors.primary, 1),
        borderColor: hexToRGBA(graphSettings.colors.primary),
        label: i18next.t('fishHealthIndicators.fullyMature'),
        borderWidth: 2,
    });

    dataSets.push({
        data: weekly.map((week) => {
            return (1 - (Number(week.startedMaturation) + Number(week.fullyMature))) * 100;
        }),
        hidden: inactiveSetLegend.includes('noMaturation'),
        backgroundColor: hexToRGBA(graphSettings.colors.dark, 0.3),
        borderColor: hexToRGBA(graphSettings.colors.primary),
        label: i18next.t('noMaturation'),
        borderWidth: 2,
    });

    console.log('labelsWithText', labelsWithText);

    return {
        labels: [...labelsWithText],
        datasets: [...dataSets],
    };
};

export default OverViewToBarChartMapper;
export { OverViewToBarChartMapper, CombinedWelfareResultMapper };
