import { JSX } from 'react';

import { Button } from 'components/atoms/Buttons';

interface DownloadPdfProps {
    buttonText: string;
    docGenerator: () => Promise<JSX.Element>;
    fileName: string;
}
export const DownloadPDF = ({ buttonText, docGenerator, fileName }: DownloadPdfProps) => {
    const sanitizedFileName = fileName
        .replace(/\s+/g, '-') // Replace white spaces with '-'
        .replace(/[^a-zA-Z0-9-\u00C0-\u024F\u1E00-\u1EFF]/g, ''); // Remove special characters except English and non-English alphabets

    const downloadPDF = async () => {
        const { pdf } = await import('@react-pdf/renderer');
        const blob = await pdf(await docGenerator()).toBlob();
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${sanitizedFileName}.pdf`);
        link.click();
        URL.revokeObjectURL(url);
    };

    return (
        <Button className="ml-2" onClick={downloadPDF}>
            {buttonText}
        </Button>
    );
};
