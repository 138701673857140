import React from 'react';
import { Link } from 'react-router-dom';

export interface TabbedNavigationProps {
    children?: React.ReactNode;
    active?: boolean;
    index?: number;
    useRouter?: boolean;
    to?: string;
}

const Tab = ({ children, active, index, useRouter = true, to = '#' }: TabbedNavigationProps) => {
    return (
        <div>
            {useRouter ? (
                <Link to={to} data-active={active} data-index={index} tabIndex={index - 1}>
                    {children}
                </Link>
            ) : (
                <a href={to + index} data-active={active} data-index={index} tabIndex={index - 1}>
                    {children}
                </a>
            )}
        </div>
    );
};

export default Tab;
export { Tab };
