export default {
    id: 'draw-background',
    afterDraw: (chart, options) => {
        chart.ctx.save();
        chart.ctx.globalCompositeOperation = 'destination-over';
        chart.ctx.fillStyle = options.color || 'rgba(255,255,255,255)';
        chart.ctx.fillRect(0, 0, chart.canvas.width, chart.canvas.height);
        chart.ctx.restore();
    },
};
