import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './LabelBadgeBlock.module.scss';

export interface LabelBadgeBlockProps {
    label?: string;
    children?: React.ReactNode | null;
    term?: string;
    fishTypeName?: string;
}

const LabelBadgeBlock = ({
    children = null,
    label = '',
    term = '',
    fishTypeName = 'salmon',
}: LabelBadgeBlockProps) => {
    const { t } = useTranslation();
    return (
        <div className={styles.labelbadgeblock}>
            <div>
                <span
                    data-type="badge"
                    data-value={label}
                    data-species={fishTypeName.toLowerCase()}>
                    {t(term)}
                    {children && (
                        <span className={styles.labelbadgeblock__content}>{children}</span>
                    )}
                </span>
            </div>
        </div>
    );
};

export default LabelBadgeBlock;
export { LabelBadgeBlock };
