import React from 'react';
import { useTranslation } from 'react-i18next';
import { Table } from 'reactstrap';

import Loader from '../../atoms/Loader';
import { Box } from '../../Layout';

import { UserRow, UserRowProps } from './UserRow';

import styles from './style.module.scss';

const BOX_STYLE = {
    maxHeight: `${800}px`,
    overflow: 'auto',
};

interface UserTableProps {
    users: UserRowProps['user'][];
    isFetching: boolean;
    canDeleteUsers: boolean;
    canImpersonateUsers: boolean;
    clientId: number;
    deleteUser: (id: number) => Promise<unknown>;
    impersonateUser: (id: number) => Promise<unknown>;
}

const UserTable = ({
    isFetching,
    users,
    canDeleteUsers,
    canImpersonateUsers,
    clientId,
    deleteUser,
    impersonateUser,
}: UserTableProps) => {
    const { t } = useTranslation();
    return (
        <Box title={t('Users')} customStyle={BOX_STYLE}>
            <Table>
                <thead className={styles.theadStyle}>
                    <tr>
                        <th>{t('Name')}</th>
                        <th>{t('E-mail')}</th>
                        <th>{t('Role')}</th>
                        <th>{t('Confirmed')}</th>
                        {canImpersonateUsers && <th>{t('Login as user')}</th>}
                        {canDeleteUsers && <th>{t('Delete user')}</th>}
                    </tr>
                </thead>
                <tbody>
                    {users?.map((user, index) => (
                        <UserRow
                            key={index}
                            user={user}
                            clientId={clientId}
                            canDeleteUsers={canDeleteUsers}
                            canImpersonateUserUsers={canImpersonateUsers}
                            deleteUser={deleteUser}
                            impersonateUser={impersonateUser}
                        />
                    )) ??
                        (isFetching ? (
                            <tr>
                                <td colSpan={canDeleteUsers ? 7 : 5}>
                                    <Loader />
                                </td>
                            </tr>
                        ) : (
                            <tr>
                                <td colSpan={5}>
                                    <i>{t('No data')}</i>
                                </td>
                            </tr>
                        ))}
                </tbody>
            </Table>
        </Box>
    );
};

export default UserTable;
