import React from 'react';
import { useTranslation } from 'react-i18next';
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from 'reactstrap';
import cn from 'classnames';

import styles from './ChangeLanguage.module.scss';

const ChangeLanguage = () => {
    const { i18n } = useTranslation();

    const changeLanguage = (language) => i18n.changeLanguage(language);
    const isLanguage = (language) => i18n.language === language;

    const getCurrentLanguage = () => {
        return (
            <span className={styles.selectedLanguage}>
                <div className={styles.globeIcon}>
                    <i className="fa fa-globe" />
                </div>
                {i18n.language === 'nb' ? (
                    <span>Norsk (Bokmål)</span>
                ) : i18n.language === 'es' ? (
                    <span>Español</span>
                ) : (
                    <span>English</span>
                )}
            </span>
        );
    };

    return (
        <UncontrolledDropdown nav inNavbar>
            <DropdownToggle className="pt-0 small text-lg-center" nav>
                {getCurrentLanguage()}
            </DropdownToggle>
            <DropdownMenu right className={styles.dropdownMenu}>
                <DropdownItem onClick={() => changeLanguage('en')}>
                    <span
                        className={cn(
                            {
                                'font-weight-bold': isLanguage('en'),
                            },
                            styles.dropDownMenuItemContent
                        )}>
                        <span>English (US)</span>
                    </span>
                </DropdownItem>
                <DropdownItem onClick={() => changeLanguage('nb')}>
                    <span
                        className={cn(
                            { 'font-weight-bold': isLanguage('nb') },
                            styles.dropDownMenuItemContent
                        )}>
                        <span>Norsk (Bokmål)</span>
                    </span>
                </DropdownItem>
                <DropdownItem onClick={() => changeLanguage('es')}>
                    <span
                        className={cn(
                            { 'font-weight-bold': isLanguage('es') },
                            styles.dropDownMenuItemContent
                        )}>
                        <span>Español</span>
                    </span>
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledDropdown>
    );
};

export default ChangeLanguage;
export { ChangeLanguage };
