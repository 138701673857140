import moment from 'moment-timezone';

const DEFAULT_OPTS = {
    emptyOk: true,
    ifEmpty: undefined,

    undefinedOk: true, // if true, undefined will be returned as is
    nullOk: true, // if true, null will be returned as is

    /*
     * if true, unix timestamps will be converted to moment objects,
     * otherwise object will be parsed as is (and maybe as milliseconds)
     */
    unixOk: true,
    timezone: undefined, // no changes
};

function parseTs(ts, opts) {
    opts = { ...DEFAULT_OPTS, opts };

    if (opts.emptyOk && ts === '') ts = opts.ifEmpty;

    if (!opts.undefinedOk && ts === undefined) ts = moment();
    if (!opts.nullOk && ts === null) ts = moment();
    if (ts === undefined || ts === null) return ts;

    // check only numbers and strings
    if (
        opts.unixOk &&
        (typeof ts === 'number' || typeof ts === 'string') &&
        !Number.isNaN(Number(ts))
    ) {
        ts = moment.unix(ts);
    }

    if (opts?.timezone !== null && opts?.timezone !== undefined) ts = moment.tz(ts, opts.timezone);

    return ts;
}

export default parseTs;
