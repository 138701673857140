import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from 'reactstrap';
import { CreateNewContractInput as UpdateContractInput } from 'services/types';

import { Button } from 'components/atoms/Buttons';

export interface ModalUpdateBioscopeContractProps {
    onSubmitted: (contractId: number, ev: UpdateContractInput) => void;
    contractData: {
        id: number;
        clientId: number;
        count: number;
        description: string;
    };
}

const ModalUpdateBioscopeContract = ({
    contractData,
    onSubmitted,
}: ModalUpdateBioscopeContractProps) => {
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);

    const [formData, setFormData] = useState<UpdateContractInput>({
        ...contractData,
    });

    function updateForm<T extends keyof UpdateContractInput>(key: T) {
        return (value: UpdateContractInput[T]) =>
            setFormData((formData) => ({ ...formData, [key]: value }));
    }

    const onFormDataOnSubmit = (ev: React.FormEvent<HTMLFormElement>) => {
        onSubmitted(contractData.id, formData);
        setOpen(false);
        ev.preventDefault();
    };

    return (
        <>
            <Button
                id="openUpdateBioscopeContractModal"
                data-testid="openUpdateBioscopeContractModal"
                className={'mr-2'}
                onClick={() => setOpen(true)}>
                {t('Update')}
            </Button>
            <Modal isOpen={open} toggle={toggle}>
                <ModalHeader toggle={toggle}>{t('Update Bioscope Contract')}</ModalHeader>
                <Form onSubmit={onFormDataOnSubmit}>
                    <ModalBody>
                        <FormGroup>
                            <Label for="ContractNumber">{t('Bioscope Count')}</Label>
                            <Input
                                id="ContractNumber"
                                name="ContractNumber"
                                type="number"
                                value={formData.count}
                                onChange={(ev) => updateForm('count')(parseInt(ev.target.value))}
                                placeholder={t('')}
                                required={false}
                            />
                            <Label for="description">{t('Description')}</Label>

                            <Input
                                id="Description"
                                name="Description"
                                type="text"
                                value={formData.description}
                                onChange={(ev) => updateForm('description')(ev.target.value)}
                                placeholder={t('')}
                                required={false}
                            />
                        </FormGroup>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" type="submit">
                            {t('Update')}
                        </Button>{' '}
                        <Button color="secondary" onClick={() => setOpen(false)}>
                            {t('Cancel')}
                        </Button>
                    </ModalFooter>
                </Form>
            </Modal>
        </>
    );
};

export default ModalUpdateBioscopeContract;
export { ModalUpdateBioscopeContract };
