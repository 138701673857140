import { useTranslation } from 'react-i18next';

import Notice from '@/components/atoms/Notice/Notice';
export const TroutNotice = () => {
    const { t } = useTranslation();

    return (
        <Notice
            heading="Regarding Rainbow trout AI model"
            showCloseButton={false}
            variant="warning"
            message={t('TroutNotice')}
        />
    );
};
