import React from 'react';

import { OptoTooltip } from 'components/atoms/OptoTooltip';

import styles from './AnchorButton.module.scss';

export interface AnchorButtonProps {
    children: React.ReactNode;
    to: string;
    target?: string;
    tooltip?: string;
    variant?: 'primary' | 'secondary' | 'normal_secondary';
    size?: 'small' | 'medium' | 'large';
    disabled?: boolean;
}

const AnchorButton = ({
    to,
    target = '_self',
    tooltip = '',
    variant = 'primary',
    disabled = false,
    size = 'medium',
    children,
}: AnchorButtonProps) => {
    const useTooltip = tooltip.length > 0;
    return useTooltip ? (
        <OptoTooltip nub="down-left" content={tooltip}>
            <a
                data-size={size}
                data-variant={variant}
                className={styles.anchorbutton}
                href={to}
                role="button"
                target={target}
                title="">
                <span>{children}</span>
            </a>
        </OptoTooltip>
    ) : (
        <a
            title=""
            data-size={size}
            data-variant={variant}
            className={styles.anchorbutton}
            href={to}
            role="button"
            target={target}
            aria-disabled={disabled}>
            <span>{children}</span>
        </a>
    );
};

export default AnchorButton;
export { AnchorButton };
