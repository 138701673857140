import React from 'react';

import styles from './ToolTipContent.module.scss';

export interface ToolTipContentProps {
    children?: React.ReactNode;
    content?: {
        key: string;
        value: string;
    }[];
}

const ToolTipContent = ({ children, content }: ToolTipContentProps) => {
    return (
        <div className={styles.tooltipcontent}>
            <div data-type="tooltip-content">
                {content?.map((item) => (
                    <div key={item.key}>
                        <div>{item.key}:</div>
                        <div>{item.value}</div>
                    </div>
                ))}
            </div>
            {children}
        </div>
    );
};

export default ToolTipContent;
export { ToolTipContent };
