import { useEffect, useRef, useState } from 'react';

import styles from './Poi.module.scss';

export interface PoiProps {
    isColor?: boolean;
    poiCords: {
        topProsent: number;
        leftProsent: number;
        PoiWidth: number;
        PoiHeight: number;
    };
    toolTip?: string;
    showPoiMeta?: boolean;
    instanceKey?: string;
}

const Poi = ({
    poiCords,
    toolTip = '',
    showPoiMeta,
    isColor = true,
    instanceKey = 'default',
}: PoiProps) => {
    const poiRef = useRef(null);
    const [mouseOverText, setMouseOverText] = useState('');

    useEffect(() => {
        if (isColor && toolTip && toolTip.length > 0) {
            poiRef.current?.style.setProperty('--mouseOverText', `${toolTip}`);
            const poiDomreferenceObject = poiRef.current.getBoundingClientRect();
            setMouseOverText(toolTip);
        }
    }, [poiRef, toolTip]);

    return (
        <div
            ref={poiRef}
            className={styles.poi}
            data-tip={mouseOverText}
            data-showmeta={showPoiMeta}
            data-type={instanceKey}
            style={{
                top: `${poiCords.topProsent}%`,
                left: `${poiCords.leftProsent}%`,
                width: `${poiCords.PoiWidth}%`,
                height: `${poiCords.PoiHeight}%`,
            }}
        />
    );
};

export default Poi;
export { Poi };
