import { useTranslation } from 'react-i18next';
import { Col as BCol, Row } from 'reactstrap';
import cn from 'classnames';
import { getTitle } from 'utils';

import StatusIcon from './StatusIcon';

import styles from '../FishHealthColor.module.scss';

const MAX_NUMBER_OF_COLUMNS = 3;

function Col({
    label,
    getStatus,
    getStatusBW,
    maxNumberOfColumns = MAX_NUMBER_OF_COLUMNS,
    scrollToDiv,
}) {
    const { t } = useTranslation();
    const status = getStatus(label);
    const statusBW = getStatusBW(label);
    const title = getTitle(label);
    return (
        <BCol sm={12 / maxNumberOfColumns} className={styles.col}>
            <div
                onClick={() => scrollToDiv(label.replace(/\s+/g, ''))}
                className="colorWelfareParam">
                <h3 className={styles.title}>{t(title)}</h3>
                <StatusIcon
                    status={title === 'fishHealthIndicators.wound' ? status : statusBW}
                    label={title}
                    downGrade={getStatus('downgrade')}
                />
            </div>
        </BCol>
    );
}

function Rows({
    labels,
    getStatus,
    getStatusBW,
    maxNumberOfColumns = MAX_NUMBER_OF_COLUMNS,
    scrollToDiv,
}) {
    labels = labels || [];

    return labels
        .reduce((groups, col, index) => {
            const groupIndex = Math.floor(index / maxNumberOfColumns);

            if (index % maxNumberOfColumns === 0) {
                groups.push([]);
            }

            groups[groupIndex].push(col);

            return groups;
        }, [])
        .map((row, index) => (
            <Row key={index} className={cn(styles.row, styles.withColumns)}>
                {row.map((col, index) => (
                    <Col
                        key={index}
                        label={col}
                        getStatus={getStatus}
                        getStatusBW={getStatusBW}
                        labels={labels}
                        scrollToDiv={scrollToDiv}
                    />
                ))}
            </Row>
        ));
}

function ColorWelfareStatus({
    labels,
    getStatus,
    getStatusBW,
    maxNumberOfColumns = MAX_NUMBER_OF_COLUMNS,
    scrollToDiv,
}) {
    return (
        <>
            <Rows
                labels={labels}
                getStatus={getStatus}
                getStatusBW={getStatusBW}
                maxNumberOfColumns={maxNumberOfColumns}
                scrollToDiv={scrollToDiv}
            />
        </>
    );
}
export default ColorWelfareStatus;
