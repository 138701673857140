// use users.featureFlags to add/update/remove user features
// this is also used to add half baked features, but do not
// forget to remove/cleanup it later, e.g.
// (do search and remove from here)

export const REQUEST_FEEDBACK = 'REQUEST_FEEDBACK';
export const HOW_MUCH_IS_THE_FISH = 'HOW_MUCH_IS_THE_FISH';

export const NEW_CAGE_OVERVIEW_WEIGHT_GRAPH = 'NEW_CAGE_OVERVIEW_WEIGHT_GRAPH';
export const CAGE_OPERATIONS = 'CAGE_OPERATIONS';
export const VISIBILITY_GRAPH = 'VISIBILITY_GRAPH';
