import React, { MouseEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import styles from './SwitchButtons.module.scss';

type SwitchConfig = {
    label: string;
    value: string;
    selected?: boolean;
};

export interface SwitchButtonsProps {
    switches?: SwitchConfig[];
    onClick?: (ev: MouseEvent<HTMLButtonElement>) => void;
    size: 'sm' | 'md' | 'lg';
}

const SwitchButtons: React.FC<SwitchButtonsProps & React.ButtonHTMLAttributes<HTMLButtonElement>> =
    ({ switches = [], onClick, size }: SwitchButtonsProps) => {
        const { t } = useTranslation();
        const [activeState, setActiveState] = useState<string>();

        return (
            <div className={classNames(styles.switchbuttons, styles[size])}>
                {switches.map((switchConfig, index) => {
                    activeState === undefined &&
                        switchConfig.selected &&
                        setActiveState(switchConfig.value);

                    return (
                        <button
                            aria-checked={activeState === switchConfig.value}
                            name={switchConfig.value}
                            key={index}
                            value={switchConfig.value}
                            className={activeState === switchConfig.value ? `${styles.active}` : ''}
                            onClick={(e) => {
                                setActiveState(switchConfig.value);
                                onClick?.(e);
                            }}>
                            {t(switchConfig.label)}
                        </button>
                    );
                })}
            </div>
        );
    };

export default SwitchButtons;
export { SwitchButtons };
