import React, { useState } from 'react';
import { Button } from 'reactstrap';

import { CopyIcon } from 'components/atoms/icons';

import styles from './CopyAsPNG.module.scss';

export default function CopyAsPNG({ chart }) {
    const [isCopied, setIsCopied] = useState(false);

    const onCopyImage = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1000);
    };
    return (
        <>
            <span className={styles.copied}>{isCopied ? 'Copied!' : null}</span>
            <Button
                title="Copy as .png"
                onClick={() => {
                    chart.canvas.toBlob((blob) =>
                        navigator.clipboard.write([new window.ClipboardItem({ 'image/png': blob })])
                    );
                    onCopyImage();
                }}
                className={styles.copyBtn}
                outline>
                <CopyIcon size={32} />
            </Button>
        </>
    );
}
