import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import { useMe } from 'contexts/meContext';
import { useCageController_Status } from 'services/hooks';
import { getClientId } from 'utils';
import formattedNumbers from 'utils/formattedNumbers';
import formattedTs from 'utils/formattedTs';

import BlockTitle from '@/components/atoms/BlockTitle';
import UmerBox from '@/components/atoms/UmerBox';
import useCage from '@/contexts/cage-context';
import { KeyNumber, KeyNumberGroup } from 'components/Layout';

import BioscopeHistoricalList from '../../Overview/components/BioscopeHistoricalList';
import { useRestOverview } from '../overview-context';

function BioscopeStatusBox() {
    const { t } = useTranslation();
    const location = useLocation();
    const { cageId, timezone } = useCage();
    const { isOptoscaleAdmin } = useMe();

    const overview = useRestOverview();
    const totalCount = overview?.data?.period.meas_cnt;

    const { data } = useCageController_Status(cageId, { staleTime: 60 * 1000 });

    const status = data?.data;

    const lastBioscopeIdTitle = status?.currentBioscope?.id
        ? `${t('Bioscope')}: ${status?.currentBioscope?.id}`
        : '';

    const activeBioscope = status?.currentBioscope?.id ?? null;
    const historicalInlineList = status?.historicalBioscopes.reduce(
        (historyList, bioscope) => {
            let entry = historyList.find((history) => history.id === bioscope.bioscopeId);
            if (!entry) {
                entry = { id: bioscope.bioscopeId, active: false, history: [] };
                historyList.push(entry);
            }
            entry.history.push({
                from: new Date(bioscope.from),
                to: bioscope.to ? new Date(bioscope.to) : null,
            });
            if (activeBioscope === bioscope.bioscopeId) {
                entry.active = true;
            }
            return historyList;
        },
        [] as {
            id: number;
            active: boolean;
            history: { from: Date; to?: Date }[];
        }[]
    );

    return (
        <UmerBox data-tut="bioscopeStatusBar">
            <BlockTitle heading={t('Status bioscope')} />
            <KeyNumberGroup numberOfColumns={3}>
                <KeyNumber
                    label={t('Active Bioscope')}
                    metric=""
                    value={
                        <Link
                            to={
                                isOptoscaleAdmin
                                    ? `/internal/bioscopes/${activeBioscope}`
                                    : `/c/${getClientId(
                                          location.pathname
                                          // eslint-disable-next-line no-mixed-spaces-and-tabs
                                      )}/bioscope/${activeBioscope}`
                            }>
                            {activeBioscope}
                        </Link>
                    }
                    tooltipText={t('descriptions.bioscopeStatus.activeBioscope')}
                />
                <KeyNumber
                    label={t('Depth')}
                    metric="m"
                    value={status?.lastData?.depthLast?.toFixed(1)}
                    ifBad="-"
                    tooltipText={t('descriptions.bioscopeStatus.depth')}
                    isFraction={true}
                />
                <KeyNumber
                    label={t('Pitch, Roll')}
                    metric=""
                    value={formattedNumbers(
                        [status?.lastData?.pitchLast, status?.lastData?.rollLast],
                        { isFraction: true }
                    )}
                    tooltipText={t('descriptions.bioscopeStatus.pitchAndRoll')}
                />
                {totalCount != null && (
                    <KeyNumber
                        label={t('Measured fish total')}
                        value={totalCount}
                        ifBad="-"
                        tooltipText={t('descriptions.bioscopeStatus.measurementCount')}
                    />
                )}
                {status?.lastData?.timestampLast && (
                    <KeyNumber
                        label={t('Last measurement')}
                        value={
                            <span style={{ fontSize: '0.8em' }}>
                                {formattedTs(status?.lastData?.timestampLast, timezone)}
                            </span>
                        }
                        tooltipText={t('descriptions.bioscopeStatus.lastMeasurement')}
                    />
                )}
                <KeyNumber
                    label={t('Historical Bioscope')}
                    metric=""
                    value={<BioscopeHistoricalList bioscopes={historicalInlineList} />}
                    tooltipText={t('descriptions.bioscopeStatus.activeBioscope')}
                />
                {isOptoscaleAdmin && status?.lastData?.lastAlive != null && (
                    <KeyNumber
                        label={t('Last lifesign')}
                        value={
                            <span style={{ fontSize: '0.8em' }}>
                                {formattedTs(status?.lastData?.lastAlive)}
                            </span>
                        }
                        tooltipText={t('descriptions.bioscopeStatus.lastLifesign')}
                    />
                )}
            </KeyNumberGroup>
        </UmerBox>
    );
}

export default BioscopeStatusBox;
