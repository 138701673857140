import React from 'react';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';
import isNumberOk from 'utils/isNumberOk';

import { Notice } from '@/components/atoms/Notice/Notice';
import UmerBox from '@/components/atoms/UmerBox';
import useCage from '@/contexts/cage-context';
import { supressStatusMessages } from '@/helpers/alarmCheckers';
import { CageType } from '@/services/types';
import { formattedWeightUnit } from '@/utils';

import { useRestFormattedOverview } from '../../formatted-overview-context';
import { useRestOverview } from '../../overview-context';

import styles from './statusBar.module.scss';

interface EstimatedWeightProps {
    scale?: boolean;
    weight?: number;
    weightUnitTag?: string;
}

const EstimatedWeight = ({
    scale = true,
    weight = 0,
    weightUnitTag = 'g',
}: EstimatedWeightProps) => {
    const { t } = useTranslation();

    const { value, weightUnit } = formattedWeightUnit(weight, weightUnitTag, 0, scale);

    if (!isNumberOk(weight)) {
        return <>{t('No estimated weight')}</>;
    }

    return (
        <>
            <strong>
                {value} {t(`weightUnit.${weightUnit}`)}
            </strong>
            <span> {t('EstimatedLivingWeight')}</span>
        </>
    );
};

function StatusBar2() {
    const { t } = useTranslation();
    const formattedOverview = useRestFormattedOverview();
    const { statusColor2, statusText2, weightNow, allGood2, disabled, noBioscopeInCage } =
        formattedOverview.data ?? {};

    const { data: overViewData, weightUnitTag } = useRestOverview();
    const { cage, loading } = useCage();

    // Filter out status messages that should not be shown
    const alertMessages: string[] =
        !allGood2 && statusText2 && statusText2?.length > 0
            ? supressStatusMessages({
                  cageType: cage?.cageType as CageType,
                  statusTexts: statusText2,
              })
            : ['allgood'];

    const statusColor = allGood2 || alertMessages[0] === 'allgood' ? 'green' : statusColor2;

    if (disabled || noBioscopeInCage || loading) {
        return null;
    }

    const alertMessageStrings = alertMessages.map((text) => {
        return t(`measurementStatusTexts.${text}`);
    });

    return (
        <UmerBox data-tut="statusBar">
            <div className={styles.statusBlock}>
                <div>
                    <div className={clsx(styles.status, `status-${statusColor}`)} />
                    {!Number.isNaN(Number.parseFloat(weightNow)) && (
                        <>
                            <span data-type="estimated-weight">
                                <EstimatedWeight
                                    weight={overViewData?.estimated_weight}
                                    scale={false}
                                    weightUnitTag={weightUnitTag}
                                />
                            </span>
                        </>
                    )}
                </div>
                <div>
                    {alertMessageStrings.length > 0 && (
                        <Notice
                            icon={false}
                            type="status"
                            heading="Status"
                            message={alertMessageStrings}
                            variant={allGood2 ? 'allgood' : 'warning'}
                        />
                    )}
                </div>
            </div>
        </UmerBox>
    );
}

export default StatusBar2;
