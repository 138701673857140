import React from 'react';

import styles from './FootNote.module.scss';

export interface FootNoteProps {
    children?: React.ReactNode;
}

const FootNote = ({ children }: FootNoteProps) => {
    return <div className={styles.footnote}>{children}</div>;
};

export default FootNote;
export { FootNote };
