import moment from 'moment';

const UNKNOWN_STATE = 'unknown';
const STALLED_STATE_THRESHOLD = 3 * 60; // 3 minutes

const states = {
    created: 'created',
    started: 'started',
    error: 'error',
    stopped: 'stopped',
    finished: 'finished',
    stalled: 'stalled',
    warning: 'warning',
};

interface BatchServiceStatusProps {
    status: string;
    updatedAt?: Date;
}

function batchServiceStatus({ status, updatedAt }: BatchServiceStatusProps) {
    if (!states[status]) return { status: UNKNOWN_STATE };

    if (
        updatedAt &&
        (status === 'created' || status === 'started') &&
        moment().subtract(STALLED_STATE_THRESHOLD, 'seconds').isAfter(updatedAt)
    ) {
        return { status: states.stalled };
    }

    return { status: states?.[status] };
}

export default batchServiceStatus;
