import React from 'react';

import styles from './CheckBox.module.scss';

export interface CheckBoxProps {
    name?: string;
    label: string;
    value: boolean;
    onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CheckBox = ({ name, label, value, onChange }: CheckBoxProps) => {
    name = name ? name : label;

    return (
        <div className={styles.check}>
            <div className={styles.checkbox_container} />

            <label className={styles.checkbox_label}>
                {label}
                <input type="checkbox" name={name} checked={value} onChange={onChange} />
                <span className={styles.mark} />
            </label>
        </div>
    );
};

export default CheckBox;
export { CheckBox };
