import React from 'react';
import { useTranslation } from 'react-i18next';
import { graphSettings } from 'config/settings';

import GrowthIcon from '@/components/atoms/icons/GrowthIcon';
import LiceIcon from '@/components/atoms/icons/LiceIcon';
import { OverviewLocation } from '@/services/types';
import { FishHealthIcon } from 'components/atoms/icons/FishHealthIcon';

import styles from './AverageHeader.module.scss';

export interface AverageHeaderProps {
    children?: React.ReactNode;
    location: OverviewLocation;
}

interface FishTypeColors<T> {
    [key: string]: T;
}

const GrowthValue = (growth: number | undefined) => {
    const { t } = useTranslation();
    return growth ? `${Math.floor(growth)} g/${t('daypartTag.day')}` : '---';
};

const WoundsValue = (wound: number | undefined) => {
    return wound
        ? `${
              (100 * Math.round(wound * 200)) / 200
              // eslint-disable-next-line no-mixed-spaces-and-tabs
          }%`
        : '---';
};

const LiceValue = (lice: number | undefined) => {
    return lice
        ? `${
              lice?.toFixed(2)
              // eslint-disable-next-line no-mixed-spaces-and-tabs
          }`
        : '---';
};

const AverageHeader = ({ location }: AverageHeaderProps) => {
    const { t } = useTranslation();
    const hasAccessToFishHealth = location.cages.some((cage) => cage.welfare);
    const hasAccessToLice = location.cages.some((cage) => cage.lice);
    const { fishTypes } = location;
    const color = (graphSettings.fishTypeName as FishTypeColors<string>)[
        fishTypes.name.toLowerCase()
    ];
    return (
        <div className={`${styles.averageheader}`} style={{ color: color }}>
            <div data-type="number-block">
                <GrowthIcon height={40} color={color} />
                <div data-type="number">
                    <span> {t('Growth')}: </span>
                    <span>{GrowthValue(location.averages.growth)}</span>
                </div>
            </div>

            {hasAccessToFishHealth && location.fishTypes.shortName !== 'trout' ? (
                <div data-type="number-block">
                    <FishHealthIcon height={35} color={color} />
                    <div data-type="number">
                        <span>{t('Wound')}: </span>
                        <span>{WoundsValue(location?.averages?.wounds)}</span>
                    </div>
                </div>
            ) : (
                ''
            )}

            {hasAccessToLice ? (
                <div data-type="number-block">
                    <LiceIcon height={40} color={color} />
                    <div data-type="number">
                        <span>{t('Lice')}: </span>
                        <span>{LiceValue(location.averages.femaleLice)}</span>
                    </div>
                </div>
            ) : (
                ''
            )}
        </div>
    );
};

export default AverageHeader;
export { AverageHeader };
