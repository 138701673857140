import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Col, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Row } from 'reactstrap';
import { useClientController_Compare } from 'services/hooks';

import { Loader } from 'components/atoms/Loader';
import { Box } from 'components/Layout';
import {
    autoTimeScale,
    formatAxsis,
    formatTooltip,
    layoutPadding,
    Options,
    startYAxisAtZero,
} from 'components/organisms/OptoGraphs/optionsModifiers';
import {
    ComparingDataMapper,
    defaultLineChartOptions,
    OptoMixedChart,
} from 'components/organisms/OptoGraphs/OptoMixedChart';
import SomethingHappened from 'components/SomethingHappened';

interface CompareContentProps {
    id: string;
    item: string;
    postfixForYAxis: 'kg' | 'g' | 'mm' | 'cm' | 'm' | 'oz' | 'lb' | '%' | 'bl/s' | '°' | '';
    unitType:
        | 'percent'
        | 'weight'
        | 'length'
        | 'number'
        | 'celcius'
        | 'meter'
        | 'height'
        | 'pixel'
        | 'angle';
    multiplier: number;
    descimals: number;
}

const COMPARECONFIG: CompareContentProps[] = [
    {
        id: '1',
        item: 'wounds',
        postfixForYAxis: '%',
        unitType: 'percent',
        multiplier: 100,
        descimals: 2,
    },
    {
        id: '2',
        item: 'weight',
        postfixForYAxis: 'g',
        unitType: 'weight',
        multiplier: 1,
        descimals: 0,
    },
    {
        id: '3',
        item: 'femaleLice',
        postfixForYAxis: '',
        unitType: 'number',
        multiplier: 1,
        descimals: 2,
    },
    {
        id: '4',
        item: 'maturation',
        postfixForYAxis: '%',
        unitType: 'percent',
        multiplier: 1,
        descimals: 2,
    },
];

interface urlDataTypes {
    [key: string]: string;
}

const CompareContent = ({ urlData, submittedData }) => {
    const { t, i18n } = useTranslation();
    const cageIds = Object.keys(urlData).map(Number);
    const cageNames = Object.values(urlData);

    function toLocale(language: string) {
        return language === 'nb' ? 'nb-NO' : language === 'es' ? 'es-ES' : 'en-US';
    }

    const [locale, setLocale] = useState('nb-NO');

    useEffect(() => {
        setLocale(toLocale(i18n.language));
    }, [i18n.language]);

    const { data, isLoading, error } = useClientController_Compare({
        cages: cageIds,
        from: submittedData?.from,
        to: submittedData?.to,
        daypartTag: submittedData?.daypartTag || 'day',
    });
    const allData = { ...data?.data };
    const dataWithCageNames: { [key: string]: any } = {};

    const [isOpen, setIsOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState(COMPARECONFIG[1]);

    for (const key in allData) {
        if (Object.hasOwn(allData, key)) {
            const data = allData[key];
            const newData = {};

            for (const dataKey in data) {
                if (Object.hasOwn(data, dataKey)) {
                    const value = data[dataKey];
                    const newName = urlData[dataKey];

                    newData[newName] = value;
                }
            }

            dataWithCageNames[key] = newData;
        }
    }

    function graphData(title, labels) {
        if (!labels) {
            return {};
        }

        return {
            title,
            labels,
            trendlines: labels.reduce((acc, cur) => {
                acc[cur] = true;
                return acc;
            }, {}),
        };
    }

    if (isLoading) {
        return <Loader />;
    }

    if (error) {
        return <SomethingHappened />;
    }

    const labelsForGraph = graphData('Compare', Object.keys(dataWithCageNames?.weight));

    const createLinks = (data: urlDataTypes) => {
        return Object.entries(data).map(([key, value], index, array) => (
            <React.Fragment key={key}>
                <a href={`cages/v3/${key}`}>{value}</a>
                {index < array.length - 1 && ' / '}{' '}
            </React.Fragment>
        ));
    };

    const handleDropdownSelect = (option) => {
        const selectedConfigItem = COMPARECONFIG.find((item) => item.item === option);
        if (selectedConfigItem) {
            setSelectedOption(selectedConfigItem);
        }
    };

    const toggleDropdown = () => {
        setIsOpen(!isOpen);
    };

    return (
        <>
            <Row>
                <Col xs={12}>
                    <h1 className="mb-3">{t('Compare pens')}</h1>
                </Col>
            </Row>
            <Box>
                <Row>
                    <Col xs={12}>
                        <p className="h4">{t('Selected pens')}:</p>
                        <h2>{createLinks(urlData)}</h2>
                    </Col>
                </Row>
            </Box>

            <Box>
                <div className="pb-2 d-flex  justify-content-center align-items-center">
                    <h3 className="mb-0 border-0">{t('Comparing')}</h3>
                    <Dropdown isOpen={isOpen} toggle={toggleDropdown} className="ml-2">
                        <DropdownToggle caret>
                            <span style={{ textTransform: 'capitalize' }}>
                                {t(selectedOption.item)}
                            </span>
                        </DropdownToggle>
                        <DropdownMenu>
                            {COMPARECONFIG.map((option) => (
                                <DropdownItem
                                    key={option.id}
                                    onClick={() => handleDropdownSelect(option.item)}>
                                    <span style={{ textTransform: 'capitalize' }}>
                                        {t(option.item)}
                                    </span>
                                </DropdownItem>
                            ))}
                        </DropdownMenu>
                    </Dropdown>
                </div>
                <OptoMixedChart
                    chartName={t('Comparing')}
                    chartData={ComparingDataMapper({
                        data: dataWithCageNames[selectedOption.item],
                    })}
                    chartOptions={Options(defaultLineChartOptions).modify(
                        autoTimeScale(),
                        layoutPadding({ padding: 0 }),
                        formatAxsis({
                            axsisID: 'y',
                            tickType: selectedOption.unitType,
                            descimals: selectedOption.descimals,
                            multiplier: selectedOption.multiplier,
                        }),
                        formatTooltip({
                            tickType: selectedOption.unitType,
                            multiplier: selectedOption.multiplier,
                            descimals: selectedOption.descimals,
                            locale: locale,
                        }),

                        startYAxisAtZero({
                            axsisID: 'y',
                        })
                    )}
                />
            </Box>
        </>
    );
};

export default CompareContent;
