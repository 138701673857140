import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Alert, Col, Input, Label, Row } from 'reactstrap';
import clsx from 'clsx';
import { LICENSES } from 'config/licenses';
import { useMe } from 'contexts/meContext';
import useMyTranslation from 'hooks/useMyTranslation';

import { Box } from 'components/Layout';

import useCage from '../../../../../contexts/cage-context';
import ImageSlider from '../../components/ImageSlider';

import { OneGraph } from './components/OneGraph';
import TwoYAxesTrendGraph from './components/TwoYAxesTrendGraph';
import WelfareIndicators from './components/WelfareIndicators';
import PrivateStuff from './PrivateStuff';
import { COLOR_NEUTRAL, formatNumber, getIcon, getTitle } from './utils';

import styles from './FishHealth.module.scss';

const WOUND_LIMIT_TO_SHOW_IMAGES = 2.0;

const WELFARE_INDICATOR_LABELS = [
    'wound',
    // 'skin spot',  removed on request
    'skin speckles',
    'Mature jaw',
];

const GRAPH_LABELS = [
    ['wound', 'temperature'],
    ['mature_jaw', 'temperature'],
];

const GRAPH_TRENDLINES = [
    { wound: true, temperature: false },
    { mature_jaw: true, temperature: false },
];

function LabelValue(status) {
    const { t } = useMyTranslation();

    const percentage = formatNumber(status.tail.percentage, {
        suffix: '%',
        decimals: 1,
    });
    const diff = formatNumber(status.diff, {
        suffix: '%',
        decimals: Math.abs(status.diff) < 10 ? 1 : 0,
    });

    const Icon = getIcon(status.op);
    const color = status.color || COLOR_NEUTRAL;

    return (
        <>
            {percentage}
            <span
                title={status.diff ? `${t('Week change')}: ${status.diff.toFixed(2)}` : ''}
                className="pl-2">
                (
                {(status.op > 0 || status.op < 0) && (
                    <span className={clsx('pr-2', styles.icon)} style={{ color }}>
                        <Icon />
                    </span>
                )}
                {diff})
            </span>
        </>
    );
}

function formatLabel(label, status, opts = {}) {
    return {
        text: getTitle(label),
        value: () => LabelValue(status),
        title: status.tail.percentage
            ? ['Weekly average', ': ', status.tail.percentage.toFixed(2)]
            : undefined,
    };
}
function formatNumberOfWoundLabel(label, status, opts = {}) {
    return {
        text: getTitle(label),
        value: () => LabelValue(status),
        title: status.tail.percentage
            ? ['Weekly average', ': ', status.tail.percentage.toFixed(2)]
            : undefined,
        onlyValue: status?.tail?.percentage ?? [],
    };
}
function graphData(title, labels, showTotal, getStatus) {
    if (!labels || typeof getStatus !== 'function') {
        return {};
    }
    let formattedWoundNumberLabels = [];
    if (title === 'Number of wounds') {
        formattedWoundNumberLabels = labels.map((label) => {
            return formatNumberOfWoundLabel(label, getStatus(label));
        });
        const adjustedNoWounds =
            100 -
            (formattedWoundNumberLabels[1].onlyValue + formattedWoundNumberLabels[2].onlyValue); // No wounds percentage calculation
        formattedWoundNumberLabels[0].onlyValue = adjustedNoWounds;
    }
    const formattedLabels = labels.map((label) => {
        return formatLabel(label, getStatus(label));
    });

    const extraFormattedLabels = [
        {
            text: getTitle('total'),
            value: formatNumber(getStatus('total').tail.totalCount),
        },
    ];

    const info = showTotal
        ? [...formattedLabels, ...extraFormattedLabels]
        : title === 'Number of wounds'
          ? [...formattedWoundNumberLabels]
          : [...formattedLabels]; // no need to reformat if only one value

    // extra is not shown on graph
    return {
        title,
        labels,
        info,
        trendlines: labels.reduce((acc, cur) => {
            acc[cur] = true;
            return acc;
        }, {}),
    };
}

const WelfareImagesBox = (props) => {
    const [checked, setChecked] = useState(true);
    const handleChange = (event) => {
        setChecked(event.currentTarget.checked);
    };

    const { t } = useTranslation();
    const { cageId } = useCage();
    const { showImages } = props;
    const { isOptoscaleAdmin } = useMe();

    return (
        <Box title={t('Welfare images')}>
            <Row>
                <Col>
                    <span>
                        {!showImages
                            ? t('Images are not shown when wounds percentage is low').toString()
                            : ''}
                    </span>
                </Col>
                <Col className="text-right">
                    <Label for="showHideBoxes" check>
                        <Input
                            type="checkbox"
                            id="showHideBoxes"
                            checked={checked}
                            onChange={handleChange}
                        />
                        {t('Show boxes')}
                    </Label>
                </Col>
            </Row>
        </Box>
    );
};

function FishHealth({ cage, data, getStatus }) {
    const { t } = useMyTranslation();
    const { isOptoscaleAdmin } = useMe();
    const cageId = data?.head?.cageId;
    const { hasCageLicense } = useCage();
    const hasAccessToFishHealthInspection = hasCageLicense(
        LICENSES.LICE_COUNT.MAX,
        LICENSES.LICE_COUNT.MIN,
        LICENSES.FISH_HEALTH.INSPECTION
    );
    const showColorInspectionImages = hasAccessToFishHealthInspection;
    const list = data?.list;
    let showTotal = true;
    const woundsGraphData = React.useMemo(() => {
        return graphData('Wounds', ['wound'], showTotal, getStatus);
    }, [showTotal, getStatus]);

    const maturationGraphData = React.useMemo(() => {
        return graphData('Maturation', ['mature jaw'], showTotal, getStatus);
    }, [showTotal, getStatus]);

    const woundClassesData = graphData(
        'Wound classes',
        ['small wound', 'medium wound', 'big wound'],
        (showTotal = false),
        getStatus
    );
    const numberOfWoundsData = graphData(
        'Number of wounds',
        ['No wound', 'one wound', 'more than one wound'],
        (showTotal = false),
        getStatus
    );
    const welfare_indicator_labels = [...WELFARE_INDICATOR_LABELS];

    //const WOUNDS_AND_TEMPERATURE_COLUMNS = [['length', 'day'], ['temperature', 'day']];
    const WOUNDS_AND_TEMPERATURE_COLUMNS = [
        ['wound', ({ list }) => list],
        ['temperature', ({ data }) => data],
    ];

    const MATURATION_AND_TEMPERATURE_COLUMNS = [
        ['mature_jaw', ({ list }) => list],
        ['temperature', ({ data }) => data],
    ];

    const COLUMNS = {
        woundsAndTemperatureData: WOUNDS_AND_TEMPERATURE_COLUMNS,
        maturationAndTemperatureData: MATURATION_AND_TEMPERATURE_COLUMNS,
    };

    const sortedDays =
        [...new Set(list.map((day) => day.day))]?.sort((a, b) =>
            a.day > b.day ? -1 : a.day < b.day ? 1 : 0
        ) ?? [];

    const wounds =
        list?.reduce((acc, cur) => {
            if (!(cur.day in acc)) acc[cur.day] = {};
            acc[cur.day] = cur;
            return acc;
        }, {}) ?? {};

    const temperature =
        data?.dailymeasurment?.reduce((acc, cur) => {
            if (!(cur.day in acc)) acc[cur.day] = {};
            acc[cur.day] = cur;
            return acc;
        }, {}) ?? {};

    const showWelfareImages = getStatus('wound').tail.percentage > WOUND_LIMIT_TO_SHOW_IMAGES;

    const dataArray = Object.entries(COLUMNS).reduce((result, [group, cols]) => {
        result[group] = cols.map(([col]) => {
            return sortedDays.map((day) => {
                const [x, y] = [day, col === 'temperature' ? temperature?.[day] : wounds?.[day]];
                return { x, y };
            });
        });
        return result;
    }, {});

    if (!list.length) {
        return <Alert color="warning">{t('No data')}</Alert>;
    }
    return (
        <>
            <Box customStyle={{ position: 'relative' }}>
                <WelfareIndicators labels={welfare_indicator_labels} getStatus={getStatus} />
            </Box>
            {!!woundsGraphData && (
                <>
                    <Box>
                        <OneGraph
                            {...woundClassesData}
                            type={'line'}
                            colors={[
                                'rgba(47, 131, 123, 0.5)',
                                'rgba(94, 197, 187, 0.7)',
                                'rgba(30, 110, 150, 0.2)',
                            ]}
                            list={list}
                            showTotalCounts={false}
                            showDiff={false}
                            stacked={true}
                            trendlines={false}
                        />
                    </Box>
                    <Box>
                        <OneGraph
                            {...numberOfWoundsData}
                            type={'line'}
                            barometerStyle={true}
                            showTotalCounts={false}
                            showDiff={false}
                            list={list}
                            stacked={false}
                            trendlines={false}
                        />
                    </Box>
                </>
            )}
            {!!maturationGraphData && (
                <Box>
                    {isOptoscaleAdmin ? (
                        <TwoYAxesTrendGraph
                            data={dataArray ? dataArray.maturationAndTemperatureData : []}
                            graphLabels={GRAPH_LABELS[1]}
                            colors={['#2f837b', '#5ec5bb80']}
                            graphTrendlines={GRAPH_TRENDLINES[1]}
                            {...maturationGraphData}
                            help={'Wounds graph help'}
                        />
                    ) : (
                        <OneGraph type={'bar'} list={list} {...maturationGraphData} />
                    )}
                </Box>
            )}
            {showColorInspectionImages && <ImageSlider cageId={cageId} />}

            <WelfareImagesBox cage={cage} showImages={showWelfareImages} />
            {!!isOptoscaleAdmin && (
                <>
                    <h3 style={{ color: '#547FA2' }}>
                        {t('Only visible for internal OptoScale admin users')}
                    </h3>
                    <hr />
                    <PrivateStuff list={list} getStatus={getStatus} />
                </>
            )}
        </>
    );
}

export default FishHealth;
