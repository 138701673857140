import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

interface Location {
    isActive: boolean;
    name: string;
    href: string;
}

interface LocationDropdownItemsProps {
    locations: Location[];
    header: string;
    closeDropdown: () => void;
    styles: Record<string, string>;
}

const LocationDropdownItems = ({
    locations,
    header,
    closeDropdown,
    styles,
}: LocationDropdownItemsProps) => {
    const { t } = useTranslation();

    if (!locations.length) {
        return null;
    }

    return (
        <>
            {header ? <DropdownItem header>{t(header)}</DropdownItem> : null}
            {locations.map((location) => (
                // biome-ignore lint/a11y/useValidAnchor: <explanation>
                <a
                    key={location.name}
                    className={styles.menuLink}
                    // deepcode ignore DOMXSS: <please specify a reason of ignoring this>
                    href={location.href}
                    onClick={(e) => {
                        closeDropdown();
                    }}>
                    {location.name}
                </a>
            ))}
        </>
    );
};

interface LocationsDropdownMenuProps {
    locations: Location[];
    styles: Record<string, string>;
    dropDownOpenHandler?: (open: boolean) => void;
}

export const LocationsDropdownMenu = ({
    locations,
    styles,
    dropDownOpenHandler = () => {},
}: LocationsDropdownMenuProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const { t } = useTranslation();

    const activeLocations = locations.filter((location) => location.isActive);
    const inactiveLocations = locations.filter((location) => !location.isActive);

    const showDivider = activeLocations.length && inactiveLocations.length;

    useEffect(() => {
        dropDownOpenHandler(isOpen);
    }, [isOpen, dropDownOpenHandler]);

    return (
        <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)} className={styles.siteDropDown}>
            <DropdownToggle
                tag="h3"
                className={styles.ScrollToHeader}
                onClick={() => setIsOpen(!isOpen)}
                caret>
                {t('Select site')}
            </DropdownToggle>

            <DropdownMenu
                modifiers={{
                    setMaxHeight: {
                        enabled: true,
                        order: 890,
                        fn: (data) => {
                            return {
                                ...data,
                                styles: {
                                    ...data.styles,
                                    overflow: 'auto',
                                    maxHeight: '20rem',
                                },
                            };
                        },
                    },
                }}
                className={styles.dropdownMenu}>
                <LocationDropdownItems
                    header="Active locations"
                    locations={activeLocations}
                    closeDropdown={() => setIsOpen(false)}
                    styles={styles}
                />
                {showDivider ? <DropdownItem divider /> : null}
                <LocationDropdownItems
                    header="Inactive locations"
                    locations={inactiveLocations}
                    closeDropdown={() => setIsOpen(false)}
                    styles={styles}
                />
            </DropdownMenu>
        </Dropdown>
    );
};
