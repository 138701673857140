import React from 'react';

import WeightDistributionHistogram from './WeightDistributionHistogram';
import WeightDistributionPlayControl from './WeightDistributionPlayControl';

function WeightDistributionHistogramPlayer() {
    return (
        <>
            <div>
                <WeightDistributionHistogram />
            </div>
            <div>
                <WeightDistributionPlayControl />
            </div>
        </>
    );
}

export default WeightDistributionHistogramPlayer;
