import React from 'react';

import styles from './legend.module.scss';

const Row = ({ color, label, count }) => {
    const row_style = {
        backgroundColor: color,
    };
    return (
        <tr className={styles.legendRow}>
            <td className="pr-3">
                <div className={styles.legendColorIndicator} style={row_style} />
            </td>
            <td className="pr-3">{label}</td>
            <td className="text-right">{count}%</td>
        </tr>
    );
};

export default ({ relativeCounts = [], colors = [], labels = [] }) => {
    return (
        <div className=" d-flex justify-content-center">
            <table className={styles.legend}>
                <tbody>
                    {relativeCounts.map((c, index) => {
                        if (Number.isNaN(c)) {
                            return null; // we need to keep the index
                        }
                        return (
                            <Row
                                key={index}
                                color={colors[index]}
                                label={labels[index]}
                                count={c}
                            />
                        );
                    })}
                </tbody>
            </table>
        </div>
    );
};
