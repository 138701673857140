import formattedNumber from './formattedNumber';

const DEFAULT_OPTS = {
    separator: ', ',
    ifBad: '-',
    metricSeparator: ' ',
    noMetricIfBad: false,
    isFraction: false,
};

// formattedNumbers([[1, 'kg'], [2, 'g'], NaN, null, 3]) -> 1 kg, 2 g, -, -, 3
// const n = formattedNumber(1); // after this n is ok number of NaN
// if (n === null || n === und.... // you do not need to do this anymore
// return <KeyNumber value={n} metric='kg' ifBad='-' />
function formattedNumbers(
    values: number | (number | [number, string])[],
    opts: Partial<typeof DEFAULT_OPTS> = {}
) {
    if (!Array.isArray(values)) values = [values];
    opts = { ...DEFAULT_OPTS, ...opts };

    return values
        .map((vm) => {
            const vmAsArray = Array.isArray(vm) ? vm : [vm];
            const [value, metric] = vmAsArray;
            const formattedValue = formattedNumber(value);
            const valueWithFractionalDigits = opts.isFraction
                ? formattedNumber(value).toLocaleString(undefined, {
                      maximumFractionDigits: 1,
                      minimumFractionDigits: 1,
                  })
                : value;
            if (Number.isNaN(formattedValue) && !opts.noMetricIfBad) return opts.ifBad;
            return [
                Number.isNaN(formattedValue) ? opts.ifBad : valueWithFractionalDigits,
                metric,
            ].join(opts.metricSeparator);
        })
        .join(opts.separator);
}

export default formattedNumbers;
