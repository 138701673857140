import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import classnames from 'classnames';

import { Button } from '@/components/atoms/Buttons/Button';
import { ShareIcon } from '@/components/atoms/icons/ShareIcon';
import OptoTooltip from '@/components/atoms/OptoTooltip';

import styles from './ShareImage.module.scss';

export interface ShareImageProps {
    permalink?: string;
    scpCommand?: string;
    isOptoscaleAdmin?: boolean;
}

const ShareImage = ({
    permalink = '',
    scpCommand = '',
    isOptoscaleAdmin = false,
}: ShareImageProps) => {
    const { t } = useTranslation();
    const [dropdownOpen, setDropdownOpen] = useState(false);
    const toggle = () => setDropdownOpen((prevState) => !prevState);

    const copyToClipboardHandler = (text: string) => {
        navigator.clipboard.writeText(text).then(
            () => {
                console.log('Copied to clipboard');
            },
            (err) => {
                console.error('Failed to copy to clipboard', err);
            }
        );
    };

    return (
        <div className={styles.shareimage}>
            <OptoTooltip content={t('Copy image url to this image')} nub={'up-right'}>
                <button name="sharebutton" type="button" onClick={toggle}>
                    <ShareIcon size={24} />
                </button>
            </OptoTooltip>
            <div className={classnames(styles.dropdownMenu, dropdownOpen ? styles.open : '')}>
                <fieldset>
                    <legend>{t('Copy image url to this image')}</legend>
                    <div data-type="formrow">
                        <input
                            type="text"
                            value={permalink}
                            readOnly
                            onClick={(e) => {
                                e.currentTarget.select();
                            }}
                        />
                        <Button
                            buttonType="button"
                            color="secondary"
                            onClick={() => {
                                copyToClipboardHandler(permalink);
                            }}>
                            {t('Copy')}
                        </Button>
                    </div>
                </fieldset>
                {isOptoscaleAdmin && scpCommand.length > 0 && (
                    <fieldset>
                        <legend>{t('Copy SCP command for image download')}</legend>
                        <div data-type="formrow">
                            <input
                                type="text"
                                value={scpCommand}
                                readOnly
                                onClick={(e) => {
                                    e.currentTarget.select();
                                }}
                            />
                            <Button
                                buttonType="button"
                                color="secondary"
                                onClick={() => {
                                    copyToClipboardHandler(scpCommand);
                                }}>
                                {t('Copy')}
                            </Button>
                        </div>
                    </fieldset>
                )}
            </div>
        </div>
    );
};

export default ShareImage;
export { ShareImage };
