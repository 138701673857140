import React from 'react';
import { useTranslation } from 'react-i18next';

import { Alert } from 'components/atoms/Alert';

interface NoDataProps {
    color?: string;
    customText?: string;
}

const NoData = ({ color, customText = 'No data' }: NoDataProps) => {
    const { t } = useTranslation();

    return <Alert color={color ?? 'warning'}>{t(customText)}</Alert>;
};

export default NoData;
