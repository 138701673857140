import React from 'react';
import { useTranslation } from 'react-i18next';
import formattedWeightUnit from 'utils/formattedWeightUnit';

import BlockTitle from '@/components/atoms/BlockTitle';
import UmerBox from '@/components/atoms/UmerBox';
import { KeyNumber, KeyNumberGroup } from 'components/Layout';

// reuse components
import KFactor from '../../Overview/components/KFactor';
import { useRestFormattedOverview } from '../formatted-overview-context';
import { useRestOverview } from '../overview-context';

function WeightAndGrowBox() {
    const { t } = useTranslation();
    const overview = useRestOverview();
    const { weightUnitTag } = overview ?? {};
    const formattedOverview = useRestFormattedOverview();
    const { weight, weightGrowth, kfactor, kfactorGrowth, expectedKFactor, cv, sgr, rgi } =
        formattedOverview?.data ?? {};

    const { value: scaledWeight, weightUnit: scaledWeightWeightUnit } = formattedWeightUnit(
        weight,
        weightUnitTag
    );
    return (
        <UmerBox data-tut="weightAndGrowth">
            <BlockTitle heading={t('Weight and growth in period')} />
            <KeyNumberGroup numberOfColumns={1}>
                <KeyNumber
                    label={t('Weight')}
                    metric={t(`weightUnitTag.${weightUnitTag}`)}
                    value={weight}
                    ifBad={'-'}
                    data-tut="weightAndGrowthWeight"
                    id="weightAndGrowthWeight"
                    tooltipText={t('descriptions.weightAndGrowthWeight')}
                />
                <KeyNumber
                    label={t('Growth')}
                    metric={`${t(`weightUnitTag.${weightUnitTag}`)}${t('/day')}`}
                    value={weightGrowth}
                    ifBad={'-'}
                    data-tut="weightAndGrowthGrowth"
                    tooltipText={t('descriptions.weightAndGrowthGrowth')}
                />
                <KeyNumber
                    label="CV"
                    metric="%"
                    value={cv}
                    ifBad={'-'}
                    data-tut="weightAndGrowthCV"
                    tooltipText={t('descriptions.weightAndGrowthCV')}
                />
                <KeyNumber
                    label="SGR"
                    metric="%"
                    value={sgr}
                    ifBad={'-'}
                    data-tut="weightAndGrowthSGR"
                    tooltipText={t('descriptions.weightAndGrowthSGR')}
                />
                <KeyNumber
                    id="rgiKeyNumber"
                    label="RGI"
                    value={rgi}
                    ifBad={'-'}
                    data-tut="weightAndGrowthRGI"
                    tooltipText={t('descriptions.weightAndGrowthRGI')}
                />
                <KFactor
                    kfactor={kfactor}
                    kfactorGrowth={kfactorGrowth}
                    expectedKFactor={expectedKFactor}
                    weight={scaledWeight}
                    weightUnit={t(`weightUnit.${scaledWeightWeightUnit}`)}
                    tooltipText={t('descriptions.weightAndGrowthK')}
                />
            </KeyNumberGroup>
        </UmerBox>
    );
}

export default WeightAndGrowBox;
