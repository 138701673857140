import queryString from 'query-string';
import isObject from 'utils/isObject';

// const xxx = mergeQueryParams('?x=1&y=2', {x: null, z: 3}); => {y: 2, z: 3}
// null/undefined will remove, do queryString.format(xxx) for string
function mergeQueryParams(...params) {
    // lets support both object and string params
    const normalized = params.reduce((acc, param) => {
        // if we encounter param as pure null/undefined we unset all params
        if (param === null || param === undefined) return {};

        if (isObject(param)) return { ...acc, ...param };
        return { ...acc, ...queryString.parse(String(param)) };
    }, {});

    return Object.entries(normalized).reduce((acc, [key, value]) => {
        if (value === null || value === undefined) {
            delete acc?.[key];
        } else {
            acc[key] = value;
        }
        return acc;
    }, {});
}

export default mergeQueryParams;
