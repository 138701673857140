import { getI18n } from 'react-i18next';
import {
    DISPLAY_DAY_FORMAT,
    DISPLAY_LONG_DAY_FORMAT,
    DISPLAY_WEEK_FORMAT,
    DISPLAY_WEEK_FORMAT_NO,
    TRANSLATE_THIS_LABEL,
} from 'utils/formattedDay';
import isNumberOk from 'utils/isNumberOk';
import toFixed from 'utils/toFixed';

export default () => {
    const i18n = getI18n();
    return {
        elements: {
            point: {
                radius: 1,
            },
        },
        tooltips: {
            mode: 'index',
            axis: 'x',
            intersect: false,
            callbacks: {
                title: (tooltipItem) => {
                    return i18n.language === 'nb'
                        ? TRANSLATE_THIS_LABEL(tooltipItem[0].xLabel)
                        : tooltipItem[0].xLabel;
                },
                label: (tooltipItem, data) => {
                    let label = data.datasets?.[tooltipItem.datasetIndex]?.label ?? '';

                    if (label) {
                        label += ': ';
                    }

                    const value = toFixed(tooltipItem.yLabel, 2);

                    if (!isNumberOk(value)) {
                        return label;
                    }

                    return `${label}${value}`;
                },
                beforeBody: (tooltipItem, data) => {
                    const datasetIndex = tooltipItem[0].datasetIndex;
                    const index = tooltipItem[0].index;
                    const pointBackgroundColor =
                        data.datasets[datasetIndex].pointBackgroundColor[index];
                    const msg =
                        pointBackgroundColor === '#A9A9A9'
                            ? `${'⚠️'} ${i18n.t('Less than 100 fish counted')}`
                            : '';
                    return msg;
                },
            },
        },
        hover: {
            mode: 'index',
            axis: 'x',
            intersect: false,
        },
        scales: {
            xAxes: [
                {
                    type: 'time',
                    time: {
                        tooltipFormat: DISPLAY_LONG_DAY_FORMAT,
                        displayFormats: {
                            day: DISPLAY_DAY_FORMAT,
                            week:
                                i18n.language === 'nb'
                                    ? DISPLAY_WEEK_FORMAT_NO
                                    : DISPLAY_WEEK_FORMAT,
                        },
                    },
                    ticks: {
                        callback: (label) =>
                            i18n.language === 'nb' ? TRANSLATE_THIS_LABEL(label) : label,
                    },
                },
            ],
            yAxes: [
                {
                    id: 'y-axis-left',
                    scaleLabel: {
                        display: true,
                        labelString: i18n.t('Lice count'),
                        fontSize: 20,
                    },
                    ticks: {
                        callback: (value) => value,
                        beginAtZero: true,
                    },
                },
                {
                    id: 'y-axis-right',
                    position: 'right',
                    gridLines: {
                        color: 'rgba(0, 0, 0, 0)',
                    },
                    scaleLabel: {
                        display: true,
                        labelString: i18n.t('Temperature'),
                        fontSize: 20,
                    },
                    ticks: {
                        max: 25,
                        min: 0,
                        callback: (value) => `${value}°C`,
                    },
                },
            ],
        },
    };
};
