import { getI18n } from 'react-i18next';
import { TRANSLATE_THIS_LABEL } from 'utils/formattedDay';
import isNumberOk from 'utils/isNumberOk';
import toFixed from 'utils/toFixed';
export default (showYLabel = true, maintainAspectRatio = true) => {
    const i18n = getI18n();
    return {
        maintainAspectRatio,
        animation: false,
        elements: {
            line: {
                tension: 0,
            },
        },
        hover: {
            mode: 'index',
            intersect: false,
        },
        plugins: {
            zoom: {
                zoom: {
                    enabled: true,
                    drag: {
                        backgroundColor: 'rgba(47, 131, 123, 0.2)',
                    },
                    mode: 'x',
                    onZoomComplete: ({ chart }) => {
                        chart.update(0);
                    },
                },
            },
            datalabels: {
                anchor: 'end',
                align: 'top',
                formatter: (value) => {
                    return value.y;
                },
                font: {
                    weight: 'bold',
                },
            },
        },
        scales: {
            xAxes: [
                {
                    type: 'time',
                    time: {
                        tooltipFormat: 'MMMM D, YYYY',
                        displayFormats: {
                            week: 'YYYY / w',
                        },
                    },
                    offset: true,
                },
            ],
            yAxes: [
                {
                    scaleLabel: {
                        display: showYLabel,
                        labelString: i18n.t('Lice per fish'),
                        fontSize: 20,
                    },
                    ticks: {
                        callback: (label) => `${label}`,
                        min: 0,
                        suggestedMax: 0.5,
                    },
                },
            ],
        },
        tooltips: {
            mode: 'index',
            intersect: false,
            callbacks: {
                title: (tooltipItem) => {
                    return i18n.language === 'nb'
                        ? TRANSLATE_THIS_LABEL(tooltipItem[0].xLabel)
                        : tooltipItem[0].xLabel;
                },
                label: (tooltipItem, data) => {
                    let label = data.datasets?.[tooltipItem.datasetIndex]?.label ?? '';
                    if (label) {
                        label += ': ';
                    }
                    const value = toFixed(tooltipItem.yLabel, 2);
                    if (!isNumberOk(value)) {
                        return label;
                    }
                    return `${label}${value}`;
                },
            },
        },
    };
};
