import React, { useCallback } from 'react';
import { useBioscopeImageController_GetImageList } from 'services/hooks';
import { BioscopeDetail } from 'services/types';

import { useMe } from '@/contexts/meContext';
import { bioscopeImageMapper, useImageState } from '@/hooks/image-state';
import { placeHolder } from '@/services/config';
import Loader from 'components/atoms/Loader';
import { Page } from 'components/Layout';
import { ImageViewContainer } from 'components/organisms/ImageViewer';

const first = 5;
const threshold = 4;

interface BioscopeImageCardContainerProps {
    bioscope: BioscopeDetail;
}

const BioscopeImageCardContainer = ({ bioscope }: BioscopeImageCardContainerProps) => {
    const bioscopeImages = useBioscopeImageController_GetImageList(bioscope.id, {
        placeholderData: placeHolder<[]>([]),
    });

    const { isOptoscaleAdmin } = useMe();

    const getBioscopeCageId = () => {
        if (!bioscope) return null;
        if (bioscope?.lastLocation?.cage?.id) {
            return bioscope.lastLocation.cage.id;
        }
        return bioscope.locations.find((l) => l.cages)?.cages.id ?? null;
    };

    const bioscopeImageState = useImageState({
        timezone: bioscope?.lastLocation?.cage?.location?.timezone ?? 'Europe/Oslo',
        loading: bioscopeImages.isLoading,
        data: bioscopeImages.data?.data,
        mapper: useCallback(bioscopeImageMapper(getBioscopeCageId()), [bioscope.id]),
        queryParam: 'bioscopeImageId',
    });
    return (
        <Loader loading={bioscopeImages.isLoading}>
            <Page title="Bioscope Images">
                <ImageViewContainer
                    key="bioscope"
                    instanceKey="bioscope"
                    isColor={false}
                    viewerConfig={{
                        showMagnifyer: false,
                        scrollActivated: true,
                        showPois: false,
                        zoomDisabled: true,
                        magnifyerDisabled: true,
                        showWoundLabel: false,
                        showHelpText: false,
                    }}
                    isOptoScaleAdmin={isOptoscaleAdmin}
                    {...bioscopeImageState}
                />
            </Page>
        </Loader>
    );
};

export default BioscopeImageCardContainer;
export { BioscopeImageCardContainer };
