import { useCallback, useEffect, useState } from 'react';

import { LeftColumn, Page, PageContent } from 'components/Layout';
import { Link } from 'react-router-dom';
import { NavbarBrand } from 'reactstrap';
import CageFilterCompare from '../../Cage2/components/CageFilterCompare';

import CompareContent from './CompareContent';
import styles from './Compare.module.scss';

const getUrlParameters = (search: string) => {
    const params = new URLSearchParams(search);
    const dataParam = params.get('data');
    if (!dataParam) {
        return [];
    }
    const dataArray = dataParam.split(',');
    const result = dataArray.reduce((result, item) => {
        const [key, value] = item.split('~');
        result[value] = key;
        return result;
    }, {});
    return result;
};

const Compare = () => {
    const [urlData, setUrlData] = useState({});
    const [submittedData, setSubmittedData] = useState<{
        from?: string;
        to?: string;
        daypartTag?: string;
    }>(null);

    useEffect(() => {
        const url = window.location.search;
        setUrlData(getUrlParameters(url));
    }, []);
    const handleFormSubmit = useCallback((from: string, to: string, daypartTag: string) => {
        setSubmittedData({ from, to, daypartTag });
    }, []);
    return (
        <Page title={'Compare'}>
            <LeftColumn>
                <nav className={styles.leftNavigationPanel}>
                    <NavbarBrand tag={Link} to="/">
                        <figure>
                            <img src="/logo.png" alt="" />
                        </figure>
                    </NavbarBrand>
                    <CageFilterCompare onFormSubmit={handleFormSubmit} />
                </nav>
            </LeftColumn>
            <PageContent>
                <CompareContent urlData={urlData} submittedData={submittedData} />
            </PageContent>
        </Page>
    );
};

export default Compare;
