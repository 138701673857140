import React, { useRef, useState } from 'react';
import { useResizeDetector } from 'react-resize-detector';

import styles from './KeyNumberSummarySection.module.scss';

export interface KeyNumberSummarySectionProps {
    children?: React.ReactNode;
    stackable?: boolean;
    gap?: boolean;
}

const KeyNumberSummarySection = ({
    children,
    stackable = false,
    gap = false,
}: KeyNumberSummarySectionProps) => {
    const ref = useRef(null);
    const [statckit, setStatckit] = useState(stackable);

    useResizeDetector({
        handleWidth: true,
        targetRef: ref,
        onResize: (width) => {
            const newStackState = stackable === true ? (width < 800 ? true : false) : false;
            setStatckit(newStackState);
        },
    });

    return (
        <div style={{ display: 'block' }}>
            {/* Need this for now because the context this component live in needs to be something other than flex */}
            <div
                data-stacked={statckit}
                data-gap={gap}
                className={styles.keynumbersummarysection}
                ref={ref}>
                {children}
            </div>
        </div>
    );
};

export default KeyNumberSummarySection;
export { KeyNumberSummarySection };
