import React from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
const Sentry = import('@sentry/browser');

export default class SentryErrorBoundary extends React.Component {
    constructor(props) {
        super(props);
        this.state = { error: null, eventId: null };
    }

    componentDidCatch(error, errorInfo) {
        this.setState({ error });

        Sentry.then((s) =>
            s.withScope((scope) => {
                scope.setExtras(errorInfo);
                const eventId = s.captureException(error);
                this.setState({ eventId });
            })
        );
    }

    render() {
        if (this.state.error) {
            //render fallback UI
            return (
                <Container>
                    <Row>
                        <Col className="text-center mt-5" md={{ size: 6, offset: 3 }}>
                            <h1>Something happened</h1>
                            <p>
                                Something happened while loading this page. You can provide us with
                                more information on what happened by clicking the button under. We
                                have already been notified and will fix the issue as soon as
                                possible.
                            </p>
                            <Button
                                onClick={async () =>
                                    (await Sentry).showReportDialog({
                                        eventId: this.state.eventId,
                                    })
                                }>
                                Report feedback
                            </Button>
                        </Col>
                    </Row>
                </Container>
            );
        }
        //when there's not an error, render children untouched
        return this.props.children;
    }
}
