import { useUserController_ResetPasswordRequest } from 'services/hooks';

import { ResetPasswordRequest } from './ResetPasswordRequest';

export const ResetPasswordRequestContainer = () => {
    const { mutateAsync: newPasswordRequest } = useUserController_ResetPasswordRequest();
    return (
        <ResetPasswordRequest
            newPasswordRequest={({ email }) => newPasswordRequest({ requestBody: { email } })}
        />
    );
};
